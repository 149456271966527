<div class="full-page custom-bg" fxLayoutAlign="center center">
    <mat-card class="login-card mat-elevation-z16"
        *ngIf="!showDBSelect && !resetPassword && !requestAccount && !enterOTP">
        <!-- <mat-card-title>
            Login to App
        </mat-card-title> -->
        <mat-card-content>

            <app-logo-circle></app-logo-circle>
            <div style="margin: 20px">
                <mat-form-field class="login-field" appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput placeholder="Email" [(ngModel)]="email" type="email" autocomplete="username"
                        required />
                </mat-form-field>
                <mat-form-field class="login-field" appearance="outline">
                    <mat-label>Password</mat-label>
                    <input matInput placeholder="Password" [(ngModel)]="password" [type]="inputType"
                        autocomplete="new-password" required />
                    <mat-icon matSuffix *ngIf="inputType == 'password'"
                        (click)="toggleInputType()">visibility</mat-icon>
                    <mat-icon matSuffix *ngIf="inputType != 'password'"
                        (click)="toggleInputType()">visibility_off</mat-icon>
                </mat-form-field>
                <p>
                    <a (click)="resetPassword = true" style="color: blue; text-decoration: underline;">Forgot
                        Password?</a>
                </p>
            </div>
        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button color="primary" class="login-field" id="login-btn" (click)="mongoLogin()"
                type="submit">
                Login
            </button>

            <!-- <button mat-raised-button color="primary" class="login-field" id="login-btn" (click)="workOSLogin()"
                type="submit">
                Login WorkOS
            </button> -->

        </mat-card-actions>
        <label style="position: absolute; left: 2rem; bottom: 4rem;">Version: {{appVersion}}</label>
    </mat-card>

    <!-- <iframe src="https://unassuming-legend-53-staging.authkit.app" frame-ancestors="none" style="position: fixed; width: 100vw; height: 100vh; z-index: 3011;"></iframe> -->

    <mat-card class="login-card mat-elevation-z16" *ngIf="showDBSelect">
        <!-- <mat-card-title>
            Select Client
        </mat-card-title> -->
        <mat-card-content>
            <app-logo-circle></app-logo-circle>
            <mat-form-field class="login-field" appearance="outline">
                <mat-label>Client</mat-label>
                <mat-select [(ngModel)]="selectedClient">
                    <mat-option *ngFor="let client of clients" [value]="client">{{client}}</mat-option>
                </mat-select>
            </mat-form-field>
        </mat-card-content>

        <mat-card-actions>
            <button mat-raised-button color="primary" class="login-field" id="login-btn" (click)="continue()"
                type="submit">
                Login
            </button>
        </mat-card-actions>
    </mat-card>
    <app-loader></app-loader>

    <app-password-reset (close)="resetPassword = false" *ngIf="resetPassword"></app-password-reset>

    <mat-card class="login-card mat-elevation-z16" *ngIf="requestAccount">
        <mat-card-content fxLayout="column" fxLayoutAlign="center center">
            <app-logo-circle></app-logo-circle>
            <h2>Please contact the site administrator to grant you access to a Prism account</h2>
        </mat-card-content>
        <mat-card-actions>
            <div fxLayout="row" fxLayoutAlign="space-evenly center">
                <button mat-raised-button color="primary" (click)="requestAccount = false">Back</button>
            </div>

        </mat-card-actions>
    </mat-card>

    <mat-card class="mat-elevation-z16" *ngIf="enterOTP">
        <h1>Authenticate Device</h1>
        <p>Enter the OTP sent to you via email:</p>
        <div fxLayout="column">
            <mat-form-field appearance="outline">
                <mat-label>OTP</mat-label>
                <input matInput [(ngModel)]="otp">
            </mat-form-field>
            <button mat-raised-button color="primary" (click)="authenticateDevice()"><mat-icon>check</mat-icon>
                Authenticate</button>
        </div>
    </mat-card>
</div>