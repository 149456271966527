<div fxFlex fxLayoutAlign="center center" fxLayout="column" style="overflow: hidden;">
    <!-- <mat-progress-bar style="position: absolute; top: 10vh;" mode="indeterminate"></mat-progress-bar> -->
    <app-loader *ngIf="loader.isLoading"></app-loader>

    <!-- Diagnostics Card -->
    <mat-card class="mat-elevation-z16" style="height: 75vh; width: 85vw;" fxLayout="column"
        fxLayoutAlign="space-evenly none" [ngClass]="{ 'nodisplay': showWebcam}">
        <button mat-mini-fab style="position: absolute;top: 8px;left: 8px; background-color: transparent;"
            (click)="close()">
            <mat-icon>arrow_back</mat-icon>
        </button>


        <mat-accordion style="max-height: 50vh; overflow: auto;">

            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{'app.diagnostics.deviceTests' | translate}}
                    </mat-panel-title>
                    <!-- <mat-panel-description>
                        Device Tests
                    </mat-panel-description> -->
                </mat-expansion-panel-header>
                <div fxLayout="row" fxLayoutAlign="space-evenly stretch">
                    <div matRipple (click)="testDoorSensor(); testedSensors = true;" class="block-button mat-elevation-z16" fxLayout="column"
                        fxLayoutAlign="center center">
                        <mat-spinner *ngIf="doorSensor.state == 3" class="button-spinner" diameter="25"></mat-spinner>


                        <!-- <mat-icon *ngIf="doorSensor.state != 3" [style.color]="stateStyle(doorSensor.state)">sensor_door
                        </mat-icon> -->

                        <div fxLayout="row" *ngIf="doorSensor.state != 3">
                            <div>
                                <div class="sensorState" [ngClass]="getSensorClass(sensor1)"></div>
                                <div class="innerContent">1</div>
                            </div>
                            <div [hidden]="sensor2=='not connected' || sensor2=='n/a'">
                                <div class="sensorState" [ngClass]="getSensorClass(sensor2)"></div>
                                <div class="innerContent">2</div>
                            </div>
                            <div [hidden]="sensor3=='not connected' || sensor3=='n/a'">
                                <div class="sensorState" [ngClass]="getSensorClass(sensor3)"></div>
                                <div class="innerContent">3</div>
                            </div>
                            <div [hidden]="sensor4=='not connected' || sensor4=='n/a'">
                                <div class="sensorState" [ngClass]="getSensorClass(sensor4)"></div>
                                <div class="innerContent">4</div>
                            </div>
                        </div>


                        <label>{{'app.diagnostics.doorSensor' | translate}}</label>

                        <mat-icon style="color: limegreen; position: absolute; right: -8px; bottom: -8px; font-size:medium;" *ngIf="testedSensors">check</mat-icon>
                        <mat-icon style="color: red; position: absolute; right: -8px; bottom: -8px; font-size:medium;" *ngIf="!testedSensors">cancel</mat-icon>

                    </div>



                    <div matRipple (click)="testTamper(); testedTamper = true" class="block-button mat-elevation-z16" fxLayout="column"
                        fxLayoutAlign="center center">
                        <mat-spinner *ngIf="tamper.state == 3" class="button-spinner" diameter="25"></mat-spinner>
                        <mat-icon *ngIf="tamper.state != 3" [style.color]="stateStyle(tamper.state)">do_not_touch
                        </mat-icon>
                        <label>{{'app.diagnostics.tamper' | translate}}</label>
                        <mat-icon style="color: limegreen; position: absolute; right: -8px; bottom: -8px; font-size:medium;" *ngIf="testedTamper">check</mat-icon>
                        <mat-icon style="color: red; position: absolute; right: -8px; bottom: -8px; font-size:medium;" *ngIf="!testedTamper">cancel</mat-icon>
                    </div>
                </div>

                <div fxLayout="row" fxLayoutAlign="space-evenly stretch">
                    <div matRipple (click)="testActuator(); testedActs = true;" class="block-button mat-elevation-z16" fxLayout="column"
                        fxLayoutAlign="center center">
                        <mat-spinner *ngIf="actuator.state == 3" class="button-spinner" diameter="25"></mat-spinner>

                        <!-- <mat-icon *ngIf="actuator.state != 3" [style.color]="stateStyle(actuator.state)">settings
                        </mat-icon> -->

                        <div fxLayout="row" *ngIf="actuator.state != 3">
                            <div>
                                <div class="sensorState" [ngClass]="getSensorClass(actuator1)"></div>
                                <div class="innerContent">1</div>
                            </div>
                            <div [hidden]="actuator2=='not connected'|| actuator2=='n/a'">
                                <div class="sensorState" [ngClass]="getSensorClass(actuator2)"></div>
                                <div class="innerContent">2</div>
                            </div>
                        </div>

                        <label>{{'app.diagnostics.actuator' | translate}}</label>
                        <mat-icon style="color: limegreen; position: absolute; right: -8px; bottom: -8px; font-size:medium;" *ngIf="testedActs">check</mat-icon>
                        <mat-icon style="color: red; position: absolute; right: -8px; bottom: -8px; font-size:medium;" *ngIf="!testedActs">cancel</mat-icon>
                    </div>
                    <div matRipple (click)="testPowerSupply(); testedPS = true;" class="block-button mat-elevation-z16" fxLayout="column"
                        fxLayoutAlign="center center">
                        <mat-spinner *ngIf="powerSupply.state == 3" class="button-spinner" diameter="25"></mat-spinner>
                        <mat-icon *ngIf="powerSupply.state != 3" [style.color]="stateStyle(powerSupply.state)">
                            electrical_services</mat-icon>
                        <label>{{'app.diagnostics.powerSupply' | translate}}</label>
                        <mat-icon style="color: limegreen; position: absolute; right: -8px; bottom: -8px; font-size:medium;" *ngIf="testedPS">check</mat-icon>
                        <mat-icon style="color: red; position: absolute; right: -8px; bottom: -8px; font-size:medium;" *ngIf="!testedPS">cancel</mat-icon>
                    </div>
                </div>

            </mat-expansion-panel>

            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{'app.diagnostics.siteImages' | translate}}
                    </mat-panel-title>
                    <!-- <mat-panel-description>
                        Site Images
                    </mat-panel-description> -->
                </mat-expansion-panel-header>

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>Actuators</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div style="overflow-y: hidden; overflow-x: auto; max-width: 100%; height: fit-content; padding: 8px;"
                        fxLayout="row">
                        <div class="imgBox" *ngFor="let img of actuatorImages">

                            <img style="width: 90px; height:90px;" [src]="getImgSource(img)">
                            <div class="deletePill" fxLayoutAlign="center center" matRipple (click)="deleteImg(img)">
                                <mat-icon>delete</mat-icon></div>
                        </div>
                        <div class="imgBox" fxLayout="column" fxLayoutAlign="center center"
                            (click)="addNewImg('actuator')"><mat-icon>add</mat-icon></div>
                    </div>

                </mat-expansion-panel>

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>Sensors</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div style="overflow-y: hidden; overflow-x: auto; max-width: 100%; height: fit-content; padding: 8px;"
                        fxLayout="row">
                        <div class="imgBox" *ngFor="let img of sensorImages">

                            <img style="width: 90px; height:90px;" [src]="getImgSource(img)">
                            <div class="deletePill" fxLayoutAlign="center center" matRipple (click)="deleteImg(img)">
                                <mat-icon>delete</mat-icon></div>
                        </div>
                        <div class="imgBox" fxLayout="column" fxLayoutAlign="center center"
                            (click)="addNewImg('sensor')"><mat-icon>add</mat-icon></div>
                    </div>
                </mat-expansion-panel>

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>Tampers</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div style="overflow-y: hidden; overflow-x: auto; max-width: 100%; height: fit-content; padding: 8px;"
                        fxLayout="row">
                        <div class="imgBox" *ngFor="let img of tamperImages">

                            <img style="width: 90px; height:90px;" [src]="getImgSource(img)">
                            <div class="deletePill" fxLayoutAlign="center center" matRipple (click)="deleteImg(img)">
                                <mat-icon>delete</mat-icon></div>
                        </div>
                        <div class="imgBox" fxLayout="column" fxLayoutAlign="center center"
                            (click)="addNewImg('tamper')"><mat-icon>add</mat-icon></div>
                    </div>
                </mat-expansion-panel>

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>Power Supply</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div style="overflow-y: hidden; overflow-x: auto; max-width: 100%; height: fit-content; padding: 8px;"
                        fxLayout="row">
                        <div class="imgBox" *ngFor="let img of powerSupplyImages">

                            <img style="width: 90px; height:90px;" [src]="getImgSource(img)">
                            <div class="deletePill" fxLayoutAlign="center center" matRipple (click)="deleteImg(img)">
                                <mat-icon>delete</mat-icon></div>
                        </div>
                        <div class="imgBox" fxLayout="column" fxLayoutAlign="center center"
                            (click)="addNewImg('powersupply')"><mat-icon>add</mat-icon></div>
                    </div>
                </mat-expansion-panel>

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>Site</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div style="overflow-y: hidden; overflow-x: auto; max-width: 100%; height: fit-content; padding: 8px;"
                        fxLayout="row">
                        <div class="imgBox" *ngFor="let img of siteImages">
                            <img style="width: 90px; height:90px;" [src]="getImgSource(img)">
                            <div class="deletePill" fxLayoutAlign="center center" matRipple (click)="deleteImg(img)">
                                <mat-icon>delete</mat-icon></div>
                        </div>
                        <div class="imgBox" fxLayout="column" fxLayoutAlign="center center" (click)="addNewImg('site')">
                            <mat-icon>add</mat-icon></div>
                    </div>
                </mat-expansion-panel>


            </mat-expansion-panel>

            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{'app.diagnostics.installTools' | translate}}
                    </mat-panel-title>
                    <!-- <mat-panel-description>
                        Install Tools
                    </mat-panel-description> -->
                </mat-expansion-panel-header>

                <div fxLayout="row" fxLayoutAlign="space-evenly stretch">
                    <div matRipple *ngIf="!isAligning" (click)="startSensorAlign()"
                        class="block-button mat-elevation-z16" fxLayout="column" fxLayoutAlign="center center">
                        <mat-icon style="color:white">vertical_align_center
                        </mat-icon>
                        <label>{{'app.diagnostics.sensorAlign' | translate}}</label>
                    </div>

                    <div matRipple *ngIf="isAligning" (click)="stopSensorAlign()" class="block-button mat-elevation-z16"
                        fxLayout="column" fxLayoutAlign="center center">
                        <mat-icon style="color:lightgreen">vertical_align_center
                        </mat-icon>
                        <label>{{'app.diagnostics.sensorAlign' | translate}}</label>
                    </div>

                    <!-- <button mat-raised-button style="width: 80%; margin: 4px;" color="primary" (click)="this.myContent = '0x02'; sendContent()"><mat-icon>badge</mat-icon> Get Site ID</button> -->

                    <div matRipple (click)="getSiteID()" class="block-button mat-elevation-z16" fxLayout="column"
                        fxLayoutAlign="center center">
                        <mat-icon>badge</mat-icon>
                        <label>Get Site ID</label>
                        <!-- <label>{{'app.diagnostics.sensorAlign' | translate}}</label> -->
                    </div>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel *ngIf="showCustomButtons">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Custom Funcs
                    </mat-panel-title>
                    <!-- <mat-panel-description>
                        Install Tools
                    </mat-panel-description> -->
                </mat-expansion-panel-header>

                <div style="height: 30vh; max-height: 30vh; overflow-y: auto;">
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <button mat-raised-button color="primary" style="width:50vw;"
                            (click)="action.emit(debugToken)">DBT</button>

                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <!-- <button mat-raised-button color="primary" style="width:50vw;" (click)="action.emit('*;9')">Clear DBT</button> -->
                        <button mat-raised-button color="primary" style="width:50vw;"
                            (click)="action.emit('*;99')">Clear DBT</button>

                    </div>

                    <div *ngFor="let func of customFuncs" fxLayout="row" fxLayoutAlign="space-between center">
                        <button mat-raised-button color="primary" style="width:50vw;"
                            (click)="this.myContent = func.content; action.emit(func.content)">{{func.name}}</button>
                        <button mat-mini-fab (click)="removeCustomFunc(func)"
                            color="warn"><mat-icon>delete</mat-icon></button>
                    </div>
                </div>

                <button mat-mini-fab (click)="settingCustomFunction=true"
                    style="position: absolute; bottom: 0;right:0; background-color: lime;"><mat-icon>add</mat-icon></button>

            </mat-expansion-panel>
        </mat-accordion>


        <div fxLayout="column" fxLayoutAlign="center center">
            <!-- <button mat-raised-button color="primary" style="width: 80%;" (click)="checkReportReady()"> -->
            <!-- <button mat-raised-button color="primary" style="width: 80%;" (click)="generateReportS()">Test Report Send</button> -->
            <button mat-raised-button color="primary" style="width: 80%;" (click)="tryGenRep()">
                <mat-icon>picture_as_pdf</mat-icon>Generate Report
            </button>
            <!-- <button mat-raised-button color="warn" (click)="testDB()">Create DB</button> -->
            <div fxLayout="column">
                <label>{{siteIDFromUnit}}</label>
                <label *ngIf="temperature != undefined && temperature != ''">CPU {{temperature}}&deg;C</label>
            </div>
            
        </div>

        <a (click)="openHelp()" style="position: absolute; bottom: 0px; right: 0px; margin: 4px;">
            <mat-icon>help_outline</mat-icon>
        </a>


        <div class="customButton" matRipple (click)="checkCustomButtons()">

        </div>

    </mat-card>

    <!-- Camera Component -->

    <div [ngClass]="{ 'nodisplay': !showWebcam}"
        style="height: 300px; width: 350px; border: 2px solid orange; overflow: hidden;">
        <button mat-mini-fab style="position: absolute;top: 15vh;left: 8px;" (click)="showWebcam = false">
            <mat-icon>arrow_back</mat-icon>
        </button>
        <webcam [trigger]="triggerObservable" (imageCapture)="handleImage($event)"></webcam>
        <button mat-mini-fab style="position: absolute; bottom: 10vh; left: 50vw" (click)="takeSnapshot()">
            <mat-icon>camera</mat-icon>
        </button>
        <button mat-mini-fab style="position: absolute; bottom: 10vh; right: 10vw" (click)="onOffWebCame()">
            <mat-icon>flip_camera_android</mat-icon>
        </button>
    </div>

    <!-- Confirm Report without images -->
    <mat-card class="mat-elevation-z16" style="height: 30vh; width: 85vw; position: absolute; border: 2px solid grey; z-index: 3011"
        *ngIf="showImgWarning" fxLayout="column" fxLayoutAlign="space-evenly none">
        <div fxLayoutAlign="center center" fxLayout="column">
            <h1>
                <mat-icon style="color:orange">warning</mat-icon> {{'app.diagnostics.warning' | translate}} <mat-icon
                    style="color:orange">warning
                </mat-icon>
            </h1>
            <p>{{'app.diagnostics.reportWarning' | translate}}</p>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between center">
            <button style="width: 20vw;" mat-raised-button color="primary"
                (click)="showImgWarning = false; showMailReport = true; generateReport()">
                <mat-icon style="color:greenyellow">check</mat-icon> {{'app.diagnostics.yes' | translate}}
            </button>
            <button style="width: 20vw;" mat-raised-button color="warn"
                (click)="showImgWarning = false; showMailReport=false;">
                <mat-icon style="color:black">close</mat-icon> {{'app.diagnostics.no' | translate}}
            </button>
        </div>
    </mat-card>

    <!-- Mail Report -->
    <mat-card class="mat-elevation-z16" style="height: auto; width: 85vw; position: absolute; border: 2px solid grey; z-index: 3011"
        *ngIf="!showImgWarning && showMailReport" fxLayout="column" fxLayoutAlign="space-evenly none">
        <div fxLayoutAlign="center center" fxLayout="column">
            <h1>
                <mat-icon style="color:orange">info</mat-icon> {{'app.diagnostics.sendReport' | translate}} <mat-icon
                    style="color:orange">info
                </mat-icon>
            </h1>
            <p>{{'app.diagnostics.sendReportMessage' | translate}}</p>
        </div>

        <mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput [(ngModel)]="mailAddress">
        </mat-form-field>
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <button style="width: 25vw; margin: 8px;" mat-raised-button color="primary"
                (click)="showMailReport = false; mailReport()">
                <mat-icon style="color:greenyellow">check</mat-icon> {{'app.diagnostics.send' | translate}}
            </button>
            <button style="width: 25vw; margin: 8px" mat-raised-button color="warn" (click)="showMailReport = false; showReportUpdates = false;">
                <mat-icon style="color:black">close</mat-icon> {{'app.diagnostics.cancel' | translate}}
            </button>
        </div>
    </mat-card>
</div>

<mat-card *ngIf="settingCustomFunction"
    style="position: absolute; left: 0; right: 0; margin-left:auto; margin-right: auto; z-index: 1000;"
    fxLayout="column" fxLayoutAlign="space-evenly center">
    <mat-form-field>
        <mat-label>Function Name</mat-label>
        <input matInput [(ngModel)]="myCustomName">
    </mat-form-field>
    <mat-form-field>
        <mat-label>Function Content</mat-label>
        <input matInput [(ngModel)]="myCustomContent">
    </mat-form-field>
    <button mat-raised-button color="primary" (click)="addCustomFunc()"><mat-icon>save</mat-icon> Save</button>
    <button mat-mini-fab color="warn" style="position: absolute; top:0;right:0;"
        (click)="settingCustomFunction=false"><mat-icon>close</mat-icon></button>
</mat-card>

<div class="backdrop" *ngIf="showAskSubmit || showMailReport || showReportUpdates"></div>
<mat-card *ngIf="showAskSubmit" style="position: absolute; left: 0; right: 0; top:0; bottom:0; margin:auto; z-index: 3011; height:fit-content; width: 90vw; border: 1px solid white;"
fxLayout="column" fxLayoutAlign="space-evenly center">
    <h2>Do you wish to submit this lock for handover?</h2>

    <div fxLayout="row" fxLayoutAlign="space-evenly center" style="width: 100%">
        <button mat-raised-button color="primary" (click)="submitHOReq = true; tryStandDown(); showAskSubmit=false;"><mat-icon>check</mat-icon>Yes</button>
        <button mat-raised-button color="warn" (click)="submitHOReq = false; tryStandDown(); showAskSubmit=false;"><mat-icon>cancel</mat-icon>No</button>
    </div>
</mat-card>

<div class="updateReports" *ngIf="showReportUpdates">
    <ol>
        <li *ngIf="reportStep == 1">Trying Stand Down...</li>
        <li *ngIf="reportStep > 1 && sdSuccess" style="color: lime">Stand Down Complete</li>
        <li *ngIf="reportStep > 1 && !sdSuccess" style="color: red">Stand Down Failed</li>
        <li *ngIf="reportStep == 3">Generating Report...</li>
        <li *ngIf="reportStep == 4" style="color: orange">Report Generated, trying to upload...</li>
        <li *ngIf="reportStep ==5" style="color:lime">Report Uploaded</li>
        <li *ngIf="reportStep ==6">Mailing Report...</li>
        <li *ngIf="reportStep ==7" style="color:lime">Report Stored Locally</li>
    </ol>
</div>