<mat-sidenav-container>
  <mat-sidenav #drawer mode="over">
    <!-- This is the list of menu items when you press the hamburger menu button top left -->
    <mat-nav-list (click)="uniCounter = 0;">
      <a mat-list-item (click)="drawer.toggle(); uniCounter = 0;" [routerLink]="['page-one']">
        <mat-icon>home</mat-icon> {{'app.menu.home' | translate}}
      </a>
      <a mat-list-item (click)="drawer.toggle(); navToVKeys(); uniCounter = 0;" [disabled]="checkOnline()">
        <mat-icon>lock</mat-icon> {{'app.menu.vKeys' | translate}}
      </a>

      <a mat-list-item (click)="drawer.toggle(); uniCounter = 0;" [routerLink]="['page-two']">
        <mat-icon>bluetooth</mat-icon> {{'app.menu.controllerFunctions' | translate}}
      </a>

      <a mat-list-item *ngIf="sysadmin" (click)="drawer.toggle(); uniCounter = 0;" [routerLink]="['logs']">
        <mat-icon>manage_search</mat-icon> Logs
      </a>
      <a mat-list-item (click)="drawer.toggle(); uniCounter = 0;" [routerLink]="['admin-home']"
        [disabled]="checkOnline()">
        <mat-icon>edit</mat-icon> {{'app.menu.admin' | translate}}
      </a>
      <a mat-list-item (click)="drawer.toggle(); uniCounter = 0;" [routerLink]="['my-tokens']">
        <mat-icon>visibility</mat-icon> {{'app.menu.myKeys' | translate}}
      </a>
      <a mat-list-item (click)="drawer.toggle(); uniCounter = 0;" [routerLink]="['my-history']">
        <mat-icon>alarm</mat-icon> My History
      </a>
      <a mat-list-item (click)="drawer.toggle(); uniCounter = 0;" [routerLink]="['testing']"
        *ngIf="username == 'werner'">
        <mat-icon>alarm</mat-icon> Testing
      </a>
      <!-- <a mat-list-item (click)="drawer.toggle(); uniCounter = 0;" [routerLink]="['handovers']">
      <mat-icon>handshake</mat-icon> Handovers
    </a> -->
    <a mat-list-item (click)="drawer.toggle(); uniCounter = 0;" [routerLink]="['my-requests']">
    <mat-icon>compare_arrows</mat-icon> My Requests
  </a>

      <a mat-list-item (click)="drawer.toggle(); openManual(); uniCounter = 0;">
        <mat-icon>help_outline</mat-icon> {{'app.menu.help' | translate}}
      </a>
      <a mat-list-item (click)="drawer.toggle(); uniCounter = 0; logout();">
        <mat-icon>logout</mat-icon> {{'app.menu.logout' | translate}}
      </a>
    </mat-nav-list>

    <!-- <app-popup-menu></app-popup-menu> -->

    <div style="position: absolute; bottom: 5vh; left: 8px">
      <a [ngStyle]="selectedLang == 'en' ? {color: '#32CD32'} : {color: '#ffffff'}" (click)="setLang('en')">EN</a> | <a [style]="selectedLang == 'de' ? 'color: #32CD32' : 'color: #ffffff'" (click)="setLang('de')">DE</a>
    </div>

    <label style="position: absolute; bottom: 2vh; left: 8px" (click)="doUniCounter()">v{{appVersion}}
      ({{deployment}})</label>
    <img *ngIf="showUni" style="position: absolute; bottom: 2vh; right: 8px; width: 48px;"
      src="../../../assets/unicorn.png">
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="custom-bg" fxLayout=column fxLayoutAlign="space-between stretch" fxFlex=100>
      <mat-toolbar class="mat-elevation-z8" style="height: 10vh !important; z-index: 1000;">
        <button mat-icon-button (click)="drawer.toggle()">
          <mat-icon>menu</mat-icon>
        </button>
        <!-- PRISM logo top center for all but Neocom -->
        <span>
          <img src="../../../assets/prism.png"
            style="position: absolute;top: 8px; left: 43vw; width: 14vw; max-height: 8vh;" *ngIf="showEthele"
            (click)="checkLogo()">
          <img src="../../../assets/prism.png"
            style="position: absolute;top: 14px; right: 2vw; width: 10vw; max-height: 8vh;" *ngIf="showNeocom"
            (click)="checkLogo()">
          <img src="../../../assets/panda.png"
            style="position: absolute;top: 0px; left: 35vw; width: 30vw; max-height: 11vh;" *ngIf="showShon"
            (click)="checkLogo()">
          <img src="../../../assets/bean.png"
            style="position: absolute;top: 14px; left: 40vw; width: 20vw; max-height: 8vh;" *ngIf="showRyno"
            (click)="checkLogo()">
          <img src="../../../assets/skull.png"
            style="position: absolute;top: 14px; left: 40vw; width: 20vw; max-height: 8vh;" *ngIf="showWerner"
            (click)="checkLogo()">
          <img src="../../../assets/skull.png"
            style="position: absolute;top: 14px; left: 40vw; width: 20vw; max-height: 8vh;" *ngIf="showCoolLogo"
            (click)="checkLogo()">
        </span>

      </mat-toolbar>
      <div fxLayout="column" fxLayoutAlign="center center">
        <router-outlet></router-outlet>
      </div>
      <mat-toolbar class="mat-elevation-z8" style="height: 8vh !important; z-index: 1000;">
        <label
          style="position: absolute; right: 2vw; bottom: 3vh; font-size: smaller; color:#F37321">{{username}}</label>
        <label style="position: absolute; right: 2vw; bottom: 1vh; font-size: x-small; color:#F37321"
          [ngStyle]="{'color':getColor()}">{{admin}}</label>
        <mat-icon *ngIf="conn.online"
          style="color: limegreen; font-size: smaller; position: absolute; left: 10px;bottom:3vh;">wifi</mat-icon>
        <mat-icon *ngIf="!conn.online"
          style="color: red; font-size: smaller; position: absolute; left: 10px;bottom:3vh;">wifi_off</mat-icon>
        <label style="position: absolute; left: 2vw; bottom: 1vh; font-size: x-small; color:#F37321"><a
            (click)="checkSites()" [matTooltip]="sitesString">{{clientdb}}</a> </label>
        <label style="font-size: xx-small; position: absolute; bottom: 1vh; left: 43vw;">
          <!-- Bottom center Logo of ethele -->
          <img src="../../../assets/ethele-white.png" style="width: 14vw;">
          <!-- Logo in top right corner for Superfecta -->
          <img *ngIf="clientdb == 'superfecta'" src="../../../assets/clients/sft/sft_logo_1.png"
            style="position: fixed; left:72vw; top: 2vh; width: 25vw;">
          <!-- Logo in top center for Neocom -->
          <img *ngIf="clientdb == 'neocom'" src="../../../assets/clients/neocom/neocom-tp-1.png"
            style="position: fixed; left:43vw; top: 1vh; width: 14vw; max-height: 8vh;">
        </label>
      </mat-toolbar>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<!-- This is not used anymore -->
<mat-card *ngIf="showSites" style="position: absolute; left: 10vw; top: 10vh; z-index: 1000; width: 80vw;">
  <mat-card-title>Site List</mat-card-title>
  <mat-card-content>
    <div *ngFor="let site of siteList" fxLayout="column">
      <label>Site: {{site[1]}}</label>
      <label>ID: {{site[0]}}</label>
      <label>--------</label>
    </div>
  </mat-card-content>
  <mat-card-actions fxLayoutAlign="center center">
    <button mat-raised-button (click)="showSites = false" color="warn">Close</button>
  </mat-card-actions>
</mat-card>