<!-- <div fxFlex fxLayoutAlign="start start" fxLayout="column" style="width: 100vw; height: 82vh; position: relative; top:0; z-index: 0;"> -->
  <div fxFlex fxLayoutAlign="start start" fxLayout="column" >
  <app-loader></app-loader>
  <!-- <img src="../../../assets/prism-hi-res-correct.jpg" style="z-index: 5; position: absolute; top: 8vh; filter: grayscale(100%) blur(2px); object-fit: cover; height: 90vh; overflow: hidden;"> -->

  <!-- <div class="shimmer-effect"> -->

  <!-- <label class="shimmer-effect"
    style="left: 0;right:0;margin-left: auto; margin-right: auto; margin-top: 24px; font-size: 2rem; padding: 2px;">Welcome</label>
  <label class="shimmer-effect"
    style="left: 0;right:0;margin-left: auto; margin-right: auto; margin-top: 14px; font-size: 1.5rem">{{username}}</label>


  <div matRipple id="box1" class="block-button mat-elevation-z16" fxLayout="column" fxLayoutAlign="space-evenly center">
    <mat-icon style="color: white">lock</mat-icon>
    <label>User v-Keys</label>
  </div>

  <div matRipple id="box2" class="block-button mat-elevation-z16" fxLayout="column" fxLayoutAlign="space-evenly center">
    <mat-icon style="color: white">bluetooth_connected</mat-icon>
    <label>Controller Functions</label>
  </div>

  <div matRipple id="box3" class="block-button mat-elevation-z16" fxLayout="column" fxLayoutAlign="space-evenly center">
    <mat-icon style="color: white">edit</mat-icon>
    <label>Admin</label>
  </div>


  <div matRipple id="box4" class="block-button mat-elevation-z16" fxLayout="column" fxLayoutAlign="space-evenly center">
    <mat-icon style="color: white">visibility</mat-icon>
    <label>My v-Keys</label>
  </div>

</div> -->




<!-- </div> -->



  <mat-card class="mat-elevation-z16" style="height: 20vh; z-index:100; opacity: 0.9;">
    <mat-card-title class="shimmer-effect"> {{'app.welcometitle' | translate}} </mat-card-title>
    <mat-card-subtitle>{{username}}</mat-card-subtitle>
    <mat-card-content>
      <p>{{ 'app.welcome' | translate}}</p>
    </mat-card-content>
  </mat-card>

