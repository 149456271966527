import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth.service';
import { BiometricsService } from 'src/app/services/biometrics.service';
import { LoaderService } from 'src/app/services/loader.service';
import { LocalDatabaseService } from 'src/app/services/local-database.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { WebRequestsService } from 'src/app/services/web-requests.service';
var bcrypt = require('bcryptjs');

@Component({
  selector: 'app-get-system-tokens',
  templateUrl: './get-system-tokens.component.html',
  styleUrls: ['./get-system-tokens.component.scss']
})
export class GetSystemTokensComponent implements OnInit {

  @Output() close = new EventEmitter();
  public user = {}
  public email = ""
  public password = ""

  public db: any;

  public isLoggedIn: boolean = false;
  public tokensSet: boolean = false;
  public tokenSites: string[] = [];
  showEnterPwd = false;

  public configTemplatesList:any = [];


  constructor(private webReq: WebRequestsService,
    private auth: AuthService,
    public loader: LoaderService,
    public snackBar: MatSnackBar,
    public notifier: NotificationsService,
    public localDB: LocalDatabaseService, public translate: TranslateService,
    public biometrics: BiometricsService) { 
      translate.use(localStorage.getItem('lang')!);
    }

  ngOnInit(): void {
    this.localDB.dbConnect().then((res:any) => {
      this.db = res.result;
    })
    if (localStorage.getItem('isLoggedIn') == "true") {
      this.isLoggedIn = true;
    }
  }

  retrieveTokens() {
    var tokenList: any;
    this.webReq.getSystemTokens(localStorage.getItem('username')).subscribe((res: any) => {
      const uniqueSites = Array.from(new Set(res.tokens.map((item:any) => item.siteID)));
      this.getSiteConfigTemplates(uniqueSites);
      tokenList = res.tokens;
      tokenList.forEach((token: any) => {
        if (token.functionCode == 0) {
          this.tokenSites.push(token.sitename);
        }
      });

      this.tokenSites = Array.from(new Set(this.tokenSites));
      this.tokenSites.forEach(site => {
        localStorage.setItem("t_" + site + "_from", tokenList.filter((x: any) => x.sitename == site)[0].fromDate)
        localStorage.setItem("t_" + site + "_to", tokenList.filter((x: any) => x.sitename == site)[0].toDate)
      })
      if (tokenList.length > 0) {
        this.tokensSet = true;
        this.localDB.setAllTokens(this.db, tokenList).then(res => {
          this.loader.isLoading = false;

        });
      } else {
        this.tokensSet = false;
      }

      this.loader.isLoading = false;
    }, err => {
      this.notifier.openMessage('Connection error', 'error')
      this.loader.isLoading = false;
    });
  }

  getSiteConfigTemplates(uniqueSites: any) {
    this.webReq.getSiteConfigTemplates(uniqueSites).subscribe(res => {
      this.configTemplatesList = res;
      let currentConfigs:any = [];
      if(localStorage.getItem('configTemplates') != null && localStorage.getItem('configTemplates') != undefined) {
        let currentConfigsString:any = localStorage.getItem('configTemplates');
        currentConfigs = JSON.parse(currentConfigsString);
      }
      this.configTemplatesList.forEach((config:any) => {
        let found = false;
        currentConfigs.forEach((curConf:any)=> {
          if(curConf.siteID == config.siteID) {
            found = true;
          }
        })
        if(!found) {
          currentConfigs.push({
            siteID: config.siteID, 
            configString1: config.configTemplate.configString1, 
            configString2: config.configTemplate.configString2, 
            configString3: config.configTemplate.configString3, 
            ssid: config.configTemplate.ssid, 
            password: config.configTemplate.password
          })
        }
      })
      localStorage.setItem('configTemplates', JSON.stringify(currentConfigs));
    })
  }

  authenticate() {
    if(localStorage.getItem('authOnKey') == 'true') {
      var hasBiometrics = localStorage.getItem('hasBiometrics');
      if (hasBiometrics != null && hasBiometrics != undefined && hasBiometrics == 'true') {
        if (localStorage.getItem('userID') != null || localStorage.getItem('userID') != undefined) {
          this.biometrics.authenticateFinger().then(res => {
            if(res == true) { // Continue getting keys
              this.retrieveTokens();
            }
            else {
              this.notifier.openMessage('Failed to authenticate', 'error', 3);
            }
          })
        }
  
      } else {
        this.showEnterPwd = true;
      }
    } else {
      this.retrieveTokens();
    }
  }
  confirmPassword() {
    if (bcrypt.compareSync(this.password, localStorage.getItem('mop'))) { // Call this only if biometrics unavailable
   // Continue getting keys
   this.showEnterPwd = false;
   this.retrieveTokens();
  } else {
   this.notifier.openMessage('Failed to authenticate', 'error', 3);
 }
}


  closing() {
    this.close.emit();
  }
}
