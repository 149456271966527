<div fxFlex fxLayoutAlign="center center" fxLayout="column">
  <app-loader *ngIf="!isDiagnostics"></app-loader>

  <!-- The below UI is shown if a user must select the site they are trying to connect to -->
  <app-site-select (btSite)="siteSelected = true; checkSelectedDoor()" [currentState]="siteSelected"
    *ngIf="!siteSelected"></app-site-select>

  <!-- <app-pin-code [available]="pinAvailable" (normalOp)="authed = true" *ngIf="siteSelected && !isConnected && !authed"></app-pin-code> -->

  <app-testing (debuggerClosed)="isDebugging = false" *ngIf="isDebugging"></app-testing>

  <app-diagnostics (action)="doEventAction($event)" [testSubject]="testSubject" [siteIDFromUnit]="siteIDFromUnit"
    (reloadSubs)="recreateSubscriptions()" (closed)="writeAction('*;9');isDiagnostics = false"
    *ngIf="isDiagnostics"></app-diagnostics>

  <app-configuration (action)="doEventAction($event)" [confSubject]="confSubject" (reloadSubs)="recreateSubscriptions()"
    (closed)="writeAction('99'); isConfig = false; listenType = 0;" *ngIf="isConfig"></app-configuration>

  <!-- <mat-card *ngIf="siteSelected && !isDebugging && !isDiagnostics && !isConfig && authed" class="mat-elevation-z16" style="height: 75vh; width: 85vw;" fxLayoutAlign="space-evenly none" fxLayout="column"> -->
  <mat-card *ngIf="siteSelected && !isDebugging && !isDiagnostics && !isConfig" class="mat-elevation-z16"
    style="height: 75vh; width: 85vw;" fxLayoutAlign="space-evenly none" fxLayout="column">
    <mat-form-field *ngIf="btService.tokenSet == true" appearance="outline">
      <mat-label>Write Value</mat-label>
      <input matInput placeholder="Write Value" [(ngModel)]="testValue">
    </mat-form-field>



    <div matRipple style="position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%);"
      *ngIf="!isConnected" (click)="authenticate()" class="block-button mat-elevation-z16" fxLayout="column"
      fxLayoutAlign="center center">
      <!-- <mat-spinner *ngIf="doorSensor.state == 3" class="button-spinner" diameter="25"></mat-spinner> -->
      <mat-icon style="color: darkblue">bluetooth_connected</mat-icon>
      <label>{{'app.bt.connect' | translate}}</label>
    </div>

    <!-- =============================================================================================================================================== -->

    <!-- =============================== STATES =============================== -->

    <div class="feedbackIcon" *ngIf="feedbackLog.length > 0" style="position: absolute; left:0; right:0; top: 4px; margin-left: auto; margin-right: auto; width:fit-content"><mat-icon (click)="showFeedback()">feedback</mat-icon></div>

    <div fxLayout="row" fxLayoutAlign="space-evenly start" *ngIf="isConnected" style="position: absolute; left:0; right:0; top: 4px;" (click)="writeAction('?')">
      <div fxLayout="column" fxLayoutAlign="space-between center" style="position: absolute; left: 0;">
        <div style="position:absolute; left: 4px; top: 0">
          <label>Sensors</label>
        </div>
        <div style="position: absolute; left: 4px; top:16px">
          <div id="sensors" fxLayout="row">
            <div>
              <div class="sensorState" [ngClass]="getSensorClass(currentDoorState1)"></div>
              <div class="innerContent">1</div> 
            </div>

            <div>
              <div class="sensorState" [ngClass]="getSensorClass(currentDoorState2)"></div>
              <div class="innerContent">2</div> 
            </div>

            <div>
              <div class="sensorState" [ngClass]="getSensorClass(currentDoorState3)"></div>
              <div class="innerContent">3</div> 
            </div>

            <div>
              <div class="sensorState" [ngClass]="getSensorClass(currentDoorState4)"></div>
              <div class="innerContent">4</div> 
            </div>

          </div>
        </div>
      </div>

      <div fxLayout="column" fxLayoutAlign="space-evenly center" style="position: absolute; right: 0;">
        <div style="position: absolute; right: 4px; top:0">
          <label>Locks</label>
        </div>

        <div style="position: absolute; right: 4px; top:16px;">
          <div id="doors" fxLayout="row">

            <div>
              <div class="sensorState" [ngClass]="getSensorClass(currentLockState1)"></div>
              <div class="innerContent">1</div>
            </div>

            <div>
              <div class="sensorState" [ngClass]="getSensorClass(currentLockState2)"></div>
              <div class="innerContent">2</div>
            </div>

            <div hidden>
              <div class="sensorState" [ngClass]="getSensorClass(currentLockState3)"></div>
              <div class="innerContent">3</div>
            </div>

            <div hidden>
              <div class="sensorState" [ngClass]="getSensorClass(currentLockState4)"></div>
              <div class="innerContent">4</div>
            </div>
          </div>
        </div>

      </div>

    </div>

    <!-- ====================================================================== -->
    <mat-button-toggle-group *ngIf="isConnected" name="doorSelect" color="primary" (click)="writeAction('?')"
      style="width: fit-content; left:0; right: 0; margin-left: auto; margin-right:auto; margin-top: 16px;" [(ngModel)]="selectedDoor">
      <mat-button-toggle [value]="1" [disabled]="!hasDoor1"><img src="assets/door1t1.png"><mat-icon class="toggleButtonIcon" [ngStyle]="{'color': hasDoor1 ? 'lime' : 'red' }">{{getIcon('1')}}</mat-icon></mat-button-toggle>
      <mat-button-toggle [value]="2" [disabled]="!hasDoor2"><img src="assets/door2t1.png"><mat-icon class="toggleButtonIcon" [ngStyle]="{'color': hasDoor2 ? 'lime' : 'red' }">{{getIcon('2')}}</mat-icon></mat-button-toggle>
    </mat-button-toggle-group>
    <!-- <button (click)="changeSelectedDoor()">Change Door</button> -->
    <div fxLayout="row" fxLayoutAlign="space-evenly center">
      <!-- <div matRipple *ngIf="isConnected" (click)="writeAction('0')" class="block-button mat-elevation-z16" fxLayout="column" fxLayoutAlign="center center"> -->
      <div matRipple *ngIf="isConnected" (click)="tryMultiDoor('0')" class="block-button mat-elevation-z16"
        fxLayout="column" fxLayoutAlign="center center">
        <mat-icon style="color: orange">lock_open</mat-icon>
        <label>{{'app.bt.unlock' | translate}}</label>
      </div>



      <div matRipple *ngIf="isConnected && userType != 'basic'" (click)="ToggleLED()"
        class="block-button mat-elevation-z16" fxLayout="column" fxLayoutAlign="center center">
        <mat-icon>notifications_active</mat-icon>
        <label>{{'app.bt.buzz' | translate}}</label>
      </div>

    </div>

    <div fxLayout="row" fxLayoutAlign="space-evenly center">
      <div matRipple *ngIf="isConnected && userType != 'basic'" (click)="lastAction = '1'; tryMultiDoor('1')"
        class="block-button mat-elevation-z16" fxLayout="column" fxLayoutAlign="center center">
        <mat-icon style="color:lightgreen">lock</mat-icon>
        <label>{{'app.bt.lock' | translate}}</label>
      </div>
      <div matRipple *ngIf="isConnected && userType == 'basic'" (click)="tryDumbLock()"
        class="block-button mat-elevation-z16" fxLayout="column" fxLayoutAlign="center center">
        <mat-icon style="color:lightgreen">lock</mat-icon>
        <label>{{'app.bt.lock' | translate}}</label>
      </div>

      <div matRipple *ngIf="isConnected && userType != 'basic'" (click)="lastAction = '2'; tryMultiDoor('2')"
        class="block-button mat-elevation-z16" fxLayout="column" fxLayoutAlign="center center">
        <!-- <div matRipple *ngIf="isConnected && userType != 'basic'" (click)="lastAction = '2'; writeAction('2')" class="block-button mat-elevation-z16" fxLayout="column" fxLayoutAlign="center center"> -->
        <!-- <mat-spinner *ngIf="doorSensor.state == 3" class="button-spinner" diameter="25"></mat-spinner> -->
        <mat-icon style="color:yellow">gpp_good</mat-icon>
        <label>{{'app.bt.sd' | translate}}</label>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-evenly center" *ngIf="userType != 'basic'">
      <div matRipple *ngIf="isConnected" (click)="tryConfig()" class="block-button mat-elevation-z16" fxLayout="column"
        fxLayoutAlign="center center">
        <!-- <mat-spinner *ngIf="doorSensor.state == 3" class="button-spinner" diameter="25"></mat-spinner> -->
        <mat-icon [ngStyle]="{'color': getConfigAvailable()}">settings</mat-icon>
        <label>{{'app.bt.config' | translate}}</label>
      </div>

      <div matRipple *ngIf="isConnected && userType != 'basic'" (click)="enterDiagnostics()"
        class="block-button mat-elevation-z16" fxLayout="column" fxLayoutAlign="center center">
        <!-- <mat-spinner *ngIf="doorSensor.state == 3" class="button-spinner" diameter="25"></mat-spinner> -->
        <mat-icon style="color:orange">health_and_safety</mat-icon>
        <label>{{'app.bt.diagnostics' | translate}}</label>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-evenly center">
      <div matRipple *ngIf="isConnected && userType != 'basic'" (click)="tryDebug()"
        class="block-button mat-elevation-z16" fxLayout="column" fxLayoutAlign="center center">
        <!-- <mat-spinner *ngIf="doorSensor.state == 3" class="button-spinner" diameter="25"></mat-spinner> -->
        <mat-icon style="color:gray" [ngStyle]="{'color': getButtonAvailable()}">bug_report</mat-icon>
        <label>{{'app.bt.debug' | translate}}</label>
      </div>

      <div matRipple *ngIf="isConnected" (click)="StopBluetooth()" class="block-button mat-elevation-z16"
        fxLayout="column" fxLayoutAlign="center center">
        <!-- <mat-spinner *ngIf="doorSensor.state == 3" class="button-spinner" diameter="25"></mat-spinner> -->
        <mat-icon style="color:darkblue">bluetooth_disabled</mat-icon>
        <label>{{'app.bt.disconnect' | translate}}</label>
      </div>
    </div>

    <!-- =============================================================================================================================================== -->

    <!-- </div> -->
    <!-- </mat-card-content> -->
    <a style="position: absolute; bottom: 0px; left: 0px; margin: 4px;" #tooltip="matTooltip"
      [matTooltip]=getSelectedSite() matTooltipPosition="right" (click)="tooltip.toggle()">
      <mat-icon>info_outline</mat-icon></a>
    <a *ngIf="!isConnected" (click)="openHelp('3.1')"
      style="position: absolute; bottom: 0px; right: 0px; margin: 4px;"><mat-icon>help_outline</mat-icon></a>
    <a *ngIf="isConnected" (click)="openHelp('3.2')"
      style="position: absolute; bottom: 0px; right: 0px; margin: 4px;"><mat-icon>help_outline</mat-icon></a>
  </mat-card>

  <mat-card *ngIf="showEnterPwd" style="z-index: 2003; position: absolute; border: 1px solid white;" fxLayout="column">
    <h2>Enter Password</h2>
    <mat-form-field>
      <mat-label>Password</mat-label>
      <input matInput [(ngModel)]="password" type="password">
    </mat-form-field>
    <div fxLayoutAlign="space-evenly center">
      <button mat-raised-button (click)="confirmPassword()" color="primary">Confirm</button>
      <button mat-raised-button (click)="showEnterPwd = false;" color="warn">Cancel</button>
    </div>
  </mat-card>


  <div *ngIf="showFirmwareAlert" class="backDrop"></div>
  <mat-card *ngIf="showFirmwareAlert" style="z-index: 2003; position: absolute; border: 1px solid white;"
    fxLayout="column">
    <div fxLayoutAlign="center center" fxLayout="column">
      <h1>
        <mat-icon style="color:orange">warning</mat-icon> Warning <mat-icon style="color:orange">warning
        </mat-icon>
      </h1>
      <p>This unit is on an old version of firmware. Please use the OTA to update firmware to latest version. Click <a
          href="https://myprism.co.za/over-the-air-update-process/" target="_blank">here</a> to learn how. </p>

      <div fxLayoutAlign="space-evenly center">
        <!-- <button mat-raised-button (click)="confirmPassword()" color="primary">Confirm</button> -->
        <button mat-raised-button (click)="showFirmwareAlert = false;" color="warn">Close</button>
      </div>
    </div>
  </mat-card>

  <div *ngIf="showMultiDoorSelect" class="backDrop" (click)="showMultiDoorSelect = false"></div>
  <mat-card *ngIf="showMultiDoorSelect"
    style="position: absolute; left: 0; right: 0; top: 0; bottom: 0; margin: auto; height: fit-content; width: 90vw; border: 1px solid white; border-radius: 5px; z-index: 2003">
    <div *ngIf="showMultiUnlock && !showMultiStandDown" fxLayout="row" fxLayoutAlign="space-evenly center">
      <div matRipple *ngIf="isConnected" (click)="writeAction('0'); showMultiDoorSelect = false"
        class="block-button mat-elevation-z16" fxLayout="column" fxLayoutAlign="center center">
        <mat-icon style="color: orange">lock_open</mat-icon>
        <label>Unlock Door 1</label>
      </div>
      <div matRipple *ngIf="isConnected" (click)="writeAction('0_a'); showMultiDoorSelect = false"
        class="block-button mat-elevation-z16" fxLayout="column" fxLayoutAlign="center center">
        <mat-icon style="color: orange">lock_open</mat-icon>
        <label>Unlock Door 2</label>
      </div>

    </div>

    <div *ngIf="!showMultiUnlock && !showMultiStandDown" fxLayout="row" fxLayoutAlign="space-evenly center">
      <div matRipple *ngIf="isConnected" (click)="lastAction = '1'; writeAction('1'); showMultiDoorSelect = false"
        class="block-button mat-elevation-z16" fxLayout="column" fxLayoutAlign="center center">
        <mat-icon style="color:lightgreen">lock</mat-icon>
        <label>Lock Door 1</label>
      </div>
      <div matRipple *ngIf="isConnected" (click)="lastAction = '1'; writeAction('1_a'); showMultiDoorSelect = false"
        class="block-button mat-elevation-z16" fxLayout="column" fxLayoutAlign="center center">
        <mat-icon style="color:lightgreen">lock</mat-icon>
        <label>Lock Door 2</label>
      </div>
    </div>

    <div *ngIf="showMultiStandDown" fxLayout="row" fxLayoutAlign="space-evenly center">
      <div matRipple *ngIf="isConnected" (click)="lastAction = '1'; writeAction('2'); showMultiDoorSelect = false"
        class="block-button mat-elevation-z16" fxLayout="column" fxLayoutAlign="center center">
        <mat-icon style="color:lightgreen">gpp_good</mat-icon>
        <label>Stand Down Door 1</label>
      </div>
      <div matRipple *ngIf="isConnected" (click)="lastAction = '1'; writeAction('2_a'); showMultiDoorSelect = false"
        class="block-button mat-elevation-z16" fxLayout="column" fxLayoutAlign="center center">
        <mat-icon style="color:lightgreen">gpp_good</mat-icon>
        <label>Stand Down Door 2</label>
      </div>
    </div>


  </mat-card>


  <div class="backDrop" *ngIf="shouldShowFeedback" (click)="shouldShowFeedback = false;"></div>
  <mat-card *ngIf="shouldShowFeedback" style="width: 90vw; height: 60vh; position: absolute; left: 0; right: 0; top: 0; bottom: 0; margin: auto; z-index: 3011;">
    <mat-card-title>Feedback Log</mat-card-title>
    <mat-list style="height: 50vh; max-height: 50vh; overflow:auto;">
      <mat-list-item *ngFor="let item of feedbackLog">-- {{item}}</mat-list-item>
    </mat-list>

    <button mat-raised-button color="warn" (click)="feedbackLog = []"><mat-icon>cancel</mat-icon>Clear</button>
  </mat-card>
</div>