import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NotificationsService } from 'src/app/services/notifications.service';
import { WebRequestsService } from 'src/app/services/web-requests.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {

  public username: string = "";
  public email:string = "";

  @Output() close = new EventEmitter();

  constructor(public webReq: WebRequestsService, public notify: NotificationsService) { }

  ngOnInit(): void {
  }

  resetPassword() {
    let lat;
    let lon;
    navigator.geolocation.getCurrentPosition(res1 => {
      setTimeout(() => {
        navigator.geolocation.getCurrentPosition(res2 => {
          lat = res2.coords.latitude;
          lon = res2.coords.longitude;
          this.webReq.resetPassword({username: this.username, email: this.email, lat: lat, lon: lon}).subscribe(res => {
            if (res.success == true) {
              this.notify.openMessage('Check email to reset password','success', 3);
              this.closing();
            } else {
              this.notify.openMessage('Contact Administrator', 'error', 3);
            }
          });
        })
      },1000)
    })
   
  }
  closing() {
    this.close.emit('cancel');
  }

}
