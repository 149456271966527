'use strict';
const base64url = require('./base64url-arraybuffer');
const helpers = require('./helpers')

/* THIS IS A DEMO SAMPLE ONLY INTENDED FOR DEMONSTATION PURPOSES*/
/* DO NOT ATTEMPT USING THIS IN PRODUCTION */
/* FOR PROPER SERVER VERIFICATION VISIT: https://medium.com/@herrjemand/verifying-fido2-responses-4691288c8770 */
/* THIS IS A DEMO SAMPLE ONLY INTENDED FOR DEMONSTATION PURPOSES*/
/* DO NOT ATTEMPT USING THIS IN PRODUCTION */
/* FOR PROPER SERVER VERIFICATION VISIT: https://medium.com/@herrjemand/verifying-fido2-responses-4691288c8770 */
/* THIS IS A DEMO SAMPLE ONLY INTENDED FOR DEMONSTATION PURPOSES*/
/* DO NOT ATTEMPT USING THIS IN PRODUCTION */
/* FOR PROPER SERVER VERIFICATION VISIT: https://medium.com/@herrjemand/verifying-fido2-responses-4691288c8770 */
/* THIS IS A DEMO SAMPLE ONLY INTENDED FOR DEMONSTATION PURPOSES*/
/* DO NOT ATTEMPT USING THIS IN PRODUCTION */
/* FOR PROPER SERVER VERIFICATION VISIT: https://medium.com/@herrjemand/verifying-fido2-responses-4691288c8770 */
/* THIS IS A DEMO SAMPLE ONLY INTENDED FOR DEMONSTATION PURPOSES*/
/* DO NOT ATTEMPT USING THIS IN PRODUCTION */
/* FOR PROPER SERVER VERIFICATION VISIT: https://medium.com/@herrjemand/verifying-fido2-responses-4691288c8770 */
/* THIS IS A DEMO SAMPLE ONLY INTENDED FOR DEMONSTATION PURPOSES*/
/* DO NOT ATTEMPT USING THIS IN PRODUCTION */
/* FOR PROPER SERVER VERIFICATION VISIT: https://medium.com/@herrjemand/verifying-fido2-responses-4691288c8770 */

export let db = {
    'addUser': (username, struct) => {
        let userHandleToUsername = localStorage.getItem('userHandleToUsername');
        if (!userHandleToUsername)
            userHandleToUsername = '{}';

        userHandleToUsername = JSON.parse(userHandleToUsername);

        userHandleToUsername[struct.id] = username;

        localStorage.setItem(username, JSON.stringify(struct))
        localStorage.setItem('userHandleToUsername', JSON.stringify(userHandleToUsername))
    },
    'getUser': (username) => {
        let userJSON = localStorage.getItem(username);
        if (!userJSON)
            throw new Error(`Username "${username}" does not exist!`);

        return JSON.parse(userJSON)
    },

    'getUserByUserHandle': (userHandle) => {
        try {
            let userHandleToUsername = localStorage.getItem('userHandleToUsername');
            if (!userHandleToUsername)
                userHandleToUsername = '{}';

            userHandleToUsername = JSON.parse(userHandleToUsername);

            let username = userHandleToUsername[userHandle];

            let userJSON = localStorage.getItem(username);
            if (!userJSON)
                throw new Error(`Username "${username}" does not exist!`);

            return JSON.parse(userJSON)
        } catch (e) {
            return {}
        }
    },
    'userExists': (username) => {
        let userJSON = localStorage.getItem(username);
        if (!userJSON)
            return false

        return true
    },
    'updateUser': (username, struct) => {
        let userJSON = localStorage.getItem(username);
        if (!userJSON)
            throw new Error(`Username "${username}" does not exist!`);

        localStorage.setItem(username, JSON.stringify(struct))
    },
    'deleteUser': (username) => {
        localStorage.removeItem(username)
    }
}

let session = {};

/* Password section */
export let registerPassword = (payload) => {
    session = {};
    if (db.userExists(payload.username) && db.getUser(payload.username).registrationComplete)
        return Promise.reject({ 'status': 'failed', 'errorMessage': 'User already exists!' })

    db.deleteUser(payload.username)

    payload.id = base64url.encode(helpers.generateRandomBuffer(32));
    payload.credentials = [];

    db.addUser(payload.username, payload)

    session.username = payload.username;

    return Promise.resolve({ 'status': 'startFIDOEnrolment' })
}

export let loginPassword = (payload) => {
    if (!db.userExists(payload.username))
        return Promise.reject('Wrong username or password!');

    let user = db.getUser(payload.username);
    if (user.password !== payload.password)
        return Promise.reject('Wrong username or password!');

    session.username = payload.username;

    return Promise.resolve({ 'status': 'startFIDOAuthentication' })
}
/* Password section ends */

/* RK passwordless section */
export let startPasswordlessEnrolment = (payload) => {
    session = {};
    if (db.userExists(payload.username) && db.getUser(payload.username).registrationComplete)
        return Promise.reject({ 'status': 'failed', 'errorMessage': 'User already exists!' })

    db.deleteUser(payload.username)

    payload.id = base64url.encode(helpers.generateRandomBuffer(32));
    payload.credentials = [];

    db.addUser(payload.username, payload)

    session.username = payload.username;
    session.uv = true;

    return Promise.resolve({ 'status': 'startFIDOEnrolmentPasswordless' })
}

export let startUsernamelessEnrolment = (payload) => {
    session = {};
    if (db.userExists(payload.username) && db.getUser(payload.username).registrationComplete)
        return Promise.reject({ 'status': 'failed', 'errorMessage': 'User already exists!' })

    db.deleteUser(payload.username)

    payload.id = base64url.encode(generateRandomBuffer(32));
    payload.credentials = [];

    db.addUser(payload.username, payload)

    session.username = payload.username;
    session.rk = true;

    return Promise.resolve({ 'status': 'startFIDOEnrolmentRK' })
}

export let startAuthenticationPasswordless = (payload) => {
    if (!db.userExists(payload.username))
        return Promise.reject('Wrong username or password!');

    session.username = payload.username;
    session.uv = true;

    return Promise.resolve({ 'status': 'startFIDOAuthentication' })
}
/* RK passwordless section ends */

/* MakeCred sections */
export let getMakeCredentialChallenge = (options) => {
    // console.log(options)
    session.username = options.user.username;
    if (!session.username)
        return Promise.reject({ 'status': 'failed', 'errorMessage': 'Access denied!' })

    // let user = db.getUser(session.username);
    let user = options.user.username
    session.challenge = base64url.encode(helpers.generateRandomBuffer(32));

    var publicKey = {
        'challenge': session.challenge,

        'rp': {
            'name': 'Example Inc.'
        },

        'user': {
            'id': options.user.id,
            'name': options.user.username,
            'displayName': options.user.displayName
        },

        'pubKeyCredParams': [
            { 'type': 'public-key', 'alg': -7 },
            { 'type': 'public-key', 'alg': -35 },
            { 'type': 'public-key', 'alg': -36 },
            { 'type': 'public-key', 'alg': -257 },
            { 'type': 'public-key', 'alg': -258 },
            { 'type': 'public-key', 'alg': -259 },
            { 'type': 'public-key', 'alg': -37 },
            { 'type': 'public-key', 'alg': -38 },
            { 'type': 'public-key', 'alg': -39 },
            { 'type': 'public-key', 'alg': -8 }
        ],

        'status': 'ok'
    }

    if (options) {
        if (!publicKey.authenticatorSelection)
            publicKey.authenticatorSelection = {
                authenticatorAttachment: 'platform',
                userVerification: 'required',
            };

        if (options.attestation)
            publicKey.attestation = options.attestation;

        if (options.rpId)
            publicKey.rp.id = options.rpId;

        if (options.uv)
            publicKey.authenticatorSelection.userVerification = 'required';
    }

    if (session.rk) {
        if (!publicKey.authenticatorSelection)
            publicKey.authenticatorSelection = {
                authenticatorAttachment: 'platform',
                userVerification: 'required',
            };

        publicKey.authenticatorSelection.requireResidentKey = true;
    }
    console.log(publicKey)
    return Promise.resolve(publicKey)
}

export let makeCredentialResponse = (payload) => {
    if (!session.username)
        return Promise.reject({ 'status': 'failed', 'errorMessage': 'Access denied!' })

    let user = db.getUser(session.username);

    /* server processing and verifying response, blah blah blah */
    user.registrationComplete = true;
    user.credentials.push(payload.id);

    db.updateUser(session.username, user);

    session = {};

    return Promise.resolve({ 'status': 'ok' })
}
/* MakeCred Section Ends */

/* GetAssertion section */
export let getGetAssertionChallenge = () => {
    session.challenge = base64url.encode(helpers.generateRandomBuffer(32));

    var publicKey = {
        'challenge': session.challenge,
        'status': 'ok'
    }

    if (session.username) {
        let user = db.getUser(session.username);
        publicKey.allowCredentials = user.credentials.map((credId) => {
            return { 'type': 'public-key', 'id': credId }
        })
    }

    if (session.rk) {
        delete publicKey.allowCredentials
    }

    if (session.uv) {
        publicKey.userVerification = 'required';
    }

    return Promise.resolve(publicKey)
}

export let getAssertionResponse = (payload) => {
    if (!session.username && !db.getUserByUserHandle(payload.response.userHandle))
        return Promise.reject({ 'status': 'failed', 'errorMessage': 'Access denied!' })

    /* server processing and verifying response, blah blah blah */

    session = {};

    return Promise.resolve({ 'status': 'ok' })
}
/* GetAssertion ends */
