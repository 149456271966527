// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // baseUrl: "https://192.168.3.120:5000",
  // baseUrl: "https://783d08884886.sn.mynetname.net:32768",
 baseUrl: "https://783d08884886.sn.mynetname.net:32766",
  key: "18%KU4x!BbHh#b95",
  // baseUrl: "https://192.168.1.253:5001",
  // baseUrl: "https://192.168.2.106:5003",
  // baseUrl: "http://192.168.1.166:3001",

  icrypto: {
    baseUrl: "https://saw-mcaaa.mtn.co.za",
    username: "da5a73bf-851e-488b-9fbf-9187c68036e9",
    password: "z327gIjHOCtgkgzZycNN6S05Bj28K3cvVFxYmSqp"
  },
  googleMapKey: "AIzaSyDa4V2G8Dc1WhG0BRNaEfaXw0Po-n4AvB8",
  firebase: {
    apiKey: "AIzaSyAAFH-Kd0NGKuElADyiSNzGLUQ09pKpB9E",
  authDomain: "prism-v2-df011.firebaseapp.com",
  projectId: "prism-v2-df011",
  storageBucket: "prism-v2-df011.appspot.com",
  messagingSenderId: "894592159783",
  appId: "1:894592159783:web:eb81651f591d37f6957e02",
  measurementId: "G-Q2DGVCWQZ5",
  },
  pushVAPID: "BIwPhAY3ci94A_DClxBUliAbp4VLCSxtnC2ncTYbaorj3ncM5hPI6N7cWYadDJairTBXztOniTFHcEcQWsGFRzE"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
