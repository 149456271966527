import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WebRequestsService } from 'src/app/services/web-requests.service';

@Component({
  selector: 'app-alarms',
  templateUrl: './alarms.component.html',
  styleUrls: ['./alarms.component.scss']
})
export class AlarmsComponent implements OnInit {

  constructor(private myRoute: ActivatedRoute, private webReq: WebRequestsService, public router: Router) { }

  alarmUUID: any = "";
  clientdb: any =""; //added line
  type: any = "";
  sitename: any = "";
  siteID: any = "";
  time: any = "";
  acknowledged:boolean = false;
  acknowledgedBy: any = "";
  acknowledgedType: any = "";
  acknowledgedTime:any = "";
  
  ngOnInit(): void {
    this.alarmUUID=this.myRoute.snapshot.queryParamMap.get("alarmUUID")
    this.clientdb=this.myRoute.snapshot.queryParamMap.get("clientdb") //added line
    this.type=this.myRoute.snapshot.queryParamMap.get("type")
    this.sitename =this.myRoute.snapshot.queryParamMap.get("sitename")
    this.siteID = this.myRoute.snapshot.queryParamMap.get("siteID")
    this.time = this.myRoute.snapshot.queryParamMap.get("time")
    this.webReq.getAlarm(this.alarmUUID).subscribe(result => {
      if(result[0].acknowledged == 1) {
        this.acknowledged=true
        this.acknowledgedBy = result[0].acknowledgedBy
        this.acknowledgedType = result[0]._type;
        this.acknowledgedTime = result[0].acknowledgedTime
      }
    })
  }

  acknowledgeAlarm() {
    this.webReq.acknowledgeAlarm({alarm: {alarmUUID: this.alarmUUID}, username: localStorage.getItem('username'), clientdb: localStorage.getItem('clientdb')}).subscribe(result => {
      alert("Alarm Acknowledged");
      this.router.navigate(['/'])
    }, err => {
      alert("Could not acknowledge alarm!")
    })
  }

  close() {
    this.router.navigate(['/'])
  }

}
