import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { BluetoothComponent } from './pages/bluetooth/bluetooth.component';
import { HomeComponent } from './pages/home/home.component';
// import { LoginComponent } from './login/loginOld.component';
import { SidenavnewComponent} from './components/sidenavnew/sidenavnew.component';
import { LoginComponent } from './pages/login/login.component';
import { SidenavnewModule } from './components/sidenavnew/sidenavnew.module';
import { TestingComponent } from './pages/testing/testing.component';
import { TokensComponent } from './pages/token-tools/tokens/tokens.component';
import { GuardsGuard } from './guards.guard';
import { AdminhomeComponent } from './pages/admin/adminhome/adminhome.component';
import { LogsComponent } from './pages/logs/logs.component';
import { LoginModule } from './pages/login/login.module';
import { GetSystemTokensComponent } from './pages/token-tools/get-system-tokens/get-system-tokens.component';
import { QrScannerComponent } from './components/qr-scanner/qr-scanner.component';
import {DiagnosticsComponent} from './pages/diagnostics/diagnostics.component';
import { TokenToolsComponent } from './pages/token-tools/token-tools.component';
import { MyTokensComponent } from './pages/my-tokens/my-tokens.component';
import { ConfigurationComponent } from './pages/configuration/configuration.component';
import { AlarmsComponent } from './components/alarms/alarms.component';
import { HandoverManagementComponent } from './pages/handover-management/handover-management.component';
import { MyHistoryComponent } from './pages/my-history/my-history.component';
import { MyRequestsComponent } from './pages/my-requests/my-requests.component';

const routes: Routes = [
  {
    path: 'login',
    // loadChildren: () => import('./pages/login/login.module').then(m=>m.LoginModule)
    component: LoginComponent
  },
  
  {
    path: '',
    component: SidenavnewComponent,
    // loadChildren: () => import('./components/sidenavnew/sidenavnew.module').then(m=>m.SidenavnewModule)
    children: [
      {
        path: '',
        component: HomeComponent
      },
      {
        path: 'page-one',
        component: HomeComponent
      },
      {
        path: 'page-two',
        component: BluetoothComponent
      },
      {
        path: 'page-three',
        component: LoginComponent
      },
      {
        path: 'testing',
        component: TestingComponent,
        loadChildren: () => import('./pages/testing/testing.module').then(m=>m.TestingModule)
      },
      {
        path: 'generate-tokens',
        component: TokensComponent,
        loadChildren: () => import('./pages/token-tools/tokens/tokens.module').then(m=>m.TokensModule)
      },
      {
        path: 'qr-scanner',
        component: QrScannerComponent
      },
      {
        path: 'get-system-tokens',
        component: GetSystemTokensComponent,
        loadChildren: () => import('./pages/token-tools/get-system-tokens/get-system-tokens.module').then(m=>m.GetSystemTokensModule)
      },
      {
        path: 'admin-home',
        component: AdminhomeComponent,
        loadChildren:()=>import('./pages/admin/adminhome/adminhome.module').then(m=>m.AdminhomeModule),
        // canActivate:[GuardsGuard]
      },
      {
        path: 'logs',
        component: LogsComponent,
        loadChildren:()=>import('./pages/logs/logs.module').then(m=>m.LogsModule)
      },
      {
        path: 'diagnostic',
        component:DiagnosticsComponent,
        loadChildren:()=>import('./pages/diagnostics/diagnostics.module').then(m=>m.DiagnosticsModule)
      },
      {
        path: 'token-tools',
        component: TokenToolsComponent,
        loadChildren:()=>import('./pages/token-tools/token-tools.module').then(m=>m.TokenToolsModule)
      },
      {
        path: 'my-tokens',
        component: MyTokensComponent,
      },
      {
        path: 'configuration',
        component: ConfigurationComponent
      },
      {
        path: 'handovers',
        component: HandoverManagementComponent
      },
      {
        path : 'alarms',
        component: AlarmsComponent,
        loadChildren: ()=>import('./components/alarms/alarms.module').then(m=>m.AlarmsModule)
      },
      {
        path: 'my-history',
        component: MyHistoryComponent
      },
      {
        path: 'my-requests',
        component: MyRequestsComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash:true}), SidenavnewModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
