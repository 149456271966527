import { Component, OnInit } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { BluetoothCore, BrowserWebBluetooth } from '@manekinekko/angular-web-bluetooth';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsComponent } from 'src/app/components/notifications/notifications.component';
import { LoaderService } from 'src/app/services/loader.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { WebRequestsService } from '../../services/web-requests.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  showMenu = false;
  
  sites = [];

  selectedSite:any;
  username:any = "";
  password:any;
  user:any;
  token:any = "";
  showToken = false;
  myCharacteristic:any;
  public counterValue:any;
  showCounter = false;
  testValue:string = "";

  param = {value: 'world'}

  constructor(private router: Router, 
    private webReq: WebRequestsService, 
    public blt: BrowserWebBluetooth, 
    public loader: LoaderService, 
    public snackBar: MatSnackBar,
    public notify: NotificationsService,
    public translate: TranslateService) {
      translate.setDefaultLang('en');
      translate.use(localStorage.getItem('lang')!);
     }

  ngOnInit(): void {
    // location.reload()
    this.username = localStorage.getItem('username');
    this.password = localStorage.getItem('password');
    this.user = localStorage.getItem('username');
    let isLoggedIn = localStorage.getItem('isLoggedIn'); 
    if(this.username==null  || this.username == undefined || isLoggedIn == null || isLoggedIn == undefined || isLoggedIn == 'false') {
      this.router.navigate(['/login']);
    }
    // this.getSites();
    this.counterValue = "Test";
    // this.loader.isLoading = true
  }

  getSites() {
    this.loader.isLoading = true;
    this.webReq.getSites(this.user).subscribe(data => {
      this.sites = data
      this.loader.isLoading = false;
    }, err => {
      this.loader.isLoading = false;
      this.notify.openMessage('Failed to get sites', 'error')
    });
  }

  generateToken() {
    var userToken = {
      'username':this.username, 'password':this.password, 'siteID':this.selectedSite[0]
    }

    //Fix this
    this.webReq.generateToken(this.selectedSite, 0, [0,1,2]).subscribe(data => {
      this.token = data.token;
      this.showToken = true;
      localStorage.setItem('token', this.token)
      this.router.navigate(['sidenavnew/page-two'])
    });
  }

  SelectSite() {
    
    localStorage.setItem('siteID', this.selectedSite[0])
    this.router.navigate(['sidenavnew/page-two'])
  }

  getClientID() {
    this.webReq.getClients(this.user).subscribe(res => {
    });
  }
}
