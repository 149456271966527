<div fxFlex fxLayoutAlign="center center" fxLayout="column">
    <app-loader></app-loader>
    <mat-card style="height: 75vh; width: 90vw;">
        <mat-tab-group>

            <mat-tab label="Bluetooth">

                <div fxLayout="column" fxLayoutAlign="center center" *ngIf="!showLogs">
                    <div fxLayout="row" fxLayoutAlign="space-evenly center">
                        <mat-form-field style="width: 40vw;" appearance="outline" >
                            <mat-label>From:</mat-label>
                            <input matInput type="date" [(ngModel)]="from">
                        </mat-form-field>
                        <mat-form-field style="width: 40vw;" appearance="outline" >
                            <mat-label>To:</mat-label>
                            <input matInput type="date" [(ngModel)]="to">
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-evenly center">
                        <mat-form-field appearance="outline" >
                            <mat-label>User:</mat-label>
                            <mat-select [(ngModel)]="user">
                                <mat-option *ngFor="let myuser of users" [value]="myuser.username">{{myuser.username}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-evenly center">
                        <button mat-raised-button (click)="findLogs(from, to, user);" color="primary">Show Logs</button>
                    </div>

                </div>
        
                <mat-form-field *ngIf="showLogs" style="width: 85vw;" appearance="fill">
                    <mat-label>BT Log</mat-label>
                    <textarea matInput [value]=logs style="height: 60vh;"></textarea>
                </mat-form-field>

            </mat-tab>

            <mat-tab label="Success">
        
                <mat-form-field style="width: 85vw;" appearance="fill">
                    <mat-label>Success Log</mat-label>
                    <textarea matInput [value]=successLogs style="height: 60vh;"></textarea>
                </mat-form-field>
        
            </mat-tab>
            <mat-tab label="Error">
        
                <mat-form-field style="width: 85vw;" appearance="fill">
                    <mat-label>Error Log</mat-label>
                    <textarea matInput [value]=errLogs style="height: 60vh;"></textarea>
                </mat-form-field>
        
            </mat-tab>
        </mat-tab-group>
    </mat-card>
</div>