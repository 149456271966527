import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BluetoothHelpersService {

  constructor() { }

  isLockStatus(content:any) {
    const regex = /\d\d;\d\d-\d\d;\d\d/; // Also add the old way
    const regexOld = /\d\d;\d\d/;
    const regexAllSensors = /\d\d;\d\d-\d\d;\d\d-\d\d;\d\d-\d\d;\d\d/

    if(content.match(regex) || content.match(regexOld) || content.match(regexAllSensors)) {
      return true;
    } else {
      return false;
    }
    // let lockResponses = ['15;20', '15;21', '15;30', '15;31', '16;20', '16;21', '16;30', '16;31','17;20', '17;21', '17;30', '17;31', '18;20', '18;21', '18;30', '18;31', '19;20', '19;21', '19;30', '19;31' ]
    
  }

  returnLockCode(code:any) {
    switch(code) {
      case '15': return 'unlocked';
      case '16': return 'locked';
      case '17' : return 'unknown';
      case '18': return 'fault';
      case '19': return 'not set';
      case '22': return 'not present';
      default: return 'not set';
    }
  }
  returnDoorCode(code:any) {
    switch(code) {
      case '20': return 'closed';
      case '21': return 'open';
      case '30': return 'closed';
      case '31': return 'open';
      case '22' : return 'not present';
      default: return 'not set';
    }
  }

  setLockState(state: any) { // Here we can also handle the old way of handling lock states

    let lockStates = {
      lock1:'unknown',
      door1: 'unknown',
      lock2:'not set',
      door2:'not set',
      lock3: 'not set',
      door3: 'not set',
      lock4: 'not set',
      door4: 'not set'
    }
    let stateList = state.split('-');
    if(stateList.length == 2) {
      lockStates.door2 = this.returnDoorCode(stateList[1].split(';')[1])
      lockStates.lock2 = this.returnLockCode(stateList[1].split(';')[0])
    } else if (stateList.length == 4) {
      // implement four param response
      lockStates.door2 = this.returnDoorCode(stateList[1].split(';')[1])
      lockStates.lock2 = this.returnLockCode(stateList[2].split(';')[0])
      lockStates.door3 = this.returnDoorCode(stateList[2].split(';')[1])
      // lockStates.lock3 = this.returnLockCode(stateList[2].split(';')[0])
      lockStates.door4 = this.returnDoorCode(stateList[3].split(';')[1])
      // lockStates.lock4 = this.returnLockCode(stateList[3].split(';')[0])
      
    }
    lockStates.lock1 = this.returnLockCode(stateList[0].split(';')[0])
    lockStates.door1 = this.returnDoorCode(stateList[0].split(';')[1])
    
    console.info(lockStates)
    return lockStates;
  }


  getDiagnosticResult(result:any) {
    console.warn("Checking Door sensor state: " + result)
    let sensorStates = {
      sensor1:'n/a',
      sensor2: 'n/a',
      sensor3:'n/a',
      sensor4:'n/a',
    }
    
    if((result&1) > 0) {
      sensorStates.sensor1 = 'open';
    } else {
      sensorStates.sensor1 = 'closed'
    }
    if((result&2) > 0) {
      sensorStates.sensor1 = 'n/a';
    }

    if((result&4) > 0) {
      sensorStates.sensor2 = 'open';
    } else {
      sensorStates.sensor2 = 'closed'
    }
    if((result&8) > 0) {
      sensorStates.sensor2 = 'n/a'
    }

    if((result&16) > 0) {
      sensorStates.sensor3 = 'open';
    } else {
      sensorStates.sensor3 = 'closed'
    }
    if((result&32) > 0) {
      sensorStates.sensor3 = 'n/a';
    }

    if((result&64) > 0) {
      sensorStates.sensor4 = 'open';
    } else {
      sensorStates.sensor4 = 'closed'
    }
    if((result&128) > 0) {
      sensorStates.sensor4 = 'n/a';
    }

    if((result&512) > 0) {
      sensorStates.sensor3 = 'closed';
      sensorStates.sensor4 = 'closed';
    }

    if((result&1024) > 0) {
      sensorStates.sensor1 = 'closed';
      sensorStates.sensor2 = 'closed';
    }

    if((result&2048) > 0) {
      sensorStates.sensor1 = 'open';
    } else {
      sensorStates.sensor1 = 'closed';
    }

    

    
  
    console.info(sensorStates);
    return sensorStates;
  }

  getActuatorResult(result:any) {
    let actResult = {
      actuator1: 'n/a',
      actuator2: 'n/a'
    }

    if((result&1) > 0) {
      actResult.actuator1 = 'fault'
    }
    if((result&2) > 0) {
      actResult.actuator2 = 'fault'
    }
    if((result&4) > 0) {
      actResult.actuator1 = 'good'
    }
    if((result&8) > 0) {
      actResult.actuator2 = 'good'
    }
    if((result&16) > 0) {
      actResult.actuator2 = 'n/a'
    }
    console.info(actResult)
    return actResult;

  }
}





 /*
          case '15;30': currentLockState = 'unlocked';
          currentDoorState = 'open';
          break;
        case '16;30': currentLockState = 'locked';
          currentDoorState = 'open';
          break;
        case '17;30': currentLockState = 'unknown';
          currentDoorState = 'open';
          break;
        case '18;30': currentLockState = 'fault';
          currentDoorState = 'open';
          break;
        case '19;30': currentLockState = 'not set';
          currentDoorState = 'open';
          break;
  
        case '15;31': currentLockState = 'unlocked';
          currentDoorState = 'closed';
          break;
        case '16;31': currentLockState = 'locked';
          currentDoorState = 'closed';
          break;
        case '17;31': currentLockState = 'unknown';
          currentDoorState = 'closed';
          break;
        case '18;31': currentLockState = 'fault';
          currentDoorState = 'closed';
          break;
        case '19;31': currentLockState = 'not set';
          currentDoorState = 'closed';
          break;  
  */