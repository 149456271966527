import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NotificationsService } from 'src/app/services/notifications.service';

@Component({
  selector: 'app-pin-code',
  templateUrl: './pin-code.component.html',
  styleUrls: ['./pin-code.component.scss']
})
export class PinCodeComponent {

  @Input() available = false;
  @Output() normalOp = new EventEmitter();


  public pin: string = "";
  public sospin: string = "";
  public unmaskedPin: string = "";
  public maskedPin: string = "";
  public showPin: boolean = false;

  public step = 1;


  currentInput = "";

  constructor(public notify: NotificationsService) {}
  ngOnInit() {
    if(this.available == true) {
      this.step = 3;
    }
  }

  appendString(value: string) {
    this.currentInput += value;
    this.unmaskedPin = this.currentInput;
    this.maskedPin = this.getMask();
  }

  popString() {
    this.currentInput = this.currentInput.substring(0, this.currentInput.length - 1);
    this.unmaskedPin = this.currentInput;
    this.maskedPin = this.getMask();
  }

  confirmPin() {
    if (this.step == 1) {
      localStorage.setItem('pin', this.currentInput);
      this.step = 2;
      this.currentInput = "";
      this.unmaskedPin = this.currentInput;
      this.maskedPin = this.getMask();
    } else if(this.step == 2) {
      localStorage.setItem('sospin', this.currentInput);
      this.step = 3;
      this.currentInput = "";
      this.unmaskedPin = this.currentInput;
      this.maskedPin = this.getMask();
    } else if(this.step == 3) {
      if(localStorage.getItem('pin') == this.currentInput) {
        this.normalOp.emit(true);
      } else if(localStorage.getItem('sospin') == this.currentInput) {
        this.normalOp.emit(false);
        this.notify.openMessage("Distress Pin Entered!", 'info', 3);
      } else {
        this.notify.openMessage("Incorrect Pin", 'error', 3);
      } 
    }
  }

  confirmDistressPin() {
    if (this.step == 2) {
      localStorage.setItem('sospin', this.currentInput);
      this.step = 3;
      this.available = true;
    }
  }

  getMask() {
    let myString = "";
    for (let i = 0; i < this.currentInput.length; i++) {
      myString += "*";
    }
    return myString;
  }
}
