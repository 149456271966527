import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PushService {

  constructor() { }

  async askPermission() {
    return new Promise((resolve, reject) => {
      const permissionResult = Notification.requestPermission(res => {
        resolve(res);
      }).then( myRes => {
        if(myRes !== 'granted') {
          throw new Error('Permission Denied');
        }
      });

    });
  }

  getNotificationPermissionState() {
    if (navigator.permissions) {
      return navigator.permissions.query({name: 'notifications'})
      .then((result) => {
        return result.state;
      });
    }
  
    return new Promise((resolve) => {
      resolve(Notification.permission);
    });
  }

  registerServiceWorker() {
    return navigator.serviceWorker.register('ngsw-worker.js')
    .then(function(registration) {
      console.info('Service worker successfully registered.');
      
      return registration;
    })
    .catch(function(err) {
      console.error('Unable to register service worker.', err);
    });
  }

  // subscribeUserToPush() {
    
  
  //   const subscribeOptions = {
  //     userVisibleOnly: true,
  //     applicationServerKey: urlBase64ToUint8Array(
  //       'BEl62iUYgUivxIkv69yViEuiBIa-Ib9-SkvMeAtA3LFgDzkrxZJjSgSnfckjBJuBkr3qBUYIHBQFLXYp5Nksh8U'
  //     )
  //   };

  //   return  registration.pushManager.subscribe(subscribeOptions);
  
  // }
}
