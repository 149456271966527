import { AfterContentChecked, AfterViewChecked, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AdminService } from 'src/app/services/admin.service';
import { LoaderService } from 'src/app/services/loader.service';
import { LocalDatabaseService } from 'src/app/services/local-database.service';
import { WebRequestsService } from 'src/app/services/web-requests.service';
// import { EventEmitter } from 'stream';

@Component({
  selector: 'app-site-select',
  templateUrl: './site-select.component.html',
  styleUrls: ['./site-select.component.scss']
})
export class SiteSelectComponent implements OnInit,AfterContentChecked {

  @Output() btSite = new EventEmitter();
  @Input() currentState: boolean = false;
  public sites: any;
  public selectedSite: any;
  public availableSites: Set<string> = new Set();
  public availableSites1: any[] = [];
  public siteAvailability : any[] = [];
  public noTokens: boolean = false;

  public allTokens:any=[];

  public db: any;

  constructor(public adminService: AdminService, 
    public loader: LoaderService, 
    public webReq: WebRequestsService, 
    public snackBar: MatSnackBar,
    public router: Router,
    public localDB: LocalDatabaseService,
    public translate: TranslateService) { 
      translate.use(localStorage.getItem('lang')!);
    }

  ngOnInit(): void {
    // this.getSites();
    let tempTokens:any = [];
    let tempSites:any=[];
    let availableSites1: any = [];
    this.localDB.dbConnect().then((con:any) => {
      this.db = con.result;
      this.localDB.getAllTokens(this.db).then((res:any) => {
        res.forEach((token:any) => {
          if(this.checkValidity(token) == "current") {
            tempTokens.push(token);
            tempSites.push({sitename: token.sitename, siteID: token.siteID});
          }
        });
        // At this point I have all valid tokens -> now select site if more than one

        tempSites.forEach((site:any) => {
          if(availableSites1.length == 0) {
            availableSites1.push(site)
          } else {
            let found = false;
            availableSites1.forEach((avSite:any) => {
              
              if(site == avSite) {
                found = true
              }
            });
            if(!found) {
              availableSites1.push(site);
            }
          }
        });
        
        let ts:any = [];

        availableSites1.forEach((site:any) => {
          let found = false;
          ts.forEach((tsSite:any) => {
            if(tsSite.siteID == site.siteID) {
              found = true;
            }
          });
          if (!found) {
            ts.push(site);
          }
        });

        // this.availableSites1 = new Set(tempSites);
        this.availableSites1 = ts;
        this.allTokens = tempTokens;
        if(this.availableSites1.length == 0) {
          this.noTokens = true;
        }
        // else if(this.availableSites1.length == 1) {
        //   this.checkSites();
        // }
      });
    });
  }

  ngAfterContentChecked(): void {
    // this.checkSites();
  }
// Iterates through all the tokens and retrieves a list of sites from the token objects
  getSites() {
    let tempSites = new Array<string>();
    let tempDetails:any[] = [];
    this.siteAvailability = [];

    
    for (var key in localStorage) {
      if (key.includes("t_")&& !key.includes("_time") && !key.includes("_diag") && !key.includes("_from") && !key.includes("_to") && !key.includes("_conf")) {
        tempSites.push(key.substring(2, key.length - 2));
      }
    }

    for (var key in localStorage) {
      if (key.includes("_from")) {
        tempDetails.push({"sitename":key.substring(2, key.length - 5), "from":localStorage.getItem(key)});
      }
    }
    for (var key in localStorage) {
      if (key.includes("_to") && key != "access_token") {
        var temp = tempDetails.filter((x:any)=> x.sitename == key.substring(2, key.length - 3))[0] 
        temp = {"sitename": temp.sitename, "from":temp.from, "to": localStorage.getItem(key)} 
        tempDetails.forEach(detail => {
          if(detail.sitename == temp.sitename && detail.from == temp.from) {
            this.siteAvailability.push(temp);
          }
        });;
      }
    }
    this.availableSites = new Set(tempSites);
    this.siteAvailability.forEach(element => {
      let datenow = new Date().getTime();
      if (element.from > datenow || element.to < datenow) {
       this.availableSites.delete(element.sitename); 
      }
    });
  }
// Iterates through all the tokens and retrieves a list of sites from the token objects for valid tokens for said sites
  checkSites() {
    if (this.availableSites1.length == 0) {
      this.noTokens = true;
    } else if (this.availableSites1.length == 1) {
      // var values = this.availableSites1.values();
      // var obj = values.next();
      // var chosen = obj.value;
      var chosen = this.availableSites1[0];
      this.confirmSite(chosen);
    }
  }

  confirmSite(mySite: any) {

    this.clearLocalTokens();

    localStorage.setItem("selectedBTSite", mySite.sitename);
    localStorage.setItem("selectedBTSiteID", mySite.siteID);

    this.allTokens = this.allTokens.filter((x:any)=>x.sitename == mySite.sitename);

    let foundMultiDoor = false; // Rather check foor individual door codes:
    let foundDoor1 = false;
    let foundDoor2 = false;
    /*


    */
    this.allTokens.forEach((token: any) => {
      if (token.functionCode == 1) {
        localStorage.setItem("t_" + token.sitename + "_time", token.token);
      }
      if (token.functionCode == 0) {
        localStorage.setItem("t_" + token.sitename + "_" + token.opCode, token.token);
        // this.tokenSites.push(token.sitename);
      }
      if (token.functionCode == 3) {
        localStorage.setItem("t_" + token.sitename + "_diag", token.token);
      }
      if (token.functionCode == 2) {
        localStorage.setItem("t_" + token.sitename + "_conf", token.token);
      }
      if (token.functionCode == 4) {
        localStorage.setItem("t_" + token.sitename + "_reset", token.token)
      }
      if (token.functionCode == 5) {
        foundMultiDoor = true;
        localStorage.setItem("t_" + token.sitename + "_" + token.opCode + "_a", token.token);
      }
    
      if(token.functionCode == 0 && token.opCode == 0) {
        foundDoor1 = true;
      }
      if(token.functionCode == 5 && token.opCode == 0) {
        foundDoor2 = true;
      }


      localStorage.setItem('validFrom', token.fromDate);
      localStorage.setItem('validTo', token.toDate);
    });

    if(foundDoor1 && !foundDoor2) {
      localStorage.setItem("hasDoor1", "true");
      localStorage.setItem("hasDoor2", "false");
      localStorage.setItem("isMultiDoor", "false") 
    } else if(!foundDoor1 && foundDoor2) {
      localStorage.setItem("hasDoor1", "false");
      localStorage.setItem("hasDoor2", "true");
      localStorage.setItem("isMultiDoor", "false") 
    } else if(foundDoor1 && foundDoor2) {
      localStorage.setItem("hasDoor1", "true");
      localStorage.setItem("hasDoor2", "true");
      localStorage.setItem("isMultiDoor", "true") 
    }
    this.btSite.emit("close");
  }

  goToGetTokens() {
    this.router.navigate(['/token-tools']);
  }
// Checks the validity of a token
  checkValidity(row: any): string {
    var currentTime = new Date().getTime();
    var throwAway = currentTime - 1000 * 60 * 60 * 24 * 2;
    if (row.fromDate <= currentTime && row.toDate >= currentTime) {
      return "current";
    } else if(row.toDate < throwAway) {
      this.localDB.deleteTokenSet(this.db,row.id).then(res => {
      });
      return "revoked";
    } else if (row.fromDate < currentTime && row.toDate < currentTime) {
      return "past";
    } else if (row.fromDate > currentTime) {
      return "future";
    } else {
      return "";
    }
  }
// Checks online state of device
  checkOnline() {
    if(navigator.onLine == true) {
      return false
    } else {
      return true
    }
  }

  clearLocalTokens() {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i)
      if(key?.includes('t_')) {
        localStorage.removeItem(key);
      }
    }
  }
}
