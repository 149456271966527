import { HttpClient } from '@angular/common/http';
// import { templateJitUrl } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BluetoothAction } from '../models/bluetooth-action';
import { ConfigurationComponent } from '../pages/configuration/configuration.component';
import { WebRequestsService } from './web-requests.service';
import { LocalDatabaseService } from './local-database.service';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  public btLog: BluetoothAction[] = [];
  public logItem: BluetoothAction = {};
  public componentCounter = 0;
  constructor(public http: HttpClient, public webReq: WebRequestsService, public localDB: LocalDatabaseService) { }


  getLocation(): Promise<any> {
    return new Promise((resolve, reject) => {
      let coords = { lat: 0, lon: 0 }
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(res => {
          if (res) {
            coords.lat = res.coords.latitude;
            coords.lon = res.coords.longitude;
            resolve(coords);
          } else {
            resolve(coords);
          }
        });
      } else {
        resolve(coords);
      }
    });
  }


  async addLog(action: any, online: boolean, site: any, siteID: any) {
    this.logItem.time = new Date().getTime().toString();
    this.logItem.user = localStorage.getItem('username');
    this.logItem.action = this.getAction(action);
    this.logItem.device = navigator.userAgent;
    this.logItem.site = site;
    this.logItem.siteID = siteID;
    let coords = await this.getLocation();
    if (coords != undefined) {
      this.logItem.lat = coords.lat;
      this.logItem.lon = coords.lon;
    }
    this.logItem.client = localStorage.getItem('clientdb');
    this.btLog.push(this.logItem);
    if (online) {
      this.sendLogItem(this.logItem);
    } else {
      if (localStorage.getItem('btlog') != null && localStorage.getItem('btlog') != undefined) {
        let tempLog = JSON.parse(localStorage.btlog);
        tempLog.push(this.logItem);
        localStorage.btlog = JSON.stringify(tempLog);
      } else {
        let tempLog = [];
        tempLog.push(this.logItem);
        localStorage.btlog = JSON.stringify(tempLog);
      }
    }
  }

  async addMACLog(mac: any, online: boolean, site: any) {
    let macLog = {
      siteID: site,
      mac: mac,
      clientdb: localStorage.getItem('clientdb')
    }
    if (online) {
      this.sendMACItem(macLog)
    } else {
      if (localStorage.getItem('macLog') != null && localStorage.getItem('macLog') != undefined) {
        let tempLog = JSON.parse(localStorage.macLog);
        tempLog.push(macLog);
        localStorage.macLog = JSON.stringify(tempLog);
      } else {
        let tempLog = [];
        tempLog.push(macLog);
        localStorage.macLog = JSON.stringify(tempLog);
      }
    }
  }

  async addErrorBTLog(action: any, online: boolean, site: any) {
    this.logItem.time = new Date().getTime().toString();
    this.logItem.user = localStorage.getItem('username');
    this.logItem.action = "Error: " + action;
    this.logItem.device = navigator.userAgent;
    this.logItem.site = site;
    let coords = await this.getLocation();
    if (coords != undefined) {
      this.logItem.lat = coords.lat;
      this.logItem.lon = coords.lon;
    }
    if (online) {
      this.sendLogItem(this.logItem);
    } else {
      if (localStorage.getItem('btlog') != null && localStorage.getItem('btlog') != undefined) {
        let tempLog = JSON.parse(localStorage.btlog);
        tempLog.push(this.logItem);
        localStorage.btlog = JSON.stringify(tempLog);
      } else {
        let tempLog = [];
        tempLog.push(this.logItem);
        localStorage.btlog = JSON.stringify(tempLog);
      }
    }
  }

  addResponseLog() {
    // TODO - add response logs for upload...
  }

  getAction(action: any) {
    if (action.match(/\d;\d/)) {
      if (action.substring(0, 2) == "7;") {
        return "Use Maglock set to " + action.substring(action.indexOf(';') + 1, action.length)
      }
      else if (action.substring(0, 2) == "6;") {
        return "Max current set to " + action.substring(action.indexOf(';') + 1, action.length)
      }
      else if (action.substring(0, 2) == "5;") {
        return "Actuator Timeout set to " + action.substring(action.indexOf(';') + 1, action.length)
      }
      else if (action.substring(0, 2) == "4;") {
        return "Invert Actuator set to " + action.substring(action.indexOf(';') + 1, action.length)
      }
      else if (action.substring(0, 2) == "3;") {
        return "Site ID set to " + action.substring(action.indexOf(';') + 1, action.length)
      }
      else if (action.substring(0, 2) == "2;") {
        return "Use Door Contact set to " + action.substring(action.indexOf(';') + 1, action.length)
      }
      else if (action.substring(0, 3) == "10;") {
        return "Actuator length set to " + action.substring(action.indexOf(';') + 1, action.length)
      }
      else if (action.substring(0, 3) == "11;") {
        return "Actuator speed set to " + action.substring(action.indexOf(';') + 1, action.length)
      }
      else if (action.substring(0, 3) == "96;") {
        return "Config set to " + action.substring(action.indexOf(';') + 1, action.length)
      }
      else if (action.substring(0, 2) == "9;") {
        return "Set Device Name to: " + action.substring(action.indexOf(';') + 1, action.length);
      }
    }

    if (action.includes("Stand down success")) {
      return action;
    }

    if (action.includes('PRO') || action.includes('PLUS') || action.includes('LITE')) {
      return action;
    }

    if (action.includes('Linked') || action.includes('Unlinked')) {
      return action;
    }

    if (action.includes('Temperature')) {
      return action;
    }

    if(action.includes('MAC')) {
      return action;
    }

    if(action.toLowerCase().includes("offline") || action.toLowerCase().includes("secondary")) {
      return action;
    }

    switch (action) {
      case '0': return 'Open Lock';
        break;
      case '1': return 'Close Lock';
        break;
      case '2': return 'Stand Down';
        break;
      case '3': return 'Set Time';
        break;
      case 'time': return 'Set Time';
        break;
      case 'conf': return 'Enter Config';
        break;
      case 'diag': return 'Diagnostics';
        break;
      case 'reset': return 'Request Factory Reset';
        break;
      case '?': return 'Request State';
        break;
      case '$': return 'Request Internal Software Update';
        break;
      case '#': return 'PDI';
        break;
      case '*;2': return 'Test Actuator';
        break;
      case '*;1': return 'Test Tamper';
        break;
      case '*;0': return 'Test Door Sensor';
        break;
      case '*;4': return 'Start Sensor Alignment';
        break;
      case '*;5': return 'Stop Sensor Alignment';
        break;
      case '*;3': return 'Test Power Supply';
        break;
      case '*;9': return 'Exit Diagnostics';
        break;
      case '99': return 'Exit Config';
        break;
      case '98': return 'Save Config';
        break;
      case '97': return 'Get Config';
        break;
      case '96': return 'Sent Config String';
        break;
      case '12': return 'Started OTA';
        break;
      case '8;2': return 'Reset WIFI';
        break;
      case '8;1': return 'Set WIFI On';
        break;
      case '8;0': return 'Set WIFI Off';
        break;
      case 'Actuator;0': return 'Actuator Diagnostic result: OK';
        break;
      case 'Actuator;1': return 'Actuator Diagnostic result: Fail';
        break;
      case 'Tamper;0': return 'Tamper Diagnostic result: OK';
        break;
      case 'Tamper;1': return 'Tamper Diagnostic result: Fail';
        break;
      case 'DoorSensor;0': return 'Door Sensor Diagnostic result: OK';
        break;
      case 'DoorSensor;1': return 'Door Sensor Diagnostic result: FAIL';
        break;
      case 'PowerSupply;0': return 'Power Supply Diagnostic result: OK';
        break;
      case 'PowerSupply;1': return 'Power Supply Diagnostic result: FAIL';
        break;
      case '27': return 'TPM1';
        break;
      case '28': return 'TPM2';
        break;
      case '0x06': return 'Check Temperature';
        break;
      default: console.warn('Unknown Action: ' + action)
        return 'Unknown Action: ' + action;
        break;
    }
  }

  sendLogItem(logItem: any) {
    let myEvent = [];
    myEvent.push(logItem);

    this.webReq.pushBTEvents(myEvent).subscribe(res => {
    });
  }

  sendMACItem(logItem: any) {
    let myEvent = [];
    myEvent.push(logItem);
    this.webReq.pushMACEvents(myEvent).subscribe(res => {

    })
  }

  async sendAllLogs() {
    if (localStorage.getItem('btlog') != null && localStorage.getItem('btlog') != undefined) {
      let logs = JSON.parse(localStorage.btlog);
      if (logs.length > 0) {
        let res = await this.webReq.pushBTEvents(logs).toPromise()
        localStorage.btlog = JSON.stringify([]);
        this.sendAllMACLogs();
        // this.sendReports();
        return
      } else {
        this.sendAllMACLogs();
        return
      }
    }
  }

  async sendAllMACLogs() {
    if (localStorage.getItem('macLog') != null && localStorage.getItem('macLog') != undefined) {
      let logs = JSON.parse(localStorage.macLog);
      if (logs.length > 0) {
        let res = await this.webReq.pushMACEvents(logs).toPromise()
        localStorage.macLog = JSON.stringify([]);
        this.sendReports();
        return
      } else {
        this.sendReports();
        return
      }
    }
  }

  async sendReports() {
    let reports: any = await this.localDB.getReports();
    if (reports.length > 0) {
      reports.forEach((report: any) => {
        this.webReq.uploadReport({ file: report.file, siteID: report.siteID, clientdb: report.clientdb, filename: report.filename }).subscribe(result => {
          this.localDB.clearReports();
          this.sendHOReqs();
        }, err => {
          console.warn("error on upload....")
          console.warn(err)
        })
      });
    } else {
      this.sendHOReqs();
    }
  }

  async sendHOReqs() {
    let reqList: any = [];
    if (localStorage.getItem('handoverReqs') != null && localStorage.getItem('handoverReqs') != undefined) {
      reqList = JSON.parse(localStorage.getItem('handoverReqs')!);
    }
    if (reqList.length > 0) {
      reqList.forEach((element: any) => {
        this.webReq.generateHandoverRequest(element).subscribe(result => {
          if (result.message == "Request Complete") {
            console.info("Handover Request sent!")
            reqList = [];
            localStorage.setItem('handoverReqs', JSON.stringify(reqList))
          }
        })
      });
    }
  }

}
