<div>
    <mat-card fxLayout="column" style="width: 90vw; padding: 8px;" *ngIf="acknowledged == false">
        <mat-card-title>Acknowledge Alarm</mat-card-title>
        <!--<mat-card-subtitle>{{alarmUUID}}</mat-card-subtitle> --> <!-- hidden from the gui-->
        <!-- <label>Alarm UUID: {{alarmUUID}}</label> -->
        <label>Customer Name: {{clientdb}}</label>   <!-- THIS WAS CHANGED-->
        <label>Alarm Type: {{type}}</label>
        <label>Alarm SiteID: {{siteID}}</label>
        <label>Alarm Site: {{sitename}}</label>
        <label>Alarm Time: {{time}}</label>
        <button mat-raised-button color="accent" (click)="acknowledgeAlarm()">Acknowledge</button>
    </mat-card>

    <mat-card fxLayout="column" style="width: 90vw; padding: 8px;" *ngIf="acknowledged == true">
        <mat-card-title>Already Acknowleded</mat-card-title>
        <!--<mat-card-subtitle>{{alarmUUID}}</mat-card-subtitle>-->
        <!-- <label>Alarm UUID: {{alarmUUID}}</label> -->
        <h2>This "{{acknowledgedType}}" alarm was already acknowledged by {{acknowledgedBy}} at {{acknowledgedTime}}</h2>
        <button mat-raised-button color="warn" (click)="close()">Close</button>
    </mat-card>
</div>
