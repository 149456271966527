<div fxFlex fxLayoutAlign="center center" fxLayout="column">
  <app-loader></app-loader>
  <mat-card class="mat-elevation-z16" style="height:80vh; width: 99vw;" fxLayoutAlign="start center" fxLayout="column"
    *ngIf="!useDB">

    <table mat-table class="mat-elevation-z16" style="width: 100%;" [dataSource]="dataSource" class="mat-elevation-z8">

      <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->


      <!-- Site ID Column -->
      <ng-container matColumnDef="siteID">
        <th mat-header-cell *matHeaderCellDef> Site ID </th>
        <td mat-cell *matCellDef="let element"> {{element.siteID}} </td>
      </ng-container>

      <!-- Sitename Column -->
      <ng-container matColumnDef="sitename">
        <th mat-header-cell *matHeaderCellDef> {{'app.myTokens.siteLabel' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.sitename}} </td>
      </ng-container>

      <!-- From Column -->
      <ng-container matColumnDef="from">
        <th mat-header-cell *matHeaderCellDef> {{'app.myTokens.fromLabel' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.from | date:"MM/dd/yy HH:mm"}} </td>
      </ng-container>

      <!-- To Column -->
      <ng-container matColumnDef="to">
        <th mat-header-cell *matHeaderCellDef> {{'app.myTokens.toLabel' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.to | date:"MM/dd/yy HH:mm"}} </td>
      </ng-container>

      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef> Delete </th>
        <td mat-cell *matCellDef="let element"> <mat-icon
            (click)="deleteTokenNew(element.sitename, element.from, element.to)" style="color:red">delete</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="info">
        <th mat-header-cell *matHeaderCellDef> Info </th>
        <td mat-cell *matCellDef="let element"> <mat-icon (click)="getInfo(element.siteID)" style="color: white !important">info</mat-icon> </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row [ngClass]="checkValidity(row)" *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [length]="siteAvailability.length" [pageSizeOptions]="[5, 10]"
      style="position: absolute; bottom: 25px;"></mat-paginator>

  </mat-card>

  <mat-card class="mat-elevation-z16" style="height:80vh; width: 99vw;" fxLayoutAlign="start center" fxLayout="column"
    *ngIf="useDB">
    <div>Database</div>
    <div>Items:</div>

    <button mat-raised-button color="primary" (click)="createDisplay()">Create/Display database</button>
  </mat-card>


  <div class="backdrop" *ngIf="showInfo" (click)="showInfo=false"></div>
  <mat-card *ngIf="showInfo" id="infoCard">
    <mat-card-header>
      <mat-card-title>Site Contact Info</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div fxLayout="column">
        <label>Name: {{contactPerson.name}}</label>
        <label>Email: <a [href]="'mailto:'+contactPerson.email">{{contactPerson.email}}</a></label>
        <label>Cell: <a [href]="'tel:'+contactPerson.cellphone">{{contactPerson.cellphone}}</a></label>
      </div>
    </mat-card-content>
  </mat-card>

</div>