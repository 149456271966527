<div fxFlex fxLayoutAlign="center center" fxLayout="column">
    <app-loader></app-loader>
    <mat-card class="mat-elevation-z16" style="height: 75vh; width: 85vw;" fxLayoutAlign="space-evenly none"
        fxLayout="column">
        <button mat-mini-fab style="position: absolute; top: 8px; left: 8px; background-color: transparent; z-index: 2002;"
            (click)="closing()">
            <mat-icon>arrow_back</mat-icon>
        </button>

       
        <!-- Select User  -->
        <mat-stepper orientation="vertical" linear=true style="max-height: 60vh; overflow: auto">
            <mat-step>
                <ng-template matStepLabel>{{'app.cloudTokens.reason' | translate}}</ng-template>
                <mat-form-field appearance="outline">
                    <mat-label>{{'app.cloudTokens.reason' | translate}}</mat-label>
                    <mat-select [(ngModel)]="reason">
                        <!-- <mat-form-field appearance="outline">
                            <mat-label>Filter</mat-label>
                            <input matInput type="text" (keyup)="applySitesFilter($event)" style="color:white" autocomplete="off" placeholder="Ex. default" [(ngModel)]="siteFilter">
                        </mat-form-field> -->
                        <mat-option *ngFor="let reason of reasonList" [value]="reason">
                            {{reason}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div>
                    <button mat-raised-button matStepperNext color="primary">{{'app.cloudTokens.next' | translate}}</button>
                </div>
            </mat-step>
            
            <mat-step>
                <ng-template matStepLabel>{{'app.cloudTokens.selectUser' | translate}}</ng-template>
                <mat-form-field appearance="outline">
                    <mat-label>{{'app.cloudTokens.user' | translate}}</mat-label>
                    <mat-select [(ngModel)]="username"  (openedChange)="clearUserFilter($event)">
                        <mat-form-field appearance="outline">
                            <mat-label>Filter</mat-label>
                            <input matInput type="text" (keyup)="applyUsersFilter($event)" style="color:white" autocomplete="off" placeholder="Ex. default" [(ngModel)]="userFilter">
                        </mat-form-field>
                        <mat-option *ngFor="let user of filteredUsers" (click)="getUserSites(user)" [value]="user">
                            {{user.username}} ({{user.email}})
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div>
                    <button mat-raised-button matStepperNext color="primary">{{'app.cloudTokens.next' | translate}}</button>
                </div>
            </mat-step>
            <!-- Select Site -->
            <mat-step>
                <ng-template matStepLabel>{{'app.cloudTokens.selectSite' | translate}}</ng-template>
                <mat-form-field appearance="outline" *ngIf="userSites != null">
                    <mat-label>{{'app.cloudTokens.site' | translate}}</mat-label>
                    <mat-select [(ngModel)]="siteID"  (openedChange)="clearSitesFilter($event)">
                        <mat-form-field appearance="outline">
                            <mat-label>Filter</mat-label>
                            <input matInput type="text" (keyup)="applySitesFilter($event)" style="color:white" autocomplete="off" placeholder="Ex. default" [(ngModel)]="siteFilter">
                        </mat-form-field>
                        <mat-option *ngFor="let site of filteredUserSites" [value]="site">({{site.siteID}}) {{site.siteName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <div *ngIf="siteID?.secondaryController">
                    <mat-checkbox [(ngModel)]="siteID.door1">Door 1</mat-checkbox>
                    <mat-checkbox [(ngModel)]="siteID.door2">Door 2</mat-checkbox>
                </div>

                <div>
                    <button mat-raised-button matStepperNext color="primary" [disabled]="siteID?.secondaryController && !siteID?.door1 && !siteID?.door2">{{'app.cloudTokens.next' | translate}}</button>
                </div>
            </mat-step>
            <!-- From Date -->
            <mat-step>
                <ng-template matStepLabel>{{'app.cloudTokens.validFrom' | translate}}</ng-template>
                <mat-form-field appearance="outline">
                    <mat-label>From</mat-label>
                    <input matInput [(ngModel)]="fromDate" [min]="minDate" type="datetime-local">
                </mat-form-field>
                <div>
                    <button mat-raised-button matStepperNext color="primary">{{'app.cloudTokens.next' | translate}}</button>
                </div>
            </mat-step>
            <!-- To Date -->
            <mat-step>
                <ng-template matStepLabel>{{'app.cloudTokens.validTo' | translate}}</ng-template>
                <mat-form-field appearance="outline">
                    <mat-label>To</mat-label>
                    <input matInput [(ngModel)]="toDate" [min]="fromDate" type="datetime-local">
                </mat-form-field>
                <div>
                    <button mat-raised-button matStepperNext color="primary">{{'app.cloudTokens.next' | translate}}</button>
                </div>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>{{'app.cloudTokens.confirm' | translate}}</ng-template>
                <div *ngIf="username != undefined && siteID != undefined">
                    <!-- <div>Reason: {{reason}}</div> -->
                    <div>{{username.username}}</div>
                    <div>{{username.email}}</div>
                    <div>{{siteID.siteID}}</div>
                    <div>{{siteID.siteName}}</div>
                    <div>{{fromDate | date:"dd/MM/yy HH:mm:ss"}}</div>
                    <div>{{toDate | date:"dd/MM/yy HH:mm:ss"}}</div>
                </div>
            </mat-step>

        </mat-stepper>




        <button mat-raised-button color="primary" (click)="authenticate()" [disabled]="username == undefined || siteID == undefined">
            <mat-icon>sms</mat-icon> {{'app.cloudTokens.generate' | translate}}
        </button>
    </mat-card>

    <mat-card *ngIf="showEnterPwd" style="z-index: 2003; position: absolute; border: 1px solid white;" fxLayout="column">
        <h2>Enter Password</h2>
        <mat-form-field>
            <mat-label>Password</mat-label>
            <input matInput [(ngModel)]="password" type="password">
        </mat-form-field>
        <div fxLayoutAlign="space-evenly center">
            <button mat-raised-button (click)="confirmPassword()" color="primary">Confirm</button>
            <button mat-raised-button (click)="showEnterPwd = false;" color="warn">Cancel</button>
        </div>
    </mat-card>
</div>