<div fxFlex fxLayoutAlign="center center" fxLayout="column">

    <mat-card class="mat-elevation-z16" style="height: 75vh; width: 85vw;" fxLayoutAlign="start center"
        fxLayout="column">
        <!-- <button mat-mini-fab style="position: absolute;top: 8px;left: 8px; background-color: transparent;"
            (click)="close()" [disabled]="disableBack"> -->
        <button mat-mini-fab style="position: absolute;top: 8px;left: 8px; background-color: transparent;"
            (click)="close()">
            <mat-icon>arrow_back</mat-icon>
        </button>


        <!-- <mat-accordion class="mat-elevation-z8"  style="width: 84vw; height: 52vh; overflow-y: auto;"> -->
        <mat-accordion style="width: 84vw; height: 52vh; overflow-y: auto; position: absolute; top: 52px;">

            <mat-expansion-panel *ngIf="hasConfigTemplate" style="border: 1px solid white; box-shadow: 0px 0px 4px 5px purple; margin:4px;">
                <mat-expansion-panel-header>
                    <mat-panel-title style="color: limegreen">Config Template</mat-panel-title>
                </mat-expansion-panel-header>

                <div fxLayout="column">
                    <label>You have a config template available. Do you want to load the template?</label>
                    <button mat-raised-button color="primary" (click)="setConfigTemplate()"><mat-icon>save</mat-icon> Load and Save</button>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>Summary</mat-panel-title>
                    <mat-panel-description>Config Summary</mat-panel-description>
                </mat-expansion-panel-header>
                <div fxLayout="column">
                    <label>Site ID: {{siteID}}</label>
                    <label>Use Door Contact without door sensor: {{useDoorContact ? 'Yes' : 'No'}}</label>
                    <label>Invert Actuator: {{invertActuator ? 'Yes' : 'No'}} </label>
                    <label>Toggle Lock: {{maglockToggle ? 'Yes' : 'No'}}</label>
                    <label>Timeout: {{actuatorTimeout}}s</label>
                    <label>Max Current: {{maxCurrent}}A</label>
                    <label>Speed: {{actSpeed}}mm/s</label>
                    <label>Length: {{actLength}}mm</label>

                    <!-- <label *ngIf="version >= 'PRO-S3-O'">Sensor Type: {{getSensorType(sensorType)}}</label> -->
                    <label *ngIf="!testOldVersion(version)">Sensor Type: {{getSensorType(sensorType)}}</label>
                    <label *ngIf="!testOldVersion(version)">Use 2 sensors: {{use2Sensors ? 'Yes' : 'No'}}</label>
                    <label *ngIf="!testOldVersion(version)">Use Timer: {{useTimer ? 'Yes' : 'No'}}</label>
                    <label *ngIf="!testOldVersion(version)">Use Tamper: {{useTamperRelay ? 'Yes' : 'No'}}</label>
                    <label *ngIf="!testOldVersion(version)">Use Door Contact to Inhibit: {{useDCtoInhibit ? 'Yes' : 'No'}}</label>
                    <label *ngIf="!testOldVersion(version)">Door Contact Normally Open: {{dcIsNO ? 'Yes' : 'No'}}</label>
                    <label *ngIf="!testOldVersion(version)">Door 1 Auto Lock: {{door1AutoLock ? 'Yes' : 'No'}}</label>
                    <label *ngIf="!testOldVersion(version) && door2AutoLock">Door 2 Auto Lock: {{door2AutoLock ? 'Yes' : 'No'}}</label>
                    <label>MAC: {{macAddress}}</label>
                    <label>Firmware: {{version}}</label>

                    <button mat-raised-button color="primary" (click)="downloadSummary()"><mat-icon>download</mat-icon>
                        Download Summary</button>
                </div>

            </mat-expansion-panel>


            <!-- Device Config -->
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>1.</mat-panel-title>
                    <mat-panel-description>PRISM Device</mat-panel-description>
                </mat-expansion-panel-header>

                <mat-form-field appearance="outline">
                    <mat-label>{{'app.config.siteID' | translate}}</mat-label>
                    <input matInput [(ngModel)]="siteID" [disabled]="!gotConfig || !isEditSiteID || !gotVersion"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" />
                    <button matSuffix mat-icon-button aria-label="Clear" (click)="toggleEditSiteID()">
                        <mat-icon>edit</mat-icon>
                    </button>
                </mat-form-field>

            </mat-expansion-panel>
            <!-- Actuator Config -->
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>2.</mat-panel-title>
                    <mat-panel-description>Actuator</mat-panel-description>
                </mat-expansion-panel-header>

                <mat-slide-toggle style="margin: 8px;" color="primary" [(ngModel)]="door1AutoLock"
                    [disabled]="!gotConfig || !gotVersion">Auto Lock Door 1</mat-slide-toggle>
                    <mat-slide-toggle style="margin: 8px;" color="primary" [(ngModel)]="door2AutoLock"
                    [disabled]="!gotConfig || !gotVersion">Auto Lock Door 2</mat-slide-toggle>
                <!-- user Timer is for new Firmware -->
                <mat-slide-toggle style="margin: 8px;" color="primary" *ngIf="!testOldVersion(version)"
                    [(ngModel)]="useTimer" (click)="checkTimeoutDefault()">Use Timer</mat-slide-toggle>
                <mat-slide-toggle style="margin: 8px;" color="primary" [(ngModel)]="invertActuator"
                    [disabled]="!gotConfig || !gotVersion">{{'app.config.useActuator' | translate}}</mat-slide-toggle>
                <mat-slide-toggle style="margin: 8px;" color="primary" [(ngModel)]="maglockToggle"
                    [disabled]="!gotConfig || !gotVersion">Toggle Lock</mat-slide-toggle>



                <!-- <mat-slide-toggle color="primary"></mat-slide-toggle> -->
                <div fxLayoutAlign="space-between center"
                    style="border: 1px solid gray; border-radius: 5px; margin: 4px; padding: 4px;">
                    <mat-label>{{'app.config.timeout' | translate}}</mat-label>
                    <mat-slider color="primary" [(ngModel)]="actuatorTimeout" min="1" max="20" step="1" thumbLabel
                        [disabled]="!gotConfig || !gotVersion"></mat-slider>
                    <mat-form-field style="width: 24px;">
                        <input matInput [(ngModel)]="actuatorTimeout" min="1" max="20" type="number">
                    </mat-form-field>
                    <mat-label>s</mat-label>
                </div>

                <div fxLayoutAlign="space-between center"
                    style="border: 1px solid gray; border-radius: 5px; margin: 4px; padding: 4px;">
                    <mat-label>{{'app.config.current' | translate}}</mat-label>
                    <mat-slider color="primary" [(ngModel)]="maxCurrent" min="0.25" max="6" step="0.01" thumbLabel
                        [disabled]="!gotConfig || !gotVersion"></mat-slider>
                    <mat-form-field style="width: 24px;">
                        <input matInput [(ngModel)]="maxCurrent">
                    </mat-form-field>
                    <mat-label>A</mat-label>
                </div>

                <div fxLayoutAlign="space-between center"
                    style="border: 1px solid gray; border-radius: 5px; margin: 4px; padding: 4px;">
                    <mat-label>{{'app.config.speed' | translate}}</mat-label>
                    <mat-slider color="primary" [(ngModel)]="actSpeed" min="0" max="15" step="1" thumbLabel
                        [disabled]="!gotConfig || !gotVersion"></mat-slider>
                    <mat-form-field style="width: 24px;">
                        <input matInput [(ngModel)]="actSpeed">
                    </mat-form-field>
                    <mat-label>mm/s</mat-label>
                </div>
                <div fxLayoutAlign="space-between center"
                    style="border: 1px solid gray; border-radius: 5px; margin: 4px; padding: 4px;">
                    <mat-label>{{'app.config.length' | translate}}</mat-label>
                    <mat-slider color="primary" [(ngModel)]="actLength" min="0" max="150" step="1" thumbLabel
                        [disabled]="!gotConfig || !gotVersion"></mat-slider>
                    <mat-form-field style="width: 24px;">
                        <input matInput [(ngModel)]="actLength">
                    </mat-form-field>
                    <mat-label>mm</mat-label>
                </div>

                <div>
                    <!-- put Door contact -->
                </div>


            </mat-expansion-panel>

            <!-- Sensor Configs -->
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>3.</mat-panel-title>
                    <mat-panel-description>Sensors</mat-panel-description>
                </mat-expansion-panel-header>

                <div fxLayout="column" fxLayoutAlign="space-evenly start">

                    <!-- New Configs -->

                    <mat-form-field appearance="outline" *ngIf="!testOldVersion(version)">
                        <mat-label>Sensor Type</mat-label>
                        <mat-select [(ngModel)]="sensorType">
                            <mat-option [value]="0">Magnetic Contacts</mat-option>
                            <mat-option [value]="1">PRISM Smart IR</mat-option>
                            <mat-option [value]="2">Induction Sensor</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-slide-toggle style="margin: 8px;" color="primary" [(ngModel)]="useDoorContact"
                        [disabled]="!gotConfig || !gotVersion">Use door contact w/o door sensor</mat-slide-toggle>


                    <mat-slide-toggle style="margin: 8px;" color="primary" [(ngModel)]="use2Sensors"
                        [disabled]="!gotConfig || sensorType != 2 || !gotVersion" *ngIf="!testOldVersion(version)">Use 2
                        sensors</mat-slide-toggle>

                    <mat-slide-toggle style="margin: 8px;" color="primary" *ngIf="!testOldVersion(version)"
                        [(ngModel)]="useTamperRelay">Use Tamper Relay</mat-slide-toggle>
                    <mat-slide-toggle style="margin: 8px;" color="primary" *ngIf="!testOldVersion(version)"
                        [(ngModel)]="useDCtoInhibit">Use Door Contact & Door Sensor</mat-slide-toggle>
                    <mat-slide-toggle style="margin: 8px;" color="primary" *ngIf="!testOldVersion(version)"
                        [(ngModel)]="dcIsNO">Door Contact Normally Open</mat-slide-toggle>


                    <!--  -->
                    <div style="border: 1px solid gray; border-radius: 5px; margin: 4px; padding: 4px; width: 100%"
                        *ngIf="testOldVersion(version)" [hidden]="!gotConfig || !gotVersion">
                        <!-- put tamper relay -->
                        <label>Tamper Relay</label>
                        <div fxLayout="row" fxLayoutAlign="space-evenly center">
                            <div class="ioBtn" fxLayout="column" fxLayoutAlign="center center"
                                style="border-color: lime; color: lime; font-weight: bold;"
                                (click)="setTamperRelay(true)">
                                I
                            </div>
                            <div class="ioBtn" fxLayout="column" fxLayoutAlign="center center"
                                style="border-color: red; color: red; font-weight: bold;"
                                (click)="setTamperRelay(false)">
                                O
                            </div>
                        </div>

                    </div>
                    <div style="border: 1px solid gray; border-radius: 5px; margin: 4px; padding: 4px; width: 100%"
                    *ngIf="testOldVersion(version)" [hidden]="!gotConfig || !gotVersion">
                        <!-- put tamper relay -->
                        <label>Use door contact w/ door sensor</label>
                        <div fxLayout="row" fxLayoutAlign="space-evenly center">
                            <div class="ioBtn" fxLayout="column" fxLayoutAlign="center center"
                                style="border-color: lime; color: lime; font-weight: bold;"
                                (click)="setInhibitSD(true)">
                                I
                            </div>
                            <div class="ioBtn" fxLayout="column" fxLayoutAlign="center center"
                                style="border-color: red; color: red; font-weight: bold;" (click)="setInhibitSD(false)">
                                O
                            </div>
                        </div>

                    </div>

                </div>

            </mat-expansion-panel>

            <!-- Secondary Controller configs -->
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>4.</mat-panel-title>
                    <mat-panel-description>Secondary Controller</mat-panel-description>
                </mat-expansion-panel-header>

                <div fxLayout="column" fxLayoutAlign="space-evenly start">

                    <mat-slide-toggle style="margin: 8px;" color="primary" [(ngModel)]="secondaryPresent" (click)="checkTPMSecondToggle('sec')">Secondary Controller Present</mat-slide-toggle>
                    
                    <mat-slide-toggle style="margin: 8px;" color="primary" [(ngModel)]="tpmPresent" (click)="checkTPMSecondToggle('tpm')">TPM Present</mat-slide-toggle>
                    
                    <mat-form-field appearance="outline" *ngIf="secondaryPresent">
                        <mat-label>Secondary Sensor Type</mat-label>
                        <mat-select [(ngModel)]="secondarySensorType">
                            <mat-option [value]="0">Magnetic Contacts</mat-option>
                            <mat-option [value]="1" (click)="secondaryUse2Sensors = false">PRISM Smart IR</mat-option>
                            <mat-option [value]="2">Induction Sensor</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-slide-toggle style="margin: 8px;" color="primary" [(ngModel)]="secondaryUse2Sensors" *ngIf="secondaryPresent" [disabled]="secondarySensorType == 1">Secondary use 2 sensors</mat-slide-toggle>

                    <button mat-raised-button (click)="getSetTPM('27')" color="primary" style="margin: 4px;"><mat-icon>link</mat-icon> Link and ID TPM 1</button>
                    <button mat-raised-button (click)="getSetTPM('28')" color="primary" style="margin: 4px;"><mat-icon>link</mat-icon> Link and ID TPM 2</button>
                </div>
            </mat-expansion-panel>


            <!-- Advanced configs which reboots unit -->
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>{{'app.config.advanced' | translate}}</mat-panel-title>
                    <mat-panel-description>{{'app.config.descAdvanced' | translate}}</mat-panel-description>
                </mat-expansion-panel-header>

                <label style="color: red">{{'app.config.warning' | translate}}</label>

                <div fxLayoutAlign="start center" fxLayout="column"
                    style="margin: 2px; border: 2px solid grey; border-radius: 2px;">
                    <mat-label>WiFi</mat-label>
                    <!-- <mat-label>Enable WiFi</mat-label> -->
                    <div fxLayout="row" fxLayoutAlign="space-evenly center">
                        <button mat-mini-fab color="primary" style="margin: 4px;"
                            [disabled]="!gotConfig || currentWifiState == true || !gotVersion"
                            (click)="changeBluetoothTo = 'on'; showWifiWarning = true;">
                            <mat-icon>wifi</mat-icon>
                        </button>
                        <button mat-mini-fab color="primary" style="margin: 4px;"
                            [disabled]="!gotConfig || currentWifiState == false || !gotVersion"
                            (click)="changeBluetoothTo = 'off'; showWifiWarning = true;">
                            <mat-icon>wifi_off</mat-icon>
                        </button>
                        <button mat-mini-fab color="primary" style="margin: 4px;" [disabled]="!gotConfig || !gotVersion"
                            (click)="showWifiResetWarning = true">
                            <mat-icon>restart_alt</mat-icon>
                        </button>
                    </div>
                    <div fxLayout="column" fxLayoutAlign="space-evenly center">
                        <mat-form-field appearance="outline">
                            <mat-label>SSID (WiFi Name)</mat-label>
                            <input matInput [(ngModel)]="ssid">
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Password</mat-label>
                            <input matInput [(ngModel)]="password">
                        </mat-form-field>
                        <button mat-raised-button color="primary" style="margin: 8px"
                            (click)="saveSSIDconf()" [disabled]="!gotConfig || !gotVersion"><mat-icon>save</mat-icon> Save SSID details</button>
                    </div>
                </div>
                <div style="margin: 2px; border: 2px solid grey; border-radius: 2px; padding: 2px;"
                    fxLayoutAlign="start center" fxLayout="column">
                    <mat-label>{{'app.config.device' | translate}}</mat-label>
                    <mat-form-field appearance="outline">
                        <mat-label>{{'app.config.deviceName' | translate}}</mat-label>
                        <input matInput placeholder="name" maxlength="8" [(ngModel)]="changeNameTo"
                            [disabled]="!gotConfig || !gotVersion">
                    </mat-form-field>
                    <button mat-raised-button color="primary" style="margin: 4px; width: 90%;"
                        (click)="showRenameWarning = true" [disabled]="!gotConfig || !gotVersion">
                        <mat-icon>save</mat-icon> {{'app.config.saveDeviceName' | translate}}
                    </button>
                </div>


                <div style="margin: 2px; border: 2px solid grey; border-radius: 2px; padding: 2px;"
                    fxLayoutAlign="start center" fxLayout="column">
                    <mat-label>Firmware</mat-label>
                    <button mat-raised-button color="accent" style="width: 90%;"
                        (click)="showOTAWarning = true;"><mat-icon>system_update</mat-icon>
                        {{'app.config.firmwareButton' | translate}}</button>
                </div>

                <div style="margin: 2px; border: 2px solid grey; border-radius: 2px; padding: 2px;"
                    fxLayoutAlign="start center" fxLayout="column">
                    <mat-label>{{'app.config.Reset' | translate}}</mat-label>
                    <button mat-raised-button color="warn" style="margin: 4px; width: 90%;"
                        (click)="showResetWarning = true" [disabled]="checkSiteIDValid()">
                        <mat-icon>history</mat-icon> {{'app.config.factoryReset' | translate}}
                    </button>
                </div>


            </mat-expansion-panel>

            <!-- Import/Export configs (ensure this is reverse compatible with new configs) -->
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>Auto Config</mat-panel-title>
                    <mat-panel-description>Export/Import</mat-panel-description>
                </mat-expansion-panel-header>

                <div fxLayout="column" fxLayoutAlign="space-evenly center">
                    <button mat-raised-button color="primary" style="margin: 4px"
                        (click)="exportConfig()" [disabled]="!gotConfig || !gotVersion"><mat-icon>upload</mat-icon> Export Config</button>
                    <button mat-raised-button color="primary" style="margin: 4px"
                        (click)="importConfig()" [disabled]="!gotConfig || !gotVersion"><mat-icon>download</mat-icon> Import Config</button>
                    <input type="file" id="fileInput" style="display: none;">

                </div>

            </mat-expansion-panel>
        </mat-accordion>







        <button mat-raised-button color="primary" style="width: 70vw; position: absolute; bottom: 40px;"
            (click)="sendConfigNew(); gotConfig= false;"
            [disabled]="!gotConfig || siteID=='' || checkValidData()==false || !gotVersion">
            <mat-icon style="color: black">save</mat-icon> {{'app.config.saveConfig' | translate}}
        </button>
        <label style="position: absolute; bottom: 12px;">{{'app.config.version' | translate}}: {{version}}</label>
    </mat-card>

    <!-- WiFi change warning -->
    <mat-card class="mat-elevation-z16" style="height: 30vh; width: 85vw; position: absolute; border: 2px solid grey"
        *ngIf="showWifiWarning" fxLayout="column" fxLayoutAlign="space-evenly none">
        <div fxLayoutAlign="center center" fxLayout="column">
            <h1>
                <mat-icon style="color:orange">warning</mat-icon> {{'app.config.warningWord' | translate}} <mat-icon
                    style="color:orange">warning
                </mat-icon>
            </h1>
            <!-- <p>You are about to turn WiFi {{changeBluetoothTo}}. Do you wish to continue and reboot?</p> -->
            <p>{{'app.config.wifiChange' | translate:{state:changeBluetoothTo} }}</p>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between center">
            <button style="width: 20vw;" mat-raised-button color="primary" (click)="showWifiWarning = false; setWiFi()">
                <mat-icon style="color:greenyellow">check</mat-icon> {{'app.config.yes' | translate}}
            </button>
            <button style="width: 20vw;" mat-raised-button color="warn" (click)="showWifiWarning = false;">
                <mat-icon style="color:black">close</mat-icon> {{'app.config.no' | translate}}
            </button>
        </div>
    </mat-card>
    <!-- ====================== -->

    <!-- WiFi reset warning -->
    <mat-card class="mat-elevation-z16" style="height: 30vh; width: 85vw; position: absolute; border: 2px solid grey"
        *ngIf="showWifiResetWarning" fxLayout="column" fxLayoutAlign="space-evenly none">
        <div fxLayoutAlign="center center" fxLayout="column">
            <h1>
                <mat-icon style="color:orange">warning</mat-icon> Warning <mat-icon style="color:orange">warning
                </mat-icon>
            </h1>
            <p>{{'app.config.wifiResetWarning' | translate}}</p>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between center">
            <button style="width: 20vw;" mat-raised-button color="primary"
                (click)="showWifiResetWarning = false; resetWiFi()">
                <mat-icon style="color:greenyellow">check</mat-icon> {{'app.config.yes' | translate}}
            </button>
            <button style="width: 20vw;" mat-raised-button color="warn" (click)="showWifiResetWarning = false;">
                <mat-icon style="color:black">close</mat-icon> {{'app.config.no' | translate}}
            </button>
        </div>
    </mat-card>
    <!-- ====================== -->

    <!-- Device Name change warning -->
    <mat-card class="mat-elevation-z16" style="height: 30vh; width: 85vw; position: absolute; border: 2px solid grey"
        *ngIf="showRenameWarning" fxLayout="column" fxLayoutAlign="space-evenly none">
        <div fxLayoutAlign="center center" fxLayout="column">
            <h1>
                <mat-icon style="color:orange">warning</mat-icon> {{'app.config.warning' | translate}} <mat-icon
                    style="color:orange">warning
                </mat-icon>
            </h1>
            <!-- <p>Do you want to change unit name to "{{changeNameTo}}" and reboot?</p> -->
            <p>{{'app.config.renameWarning' | translate:{name:changeNameTo} }}</p>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between center">
            <button style="width: 20vw;" mat-raised-button color="primary"
                (click)="showRenameWarning = false; renameUnit()">
                <mat-icon style="color:greenyellow">check</mat-icon> {{'app.config.yes' | translate}}
            </button>
            <button style="width: 20vw;" mat-raised-button color="warn" (click)="showRenameWarning = false;">
                <mat-icon style="color:black">close</mat-icon> {{'app.config.no' | translate}}
            </button>
        </div>
    </mat-card>
    <!-- ====================== -->

    <!-- OTA warning -->
    <mat-card class="mat-elevation-z16" style="height: 30vh; width: 85vw; position: absolute; border: 2px solid grey"
        *ngIf="showOTAWarning" fxLayout="column" fxLayoutAlign="space-evenly none">
        <div fxLayoutAlign="center center" fxLayout="column">
            <h1>
                <mat-icon style="color:orange">warning</mat-icon> {{'app.config.warningWord' | translate}} <mat-icon
                    style="color:orange">warning
                </mat-icon>
            </h1>
            <p>{{'app.config.otaWarning' | translate}}</p>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between center">
            <button style="width: 20vw;" mat-raised-button color="primary"
                (click)="showRenameWarning = false; startOTA()">
                <mat-icon style="color:greenyellow">check</mat-icon> {{'app.config.yes' | translate}}
            </button>
            <button style="width: 20vw;" mat-raised-button color="warn" (click)="showOTAWarning = false;">
                <mat-icon style="color:black">close</mat-icon> {{'app.config.no' | translate}}
            </button>
        </div>
    </mat-card>
    <!-- ====================== -->

    <!-- ====================== -->

    <!-- Factory reset warning -->
    <mat-card class="mat-elevation-z16" style="height:auto; width: 85vw; position: absolute; border: 2px solid grey"
        *ngIf="showResetWarning" fxLayout="column" fxLayoutAlign="space-evenly none">
        <div fxLayoutAlign="center center" fxLayout="column">
            <h1>
                <mat-icon style="color:orange">warning</mat-icon> {{'app.config.warningWord' | translate}} <mat-icon
                    style="color:orange">warning
                </mat-icon>
            </h1>
            <p>{{'app.config.factoryResetWarning' | translate}}</p>
            <mat-checkbox style="margin: 8px;" [(ngModel)]="canReset">{{'app.config.accept' | translate}}</mat-checkbox>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between center">
            <button style="width: 45%; margin-top:8px; margin-bottom: 8px;" mat-raised-button color="primary"
                [disabled]="!canReset" (click)="showResetWarning = false; factoryReset()">
                <mat-icon style="color:greenyellow">check</mat-icon> {{'app.config.resetDevice' | translate}}
            </button>
            <button style="width: 45%; margin-top:8px; margin-bottom: 8px;" mat-raised-button color="warn"
                (click)="showResetWarning = false;">
                <mat-icon style="color:black">close</mat-icon> {{'app.config.cancel' | translate}}
            </button>
        </div>
    </mat-card>
    <!-- ====================== -->

</div>