
<div fxFlex fxLayoutAlign="center center" fxLayout="column">

    <div fxFlex fxLayoutAlign="center center" fxLayout="column">

        <app-user-admin (close)="userAdmin = false" *ngIf="userAdmin" ></app-user-admin>
        <app-site-admin (close)="siteAdmin = false" *ngIf="siteAdmin"></app-site-admin>
        <app-password-update (close)="changePassword = false" *ngIf="changePassword"></app-password-update>
        <app-biometrics (close)="setBiometrics = false" *ngIf="setBiometrics"></app-biometrics>
        <app-notification-config (close)="notifications = false" *ngIf="notifications"></app-notification-config>
        <app-sub-contractors-admin (close)="subcontractorAdmin = false" *ngIf="subcontractorAdmin"></app-sub-contractors-admin>
        

        <mat-card *ngIf="!userAdmin && !siteAdmin && !changePassword && !setBiometrics && !notifications && !subcontractorAdmin" class="mat-elevation-z16"
            style="height: 75vh; width: 85vw;" fxLayoutAlign="space-evenly none" fxLayout="column">
            <div fxLayout="row" fxLayoutAlign="space-evenly center" *ngIf="isAdmin || canCommission">
                <div matRipple (click)="openUserAdmin()" class="block-button mat-elevation-z16" fxLayout="column" fxLayoutAlign="center center">
                    <mat-icon style="color: greenyellow">person</mat-icon>
                    <label>{{'app.adminHome.userLabel' | translate}}</label>
                </div>

                <div matRipple (click)="siteAdmin = true" class="block-button mat-elevation-z16" fxLayout="column"
                    fxLayoutAlign="center center">
                    <mat-icon style="color: greenyellow">domain</mat-icon>
                    <label>{{'app.adminHome.sitesLabel' | translate}}</label>
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-evenly start">
                
                <div matRipple (click)="trySubcontractorEdit()" class="block-button mat-elevation-z16" fxLayout="column" fxLayoutAlign="center center">
                    <mat-icon style="color: greenyellow">groups</mat-icon>
                    <label style="font-size: smaller;">{{'app.adminHome.subContractorsLabel' | translate}}</label>
                </div>
                
                <div matRipple (click)="notifications = true" class="block-button mat-elevation-z16" fxLayout="column"
                    fxLayoutAlign="center center">
                    <mat-icon style="color: greenyellow">notifications</mat-icon>
                    <label>{{'app.adminHome.notificationsLabel' | translate}}</label>
                </div>
                
            </div>

            <div fxLayout="row" fxLayoutAlign="space-evenly center">
                <div matRipple (click)="changePassword = true" class="block-button mat-elevation-z16" fxLayout="column"
                    fxLayoutAlign="center center">
                    <mat-icon style="color: greenyellow">key</mat-icon>
                    <label>{{'app.adminHome.pwdChangeLabel' | translate}}</label>
                </div>

                <div (click)="setBiometrics = true" class="block-button mat-elevation-z16" fxLayout="column"
                    fxLayoutAlign="center center">
                    <mat-icon style="color: greenyellow">fingerprint</mat-icon>
                    <label>{{'app.adminHome.biometricsLabel' | translate}}</label>
                </div>
            </div>

         

            <a (click)="openHelp('admin')" style="position: absolute; bottom: 0px; right: 0px; margin: 4px;"><mat-icon>help_outline</mat-icon></a>
        </mat-card>
    </div>
</div>
