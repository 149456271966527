import { Component, OnInit } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { LoaderService } from 'src/app/services/loader.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { WebRequestsService } from 'src/app/services/web-requests.service';
import jwt_decode from 'jwt-decode';
import { BiometricsService } from 'src/app/services/biometrics.service';
import { ConnectivityService } from 'src/app/services/connectivity.service';
import { PwaService } from 'src/app/services/pwa.service';
import { SwPush } from '@angular/service-worker';
import * as FP from '@fingerprintjs/fingerprintjs';
import { LocalDatabaseService } from 'src/app/services/local-database.service';
// var pjson = require('../../../../package.json');
var pjson = require('../../../../package.json');

var bcrypt = require('bcryptjs');
// import bcrypt from "bcrypt";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public user = {};
  public email: string = "";
  public password: string = "";
  public pwdCred = { id: "werner", name: "Werner", password: "test" }
  public showDBSelect = false;
  public clients: any[] = [];
  public selectedClient = "";
  public resetPassword: boolean = false;
  public routeToGo: any = "";

  public clientDB: string = "";

  public jwtData: any = {};

  public requestAccount: boolean = false;
  public requirements: string = "";
  public newEmail: string = "";
  public trustedDevice: boolean = false;
  public enterOTP: boolean = false;
  public otp: string = "";
  public appVersion = "";
  public inputType = 'password';

  constructor(private router: Router,
    private webReq: WebRequestsService,
    private auth: AuthService,
    public loader: LoaderService,
    public snackBar: MatSnackBar,
    public notifier: NotificationsService,
    public biometrics: BiometricsService,
    public conn: ConnectivityService,
    public pwa: PwaService,
    public swPush: SwPush,
    public localDB: LocalDatabaseService) { }

  async ngOnInit() {
    // console.log(FP)
    this.getVersion();
    this.localDB.checkDeviceDB().then(result => {
      if(result) {
        this.trustedDevice = true;
      } else {
        this.trustedDevice = false;
      }
    }) 
    this.loader.isLoading = false;
    // console.log("Online: " + this.conn.online);
    this.tryBiometricLogin();
    if (localStorage.getItem('lastRoute') != null && localStorage.getItem('lastRoute') != undefined) {
      this.routeToGo = localStorage.getItem('lastRoute');
    }

  }

  toggleInputType() {
    if(this.inputType == 'password') {
      this.inputType = 'text';
    } else {
      this.inputType = 'password';
    }
  }

  tryBiometricLogin() {

    var hasBiometrics = localStorage.getItem('hasBiometrics');
    if (hasBiometrics != null && hasBiometrics != undefined && hasBiometrics == 'true') {
      if (localStorage.getItem('userID') != null || localStorage.getItem('userID') != undefined) {
        this.biometrics.authenticateFinger().then((res: any) => {
          if (res == true) {


            // Check online for actual login as well
            this.conn.checkOnline();
            if (this.conn.online) {
              let userID = localStorage.getItem('userID'); // Device is online and fingerprint authed, login through api for jwt etc.
              let username = localStorage.getItem('username');
              this.webReq.biometricsLogin({ userID: userID, username: username }).subscribe((data: any) => {
                if (data.success == true) {
                  localStorage.setItem('access_token', data.access_token)
                  this.jwtData = jwt_decode(data.access_token);
                  localStorage.setItem('username', this.jwtData.username);
                  localStorage.setItem('email', this.jwtData.email);
                  localStorage.setItem('userID', this.jwtData._id);
                  localStorage.setItem('isLoggedIn', 'true');
                  localStorage.setItem('userType', this.jwtData.userType);
                  if (this.jwtData.isAdmin == true) {
                    localStorage.setItem('admin', 'true')
                  } else {
                    localStorage.setItem('admin', 'false')
                  }
                  if (this.jwtData.tempAdmin == true) {
                    localStorage.setItem('tempAdmin', 'true');
                  } else {
                    localStorage.removeItem('tempAdmin');
                  }
                  if (this.jwtData.sysAdmin == true) {
                    localStorage.setItem('sysAdmin', 'true');
                  } else {
                    localStorage.removeItem('sysAdmin');
                  }
                  if (this.jwtData.generateTokens == true) {
                    localStorage.setItem('generateTokens', 'true');
                  } else {
                    localStorage.setItem('generateTokens', 'false')
                  }
                  if (this.jwtData.canCommission == true) {
                    localStorage.setItem('canCommission', 'true');
                  } else {
                    localStorage.setItem('canCommission', 'false')
                  }
                  if (this.jwtData.canHandover == true) {
                    localStorage.setItem('canHandover', 'true');
                  } else {
                    localStorage.setItem('canHandover', 'false')
                  }
                  if (this.jwtData.canConfigure == true) {
                    localStorage.setItem('canConfigure', 'true');
                  } else {
                    localStorage.setItem('canConfigure', 'false');
                  }

                  if(this.trustedDevice) {
                    this.auth.isLoggedIn = true;
                    this.loader.isLoading = false;
  
                    if (this.jwtData.clientdb.length > 1) {
                      this.clients = this.jwtData.clientdb;
                      this.showDBSelect = true;
                    } else if (this.jwtData.clientdb.length == 1) {
                      this.clients = this.jwtData.clientdb;
                      this.selectedClient = this.jwtData.clientdb[0];
                      localStorage.setItem('clientdb', this.selectedClient); // Here check the clientdb for auth requirements!!!!
  
                      this.webReq.checkAuthReqs(this.selectedClient).subscribe(results => {
                        if (results.authOnKey == true) {
                          localStorage.setItem('authOnKey', 'true');
                        } else {
                          localStorage.setItem('authOnKey', 'false');
                        }
                        if (results.authOnConnect == true) {
                          localStorage.setItem('authOnConnect', 'true');
                        } else {
                          localStorage.setItem('authOnConnect', 'false');
                        }
  
                        this.subscribeNotifications();
                        if (localStorage.getItem('shouldGoLastRoute') != 'true') {
                          this.router.navigate([''], { replaceUrl: true });
                        } else {
                          localStorage.setItem('shouldGoLastRoute', 'false');
                          this.router.navigateByUrl(decodeURIComponent(this.routeToGo));
                        }
                      });
  
                    }
                  } else {
                    this.webReq.requestOTP({userID: this.jwtData._id}).subscribe(result => {
                      this.loader.isLoading = false;
                      if(result.success == true){
                        this.enterOTP = true;
                        this.notifier.openMessage(result.message, 'success',5)
                      } else {
                        this.notifier.openMessage(result.message, 'error', 5);
                      }
                    });
                  }
                  

                }
              })
            } else { // Device offline -> use offline authentication (Need to reLogin to get new JWT and be able to get new tokens etc.)
              localStorage.setItem('isLoggedIn', 'true');
              this.auth.isLoggedIn = true;
              this.loader.isLoading = false;
              this.notifier.openMessage('Offline Login Success', 'success', 2)
              if (localStorage.getItem('shouldGoLastRoute') != 'true') {
                this.router.navigate([''], { replaceUrl: true });
              } else {
                localStorage.setItem('shouldGoLastRoute', 'false');
                this.router.navigateByUrl(encodeURIComponent(this.routeToGo));
              }
            }




          } else {
            // console.log("Can't authenticate");
            this.loader.isLoading = false;
            this.notifier.openMessage('Invalid Credentials', 'error');
          }
        });
      }
    }
  }

  async mongoLogin() {
    this.loader.isLoading = true;
    this.user = {
      'username': this.email,
      'password': this.password,
    }

    this.conn.checkOnline();
    if (!this.conn.online) {
      if (bcrypt.compareSync(this.password, localStorage.getItem('mop'))) {
        localStorage.setItem('isLoggedIn', 'true');
        this.loader.isLoading = false;
        this.notifier.openMessage('Offline login success', 'success', 3);
        this.router.navigate([''], { replaceUrl: true });
      } else {
        this.loader.isLoading = false;
        this.notifier.openMessage('Offline login failed', 'error', 3);
      }
    }
    else {
      this.webReq.login(this.user).subscribe(data => {
        if(data.success && !this.trustedDevice) {
          localStorage.setItem('mop', bcrypt.hashSync(this.password, 10));
          localStorage.setItem('access_token', data.access_token)
          this.jwtData = jwt_decode(data.access_token);
          localStorage.setItem('username', this.jwtData.username);
          localStorage.setItem('email', this.jwtData.email);
          localStorage.setItem('userID', this.jwtData._id);
          this.webReq.requestOTP({userID: this.jwtData._id}).subscribe(result => {
            if(result.success == true) {
              this.enterOTP = true;
              this.notifier.openMessage(result.message, 'success', 5);
            } else {
              this.notifier.openMessage(result.message, 'error', 5);
            }
            this.loader.isLoading = false;

          });
          
        } else {
          if (data.success == true) {
            localStorage.setItem('mop', bcrypt.hashSync(this.password, 10));
            localStorage.setItem('access_token', data.access_token)
            this.jwtData = jwt_decode(data.access_token);
            localStorage.setItem('username', this.jwtData.username);
            localStorage.setItem('email', this.jwtData.email);
            localStorage.setItem('userID', this.jwtData._id);
            localStorage.setItem('isLoggedIn', 'true');
            localStorage.setItem('userType', this.jwtData.userType);
            if (this.jwtData.isAdmin == true) {
              localStorage.setItem('admin', 'true')
            } else {
              localStorage.setItem('admin', 'false')
            }
            if (this.jwtData.tempAdmin == true) {
              localStorage.setItem('tempAdmin', 'true');
            } else {
              localStorage.removeItem('tempAdmin');
            }
            if (this.jwtData.sysAdmin == true) {
              localStorage.setItem('sysAdmin', 'true');
            } else {
              localStorage.removeItem('sysAdmin');
            }
  
            if (this.jwtData.generateTokens == true) {
              localStorage.setItem('generateTokens', 'true');
            } else {
              localStorage.setItem('generateTokens', 'false')
            }
            if (this.jwtData.canCommission == true) {
              localStorage.setItem('canCommission', 'true');
            } else {
              localStorage.setItem('canCommission', 'false')
            }
            if (this.jwtData.canHandover == true) {
              localStorage.setItem('canHandover', 'true');
            } else {
              localStorage.setItem('canHandover', 'false')
            }
  
            if (this.jwtData.canConfigure == true) {
              localStorage.setItem('canConfigure', 'true');
            } else {
              localStorage.setItem('canConfigure', 'false');
            }
            this.auth.isLoggedIn = true;
            this.loader.isLoading = false;
  
            var askForBioEnroll = localStorage.getItem('askForBiometricEnrollment');
  
            if (askForBioEnroll == null || askForBioEnroll == undefined) {
              this.biometrics.enrollAuth().then(res => {
                // this.router.navigate([''], { replaceUrl: true });
                if (res == true) {
                  localStorage.setItem('askForBiometricEnrollment', 'false');
                  localStorage.setItem('hasBiometrics', 'true');
                  alert('Biometrics Enrolled');
                }
                else {
                  localStorage.setItem('askForBiometricEnrollment', 'false');
                  alert('Enrollment Cancelled');
                }
  
              }).catch(err => {
                localStorage.setItem('askForBiometricEnrollment', 'false');
                alert('Enrollment Cancelled');
              });
            }
  
            if (this.jwtData.clientdb.length > 1) {
              this.clients = this.jwtData.clientdb;
              this.showDBSelect = true;
            } else if (this.jwtData.clientdb.length == 1) {
              this.clients = this.jwtData.clientdb;
              this.selectedClient = this.jwtData.clientdb[0];
              localStorage.setItem('clientdb', this.selectedClient); // Also Check auth requirements here
  
              this.webReq.checkAuthReqs(this.selectedClient).subscribe(results => {
                if (results.authOnKey == true) {
                  localStorage.setItem('authOnKey', 'true');
                } else {
                  localStorage.setItem('authOnKey', 'false');
                }
                if (results.authOnConnect == true) {
                  localStorage.setItem('authOnConnect', 'true');
                } else {
                  localStorage.setItem('authOnConnect', 'false');
                }
  
                if (localStorage.getItem('shouldGoLastRoute') != 'true') {
                  this.router.navigate([''], { replaceUrl: true });
                } else if(localStorage.getItem('shouldGoLastRoute') == 'true') {
                  localStorage.setItem('shouldGoLastRoute', 'false');
                  this.router.navigateByUrl(decodeURIComponent(this.routeToGo));
                }
              });
  
  
            }
          } else {
            this.loader.isLoading = false;
            this.notifier.openMessage('Invalid Credentials', 'error');
          }
        }
        
      }, err => {
        this.loader.isLoading = false;
        this.notifier.openMessage('Try Again Later', 'error');
      });
    }

  }
  continue() {
    if (this.selectedClient.length == 0 || this.selectedClient == null || this.selectedClient == undefined) {
      this.notifier.openMessage('Please select a client', 'info', 3);
    } else {
      localStorage.setItem('clientdb', this.selectedClient); // Also check auth requirements here!!!!

      this.webReq.checkAuthReqs(this.selectedClient).subscribe(results => {
        if (results.authOnKey == true) {
          localStorage.setItem('authOnKey', 'true');
        } else {
          localStorage.setItem('authOnKey', 'false');
        }
        if (results.authOnConnect == true) {
          localStorage.setItem('authOnConnect', 'true');
        } else {
          localStorage.setItem('authOnConnect', 'false');
        }
        this.subscribeNotifications();
        this.subscribeNotificationClick();
        if (localStorage.getItem('shouldGoLastRoute') != 'true') {
          this.router.navigate([''], { replaceUrl: true });
        } else {
          localStorage.setItem('shouldGoLastRoute', 'false');
          this.router.navigateByUrl(decodeURIComponent(this.routeToGo));
        }
      })

    }

  }


  subscribeNotifications() {
    if (this.swPush.isEnabled) {
      this.pwa.subscribeToPush().then(subscription => {
        // console.log("Subscription returned")
        if (localStorage.getItem('notificationSubscription') != 'true') {
          this.webReq.subscribeNotifications({
            username: localStorage.getItem('username'),
            userID: localStorage.getItem('userID'),
            subscription: subscription,
            clientdb: localStorage.getItem('clientdb')
          }).subscribe((res: any) => {
            if (res.success == 'true')
              localStorage.setItem('notificationSubscription', 'true');
          })
        }
      });
    }
  }

  subscribeNotificationClick() {
    if (this.swPush.isEnabled) {
      this.pwa.subscribeToNotificationClicks().then(myClick => {
      })
    }
  }

  goToFlags() {
    // navigator.tabs.create({url:"chrome://flags/#enable-experimental-web-platform-features"});
  }
  ChromeFlags() {
    window.open("chrome://flags/#enable-experimental-web-platform-features");
  }

  storeCred() {
    let myCred = new PasswordCredential({ id: this.email, password: this.password, name: this.email, iconURL: '' });
    navigator.credentials.store(myCred)
  }

  registerCredential() {
    try {
      navigator.credentials.create({
        publicKey: {
          rp: {
            id: 'https://app.myprism.co.za/',
            // id: 'localhost',
            // id: '192.168.1.166',
            name: 'test'
          },
          user: {
            id: new Uint8Array(16),
            name: this.email,
            displayName: this.email
          },
          challenge: new Uint8Array(16),
          pubKeyCredParams: [
            {
              type: 'public-key',
              alg: -7
            }
          ]
        }
      }).then(c => {
        alert('Credential registered');
      }).catch(ex => {
        alert(ex);
      });
    }
    catch (ex) {
      console.warn(ex);
    }

  }

  sendRequestAccount() {
    this.webReq.requestNewAccount(this.newEmail, this.requirements, this.clientDB).subscribe(res => {
      alert(res.message);
      this.requestAccount = false;
    });
  }

  getLocation() {
    return new Promise((resolve) => {
      try {
        const options = {
          enableHighAccuracy: true,
          timeout: 3000,
          maximumAge: 0
        }
        navigator.geolocation.getCurrentPosition(pos => {
          navigator.geolocation.getCurrentPosition(pos1 => {
            resolve(pos1)
          }, err1 => {
            resolve(err1)
          }, options)
        }, err => {
          resolve(err)
        }, options)
      }catch (err) {
        console.warn(err)
        resolve({coords: {latitude: "00.00", longitude: "00.00"}})
      }


    })
  }

  async authenticateDevice() {
    this.loader.isLoading = true;
    let fpPromise = await FP.load();
    let fpRes = await fpPromise.get();
    let visitorID = fpRes.visitorId;
    let firstTimeLogin = new Date();
    let location:any = await this.getLocation();
    let lat = "00.00"
    let lon = "00.00"
    if(location.coords != undefined) {
      lat = location.coords.latitude;
      lon = location.coords.longitude;
    } 
    
    // console.log(location);
    
    let authObj = {
      visitorID: visitorID,
      firstLogin: firstTimeLogin,
      userID: localStorage.getItem('userID'),
      otp: this.otp,
      lat: lat,
      lon: lon 
    }
    console.log(authObj)
    // Send http request to add this device to the user, on success return to login screen as below
    this.webReq.authenticateOTP(authObj).subscribe(authRes => {
      console.log(authRes)
      if(authRes.modifiedCount == 1) {
        
        this.localDB.createFirstLogin(authObj).then(result => {
          if(result == true) {
            this.enterOTP = false;
            this.trustedDevice = true;   
            this.loader.isLoading = false;
            this.notifier.openMessage('Device Authenticated, please login again', 'success', 5)
          }
        });
      } else {
        this.loader.isLoading = false;
        this.notifier.openMessage("Failed to authenticate", 'error', 3)
      }
      
    });
    
  }

  getVersion() {
    this.appVersion = pjson.version;
  }

  workOSLogin() {
    // this.webReq.workOSLogin({email: this.email, password:this.password}).subscribe(result => {
    //   console.log(result);
    // }, err => {
    //   console.log(err)
    // })
  }

}
