// import { unescapeIdentifier } from '@angular/compiler';
import { Injectable } from '@angular/core';
import base64url from 'base64url';
import { resolve } from 'dns';
import { WebRequestsService } from './web-requests.service';
import { WebauthnService } from './webauthn.service';


const helpers = require('../../assets/biometric-helpers/helpers');
const serverSample = require('../../assets/biometric-helpers/server.sample')


@Injectable({
  providedIn: 'root'
})
export class BiometricsService {

  constructor(public webReq: WebRequestsService, public webAuthnService: WebauthnService) { }

  Register(username: any, password: any) {
    var enc = new TextDecoder()
    var challenge = new Uint8Array(26);
    localStorage.setItem('challenge', enc.decode(challenge));

    return new Promise((resolve, reject) => {
      navigator.credentials.create({
        publicKey: {
          // random, cryptographically secure, at least 16 bytes
          challenge: challenge,
          // relying party
          rp: {
            name: "Prism", // sample relying party
            id: "localhost"
            // id: "myprism.co.za"
          },
          user: {
            id: new Uint8Array(26),
            name: username,
            displayName: username,
          },

          authenticatorSelection: { userVerification: "preferred", authenticatorAttachment: "cross-platform" },
          attestation: "direct",
          pubKeyCredParams: [
            {
              type: "public-key",
              alg: -7, // "ES256" IANA COSE Algorithms registry
              // alg: -257,
            },
            {
              type: "public-key",
              alg: -257
            },
          ],
        },
      })
        .then((res: any) => {
          // var json = JSON.parse(res);
          localStorage.setItem("credentialID", res.id);

          console.info("Registered Credential:");
          resolve(true);
        })
        .catch(err => {
          console.warn(err.message);
          resolve(false);
        });

    })

  }

  Authenticate() {
    var credID = localStorage.getItem('credentialID');
    var challengeString: string | null = localStorage.getItem('challenge');
    var challengeUint: Uint8Array;
    var credIDBuff: Uint8Array;
    var encoder = new TextEncoder();
    if (challengeString != null && credID != null) {
      challengeUint = encoder.encode(challengeString);
      credIDBuff = encoder.encode(credID);
    }


    return new Promise((resolve, reject) => {
      if (credIDBuff == null || credIDBuff == undefined || challengeUint == null || challengeUint == undefined) {
        resolve(false);
      }
      navigator.credentials.get({
        publicKey: {
          // random, cryptographically secure, at least 16 bytes
          challenge: challengeUint,
          allowCredentials: [
            {
              id: credIDBuff,
              type: "public-key",
            },
          ],
          timeout: 150000,
          userVerification: "preferred",
          // authenticatorSelection: { userVerification: "preferred" },
        },
      })
        .then((res) => {
          // Send data to relying party's servers

          resolve(true);
        })
        .catch((err) => {
          alert("Invalid FIDO device");
          console.warn(err.message)
          resolve(false);
        });
    })

  }

  enrollAuth() {
    // this.webAuthnService.myRegister();

    return new Promise((resolve,reject) => {
      let _username = localStorage.getItem('userID');
    
      // var _username = localStorage.getItem()
      serverSample.registerPassword({ username: _username, displayName: _username }).then((serverResponse: any) => {
        if (serverResponse.status !== 'startFIDOEnrolment')
          throw new Error('Error registering user! Server returned: ' + serverResponse.errorMessage);
  
        return serverSample.getMakeCredentialChallenge({user: { username: _username, displayName: _username, id: _username }})
      }).then((makeCredChallenge: any) => {
        makeCredChallenge = helpers.preformatMakeCredReq(makeCredChallenge);
        return navigator.credentials.create({ 'publicKey': makeCredChallenge })
      }).then((newCredentialInfo: any) => {
        newCredentialInfo = helpers.publicKeyCredentialToJSON(newCredentialInfo)
  
        return serverSample.makeCredentialResponse(newCredentialInfo)
      }).then((serverResponse:any) => {
        if(serverResponse.status !== 'ok')
            throw new Error('Error registering user! Server returned: ' + serverResponse.errorMessage);
        
        // alert('Success!');
        resolve(true);
    })
    .catch((error:any) => {
        // alert('FAIL' + error)
        console.warn('FAIL', error)
        localStorage.removeItem(_username != null ? _username : '');

        reject(false);
    })
    });

  
  }

  authenticateFinger() {
    return new Promise((resolve,reject) => {
      let result = false;
      let _username = localStorage.getItem('userID');
      serverSample.loginPassword({username:_username})
      .then((serverResponse:any) => {
          if(serverResponse.status !== 'startFIDOAuthentication')
              throw new Error('Error logging in! Server returned: ' + serverResponse.errorMessage);  
          return serverSample.getGetAssertionChallenge()
      })
      .then((getAssertionChallenge:any) => {
          getAssertionChallenge = helpers.preformatGetAssertReq(getAssertionChallenge);
          return navigator.credentials.get({ 'publicKey': getAssertionChallenge })
      })
      .then((newCredentialInfo:any) => {
          newCredentialInfo = helpers.publicKeyCredentialToJSON(newCredentialInfo)
  
          return serverSample.getAssertionResponse(newCredentialInfo)
      })
      .then((serverResponse:any) => {
          if(serverResponse.status !== 'ok')
              throw new Error('Error registering user! Server returned: ' + serverResponse.errorMessage);
          // alert('Success!');
          result = true;
          resolve(true)
      })
      .catch((error:any) => {
          // alert('FAIL' + error)
          console.warn('FAIL', error)
          resolve(false);
      })
    });
    
  }

  authenticateFingerOffline() {
      let _username = localStorage.getItem('userID');
      serverSample.loginPassword({username:_username})
      .then((serverResponse:any) => {
          if(serverResponse.status !== 'startFIDOAuthentication')
              throw new Error('Error logging in! Server returned: ' + serverResponse.errorMessage);  
          return serverSample.getGetAssertionChallenge()
      })
      .then((getAssertionChallenge:any) => {
          getAssertionChallenge = helpers.preformatGetAssertReq(getAssertionChallenge);
          return navigator.credentials.get({ 'publicKey': getAssertionChallenge })
      })
      .then((newCredentialInfo:any) => {
          newCredentialInfo = helpers.publicKeyCredentialToJSON(newCredentialInfo)
  
          return serverSample.getAssertionResponse(newCredentialInfo)
      })
      .then((serverResponse:any) => {
          if(serverResponse.status !== 'ok')
              throw new Error('Error registering user! Server returned: ' + serverResponse.errorMessage);
          alert('Success!');
          return true;
      })
      .catch((error:any) => {
          alert('FAIL' + error)
          console.warn('FAIL', error)
          return false;
      })
  }

}
