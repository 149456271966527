import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BiometricsService } from 'src/app/services/biometrics.service';
import { LoaderService } from 'src/app/services/loader.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { WebRequestsService } from 'src/app/services/web-requests.service';
var bcrypt = require('bcryptjs');

@Component({
  selector: 'app-generate-system-tokens',
  templateUrl: './generate-system-tokens.component.html',
  styleUrls: ['./generate-system-tokens.component.scss']
})
export class GenerateSystemTokensComponent implements OnInit {

  @Output() close = new EventEmitter();
  @Input() reasonList: any[] = ["None"]; 
  @Input() users: any;
  @Input() filteredUsers:any;

  password: any = "";
  showEnterPwd = false;

  reason: any;
  
  public userList: any;
  // public filteredUsers: any;
  public userFilter: any;
  public userSites: any;
  public filteredUserSites:any;
  public siteFilter: any;
  public siteID: any;
  public username: any;
  public tokensSet = false;
  public fromDate:any  = new Date(Date.now() + 119*60000).toISOString().slice(0, 16); // toISO returns GMT Time (-2 hours)
  public toDate:any = new Date(Date.now() + 150*60000).toISOString().slice(0, 16);
  public minDate: any = new Date(Date.now() + 118*60000).toISOString().slice(0, 16);

  constructor(public webReq: WebRequestsService, 
    public loader: LoaderService, 
    public notifier: NotificationsService, 
    public translate: TranslateService,
    public biometrics: BiometricsService) { 
    translate.setDefaultLang('en');
    translate.use(localStorage.getItem('lang')!);
  }

  ngOnInit(): void {
    this.filteredUsers.sort((a:any,b:any)=> a.username.localeCompare(b.username));
  }

  applyUsersFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.filteredUsers = this.users.filter((x:any) => x.username.toLowerCase().includes(filterValue));
    if(filterValue == "") {
      this.filteredUsers = this.users;
    }
  }

  applySitesFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.filteredUserSites = this.userSites.filter((x:any) => x.siteName.toLowerCase().includes(filterValue) || x.siteID.toString().includes(filterValue));
    if(filterValue == "") {
      this.filteredUserSites = this.userSites;
    }
  }

  clearUserFilter(event:any) {
    this.userFilter = '';
    this.filteredUsers = this.users;
  }

  clearSitesFilter(event:any) {
    this.siteFilter = '';
    this.filteredUserSites = this.userSites;
  }

  authenticate() {
    if(localStorage.getItem('authOnKey') == 'true') {
      var hasBiometrics = localStorage.getItem('hasBiometrics');
      if (hasBiometrics != null && hasBiometrics != undefined && hasBiometrics == 'true') {
        if (localStorage.getItem('userID') != null || localStorage.getItem('userID') != undefined) {
          this.biometrics.authenticateFinger().then(res => {
            if(res == true) { // Continue getting keys
              this.generateSystemTokens(this.siteID);
            }
            else {
              this.notifier.openMessage('Failed to authenticate', 'error', 3);
            }
          })
        }
  
      } else {
        this.showEnterPwd = true;
      }
    } else {
        this.generateSystemTokens(this.siteID);
    }
  }
  confirmPassword() {
    if (bcrypt.compareSync(this.password, localStorage.getItem('mop'))) { // Call this only if biometrics unavailable
   // Continue getting keys
   this.showEnterPwd = false;
   this.generateSystemTokens(this.siteID);
  } else {
   this.notifier.openMessage('Failed to authenticate', 'error', 3);
 }
}

  generateSystemTokens(site:any) {
    this.getTokensNew(site);
  }

  getUserSites(user: any) {
      this.userSites = user.sites.filter((x:any)=> x != null && x.clientdb == localStorage.getItem('clientdb'));
      this.userSites = this.userSites.filter((x:any) => x.handedOver != true);

      this.filteredUserSites = this.userSites;
  }

  getTokens(site:any) {
    this.loader.isLoading = true;
    //Time Token:
    this.webReq.generateSystemTokens(site.id, site.siteID,1, [0],this.username.username).subscribe(res => {
      //Function Codes:
      this.webReq.generateSystemTokens(site.id, site.siteID,0, [0, 1, 2],this.username.username).subscribe(res => {        
        this.tokensSet = true;
        this.loader.isLoading = false;
        this.notifier.openMessage('Tokens generated', 'success');
      }, err => {
        this.loader.isLoading = false;
        this.notifier.openMessage('Failed to generate tokens', 'error');
      });
    }, err => {
      this.loader.isLoading = false;
      this.notifier.openMessage('Failed to generate tokens', 'error');
    });
  }

  getTokensNew(site:any) {
    this.loader.isLoading = true;
    // Codes are passed [<function_code>,<operational_code>]

    let codes = [];
    if (site.door1 == true && site.door2 == true) {
      codes = [[1, 0], [0, 0], [0, 1], [0, 2], [3, 0], [2, 0], [4, 0], [5,0], [5,1], [5,2]]
    } else if (site.door2 == true) {
      codes = [[1, 0], [3, 0], [2, 0], [4, 0], [5,0], [5,1], [5,2]]
    } else {
      codes = [[1, 0], [0, 0], [0, 1], [0, 2], [3, 0], [2, 0], [4, 0]]
    }

    this.webReq.generateSystemTokensNew(site,codes,new Date(this.fromDate).getTime(), new Date(this.toDate).getTime(),this.username.username, this.username._id, this.reason).subscribe(res=> {
      this.tokensSet = true;
      this.loader.isLoading = false;
      console.log(res)
      if(res.success == false) {
        this.notifier.openMessage(res.message, 'error');
      } else {
        this.notifier.openMessage('v-Keys generated', 'success');
      }
    }, err => {
      this.loader.isLoading = false;
      this.notifier.openMessage('Failed to generate v-Keys', 'error');
    })
  }

  closing() {
    this.close.emit();
  }

}
