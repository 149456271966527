<app-loader></app-loader>
<div class="table-container">
    <table mat-table [dataSource]="myRequests" matSort>
    
        <ng-container matColumnDef="siteID">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Site ID">
                Site ID</th>
            <td mat-cell *matCellDef="let element" [ngClass]="getClassByAccess(element.access)">
                {{element.siteID}}
            </td>
        </ng-container>
    
        <ng-container matColumnDef="siteName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Site siteName">
                Site Name</th>
            <td mat-cell *matCellDef="let element" [ngClass]="getClassByAccess(element.access)">
                {{element.siteName}}
            </td>
        </ng-container>
    
        <ng-container matColumnDef="from">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by From">
                From</th>
            <td mat-cell *matCellDef="let element" [ngClass]="getClassByAccess(element.access)">
                {{element.fromDate | date:"MM/dd/yy HH:mm"}}
            </td>
        </ng-container>
    
        <ng-container matColumnDef="to">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by to">
                To</th>
            <td mat-cell *matCellDef="let element" [ngClass]="getClassByAccess(element.access)">
                {{element.toDate | date:"MM/dd/yy HH:mm"}}
            </td>
        </ng-container>
    
        <ng-container matColumnDef="access">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by access">
                Access</th>
            <td mat-cell *matCellDef="let element" [ngClass]="getClassByAccess(element.access)">
                {{element.access}}
            </td>
        </ng-container>

        <ng-container matColumnDef="comment">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by comment">
                Comment</th>
            <td mat-cell *matCellDef="let element" [ngClass]="getClassByAccess(element.access)">
                {{element._comment}}
            </td>
        </ng-container>

        <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef>
                Delete</th>
            <td mat-cell *matCellDef="let element">
               <mat-icon style="color: red" (click)="deleteKeyRequest(element)">delete</mat-icon>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>

<mat-paginator #apipaginator [length]="myRequests.data.length" [pageSizeOptions]="[5, 10, 15, 20, 25, 50]"
style="position: absolute; bottom:8vh; width: 100%; left: 0;"></mat-paginator>