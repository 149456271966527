<mat-tab-group backgroundColor="accent" style="background-color: #6e6e6e;">
    <mat-tab label="Api">
        <div style="max-height: 78vh; height: 78vh; overflow: auto; width: 100vw; top:0;">
            <table mat-table matSort [dataSource]="apiData" style="width: 100%;">
            
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by action">
                        Action </th>
                    <td mat-cell *matCellDef="let element">
                        {{getAction(element)}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="site">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Site">
                        Site </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.sitename}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="time">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="time">
                        Date </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.time | date:"MM/dd/yy HH:mm"}}
                    </td>
                </ng-container>

    
                <tr mat-header-row *matHeaderRowDef="displayedColumnsAPI"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsAPI;"></tr>
            </table>
        </div>
       
        <mat-paginator #apipaginator [length]="apiData.data.length" [pageSizeOptions]="[5, 10, 15, 20, 25, 50]"
        style="position: absolute; bottom:0; width: 100%;"></mat-paginator>
    </mat-tab>

    <mat-tab label="Operations">
        <div style="max-height: 78vh; height: 78vh; overflow: auto; width: 100vw; top:0;">
            <table mat-table matSort [dataSource]="actionsData" style="width: 100%;">
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by action">
                        Action </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.action}}
                        <!-- {{element.email}} -->
                    </td>
                </ng-container>
    
                <ng-container matColumnDef="site">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by site">
                        Site </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.site}}
                        <!-- {{element.email}} -->
                    </td>
                </ng-container>

                <ng-container matColumnDef="time">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="time">
                        Date </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.time | date:"MM/dd/yy HH:mm"}}
                    </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumnsActions"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsActions;"></tr>
            </table>
            
            <mat-paginator #actionspaginator [length]="actionsData.data.length" [pageSizeOptions]="[5, 10, 15, 20, 25, 50]"
            style="position: absolute; bottom: 0; width: 100%;"></mat-paginator>
        </div>
        
       
    </mat-tab>
</mat-tab-group>

<button mat-mini-fab style="position: absolute; z-index: 1011; bottom: 10vh; left: 4px;" color="primary" (click)="exportHistory()"><mat-icon>upload</mat-icon></button>