<div fxFlex fxLayoutAlign="center center" fxLayout="column">
    <div class="scanner-container" *ngIf="cameraOpen && !typingSN" >
        <zxing-scanner #scanner (scanSuccess)="scanSuccessHandler($event)"></zxing-scanner>
        <button style="margin: 4px;" mat-raised-button (click)="typingSN = true;"><mat-icon color="primary">edit</mat-icon> {{'app.serializer.typeSNLabel' | translate}}</button>
        <button style="margin: 4px;" mat-raised-button (click)="cameraOpen = false; onClose()"><mat-icon color="warn">close</mat-icon> {{'app.serializer.closeLabel' | translate}}</button>
    </div>

    <div *ngIf="typingSN" >
        <mat-form-field  >
            <input matInput [(ngModel)]="serialNumber">
        </mat-form-field>

        <button mat-raised-button style="margin: 4px;"><mat-icon>camera</mat-icon>{{'app.serializer.scanQRLabel' | translate}}</button>
        <button mat-raised-button style="margin: 4px;" (click)="scanSuccessHandler(serialNumber)"><mat-icon>send</mat-icon>{{'app.serializer.submitLabel' | translate}}</button>
        <button mat-raised-button style="margin: 4px;" (click)="onClose()"><mat-icon>close</mat-icon>{{'app.serializer.cancelLabel' | translate}}</button>
    </div>
</div>