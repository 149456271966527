import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { TranslateService } from '@ngx-translate/core';
import { LocalDatabaseService } from 'src/app/services/local-database.service';
import { WebRequestsService } from 'src/app/services/web-requests.service';

@Component({
  selector: 'app-my-tokens',
  templateUrl: './my-tokens.component.html',
  styleUrls: ['./my-tokens.component.scss']
})
export class MyTokensComponent implements OnInit {

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  public useDB: boolean = false;
  public db: any;
  public availableSites: Set<string> = new Set();
  public siteAvailability: any[] = [];
  public noTokens: boolean = false;
  public dataSource = new MatTableDataSource<any>(this.siteAvailability);
  public displayedColumns = ['siteID','sitename', 'from', 'to', 'delete', 'info']
  public connection: any;

  public allTokens:any = [];

  public contactPerson = {name: '', email:'', cellphone: ''};
  public showInfo = false;

  constructor(public localDB: LocalDatabaseService, public translate: TranslateService, public webReq: WebRequestsService) { 
    translate.setDefaultLang('en');
    translate.use(localStorage.getItem('lang')!);
  }


  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {
    this.localDB.dbConnect().then((res: any) => {
      this.db = res.result;
      this.localDB.getAllTokens(this.db).then(res => {
        this.allTokens = res;
        this.getSitesNew();
      });
    });
  }


  getSites() {
    this.siteAvailability = [];
    let tempSites = new Array<string>();
    let tempDetails: any[] = [];
    for (var key in localStorage) {
      // if (key.includes("t_")&& !key.includes("_time") && !key.includes("_diag") && !key.includes("_conf") && !key.includes("_from") && !key.includes("_to")) {
      //   tempSites.push(key.substring(2, key.length - 2));
      // }
      if (key.includes('_time')) {
        tempSites.push(key.substring(2, key.length - 5));
      }
    }

    for (var key in localStorage) {
      if (key.includes("_from")) {
        tempDetails.push({ "sitename": key.substring(2, key.length - 5), "from": localStorage.getItem(key) });
      }
    }
    for (var key in localStorage) {
      if (key.includes("_to") && key != "access_token") {
        var temp = tempDetails.filter((x: any) => x.sitename == key.substring(2, key.length - 3))[0]
        temp = { "sitename": temp.sitename, "from": temp.from, "to": localStorage.getItem(key) }
        tempDetails.forEach(detail => {
          if (detail.sitename == temp.sitename && detail.from == temp.from) {
            this.siteAvailability.push(temp);
          }
        });
      }
    }


    this.availableSites = new Set(tempSites);
    this.dataSource.data = this.siteAvailability;
    this.dataSource.paginator = this.paginator;
    // this.dataSource.data = this.availableSites;
  }

  getSitesNew() {
    this.siteAvailability = [];
    let tempSites = new Array<string>();
    let tempDetails: any[] = [];

    this.allTokens.forEach((token:any) => {
      if(token.functionCode == 1){
        console.log(token)
        tempDetails.push({'sitename': token.sitename, 'from': token.fromDate, 'to':token.toDate, 'siteID': token.siteID});  
      }
    });
    this.dataSource.data = tempDetails;
    this.dataSource.paginator = this.paginator;
  }

  checkSites() {
    if (this.availableSites.size == 0) {
      this.noTokens = true;
    } else if (this.availableSites.size == 1) {
      var values = this.availableSites.values();
      var obj = values.next();
      var chosen = obj.value;
    }
  }

  checkValidity(row: any): string {
    var currentTime = new Date().getTime();
    var throwAway = currentTime - 1000 * 60 * 60 * 24 * 2;
    if (row.from <= currentTime && row.to >= currentTime) {
      return "current";
    } else if(row.toDate < throwAway) {
      this.localDB.deleteTokenSet(this.db,row.id).then(res => {
      });
      return "revoked";
    } else if (row.from < currentTime && row.to < currentTime) {
      return "past";
    } else if (row.from > currentTime) {
      return "future";
    } else {
      return "";
    }
  }

  deleteToken(sitename: string) {
    for (var key in localStorage) {
      var substrings = key.split('_');
      if (substrings[1] == sitename) {
        localStorage.removeItem(key);
      }
    }
    this.getSites();
  }

  deleteTokenNew(sitename:any,from:any,to:any) {
    let promiseArray: Promise<any>[] = [];
    this.allTokens.forEach((token:any) => {
      if(token.sitename == sitename && token.fromDate == from && token.toDate == to) {
        promiseArray.push(this.localDB.deleteTokenSet(this.db, token.id));
      }
    });
    Promise.all(promiseArray).then((values:any) => {
      this.localDB.getAllTokens(this.db).then(res => {
        this.allTokens = res;
        this.getSitesNew();
      });
    });
  }

  createDisplay() {

  }

  getInfo(element:any) {
    this.webReq.getSiteContact(element).subscribe(result => {
      console.log(result)
      this.contactPerson = result
      this.showInfo = true;
    });
  }


}


