import { ApplicationRef, Injectable } from '@angular/core';
import { SwPush, SwUpdate } from '@angular/service-worker';
import { Observable, of, timer, from as fromPromise } from 'rxjs';
import { catchError, first, mapTo, switchMap, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { WebRequestsService } from './web-requests.service';

@Injectable({
  providedIn: 'root'
})
export class PwaService {
  constructor(
    private appRef: ApplicationRef,
    private swUpdate: SwUpdate,
    private swPush: SwPush,
    private webReq: WebRequestsService
  ) {
    if (this.swUpdate.isEnabled) {
      this.appRef.isStable.pipe(
        first(isStable => isStable === true),
        switchMap(() => this.swUpdate.available),
      ).subscribe(() => {
        this.swUpdate.activateUpdate().then(() => console.info('Service Worker Update Activated'));
      });
    }
  }

  checkForUpdate(): Observable<boolean> {
    const waitFor = 1000;

    if (this.swUpdate.isEnabled) {

      this.swUpdate.available.subscribe(update => {
        
        console.info(update);
      })

      const available$ = this.swUpdate.available.pipe(
        mapTo(true),
        timeout(waitFor),
        catchError(() => of(false)),
      );

      return fromPromise(this.swUpdate.checkForUpdate()).pipe(
        switchMap(() => available$),
      );
    }
    return timer(waitFor).pipe(mapTo(false));
  }

  checkForUpdateNew() {
  
      this.swUpdate.versionUpdates.subscribe(evt => {
        switch (evt.type) {
          case 'VERSION_DETECTED':
            console.info(`Downloading new app version: ${evt.version.hash}`);
            break;
          case 'VERSION_READY':
            console.info(`Current app version: ${evt.currentVersion.hash}`);
            console.info(`New app version ready for use: ${evt.latestVersion.hash}`);
            break;
          case 'VERSION_INSTALLATION_FAILED':
            console.warn(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
            break;
        }
      });
    

  }

  createNotification() {
    const img = '../../assets/prism_logo_lighbg.png';
    const text = 'Hey this is a local test notification'
    const notification = new Notification('Test Notification', { body: text, icon: img });
  }

  public subscribeToPush() {
    return new Promise((resolve, reject) => {
      this.swPush.requestSubscription({
        serverPublicKey: environment.pushVAPID,
      }).then(res => {
        console.info("SW Subscribed");
        resolve(res);
      }).catch(err => {
        console.error('Could not subscribe due to:', err);
        resolve(err)
      });
    })



    // TODO: Send to server.
  }

  subscribeToNotificationClicks() {
    return new Promise((resolve, reject) => {
      this.swPush.notificationClicks.subscribe(({ action, notification }) => {
        if (action == 'acknowledgeAlarm') {
          let alarmBody = notification.body;
          if(alarmBody != undefined) {
            var alarmUUID = alarmBody.substring(alarmBody.indexOf("UUID:") + 6, alarmBody.length)
            var alarmType = alarmBody.substring(16, alarmBody.indexOf(" at site"))
            var alarmSite = alarmBody.substring(alarmBody.indexOf("site:") + 6, alarmBody.indexOf(" ("))
            var alarmID = alarmBody.match(/\([\d\w]*\)/)
            if (alarmID != null) {
              var alarmIDFixed = alarmID[0].substring(1, alarmID[0].length - 1)
              let myAlarmObj = this.createAlarmObject(alarmUUID, alarmType, alarmSite, alarmIDFixed);
              this.webReq.acknowledgeAlarm(myAlarmObj).subscribe(result => {
                alert('Alarm Acknowledged');
              })
            }
          }
        }

        resolve(action);
      });
    })
  }

  createAlarmObject(alarmUUID:any, alarmType:any, alarmSite:any, alarmSiteID:any) {
    let myObj = {
      alarm: {
        siteID: alarmSiteID,
        siteName: alarmSite,
        alarmType: alarmType,
        alarmUUID: alarmUUID
      },
      username: localStorage.getItem('username'),
      clientdb: localStorage.getItem('clientdb')
    }
    return myObj
  }



}
