<div fxFlex fxLayoutAlign="center center" fxLayout="column">
  <app-loader></app-loader>
  <mat-card class="mat-elevation-z16" style="height: 75vh; width: 85vw;" fxLayoutAlign="space-between center"
    fxLayout="column">

    <div fxLayoutAlign="start start" style="width:100%">
      <button mat-mini-fab style="background-color: transparent;"
        (click)="this.myContent = '99;1'; sendContent(); close()"><mat-icon>arrow_back</mat-icon></button>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between center">
      <mat-form-field appearance="outline" style="width: 100%;">
        <mat-label>Enter Content</mat-label>
        <input matInput [(ngModel)]="myContent" />
        <button mat-icon-button matSuffix (click)="sendContent()"><mat-icon style="color: lime">send</mat-icon></button>
      </mat-form-field>
      <!-- <button mat-mini-fab (click)="sendContent()"><mat-icon>send</mat-icon></button> -->
    </div>

    <!-- <div>
      <mat-form-field  appearance="outline" style="width: 100%">
        <mat-label>
          Responses
        </mat-label>
        <textarea matInput [(ngModel)]="responses"></textarea>
      </mat-form-field>
    </div> -->



    <!-- <div fxLayout="column" fxLayoutAlign="space-evenly center"  style="width:100%; max-height: 50vh; overflow: auto;"> -->
    <div fxLayout="column" style="width:100%; max-height: 50vh; overflow: auto; align-items: center;">
      <div fxLayout="row" fxLayoutAlign="start center" style="width: 80%">
        <button mat-raised-button (click)="settingCustom = true;" color="primary"><mat-icon
            style="color: 'blue'">settings_account_box</mat-icon></button>
        <button mat-raised-button style="width: 100%; margin: 4px; color: 'blue'" color="primary"
          (click)="getAndSendCustom('')">{{customCmdLabel}}</button>
      </div>
<!--     Custom Buttons
      <div style="border: 1px solid white; width: 100%; align-items: center">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <mat-label>Custom Actions</mat-label> 
          <button mat-mini-fab style="background-color: green;"><mat-icon>add</mat-icon></button>
        </div>
        
        <div style="width: 80%;" *ngFor="let ca of customActions">
          <div fxLayout="row" fxLayoutAlign="start center" style="width: 100%">
            
            <button mat-raised-button style="width: 100%; margin: 4px; color: 'blue'" color="primary"
              (click)="getAndSendCustom(ca.action)">{{ca.label}}</button>

              <button mat-mini-fab fxLayoutAlign="center center" color="warn" style="width: 20px; height: 20px; align-items: center; align-content: center;" (click)="settingCustom = true;">
              x
              </button>
          </div>
          <mat-divider></mat-divider>
        </div>
      </div> -->
      


      <button mat-raised-button style="width: 80%; margin: 4px;" color="primary"
        (click)="this.myContent = '0x02'; sendContent()"><mat-icon>badge</mat-icon> Get Site ID</button>
      <button mat-raised-button style="width: 80%; margin: 4px;" color="primary"
        (click)="this.myContent = '$'; sendContent()"><mat-icon>system_update</mat-icon> Internal Update</button>
      <button mat-raised-button style="width: 80%; margin: 4px;" color="primary"
        (click)="this.myContent = '#'; sendContent()"><mat-icon>monitor_heart</mat-icon> PDI</button>
      <button mat-raised-button style="width: 80%; margin: 4px;" color="primary"
        (click)="this.myContent = '106;1'; sendContent()"><mat-icon>update</mat-icon> Enable Auto Update</button>
      <button mat-raised-button style="width: 80%; margin: 4px;" color="primary"
        (click)="this.myContent = '106;0'; sendContent()"><mat-icon>update_disabled</mat-icon> Disable Auto
        Update</button>
      <button mat-raised-button style="width: 80%; margin: 4px;" color="primary"
        (click)="this.myContent = '0x01'; sendContent()"><mat-icon>cleaning_services</mat-icon> House Keeping</button>
      <button mat-raised-button style="width: 80%; margin: 4px;" color="primary"
        (click)="this.myContent = '0x03'; sendContent()"><mat-icon>manage_history</mat-icon> Force Reset</button>
      <button mat-raised-button style="width: 80%; margin: 4px;" color="primary"
        (click)="this.myContent = '0x04'; sendContent()"><mat-icon>power_rounded</mat-icon> Force Reboot</button>
      <button mat-raised-button style="width: 80%; margin: 4px;" color="primary"
        (click)="this.myContent = 'E+abeAFaPX8BYlvVfqTYFNriiMU='; sendContent()"><mat-icon>bug_report</mat-icon>
        DBT</button>
        <!--<button mat-raised-button style="width: 80%; margin: 4px; background-color: blueviolet; color: lime;"
        (click)="attemptLocalOTA()"><mat-icon>bug_report</mat-icon>
        Try Local OTA</button>--> 
    </div>



    <mat-card *ngIf="settingCustom" style="position: fixed; width: 90vw; left: 5vw; top: auto" fxLayout="column">
      <mat-form-field>
        <mat-label>Function Name</mat-label>
        <input matInput [(ngModel)]="customCmdLabel">
      </mat-form-field>

      <mat-form-field>
        <mat-label>Function Content</mat-label>
        <input matInput [(ngModel)]="customCmd">
      </mat-form-field>

      <button mat-raised-button color="primary" style="margin: 4px" (click)="setCustomCmd()">Set</button>
      <button mat-raised-button color="warn" style="margin: 4px" (click)="settingCustom = false">Cancel</button>
    </mat-card>


    <!-- <div (click)="sendContent()" class="block-button mat-elevation-z16" fxLayout="column" fxLayoutAlign="center center">
      <mat-icon style="color:darkblue">send</mat-icon>
      <label>Send</label>
    </div> -->

    <a (click)="openHelp('3.4')"
      style="position: absolute; bottom: 0px; right: 0px; margin: 4px;"><mat-icon>help_outline</mat-icon></a>

  </mat-card>

  <mat-card *ngIf="otaInProgress" style="z-index: 3013; border: 1px solid white; width: 90vw; height: 75vh; left: 0;right:0; top:0;bottom:0;margin:auto; position: fixed; background-color: black">
    <div fxLayout="column" fxLayoutAlign="space-evenly center">
        <img src="assets/anim.gif" style="margin-bottom: 8px;">
        <mat-progress-bar mode="determinate" [value]="btService.otaProgress"></mat-progress-bar>
        <label>Update in Progress: {{btService.otaProgress}}%...</label>
    </div>
</mat-card>
</div>
