import { animate, animateChild, query, style, transition, trigger } from '@angular/animations';
import { ApplicationRef, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PwaService } from 'src/app/services/pwa.service';

@Component({
  selector: 'app-splash-screen',
  templateUrl:'./splash-screen.component.html', 
animations: [
  // the fade-in/fade-out animation.
  trigger('fadeOut', [
      transition(':leave', [
          query(':leave', animateChild(), {optional: true}),
          animate(400, style({opacity: 0}))
      ]),
  ]),
],
styleUrls: ['./splash-screen.component.scss'],
changeDetection: ChangeDetectionStrategy.OnPush
})

export class SplashScreenComponent implements OnInit {
  show = true;

  constructor(
      private pwaService: PwaService,
      private cdr: ChangeDetectorRef,
      private appRef: ApplicationRef,
  ) {
  }

  ngOnInit() {
      // this.pwaService.checkForUpdate()
      //     .subscribe(result => {
      //       console.log(result)
      //         console.log("Update detected, show splash")
      //         // this.show = result;
      //         this.cdr.detectChanges();
      //         // this.show = false;
      //     }, err => {
      //       this.show = false;
      //     });
  }

}
