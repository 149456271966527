import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AdminService } from 'src/app/services/admin.service';
import { WebRequestsService } from 'src/app/services/web-requests.service';

@Component({
  selector: 'app-token-tools',
  templateUrl: './token-tools.component.html',
  styleUrls: ['./token-tools.component.scss']
})
export class TokenToolsComponent implements OnInit {

  public isScanning: boolean = false;
  public isGenerating: boolean = false;
  public isGetting: boolean = false;
  public isSystem: boolean = false;
  public userList: any[] = [];
  public reasonList: any[] = ["None"];
  public isRequesting: boolean = false;

  public isAdmin: boolean = false;
  public canGenerate: boolean = false;

  constructor(public webreq: WebRequestsService, public adminService: AdminService, public translate: TranslateService) {
    translate.use(localStorage.getItem('lang')!);
   }

  ngOnInit(): void {
    if (localStorage.admin == 'true') {
      this.isAdmin = true;
    }
    if (localStorage.generateTokens == 'true') {
      this.canGenerate = true;
    }
    this.getReasons();
  }

  getUsers() {
    // this.loader.isLoading = true;
    this.webreq.getReasons().subscribe(reasons => {
      if (reasons.reasons != undefined) {
        this.reasonList = reasons.reasons;
      } else {
        this.reasonList = ["None"]
      }
      this.webreq.getUsers().subscribe(users => {
        this.userList = users;
        this.adminService.allUsers = users;
        this.isSystem = true;
      });
    });
  }

  getReasons() {
    this.webreq.getReasons().subscribe(reasons => {
      if (reasons.reasons != undefined) {
        this.reasonList = reasons.reasons;
      } else {
        this.reasonList = ["None"]
      }
    });
  }

  openGenerating() {
    this.webreq.getReasons().subscribe(reasons => {
      if (reasons.reasons != undefined) {
        this.reasonList = reasons.reasons;
      } else {
        this.reasonList = ["None"]
      }
      this.isGenerating = true
    });
  }

  openRequest() {
    this.isRequesting = true;
  }

  openHelp() {
    window.open('https://wernerg123.github.io/prism-v2-docs/#userTokens', '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
  }

  wip() {
    alert("This feature is a work in progress...")
  }
}
