import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidenavnewComponent } from './sidenavnew.component';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import {MatLegacySnackBarModule as MatSnackBarModule} from '@angular/material/legacy-snack-bar';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { TranslateModule } from '@ngx-translate/core';
import { PopupMenuComponent } from '../popup-menu/popup-menu.component';



@NgModule({
  declarations: [
    SidenavnewComponent,
    // PopupMenuComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatSidenavModule,RouterModule,MatListModule, MatToolbarModule, MatIconModule, FlexLayoutModule, MatButtonModule, MatSnackBarModule,MatTooltipModule,
    TranslateModule
  ]
})
export class SidenavnewModule { }
