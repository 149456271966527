// import { temporaryAllocator } from '@angular/compiler/src/render3/view/util';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConnectivityService } from 'src/app/services/connectivity.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { WebRequestsService } from 'src/app/services/web-requests.service';
import { environment } from 'src/environments/environment';
var pjson = require('../../../../package.json');

@Component({
  selector: 'app-sidenavnew',
  templateUrl: './sidenavnew.component.html',
  styleUrls: ['./sidenavnew.component.scss']
})
export class SidenavnewComponent implements OnInit {

  public username: string = "";
  public admin: string = "user";
  public clientdb: any="";
  public sysadmin = false;
  public canGenerate = false;
  public sitesString: any="";
  public siteList: any=[];
  public showSites = false;
  public canBypass = false;
  public online = true;
  public appVersion = "";
  public deployment = 'dev';
  public uniCounter = 0;
  public showUni = false;
  public showShon = false;
  public showRyno = false;
  public showEthele = true;
  public showNeocom = false;
  public showWerner = false;
  public logoCounter = 0;
  public showCoolLogo = false;
  public selectedLang = 'en';

  constructor(private router: Router, public webReq: WebRequestsService, public conn: ConnectivityService, public notify: NotificationsService, public translate: TranslateService) {
    translate.setDefaultLang('en');
   }

  ngOnInit(): void {
      this.appVersion = pjson.version;
      this.selectedLang = localStorage.getItem('lang')!;
      if(environment.production == true) {
        this.deployment = 'release';
      } else {
        this.deployment = 'demo'
      }
      var tempName = localStorage.getItem('username')
      if (tempName != null) {
        this.username = tempName;
        this.checkLogo();
        if (this.username == "sysadmin") {
          this.sysadmin = true;
          this.canBypass = true;
        } 
      }
      if (localStorage.getItem('admin') == 'true') {
        this.admin = "admin"
      } else {
        this.admin = "user"
      }
      if (localStorage.getItem('generateTokens') == 'true') {
        this.canGenerate = true;
      }
      this.clientdb=localStorage.getItem('clientdb');
  }

  getUserType() {
    return localStorage.getItem('userType');
  }
  

  checkSites() {
    if(this.sysadmin == true) {
      this.webReq.getSitesAll().subscribe(data=> {
        this.siteList = data;
        this.showSites = true;
      })
    }
  }

  openManual() {
    // window.open('https://wernerg123.github.io/prism-v2-docs/', '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
    window.open('https://myprism.co.za/manual/', '_blank', 'location=yes,scrollbars=yes,status=yes');
  }

  navToVKeys() {
    if(navigator.onLine == true) {
      this.router.navigate(['/token-tools']);
    } else {
      this.notify.openMessage('Device offline, function unavailable', 'info', 2)
    }
  }

  logout() {
    localStorage.setItem('isLoggedIn', 'false');
    localStorage.removeItem('access_token')
    this.router.navigate(['/login']);
  }

  checkOnline() {
    if(navigator.onLine == true) {
      return false
    } else {
      return true
    }
  }

  getColor() {
    if(localStorage.getItem('sysAdmin') == 'true') {
      return 'gold';
    } else if(localStorage.getItem('tempAdmin') == 'true') {
      return 'lime'
    } else {
      return '#F37321';
    }

  }

  doUniCounter() {
    this.uniCounter++;
    if(this.uniCounter%15==0) {
      this.showUni = true;
    } else {
      this.showUni = false;
    }
  }

  checkLogo() {
    this.logoCounter++;
    if(this.logoCounter%15==0) {


      this.showUni = true;
      if(this.username == 'shawn' || this.username == 'shawn@ethele.co.za') {
        this.showEthele = false;
        this.showNeocom = false;
        this.showWerner = false;
        this.showRyno = false;
        this.showShon = true;
      } else if(this.username == 'ryno' || this.username == 'ryno@ethele.co.za') {
        this.showEthele = false;
        this.showNeocom = false;
        this.showWerner = false;
        this.showRyno = true;
        this.showShon = false;
      } else if(this.username == 'werner' || this.username == 'werner@ethele.co.za' || this.username == 'wernergrobler123456@gmail.com') {
        this.showEthele = false;
        this.showNeocom = false;
        this.showWerner = true;
        this.showRyno = false;
        this.showShon = false;
      }
      else {
        this.showEthele = false;
        this.showNeocom = false;
        this.showWerner = false;
        this.showRyno = false;
        this.showShon = false;
        this.showCoolLogo = true;
      }
    
    } else {
      if(localStorage.getItem('clientdb') != 'neocom') {
        this.showEthele = true;
        this.showNeocom = false;
        this.showWerner = false;
        this.showRyno = false;
        this.showShon = false;
        this.showCoolLogo = false;
      } else {
        this.showEthele = false;
        this.showNeocom = true;
        this.showWerner = false;
        this.showRyno = false;
        this.showShon = false;
        this.showCoolLogo = false;
      }
        
    }
  }

  setLang(lang: string) {
    localStorage.setItem('lang', lang);
    this.selectedLang = 'lang';
    window.location.reload();
  }

  
}
