<div fxFlex fxLayoutAlign="center center" fxLayout="column">
    <app-loader></app-loader>
    <mat-card class="mat-elevation-z16" style="height: 75vh; width: 85vw;" fxLayoutAlign="start center"
        fxLayout="column">
        <button mat-mini-fab style="position: absolute; top: 8px; left: 8px; background-color: transparent;"
            (click)="closing()"><mat-icon>arrow_back</mat-icon></button>
        <mat-stepper orientation="vertical" linear=true>

            <mat-step>
                <ng-template matStepLabel>{{'app.getTokens.management' | translate}}</ng-template>
                <button mat-raised-button color="primary" matStepperNext (click)="authenticate()"> {{'app.getTokens.retrieve' | translate}}
                </button>
                <div>
                </div>
            </mat-step>

            <mat-step>
                <ng-template matStepLabel>v-Key Status</ng-template>
                <div style="position: absolute; left:-10%; top: 0; width: 65vw; height: 30vh; max-height: 60vh; overflow: auto;">
                    <div *ngIf="tokensSet" fxLayout="column" fxLayoutAlign="center center">
                        <mat-icon style="color: lightgreen; margin: 8px; ">check_circle</mat-icon>
                        <label style="margin: 8px;">{{'app.getTokens.loaded' | translate}}</label>
                        <ul>
                            <li *ngFor="let site of tokenSites">
                                <label style="font-size:small">{{site}}</label>
                            </li>
                        </ul>
                    </div>
                    <div *ngIf="!tokensSet" fxLayout="column" fxLayoutAlign="center center">
                        <mat-icon style="color: red; margin: 8px;">highlight_off</mat-icon>
                        <label style="margin: 8px;">{{'app.getTokens.none' | translate}}</label>
                    </div>

                    <div *ngIf="configTemplatesList.length > 0" fxLayout="column" fxLayoutAlign="center center">
                        <mat-icon style="color: lightgreen; margin: 8px;">check_circle</mat-icon>
                        <label> Config Templates Loaded:</label>
                        <ul>
                            <li *ngFor="let config of configTemplatesList">{{config.siteID}}</li>
                        </ul>
                    </div>
                </div>
            </mat-step>

        </mat-stepper>
    </mat-card>

    <mat-card *ngIf="showEnterPwd" style="z-index: 2003; position: absolute; border: 1px solid white;" fxLayout="column">
        <h2>Enter Password</h2>
        <mat-form-field>
            <mat-label>Password</mat-label>
            <input matInput [(ngModel)]="password" type="password">
        </mat-form-field>
        <div fxLayoutAlign="space-evenly center">
            <button mat-raised-button (click)="confirmPassword()" color="primary">Confirm</button>
            <button mat-raised-button (click)="showEnterPwd = false;" color="warn">Cancel</button>
        </div>
    </mat-card>
</div>