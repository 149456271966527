import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
// import { config } from 'process';
import { Subject } from 'rxjs';
import { BluetoothService } from 'src/app/services/bluetooth.service';
import { LoaderService } from 'src/app/services/loader.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { UserAdminComponent } from '../admin/user-admin/user-admin.component';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss']
})
export class ConfigurationComponent implements OnInit {

  @Input() myCharacteristic: any;
  @Input() confSubject: Subject<any> = new Subject<any>(); // use this to read events from BT components

  @Output() closed = new EventEmitter();
  @Output() action = new EventEmitter(); // this event emitter is used to send commands/packets to the parent Bluetooth component which in turn is used to write actions through the parent component 
  @Output() reloadSubs = new EventEmitter();

  // WiFi config
  public ssid = "";
  public password = "";

  // config string 0
  public useDoorContact = true;
  public siteID = "10000";
  public invertActuator = false;
  public actuatorTimeout = 10;
  public maxCurrent: any = 1.1;
  public maglockToggle = false;

  public actLength = 50; // in mm
  public actSpeed = 12; // mm/s 
  // ==============================

  // config string 1
  public sensorType: number = 1; // 0: Magnetic Contacts, 1: PRISM Smart IR, 2: Induction Sensor
  public use2Sensors: boolean = false;
  public useTimer: boolean = false; // Actuator -> disable timeout if false;
  public useTamperRelay: boolean = false;
  public useDCtoInhibit: boolean = false;
  public dcIsNO: boolean = false;
  // ==============================

  // config string 2
  public secondaryPresent: boolean = false; // 1 is present
  public tpmPresent: boolean = false; // 1 is present
  public tpm1ID: number = 0;
  public tpm2ID: number = 0;
  public secondarySensorType: number = 0; // Same as config string 1: 0: Magnetic Contacts, 1: PRISM Smart IR, 2: Induction Sensor
  public secondaryUse2Sensors: boolean = false;

  // ==============================
  public version = "";
  public configStringRes = "";
  public configStringRes1 = "";
  public configStringRes2 = "";
  public gotConfig = false;
  public gotVersion = false;
  public disableBack = true;

  public currentWifiState = false;

  public showWifiWarning: boolean = false;
  public showWifiResetWarning: boolean = false;
  public showRenameWarning: boolean = false;
  public showOTAWarning: boolean = false;
  public showResetWarning: boolean = false;
  public shouldDisconnect: boolean = false;
  public isEditSiteID: boolean = false;

  public isInternalUpdating: boolean = false;

  public canReset: boolean = false;

  public changeBluetoothTo = "off";
  public changeNameTo = "";

  public hasConfigTemplate = false;
  public myTemplate: any = null;

  door1AutoLock = false;
  door2AutoLock = false;

  public macAddress:any = '';

  constructor(public notify: NotificationsService, public btService: BluetoothService, private snackBar: MatSnackBar, public loader: LoaderService, public translate: TranslateService) {
    translate.use(localStorage.getItem('lang')!);
  }

  ngOnInit(): void {
    this.macAddress = localStorage.getItem('lastMAC') == undefined ? '' : localStorage.getItem('lastMAC');
    let configTemplatesString: any = localStorage.getItem('configTemplates');
    let configTemplates = JSON.parse(configTemplatesString);
    configTemplates?.forEach((conf: any) => {
      if (conf.siteID.toString() == localStorage.getItem('selectedBTSiteID')) {
        this.myTemplate = conf;
        this.myTemplate.configString1 = this.myTemplate.configString1.replace("xxxx", localStorage.getItem('selectedBTSiteID'))
        this.hasConfigTemplate = true;
      }
    });

    this.confSubject.subscribe(res => { // Maybe instead of listenTypes rather just use regex to validate responses recieved from device
      if (res.listenType == 1 && (res.value.toUpperCase().includes('PRO-S3') || res.value.toUpperCase().includes('LITE') || res.value.toUpperCase().includes('PLUS') )) {
        this.version = res.value;
        this.disableBack = false;
        this.gotVersion = true;
      } else if (res.listenType == 1 && res.value.includes('Invalid Site ID')) {
        this.version = res.value;
      } else if (res.listenType == 2) {

        if (this.checkConfigSetting(res.value) == 0) {
          this.configStringRes = res.value;
          this.checkConfigValues(this.configStringRes);
        } else if (this.checkConfigSetting(res.value) == 1) {
          this.configStringRes1 = res.value;
          this.checkConfigValues1(this.configStringRes1);
        } else if (this.checkConfigSetting(res.value) == 2) {
          this.configStringRes2 = res.value
          this.checkConfigValues2(this.configStringRes2)
        }

        this.gotConfig = true;
      } else if (res.listenType == 0) {
        this.notify.openMessage(res.value, 'success', 2);
        if (res.value == 0 && this.shouldDisconnect) {
          this.StopBluetooth();
        }
      }
    });
    setTimeout(() => {
      this.action.emit({ code: '97', listenType: 2 })
    }, 1500)
  }

  ngOnDestroy() {
    // needed if child gets re-created (eg on some model changes)
    // note that subsequent subscriptions on the same subject will fail
    // so the parent has to re-create parentSubject on changes
    this.confSubject.unsubscribe();
    this.reloadSubs.emit(true);
  }

  getConfig() {
    this.action.emit({ code: '97', listenType: 2 })
  }
  // This is the old config send method..no longer used in newer firmware
  sendConfig() {
    let configString = "0;" + this.setUseDoorContactValue() + ";" + this.siteID + ";" + this.setInvertActuatorValue() + ";" + this.actuatorTimeout + ";" + this.maxCurrent.toFixed(2) + ";" + this.setToggleMagLockValue()

    setTimeout(() => {
      this.action.emit({ code: '7;' + this.setToggleMagLockValue(), listenType: 0 });
      setTimeout(() => {
        this.action.emit({ code: '6;' + this.maxCurrent.toFixed(2), listenType: 0 });
        setTimeout(() => {
          this.action.emit({ code: '5;' + this.actuatorTimeout, listenType: 0 });
          setTimeout(() => {
            this.action.emit({ code: '4;' + this.setInvertActuatorValue(), listenType: 0 });
            setTimeout(() => {
              this.action.emit({ code: '3;' + this.siteID, listenType: 0 });
              setTimeout(() => {
                this.action.emit({ code: '2;' + this.setUseDoorContactValue(), listenType: 0 });
                setTimeout(() => {
                  this.action.emit({ code: '10;' + this.actLength, listenType: 0 });
                  setTimeout(() => {
                    this.action.emit({ code: '11;' + this.actSpeed, listenType: 0 });
                    setTimeout(() => {
                      this.action.emit({ code: '98', listenType: 0 });
                      setTimeout(() => {
                        this.close();
                      }, 1000);
                    }, 1000)
                  }, 1000);
                }, 1000);
              }, 1000);
            }, 1000);
          }, 1000);
        }, 1000);
      }, 1000);
    }, 1000);
    // this.action.emit({code: configString, listenType: 0});
  }
  // This is the new Config String method to send config values to the PRISM controller
  sendConfigNew() {
    // The first 0; is just placeholder for now
    if (this.sensorType != 2) {
      this.use2Sensors = false;
    }
    let configString = "96;0;" + this.setUseDoorContactValue() + ";" + this.siteID + ";" + this.setInvertActuatorValue()
      + ";" + this.actuatorTimeout + ";" + parseFloat(this.maxCurrent).toFixed(2) + ";" + this.setToggleMagLockValue() + ";" + this.actLength + ";" + this.actSpeed + ";" + (this.currentWifiState == true ? "1" : "0");
    console.info("Config String to send: " + configString)
    this.action.emit({ code: configString, listenType: 0 });
    setTimeout(() => {
      if (this.version >= 'PRO-S3-O' || this.configStringRes1 != "") {
        //sendSecondConf
        let configString2 = "96;1;" + this.sensorType + ";" + (this.use2Sensors ? '1' : '0') + ";" + (this.useTimer ? '1' : '0') + ";" + (this.useTamperRelay ? '1' : '0') + ";"
          + (this.useDCtoInhibit ? '1' : '0') + ";" + (this.dcIsNO ? '1' : '0') + ";" + (this.getAutoLockVals());
        console.info("Config string 2 to send: " + configString2)
        this.action.emit({ code: configString2, listenType: 0 });
        console.info("Sent second config string")
        setTimeout(() => {

          if (this.configStringRes2 != "") {
            let configString3 = "96;2;" + (this.secondaryPresent ? '1' : '0') + ";" + (this.tpmPresent ? '1' : '0') + ";" + this.tpm1ID + ";" + this.tpm2ID + ";" + this.secondarySensorType + ";" + (this.secondaryUse2Sensors ? '1' : '0') + ";0;0;0";
            console.info("Config string 3 to send: " + configString3)
            this.action.emit({ code: configString3, listenType: 0 });
            console.info("Sent third config string")
            console.info("Saving new configs...")
            setTimeout(() => {
              this.action.emit({ code: '98', listenType: 0 });
              setTimeout(() => {
                this.close();
              }, 2500);
            }, 2500)
          } else {
            setTimeout(() => {
              console.info("Saving new configs...")
              this.action.emit({ code: '98', listenType: 0 });
              setTimeout(() => {
                this.close();
              }, 2500);
            }, 2500)

          }
        }, 2500)
      } else {
        console.warn(this.version + " is old firmware..sending old config only")
        this.action.emit({ code: '98', listenType: 0 });
        setTimeout(() => {
          this.close();
        }, 2500);
      }
    }, 2500)
  }

  getAutoLockVals() {
    if(this.door1AutoLock && this.door2AutoLock) {
      return '3';
    } else if(this.door1AutoLock && !this.door2AutoLock) {
      return '1'
    } else if(!this.door1AutoLock && this.door2AutoLock) {
      return '2';
    } else if(!this.door1AutoLock && !this.door2AutoLock) {
      return '0';
    } else {
      return '0';
    }
  }


  //  This command saves the config on the PRISM controller
  saveConfig() {
    this.action.emit('98')
  }

  close() {
    this.loader.isLoading = true;
    setTimeout(() => {
      this.action.emit({ code: '99', listenType: 0 });
      setTimeout(() => {
        this.loader.isLoading = false;
        this.closed.emit();
      }, 1000)
    }, 1000)

  }


  setTamperRelay(value: boolean) {
    if (value == true) {
      //send 111;1
      this.action.emit({ code: '111;1' })
      navigator.vibrate([500, 500])
    } else {
      //send 111;0
      this.action.emit({ code: '111;0' })
      navigator.vibrate([200, 200])
    }
  }

  setInhibitSD(value: boolean) {
    if (value == true) {
      //send 113;1
      this.action.emit({ code: '113;1' })
      navigator.vibrate([500, 500])

    } else {
      //send 113;0
      this.action.emit({ code: '113;0' })
      navigator.vibrate([300, 300])

    }
  }

  setUseDoorContactValue() {
    if (this.useDoorContact) {
      return "1";
    } else {
      return "0";
    }
  }


  setInvertActuatorValue() {
    if (this.invertActuator) {
      return "1";
    } else {
      return "0";
    }
  }
  setToggleMagLockValue() {
    if (this.maglockToggle) {
      return "1";
    } else {
      return "0";
    }
  }

  public checkConfigValues(confStr: string) {
    var values = confStr.split(";");
    if (values[1] == "1") {
      this.useDoorContact = true;
    } else {
      this.useDoorContact = false;
    }
    this.siteID = values[2];
    if (values[3] == "1") {
      this.invertActuator = true;
    } else {
      this.invertActuator = false;
    }
    this.actuatorTimeout = parseInt(values[4]) / 1000;
    let myMaxCurrent = 1.5;
    if (isNaN(parseFloat(values[5]))) {
      this.maxCurrent = 1.5;
    } else {
      this.maxCurrent = parseFloat(values[5]);
    }
    if (values[6][0] == "1") {
      this.maglockToggle = true;
    } else {
      this.maglockToggle = false;
    }
    this.actLength = parseInt(values[7]);
    this.actSpeed = parseInt(values[8]);
    if (values[9] == '0') {
      this.currentWifiState = false;
    }
    if (values[9] == '1') {
      this.currentWifiState = true;
    }
  }
  // Set the new config values string here
  public checkConfigValues1(confStr: string) {
    var values = confStr.split(";");
    this.sensorType = parseInt(values[1]);

    if (values[2] == '0') {
      this.use2Sensors = false
    } else if (values[2] == '1') {
      this.use2Sensors = true
    }


    if (values[3] == "1") {
      this.useTimer = true;
    } else {
      this.useTimer = false;
    }

    if (values[4] == "1") {
      this.useTamperRelay = true;
    } else {
      this.useTamperRelay = false;
    }

    if (values[5] == "1") {
      this.useDCtoInhibit = true;
    } else {
      this.useDCtoInhibit = false;
    }

    if (values[6] == "1") {
      this.dcIsNO = true;
    } else {
      this.dcIsNO = false;
    }

    if(values[7] == '0') {
      this.door1AutoLock = false;
      this.door2AutoLock = false;
    } else if (values[7] == '1') {
      this.door1AutoLock = true;
      this.door2AutoLock = false;
    } else if (values[7] == '2') {
      this.door1AutoLock = false;
      this.door2AutoLock = true;
    } else if (values[7] == '3') {
      this.door1AutoLock = true;
      this.door2AutoLock = true;
    }
  }

  public checkConfigValues2(confStr: string) {
    var values = confStr.split(";");

    if (values[1] == '0') {
      this.secondaryPresent = false
    } else if (values[1] == '1') {
      this.secondaryPresent = true
    }

    if (values[2] == "1") {
      this.tpmPresent = true;
    } else if (values[2] == "0") {
      this.tpmPresent = false;
    }

    this.tpm1ID = parseInt(values[3])
    this.tpm2ID = parseInt(values[4])
    console.warn("Secondary Sensor Type Recieved:" + values[5])
    if (values[5] == '0') {
      this.secondarySensorType = 0
      console.info("Secondary Sensor Type Set to: " + this.secondarySensorType)
    } else if (values[5] == '1') {
      this.secondarySensorType = 1
      console.info("Secondary Sensor Type Set to: " + this.secondarySensorType)
    } else if (values[5] == '2') {
      this.secondarySensorType = 2
      console.info("Secondary Sensor Type Set to: " + this.secondarySensorType)
    }

    if (values[6] == '0') {
      this.secondaryUse2Sensors = false
    } else if (values[6] == '1') {
      this.secondaryUse2Sensors = true;
    }
  }

  setWiFi() {
    let bluetoothConfValue = 0;
    if (this.changeBluetoothTo == 'on') {
      bluetoothConfValue = 1;
    } else if (this.changeBluetoothTo == 'off') {
      bluetoothConfValue = 0;
    }
    this.action.emit({ code: '8;' + bluetoothConfValue, listenType: 0 });
    setTimeout(() => {
      // this.action.emit('98') // Save config code, then disconnect (Shawn Resets after recieving the 8;2 command)
      window.location.reload();
    }, 2500);
  }
  //  This method causes a reboot on the controller and will cause a disconnect from Bluetooth
  resetWiFi() {
    this.action.emit({ code: '8;2', listenType: 0 });
    setTimeout(() => {
      // this.action.emit('98') // Save config code, then disconnect (Shawn Resets after recieving the 8;2 command)
      window.location.reload();
    }, 2500);
  }
  //  This method causes a reboot on the controller and will cause a disconnect from Bluetooth
  renameUnit() {
    this.action.emit({ code: '9;' + this.changeNameTo, listenType: 0 });
    setTimeout(() => {
      this.action.emit('98') // Save config code, then disconnect
      setTimeout(() => {
        window.location.reload();
      }, 2500);
    }, 2500);
  }
  //  This method causes a reboot on the controller and will cause a disconnect from Bluetooth
  startOTA() {

    this.action.emit({ code: '12', listenType: 0 });
    setTimeout(() => {
      window.location.reload();
    }, 2500)


  }
  // This method resets the PRISM controller to use Site ID 10000 - also reboots the controller and will cause a disconnect from Bluetooth
  factoryReset() {
    // this.shouldDisconnect = true;
    this.action.emit({ code: '99', listenType: 0 });
    setTimeout(() => {
      this.action.emit({ code: 'reset', listenType: 0 });
      setTimeout(() => {
        window.location.reload();
      }, 2000)
    }, 2000);

  }

  saveSSIDconf() {
    this.action.emit({ code: '101;' + this.ssid + ';' + this.password, listenType: 0 });
    setTimeout(() => {
      // window.location.reload();
      this.action.emit({ code: '102;dhcp', listenType: 0 });
      setTimeout(() => {
        this.action.emit({ code: '103;1', listenType: 0 });
      }, 2000);
    }, 2000)
  }

  StopBluetooth() {
    // console.log("Stopping Service ...")
    if (this.btService.myReadCharacteristic) {
      this.btService.myReadCharacteristic.stopNotifications()
        .then((data: any) => {
          // console.log('> Notifications stopped');
          if (this.btService.myDevice.gatt.connected) {
            // console.log("Disconnecting from:")
            this.btService.myDevice.gatt.disconnect();
            this.snackBar.open('Disconnected...', 'Ok', { duration: 3000 })
            localStorage.removeItem("selectedBTSite");
            navigator.vibrate(200);
            setTimeout(() => {
              window.location.reload();
            }, 1500)
          }
        })
        .catch((err: any) => {
          console.log('Argh! ' + err);
        });
    }
  }

  toggleEditSiteID() {
    if (this.isEditSiteID) {
      this.isEditSiteID = false;
    } else {
      this.isEditSiteID = true;
    }
  }

  checkValidData() {
    if (this.actuatorTimeout >= 1 && this.actuatorTimeout <= 20 && this.maxCurrent >= 0.25 && this.maxCurrent <= 6 && this.actSpeed >= 0 && this.actSpeed <= 15 && this.actLength >= 0 && this.actLength <= 150) {
      return true;
    } else {
      return false;
    }
  }

  importConfig() {
    const fileInput = document.getElementById('fileInput')!;
    let selectedFile;
    fileInput.click();

    fileInput.addEventListener('change', (event: any) => {
      selectedFile = event.target.files[0];
      let myFileContents = "";
      if (selectedFile) {
        // document.getElementById('openButton').style.display = 'block';
        const reader = new FileReader();

        reader.onload = (event: any) => {
          const fileContents = event.target.result;
          myFileContents = fileContents;
          // this.checkConfigValues(myFileContents);
          var regex = /96;\d;\d;\d+;\d;\d+;\d+\.*\d*;\d;\d;\d;\d+.*/;

          if (regex.test(myFileContents)) {
            this.sendImportConfig(myFileContents);
          } else {
            alert('Invalid config file');
          }
          // processContents(fileContents) // Here process the config contents
        }
        reader.readAsText(selectedFile);
      }
    });
  }

  exportConfig() {
    let configString = "";
    if (this.version >= 'PRO-S3-O') {
      configString = "96;0;" + this.setUseDoorContactValue() + ";" + this.siteID + ";" + this.setInvertActuatorValue()
        + ";" + this.actuatorTimeout + ";" + parseFloat(this.maxCurrent).toFixed(2) + ";" + this.setToggleMagLockValue()
        + ";" + this.actLength + ";" + this.actSpeed + ";" + (this.currentWifiState == true ? "1" : "0");

      configString += "*96;1;" + this.sensorType + ";" + (this.use2Sensors ? '1' : '0') + ";" + (this.useTimer ? '1' : '0') + ";" + (this.useTamperRelay ? '1' : '0') + ";"
        + (this.useDCtoInhibit ? '1' : '0') + ";" + (this.dcIsNO ? '1' : '0') + ";" + this.getAutoLockVals();
    } else {
      configString = "96;0;" + this.setUseDoorContactValue() + ";" + this.siteID + ";" + this.setInvertActuatorValue()
        + ";" + this.actuatorTimeout + ";" + parseFloat(this.maxCurrent).toFixed(2) + ";" + this.setToggleMagLockValue()
        + ";" + this.actLength + ";" + this.actSpeed + ";" + (this.currentWifiState == true ? "1" : "0");
    }

    let filename = 'LOCK_CONFIG_' + new Date().toISOString().slice(0, 10) + '.cfg';
    const blob = new Blob([configString], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = filename; // Specify the desired file name
    a.style.display = 'none';
    document.body.appendChild(a);

    a.click();

    URL.revokeObjectURL(url); // Clean up the URL
    document.body.removeChild(a);
  }

  sendImportConfig(confstring: string) {
    if (this.version >= 'PRO-S3-O') {
      let sets = confstring.split('*');
      if (sets.length != 2) {
        alert('Invalid Config File...');
      } else {
        let values1 = sets[0].split(';');
        let values2 = sets[1].split(';');
        let conf1 = values1[0] + ";" + values1[1] + ";" + values1[2] + ";" + this.siteID + ";" + values1[4] + ";" + values1[5] + ";" + values1[6] + ";" +
          values1[7] + ";" + values1[8] + ";" + values1[9] + ";" + values1[10];
        let conf2 = values2[0] + ";" + values2[1] + ";" + values2[2] + ";" + values2[3] + ";" + values2[4] + ";" + values2[5] + ";" + values2[6] + ";" + values2[7];
        this.action.emit({ code: conf1, listenType: 0 });
        setTimeout(() => {
          this.action.emit({ code: conf2, listenType: 0 });
          setTimeout(() => {
            this.action.emit({ code: '98', listenType: 0 });
            setTimeout(() => {
              this.close();
            }, 2500)
          }, 2500);
        }, 2500);
      }
    } else {
      let values = confstring.split(';');
      let newConfString = values[0] + ";" + values[1] + ";" + values[2] + ";" + this.siteID + ";" + values[4] + ";" + values[5] + ";" + values[6] + ";" +
        values[7] + ";" + values[8] + ";" + values[9] + ";" + values[10];
      this.action.emit({ code: newConfString, listenType: 0 });
      setTimeout(() => {
        this.action.emit({ code: '98', listenType: 0 });
        setTimeout(() => {
          this.close();
        }, 2500);
      }, 2500)
    }

  }

  downloadSummary() {

    let configString = `Site ID: ${this.siteID} \nUse Door Contact without door sensor: ${this.useDoorContact ? 'Yes' : 'No'} `;
    
    configString += `\nInvert Actuator: ${this.invertActuator ? 'Yes' : 'No'} \nToggle Maglock: ${this.maglockToggle ? 'Yes' : 'No'} `;
    configString += `\nTimeout: ${this.actuatorTimeout}s \nMax Current: ${this.maxCurrent}A \nSpeed: ${this.actSpeed}mm/s \nLength: ${this.actLength}mm `;
    configString += `\nSensor Type: ${this.getSensorType(this.sensorType)} \nUse 2 Sensors: ${this.use2Sensors ? 'Yes' : 'No'} \nUse Timer: ${this.useTimer ? 'Yes' : 'No'}`;
    configString += `\nUse Tamper: ${this.useTamperRelay ? 'Yes' : 'No'} \nUse Door Contact to Inhibit: ${this.useDCtoInhibit ? 'Yes' : 'No'}`;
    configString += `\nDoor Contact Normally Open: ${this.dcIsNO ? 'Yes' : 'No'} \nDoor 1 Auto Lock: ${this.door1AutoLock ? 'Yes' : 'No'}`;
    configString += `\nDoor 2 Auto Lock: ${this.door2AutoLock ? 'Yes' : 'No'} `;
    configString += `\nFirmware: ${this.version}`;
    
    if (this.version >= 'PRO-S3-O') {
      let sensorDesc;
      if (this.sensorType = 0) {
        sensorDesc = 'Magnetic Contacts'
      } else if (this.sensorType = 1) {
        sensorDesc = 'PRISM Smart IR'
      } else {
        sensorDesc = 'Induction Sensor'
      }
      configString += `\nSensor Type: ${sensorDesc} \nUse 2 sensors: ${this.use2Sensors ? 'Yes' : 'No'} \nUse Timer: ${this.useTimer ? 'Yes' : 'No'} \nUse Tamper Relay: ${this.useTamperRelay ? 'Yes' : 'No'} \nUse Door Contact to Inhibit: ${this.useDCtoInhibit ? 'Yes' : 'No'} \nDoor Contact is Normally Open: ${this.dcIsNO ? 'Yes' : 'No'}`
    }
    let filename = 'LOCK_CONFIG_SUMMARY' + new Date().toISOString().slice(0, 10) + '.txt';
    const blob = new Blob([configString], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = filename; // Specify the desired file name
    a.style.display = 'none';
    document.body.appendChild(a);

    a.click();

    URL.revokeObjectURL(url); // Clean up the URL
    document.body.removeChild(a);
  }

  checkConfigSetting(confString: string) {
    let confItems = confString.split(';');
    if (confItems[0] == '0') {
      console.info("Got first config string")
      return 0;
    } else if (confItems[0] == '1') {
      console.info("Got second config string")
      return 1;
    } else if (confItems[0] == '2') {
      console.info("Got third config string")
      return 2;
    } else {
      return 3
    }
  }

  getSensorType(sensorType: any) {
    if (sensorType == 0) {
      return 'Magnetic Contacts'
    } else if (sensorType == 1) {
      return 'PRISM Smart IR'
    } else if (sensorType == 2) {
      return 'Induction Sensor'
    } else {
      return 'Unknown'
    }
  }

  setConfigTemplate() {
    this.loader.isLoading = true;
    this.action.emit({ code: '25', listenType: 0 });
    setTimeout(() => {
      this.notify.openMessage('Sending Primary Conf', 'info', 2);
      this.action.emit({ code: this.myTemplate.configString1, listenType: 0 });
      setTimeout(() => {
        this.notify.openMessage('Sending Secondary Conf', 'info', 2);
        this.action.emit({ code: this.myTemplate.configString2, listenType: 0 });
        setTimeout(() => {
          this.notify.openMessage('Sending Tertiary Conf', 'info', 2);
          this.action.emit({ code: this.myTemplate.configString3, listenType: 0 });
          setTimeout(() => {
            this.notify.openMessage('Saving Config', 'info', 2);
            this.action.emit({ code: '98', listenType: 0 });
            setTimeout(() => {
              if (this.myTemplate.ssid == '' || this.myTemplate.password == '' || this.myTemplate.ssid == undefined || this.myTemplate.password == undefined) {
                this.notify.openMessage('Config Saved', 'info', 2);
                this.loader.isLoading = false;
                this.close();
              } else {
                this.notify.openMessage('Setting WIFI Conf', 'info', 2);
                this.action.emit({ code: '101;' + this.myTemplate.ssid + ";" + this.myTemplate.password })
                setTimeout(() => {
                  this.notify.openMessage('Setting DHCP', 'info', 2);

                  this.action.emit({ code: '102;dhcp' });
                  setTimeout(() => {
                    this.notify.openMessage('Saving WIFI Conf', 'info', 2);
                    this.action.emit({ code: '103' });
                    setTimeout(() => {
                      this.notify.openMessage('Configs Saved, rebooting...', 'info', 25);
                      this.action.emit({code: "0x04", listenType: 0})
                    }, 2500)
                  }, 2500)
                }, 2500)
              }




            }, 2500)
          }, 2500)

        }, 2500)
      }, 2500)
    }, 2500)


  }

  getSetTPM(tpmCode:any) {
    this.loader.isLoading = true;
    this.action.emit({code: tpmCode, listenType: 0});
    this.notify.openMessage('Please wait', 'info', 5)
    setTimeout(() => {
      this.loader.isLoading = false;
    }, 5000)
  }

  getVersion(version: any) {
    let alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let versionLetter = version.split('-')[version.split('-').length - 1]
    let versionChecker = versionLetter.split('.')
    let foundNaN = false;
    let versionNumber = 0;

    versionChecker.forEach((letter: any) => {
      for (let i = 0; i < letter.length; i++) {
        if (isNaN(parseFloat(letter[i]))) {
          foundNaN = true;
          versionNumber = alphabet.indexOf(letter[i])
        }
      }
    })

    if (!foundNaN) {
      versionNumber = versionLetter
    }
    return versionNumber;
  }

  testOldVersion(version: any) {
    let oldVersions = ['A', 'B', 'C', '1C', 'D', '1D', 'E', '1E', 'F', '1F', 'G', '1G', 'H', '1H', 'I', '1I', 'J', '1J', 'K', '1K', 'L', '1L', 'M', '1M', 'N', '1N', 'O', '1O', 'P', '1P', '1'];
    let versionLetter = version.split('-')[version.split('-').length - 1];

    if (oldVersions.includes(versionLetter)) {
      return true;
    } else {
      return false;
    }
  }

  checkTPMSecondToggle(check: any) {
    setTimeout(() => {
      if (check == 'sec') {
        if (this.secondaryPresent == true) {
          this.tpmPresent = false;
          this.secondaryPresent = true;
        }

      } else if (check == 'tpm') {
        if (this.tpmPresent == true) {
          this.tpmPresent = true;
          this.secondaryPresent = false;
        }
      }

    }, 500);
  }

  checkTimeoutDefault() {
    setTimeout(() => {
      // if(useTimer)
      setTimeout(() => {
        if (this.useTimer == false) {
          this.actuatorTimeout = 15;
        }
      }, 500)
    }, 500)
  }

  checkSiteIDValid() {
    if (this.siteID == localStorage.getItem('selectedBTSiteID') && !this.siteID.includes('Invalid Site ID') && !this.version.includes('Invalid Site ID')) {
      return false;
    } else {
      return true;
    }
  }
}
