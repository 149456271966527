import { Injectable } from '@angular/core';
import { rejects } from 'assert';
import * as fs from 'fs';
import { environment } from 'src/environments/environment';
// import { ConnectivityService } from './connectivity.service';


@Injectable({
  providedIn: 'root'
})
export class LocalDatabaseService {

  
  constructor() { }

  //  Connects to the local DB on device (Browser DB) and returns the DB object
  dbConnect() {
    return new Promise((resolve, reject) => {
      var request = indexedDB.open('prism_tokens_db', 1);
      request.onsuccess = (e) => {
        resolve(e.target);
      };
      request.onupgradeneeded = (e) => {
        const objectStore = request.result.createObjectStore('tokens', { keyPath: 'id', autoIncrement: true });
        objectStore.createIndex('value', 'value', { unique: false });
        resolve(e.target);
      }
      request.onerror = (e) => {
        reject();
      }
    });
  }

  dbImgConnect() {
    return new Promise((resolve, reject) => {
      var request = indexedDB.open('site_images', 1);
      request.onsuccess = (e) => {
        resolve(e.target);
      };
      request.onupgradeneeded = (e) => {
        // db.createObjectStore("images", { keyPath: "id", autoIncrement: true });
        try {
          const objectStore = request.result.createObjectStore("images", { keyPath: "id", autoIncrement: true });
          objectStore.createIndex('value', 'value', { unique: false });
          resolve(e.target);
        }
        catch (err) {
          request.result.deleteObjectStore("images")
          request.result.createObjectStore("images")
        }
      }
      request.onerror = (e) => {
        request.result.deleteObjectStore("images")
        request.result.createObjectStore("images")
        reject();
      }
    });
  }

  // Stores the passed tokens objects in the local DB
  setAllTokens(db: any, tokens: any) {
    var crypto = require('crypto-js');
    return new Promise((resolve, reject) => {
      let objectStore = db.transaction('tokens', 'readwrite').objectStore('tokens');
      tokens.forEach((token: any) => {
        var jsonString = JSON.stringify(token);
        var b64String = btoa(jsonString);
        var value = crypto.AES.encrypt(b64String, environment.key).toString();
        objectStore.add({ value: value });
      })
      resolve(true);
    })
  }
  // Retrieves all the locally stored tokens
  getAllTokens(db: any) {
    var crypto = require('crypto-js');
    let myResults: any = [];
    return new Promise((resolve, reject) => {
      let objectStore = db.transaction('tokens', 'readwrite').objectStore('tokens');
      objectStore.getAll().onsuccess = (e: any) => {

        e.target.result.forEach((res: any) => {
          var valueToDec = res.value;
          var decrypted = crypto.AES.decrypt(res.value, environment.key).toString(crypto.enc.Utf8);
          var decoded = atob(decrypted);
          var jObject = JSON.parse(decoded);
          // let myValue = atob(crypto.AES.decrypt(res.value,environment.key).toString());
          jObject.id = res.id;
          myResults.push(jObject);
        });
        resolve(myResults);
      }
    });
  }
  // Deletes the passed token set from the local DB
  deleteTokenSet(db: any, item: any) {
    return new Promise((resolve, reject) => {
      let objectStore = db.transaction('tokens', 'readwrite').objectStore('tokens');
      objectStore.delete(item).onsuccess = (e: any) => {
        resolve(e);
      };
    });
  }

  // createDBForImages() {
  //   return new Promise((resolve) => {
  //     var request = indexedDB.open('site_images', 1);
  //     request.onsuccess = (evt:any) => {
  //       console.info(evt)
  //       const db = evt.target.result;
  //       // const objectStore = db.createObjectStore("images", {keyPath: "id", autoIncrement: true});

  //       resolve(evt.target.result)
  //     }
  //     request.onerror = (err) => {
  //       console.warn(err)
  //       resolve(err)
  //     }
  //     request.onupgradeneeded = (evt:any) => {
  //       const db = evt.target.result;
  //       const objectStore = db.createObjectStore("images", {keyPath: "id", autoIncrement: true});
  //       objectStore.createIndex('siteID', 'siteID', { unique: true });
  //     }
  //   });
  // }

  storeImages(images: any) {
    return new Promise((resolve) => {

      const request = indexedDB.open('site_images', 1);
      request.onsuccess = (event: any) => {
        const db = event.target.result;
        const transaction = db.transaction(["images"], 'readwrite');

        const os = transaction.objectStore("images")

        let imgListFromDB = os.getAll();
        imgListFromDB.onsuccess = (event: any) => {

          let imgToReplace = event.target.result.filter((x: any) => x.siteID == images.siteID);

          let imgToDelete = event.target.result.filter((x: any) => x.siteID != images.siteID);

          imgToReplace.forEach((item: any) => {
            os.delete(item.id)
          });
          imgToDelete.forEach((item: any) => {
            os.delete(item.id)
          });
        }

        let op = os.add(images)


        transaction.onsuccess = (evt: any) => {
          console.info("Images Stored")
          console.info(evt)
        }
        transaction.onerror = (err: any) => {
          console.warn(err);
        }
      }
      request.onupgradeneeded = (evt: any) => {
        const db = evt.target.result;
        const objectStore = db.createObjectStore("images", { keyPath: "id", autoIncrement: true });
      }
    });
  }

  getImages(siteID: any) {
    return new Promise((resolve) => {
      const request = indexedDB.open('site_images', 1);
      request.onsuccess = (evt: any) => {
        const db = evt.target.result;
        let imgList = db.transaction(["images"], 'readwrite').objectStore('images').getAll();

        imgList.onsuccess = (event: any) => {
          let myImages = event.target.result;
          myImages = myImages.filter((x: any) => x.siteID == siteID)[0];
          resolve(myImages)
        }
        imgList.onerror = (err: any) => {
          resolve(err)
        }

      }
      request.onupgradeneeded = (evt: any) => {
        const db = evt.target.result;
        const objectStore = db.createObjectStore("images", { keyPath: "id", autoIncrement: true });
      }
    })

  }

  confirmReportsDB() {
    return new Promise((resolve, reject) => {
      var request = indexedDB.open('site_reports', 1);
      request.onsuccess = (e) => {
        resolve(e.target);
      };
      request.onupgradeneeded = (e) => {
        const objectStore = request.result.createObjectStore('reports', { keyPath: 'id', autoIncrement: true });
        objectStore.createIndex('value', 'value', { unique: false });
        resolve(e.target);
      }
      request.onerror = (e) => {
        reject();
      }
    });
  }

  confirmTokenDB() {
    return new Promise((resolve, reject) => {
      var request = indexedDB.open('prism_tokens_db', 1);
      request.onsuccess = (e) => {
        resolve(e.target);
      };
      request.onupgradeneeded = (e) => {
        const objectStore = request.result.createObjectStore('tokens', { keyPath: 'id', autoIncrement: true });
        objectStore.createIndex('value', 'value', { unique: false });
        resolve(e.target);
      }
      request.onerror = (e) => {
        reject();
      }
    });
  }

  confirmDiagIMGDB() {
    return new Promise((resolve, reject) => {
      var request = indexedDB.open('site_images', 1);
      request.onsuccess = (e) => {
        resolve(e.target);
      };
      request.onupgradeneeded = (e) => {
        const objectStore = request.result.createObjectStore('images', { keyPath: 'id', autoIncrement: true });
        objectStore.createIndex('value', 'value', { unique: false });
        resolve(e.target);
      }
      request.onerror = (e) => {
        reject();
      }
    });
  }

  addReport(repObj: any) {
    return new Promise((resolve, reject) => {

      var request = indexedDB.open('site_reports');
      request.onsuccess = (e: any) => {
        let db = e.target.result;

        let transaction = db.transaction(["reports"], "readwrite");


        const objectStore = transaction.objectStore("reports");
        const addReq = objectStore.add(repObj);
        addReq.onsuccess = (event: any) => {
          resolve("Report added to local DB")
        }
        addReq.onerror = (event: any) => {
          console.warn("Failed to add Report to local DB");
          resolve("Report failed to add to local DB")
        }
      }
    })
  }

  getReports() {
    return new Promise((resolve) => {
      const request = indexedDB.open('site_reports', 1);
      request.onsuccess = (evt: any) => {
        const db = evt.target.result;
        let repList = db.transaction(["reports"], 'readwrite').objectStore('reports').getAll();
        repList.onsuccess = (event: any) => {
          let myReports = event.target.result;
          resolve(myReports)
        }
        repList.onerror = (err: any) => {
          resolve(err)
        }
      }
    })
  }

  clearReports() {
    return new Promise((resolve) => {
      const request = indexedDB.open('site_reports', 1);
      request.onsuccess = (evt: any) => {
        const db = evt.target.result;
        let transaction = db.transaction(["reports"], "readwrite");
        const objectStore = transaction.objectStore("reports");
        let delReq = objectStore.clear();
        delReq.onsuccess = (event: any) => {
          resolve("DB Cleared")
        }
        delReq.onerror = (event: any) => {
          console.warn("Reports DB not cleared")
          console.warn(event);
          resolve("Failed to clear DB")
        }
      }
    })
  }

  checkDeviceDB() {
    return new Promise((resolve) => {
      var dbExists = true;
      var request = window.indexedDB.open("device");
      request.onupgradeneeded = function (e) {
        dbExists = false;
        console.log("Database not exist")
        const objectStore = request.result.createObjectStore('device', { keyPath: 'id', autoIncrement: true });
        objectStore.createIndex('value', 'value', { unique: false });
        // console.log(e.target);
        resolve(false);
      }
      request.onsuccess = (e:any) => {
        const entries = request.result.transaction('device').objectStore('device').getAll();
        entries.onsuccess = (a:any) => {
          // console.log(a.target.result);
          if(a.target.result.length == 1) {
            console.log("Not first login")
            resolve(true);
          } else {
            resolve(false);
          }
        }
      }
    })
   
  }

  createFirstLogin(reqObj:any) {
    return new Promise((resolve) => {
      var conReq = indexedDB.open('device');
      conReq.onsuccess = (a: any) => {
        console.log('Connected to device db');
        let db = a.target.result;
  
        let transaction = db.transaction(["device"], "readwrite");
  
  
        const objectStore = transaction.objectStore("device");
        const addReq = objectStore.add(reqObj.firstLogin);
        addReq.onsuccess = (event: any) => {
          resolve(true)
          console.warn("first login time added...")
        }
        addReq.onerror = (event: any) => {
          console.warn("Failed to add first login time...");
          resolve(false)
         
          // resolve("Report failed to add to local DB")
        }
  
      }
    });
   
  }
}

