<mat-card *ngIf="!noTokens" class="mat-elevation-z16" style="height: 65vh; width: 85vw;" fxLayoutAlign="center center">
    <div fxLayout="column">
        <mat-form-field appearance="outline">
            <mat-label>{{'app.bt.site' | translate}}</mat-label>
            <mat-select [(ngModel)]="selectedSite">
                <mat-option *ngFor="let site of availableSites1" [value]="site">{{site.sitename}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <button mat-raised-button color="primary" (click)="confirmSite(selectedSite)">
            <mat-icon>done</mat-icon> {{'app.bt.confirmSite' | translate}}
        </button>
    </div>
</mat-card>

<mat-card *ngIf="noTokens" class="mat-elevation-z16" style="height: 65vh; width: 85vw;">
    <div fxLayout="column" style="height: 100%;" fxLayoutAlign="space-between center">
        <div fxLayout="column" fxLayoutAlign="center center" style="position: absolute; top: 35% !important">
            <mat-icon style="color: red; font-size: xx-large; margin: 4px;">highlight_off</mat-icon>
            <span style="color: white; font-size: large; margin: 4px">{{'app.bt.unavailable' | translate}}</span>
        </div>
        <button mat-raised-button color="primary" (click)="goToGetTokens()" style="position: absolute; top: 80%; width: 70%;" [disabled]="checkOnline()">
            <mat-icon>lock</mat-icon> {{'app.bt.getKeys' | translate}}
        </button>

    </div>
</mat-card>

