import { Injectable, OnDestroy } from '@angular/core';
import { LoggingService } from './logging.service';

@Injectable({
  providedIn: 'root'
})
export class ConnectivityService implements OnDestroy {

  public online: boolean = navigator.onLine;
  onlineChecks = 0;
  sentLastLogs = false;

  private onlineHandler = () => {
    this.online = true;
    this.logger.sendAllLogs();
  };

  private offlineHandler = () => {
    this.online = false;
  };


  constructor(public logger: LoggingService) {
    window.addEventListener('online', this.onlineHandler);
    window.addEventListener('offline', this.offlineHandler);
  }

  addOfflineBTLog() {

  }

  pushBTLogs() {

  }

  checkOnline() {
    // console.trace();
    try{
      if (navigator.onLine == true) {
        this.online = true;
        if (this.sentLastLogs == false) {
          this.logger.sendAllLogs();
          this.logger.sendAllMACLogs();
          this.sentLastLogs = true;
        }
      } else {
        this.online = false;
        this.sentLastLogs = false;
      }
    } catch(e) {
      console.error("Failed to check online")
    }
    
  }

  ngOnDestroy() {
    window.removeEventListener('online', () => console.info('Removed online listener'));
    window.removeEventListener('offline', () => console.info('Removed offline listener'));
  }

}
