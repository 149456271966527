import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap,catchError } from 'rxjs/operators';
import { NotificationsService } from '../services/notifications.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConnectivityService } from '../services/connectivity.service';
import { environment } from 'src/environments/environment';
import { LoaderService } from '../services/loader.service';


@Injectable()
export class AuthInterceptorInterceptor implements HttpInterceptor {

  constructor(public notifier: NotificationsService, public router: Router, private myRoute: ActivatedRoute, public conn: ConnectivityService, public loader: LoaderService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if(!request.url.includes(environment.icrypto.baseUrl) && !request.url.includes('login')) {
      const modifiedRequest = request.clone({
        
        setHeaders: {
          'Authorization': 'Bearer ' + (localStorage.getItem('access_token') == undefined ? '': localStorage.access_token),
          'X-Device-Header': localStorage.visitorID == undefined ? 'none' : localStorage.visitorID,
          'X-Device-Platform': 'app',
          'x-user-id': localStorage.userID == undefined ? '' : localStorage.userID
        }
        // headers: request.headers.set('Authorization','Bearer ' + localStorage.getItem('access_token')),
      });
      return next.handle(modifiedRequest).pipe(tap(evt => {
        this.conn.checkOnline();
      }),catchError(error => {
        console.log(error);
        if(error.status == 401 && error.statusText == "Unauthorized") {
          console.log("User not authorized")
          this.notifier.openMessage('Session Expired, please login again...','error', 3);
          localStorage.setItem('lastRoute',this.router.url.replace(/%20/gi, ' '));
          var myNewRoute = this.router.url.replace(/%20/gi, ' ');
          // console.log('Last route set to: ' + myNewRoute );
          localStorage.setItem('shouldGoLastRoute', 'true');
          setTimeout(() => {
            this.router.navigate(['/login']);
          })
        } else if(error.status == 403) {
          console.log(error)
          this.loader.isLoading = false;
          this.notifier.openMessage(error.error.message, 'error', 5);
        }
        return of(error);
      }));
    } else {
      return next.handle(request);
    }

  }

}
