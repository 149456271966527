import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AdminhomeComponent } from '../pages/admin/adminhome/adminhome.component';

@Injectable({
  providedIn: 'root'
})
export class WebRequestsService {

  constructor(private http: HttpClient) { }


  getSubcontractors() {
    let clientdb = localStorage.getItem('clientdb');
    return this.http.post<any>(environment.baseUrl + '/get_subcontractors', { "clientdb": clientdb });
  }

  updateContractor(contractor: any) {
    let clientdb = localStorage.getItem('clientdb');
    contractor.clientdb = clientdb;
    return this.http.post<any>(environment.baseUrl + '/update_subcontractor', contractor);
  }

  deleteContractor(contractor: any) {
    let clientdb = localStorage.getItem('clientdb');
    contractor.clientdb = clientdb;
    return this.http.post<any>(environment.baseUrl + '/delete_subcontractor', contractor);
  }

  addContractor(contractor: any) {
    let clientdb = localStorage.getItem('clientdb');
    contractor.clientdb = clientdb;
    return this.http.post<any>(environment.baseUrl + '/add_subcontractor', contractor);
  }
  biometricsLogin(_user: any) {
    return this.http.post<any>(environment.baseUrl + "/biometric_auth", { userID: _user.userID, username: _user.username });
  }

  subscribeNotifications(obj: any) {
    return this.http.post<any>(environment.baseUrl + "/subscribe_notifications", obj);
  }

  unsubscribeNotify(obj: any) {
    return this.http.post<any>(environment.baseUrl + "/unsubscribe_notifications", obj);
  }

  subscribeAlarms(obj: any) {
    return this.http.post<any>(environment.baseUrl + "/subscribe_alarms", obj);
  }

  unsubscribeAlarms(obj: any) {
    return this.http.post<any>(environment.baseUrl + "/unsubscribe_alarms", obj);
  }

  disableNotifications(obj: any) {
    return this.http.post<any>(environment.baseUrl + "/disable_notifications", obj);
  }

  getSubscriptions(obj: any) {
    let clientdb = localStorage.getItem('clientdb')
    let userID = localStorage.getItem('userID')
    return this.http.post<any>(environment.baseUrl + "/get_subscriptions", { "userID": userID, "clientdb": clientdb, "endpoint": obj.endpoint });
  }

  commissionSite(siteID: any, siteKey: any, customer: any, secondaryController = false, secondaryName = "", configTemplate = "") {
    let clientdb = localStorage.getItem('clientdb')
    let username = localStorage.getItem('username')
    let _by = localStorage.getItem('username')
    let _byEmail = localStorage.getItem('email')
    return this.http.post<any>(environment.baseUrl + "/add_site", {
      "sitename": siteID, "siteKey": siteKey, "clientdb": clientdb, "customer": customer, "username": username,
      "secondaryController": secondaryController, "secondaryName": secondaryName, "configTemplate": configTemplate,
      "_by": _by, "_byEmail": _byEmail
    });
  }

  deleteSite(_id: any) {
    let clientdb = localStorage.getItem('clientdb')
    let username = localStorage.getItem('username')
    let _by = localStorage.getItem('username')
    let _byEmail = localStorage.getItem('email')
    return this.http.post<any>(environment.baseUrl + "/delete_site", { "id": _id, "clientdb": clientdb, "username": username, "_by": _by, "_byEmail": _byEmail });
  }

  renameSite(site: any) {
    let _by = localStorage.getItem('username')
    let _byEmail = localStorage.getItem('email')
    return this.http.post<any>(environment.baseUrl + '/rename_site', { site: site, _by: _by, _byEmail: _byEmail });
  }

  getClients(user: any) {
    let clientdb = localStorage.getItem('clientdb')
    return this.http.post<any>(environment.baseUrl + "/get_clients", { "username": user, "clientdb": clientdb });
  }
  login(user: any) {
    let headers_node = {
      headers: new HttpHeaders(
        { 'rejectUnauthorized': 'false' }
      )
    };
    return this.http.post<any>(environment.baseUrl + "/login", user, headers_node);
  }

  checkAuthReqs(clientdb: any) {
    return this.http.post<any>(environment.baseUrl + '/auth_reqs', { clientdb: clientdb });
  }

  getSites(user: any) {
    let clientdb = localStorage.getItem('clientdb')
    return this.http.post<any>(environment.baseUrl + "/get_sites", { "username": user, "clientdb": clientdb });
  }

  getSitesAll() {
    let clientdb = localStorage.getItem('clientdb')
    return this.http.post<any>(environment.baseUrl + "/get_sites_all", { "clientdb": clientdb });
  }

  getSiteUsers(siteID: any) {
    let clientdb = localStorage.getItem('clientdb')
    return this.http.post<any>(environment.baseUrl + "/get_site_users", { "siteID": siteID, "clientdb": clientdb });
  }

  getSiteContact(element:any) {
    let clientdb = localStorage.getItem('clientdb');
    return this.http.post<any>(environment.baseUrl + "/get_site_contact", {"clientdb": clientdb, "siteID": element});
  }

  generateToken(siteId: any, funcCode: any, operations: any[], username: any = "none") {
    if (username == "none") {
      localStorage.getItem('username') != null ? username = localStorage.getItem('username') : username == "none";
    }
    const options = { responseType: 'text' as 'text' };
    let clientdb = localStorage.getItem('clientdb')
    let obj = { "siteID": siteId, "funcCode": funcCode, "operations": operations, "clientdb": clientdb, "username": username }
    return this.http.post<any>(environment.baseUrl + "/get_token", obj)
  }

  // This method accepts from date and to date in milliseconds and defaults to now and tomorrow
  generateTokenNew(site: any, codes: any[], fromDate: any = new Date().getTime(), toDate: any = new Date().setDate(new Date().getDate() + 1), username: any = "none", reason: any = "None") {
    if (username == "none") {
      localStorage.getItem('username') != null ? username = localStorage.getItem('username') : username == "none";
    }
    const options = { responseType: 'text' as 'text' };
    let clientdb = localStorage.getItem('clientdb')
    let obj = { "site": site, "codes": codes, "clientdb": clientdb, "username": username, "from": fromDate, "to": toDate, "reason": reason }
    return this.http.post<any>(environment.baseUrl + "/get_token", obj)
  }

  addUser(username: string, email: string, admin: boolean, generateTokens: boolean, cellphone: string) {
    let clientdb = localStorage.getItem('clientdb')
    let _by = localStorage.getItem('username')
    let _byEmail = localStorage.getItem('email')
    return this.http.post<any>(environment.baseUrl + "/add_user", {
      username: username, email: email, admin: admin,
      generateTokens: generateTokens, cellphone: cellphone, clientdb: clientdb,
      _by: _by, _byEmail: _byEmail
    });
  }

  getUsers() {
    let clientdb = localStorage.getItem('clientdb')
    return this.http.post<any>(environment.baseUrl + "/get_users", { clientdb: clientdb });
  }

  removeUser(username: string) {
    let clientdb = localStorage.getItem('clientdb')
    let _by = localStorage.getItem('username')
    let _byEmail = localStorage.getItem('email')
    return this.http.post<any>(environment.baseUrl + "/remove_user", { clientdb: clientdb, email: username, _by: _by, _byEmail: _byEmail });
  }

  removeUserFromSite(siteID: any, siteName: any, email: any) {
    let clientdb = localStorage.getItem('clientdb')
    let _by = localStorage.getItem('username')
    let _byEmail = localStorage.getItem('email')
    return this.http.post<any>(environment.baseUrl + "/remove_user_from_site", { clientdb: clientdb, email: email, siteID: siteID, siteName: siteName, _by: _by, _byEmail: _byEmail });
  }

  addUserToSite(email: string, site: string) {
    let clientdb = localStorage.getItem('clientdb')
    let _by = localStorage.getItem('username')
    let _byEmail = localStorage.getItem('email')

    return this.http.post<any>(environment.baseUrl + "/add_site_user", { email: email, site: site, clientdb: clientdb, _by: _by, _byEmail: _byEmail })
  }
  addUserClient(username: string, email: string, admin: any = false, generateTokens: any = false, cellphone: string = "", name: string = "", surname: string = "", canConfigure: boolean = false, canCommission: boolean = false, canHandover: boolean = false, userType: string = 'advanced', idNumber = '') {
    let clientdb = localStorage.getItem('clientdb')
    let _by = localStorage.getItem('username')
    let _byEmail = localStorage.getItem('email')

    return this.http.post<any>(environment.baseUrl + "/add_user_client", {
      username: username,
      email: email,
      admin: admin,
      generateTokens: generateTokens,
      cellphone: cellphone,
      clientdb: clientdb,
      name: name,
      surname: surname,
      canConfigure: canConfigure,
      canCommission: canCommission,
      canHandover: canHandover,
      userType: userType,
      _by: _by,
      _byEmail: _byEmail,
      idNumber: idNumber
    });
  }

  updateUser(user: any) {
    let clientdb = localStorage.getItem('clientdb')
    let _by = localStorage.getItem('username')
    let _byEmail = localStorage.getItem('email')
    user._by = _by;
    user._byEmail = _byEmail;
    return this.http.post<any>(environment.baseUrl + "/update_user", { user, clientdb: clientdb });
  }
  smsTokens(cellphone: any, siteID: any, funcCode: any, operations: any[]) {
    let clientdb = localStorage.getItem('clientdb')
    return this.http.post<any>(environment.baseUrl + "/sms_tokens", { "cellphone": cellphone, "siteID": siteID, "funcCode": funcCode, "operations": operations, "clientdb": clientdb });
  }

  getLogs() {
    return this.http.post<any>(environment.baseUrl + "/get_log", {});
  }

  getReasons() {
    let clientdb = localStorage.getItem('clientdb')
    return this.http.post<any>(environment.baseUrl + "/get_reasons", { clientdb: clientdb });
  }

  generateSystemTokens(siteId: any, sitename: any, funcCode: any, operations: any[], username: any = "none", userID: any = "") {
    let clientdb = localStorage.getItem('clientdb')
    let caller = localStorage.getItem('username');
    let obj = { "siteID": siteId, "sitename": sitename, "funcCode": funcCode, "operations": operations, "clientdb": clientdb, "username": username, "userID": userID, "caller": caller }
    return this.http.post<any>(environment.baseUrl + "/system_tokens", obj)
  }
  // This is the current system tokens method!
  generateSystemTokensNew(site: any, codes: any, fromDate: any = new Date().getTime(), toDate: any = new Date().setDate(new Date().getDate() + 1), username: any = "none", userID: any = "", reason: any = "None") {
    let clientdb = localStorage.getItem('clientdb')
    let caller = localStorage.getItem('username');
    let obj = { "site": site, "codes": codes, "clientdb": clientdb, "username": username, "userID": userID, "from": fromDate, "to": toDate, "caller": caller, "reason": reason }
    return this.http.post<any>(environment.baseUrl + "/system_tokens", obj)
  }

  getSystemTokens(user: any) {
    let clientdb = localStorage.getItem('clientdb')
    let obj = { "clientdb": clientdb, "username": user }
    return this.http.post<any>(environment.baseUrl + "/get_system_tokens", obj)
  }

  pushBTEvents(eventList: any) {
    let events = { "events": eventList };
    return this.http.post<any>(environment.baseUrl + "/bluetooth_log", events);
  }
  pushMACEvents(eventList: any) {
    let events = { "events": eventList };
    return this.http.post<any>(environment.baseUrl + "/set_mac_address", events);
  }

  getBTLogs(from: any, to: any, user: any) {
    return this.http.post<any>(environment.baseUrl + '/get_bt_log', { 'from': from, 'to': to, 'user': user });
  }

  resetPassword(user: any) {
    let clientdb = localStorage.getItem('clientdb')

    return this.http.post<any>(environment.baseUrl + '/password_reset', { "username": user.username, "email": user.email, "clientdb": clientdb, "lat": user.lat, "lon": user.lon });
  }

  requestKeys(myObj: any) {
    return this.http.post<any>(environment.baseUrl + '/request_token', myObj);
  }

  getMyRequests() {
    let myObj = {
      clientdb: localStorage.getItem('clientdb'),
      username: localStorage.getItem('username'),
      email: localStorage.getItem('email')
    }
    return this.http.post<any>(environment.baseUrl + '/my_requests', myObj);
  }

  deleteKeyRequest(item: any) {
    let clientdb = localStorage.getItem('clientdb');
    return this.http.post<any>(environment.baseUrl + '/delete_request', item);
  }

  updatePassword(user: any) {
    let clientdb = localStorage.getItem('clientdb')
    return this.http.post<any>(environment.baseUrl + '/password_update', { "username": user.username, "email": user.email, "password": user.password, "clientdb": clientdb });
  }

  requestNewAccount(email: any, type: any, clientDB: any) {
    let obj = { "email": email, "type": type, "clientdb": clientDB };
    return this.http.post<any>(environment.baseUrl + "/request_account", obj);
  }

  emailReport(username: any, userID: any, sitename: any, siteID: any, email: any, myBlob: any) {
    let obj = { "email": email, "username": username, "userID": userID, "sitename": sitename, "siteID": siteID, "file": myBlob };
    return this.http.post<any>(environment.baseUrl + '/send_report', obj);
  }

  acknowledgeAlarm(alarm: any) {
    return this.http.post<any>(environment.baseUrl + '/ack_alarm', alarm);
  }

  getAlarm(alarmUUID: any) {
    return this.http.post<any>(environment.baseUrl + '/get_alarm', { alarmUUID: alarmUUID });
  }

  addSiteGroup(group: any) {
    let clientdb = localStorage.getItem('clientdb')
    return this.http.post<any>(environment.baseUrl + "/create_site_group", { groupName: group.groupName, groupID: group.groupID, clientdb: clientdb });
  }

  deleteSiteGroup(groupObj: any) {
    let clientdb = localStorage.getItem('clientdb')
    return this.http.post<any>(environment.baseUrl + "/delete_site_group", { groupID: groupObj._id, clientdb: clientdb });
  }

  updateSiteGroup(groupObj: any) {
    return this.http.post<any>(environment.baseUrl + "/update_site_group", groupObj);
  }

  handoverSiteGroup(groupObj: any) {
    return this.http.post<any>(environment.baseUrl + '/handover_site', groupObj);
  }


  getSiteGroups() {
    let clientdb = localStorage.getItem('clientdb')
    return this.http.post<any>(environment.baseUrl + "/get_site_groups", { "clientdb": clientdb });
  }

  iCryptoAddDevice(deviceToAdd: any) {
    return this.http.post<any>(environment.baseUrl + "/add_device_icrypto", deviceToAdd);
  }

  iCryptoDeleteDevice(deviceToRemove: any) {
    return this.http.post<any>(environment.baseUrl + "/delete_device_icrypto", deviceToRemove);
  }

  iCryptoGetAccess() {
    var headers_object = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded').set('Access-Control-Allow-Origin', '*').set("Authorization", "Basic " + btoa(environment.icrypto.username + ":" + environment.icrypto.password))
    const httpOptions = {
      headers: headers_object
    };

    const body = new HttpParams().set('grant_type', 'client_credentials');
    return this.http.post<any>(environment.icrypto.baseUrl + "/oxauth/restv1/token", body.toString(), httpOptions);
  }

  handoverDevice(device: any) {
    device.username = localStorage.getItem('username');
    return this.http.post<any>(environment.baseUrl + "/handover_device", device);
  }

  takeBackDevice(device: any) {
    device.username = localStorage.getItem('username');
    return this.http.post<any>(environment.baseUrl + "/takeback_device", device);
  }

  saveConfigTemplate(obj: any) {
    let _by = localStorage.getItem('username')
    let _byEmail = localStorage.getItem('email')
    obj._by = _by;
    obj._byEmail = _byEmail
    return this.http.post<any>(environment.baseUrl + '/save_config_template', obj);
  }

  updateConfigTemplate(obj: any) {
    let _by = localStorage.getItem('username')
    let _byEmail = localStorage.getItem('email')
    obj._by = _by;
    obj._byEmail = _byEmail
    return this.http.post<any>(environment.baseUrl + '/update_config_template', obj);
  }

  getConfigTemplate() {
    let clientdb = localStorage.getItem('clientdb');
    return this.http.post<any>(environment.baseUrl + '/get_config_templates', { clientdb: clientdb })
  }

  getSiteConfigTemplates(uniqueSites: any) {
    let clientdb = localStorage.getItem('clientdb');
    return this.http.post<any>(environment.baseUrl + '/get_site_configs', { sites: uniqueSites, clientdb: clientdb })
  }

  generateHandoverRequest(obj: any) {
    let clientdb = localStorage.getItem('clientdb');
    obj.clientdb = clientdb;
    return this.http.post<any>(environment.baseUrl + '/generate_handover_request', obj)
  }

  getHandoverRequests() {
    let clientdb = localStorage.getItem('clientdb');
    return this.http.post<any>(environment.baseUrl + '/get_handovers', { clientdb: clientdb })
  }

  acceptHandover(HOObj: any) {
    return this.http.post<any>(environment.baseUrl + '/accept_handover_request', HOObj)

  }

  uploadReport(repObj: any) {
    var form_data = new FormData();
    for (var key in repObj) {
      form_data.append(key, repObj[key]);
    }

    // return this.http.post<any>(environment.baseUrl + '/upload_report', repObj);
    return this.http.post<any>(environment.baseUrl + '/upload_report', form_data);
  }

  getMyHistory() {
    let clientdb = localStorage.getItem('clientdb');
    let username = localStorage.getItem('username');
    return this.http.post<any>(environment.baseUrl + '/my-history', { username: username, clientdb: clientdb });
  }

  requestOTP(reqObj:any) {
    return this.http.post<any>(environment.baseUrl + '/request_otp', reqObj);
  }
  authenticateOTP(reqObj:any) {
    return this.http.post<any>(environment.baseUrl + '/authenticate_device', reqObj);
  }

  workOSLogin(reqObj:any) {
    // return this.http.post<any>(environment.baseUrl + '/workoslogin_1', reqObj);
  }

  readOTAFile() {
    return this.http.get('assets/firmware.bin', {responseType: 'arraybuffer'}).toPromise().then((data:ArrayBuffer) => {
      return data;
    }).catch(error => {
      throw new Error(`Failed to read file: ${error}`);
    })
  }
}
