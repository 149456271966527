import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AdminService } from 'src/app/services/admin.service';
import { LoaderService } from 'src/app/services/loader.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { WebRequestsService } from 'src/app/services/web-requests.service';

@Component({
  selector: 'app-adminhome',
  templateUrl: './adminhome.component.html',
  styleUrls: ['./adminhome.component.scss']
})
export class AdminhomeComponent implements OnInit {

  userAdmin: boolean = false;
  siteAdmin: boolean = false;
  subcontractorAdmin : boolean = false;
  changePassword:boolean = false;
  usernameFormControl = new UntypedFormControl();
  setBiometrics:boolean = false;
  notifications: boolean = false;

  isAdmin: boolean = false;
  canCommission: boolean = false;

  username: string = "";
  password: string = "";
  admin: boolean = false;
  cellphone: string = "";
  
  cellphoneRequest: string = "";

  userID_SMS: string = "";
  siteID_SMS: string = "";

  requestSiteID_SMS: string = "";
  
  userList: any[] = [];
  userSitesList: any[] = [];

  currentUserSitesList: any[] = [];

  selectedUser: any;

  constructor(private webreq: WebRequestsService, public adminService: AdminService, public notify:NotificationsService, public loader: LoaderService, public translate: TranslateService) {
    translate.setDefaultLang('en');
    translate.use(localStorage.getItem('lang')!);
   }

  ngOnInit(): void {
    if(localStorage.getItem('admin') == 'true') {
      
      this.isAdmin = true;
      this.getUsers();
    } else {
    }
    if(localStorage.getItem('canCommission') == 'true') {
      this.canCommission = true;
    } else {
    }
  this.getCurrentUserSites();
  }

  openUserAdmin() {
    if(this.isAdmin) {
      this.userAdmin = true;
    } else {
      alert('User does not have permission...')
    }
  }

  trySubcontractorEdit() {
    if(this.isAdmin) {
      this.subcontractorAdmin = true;
    } else {
      alert('User does not have permission...')
    }
  }

  smsTokens() {
    var number = this.userID_SMS[4]
    number = number.replace('0', '+27')
    this.webreq.smsTokens(number, this.siteID_SMS[0], [0],[0,1,2]).subscribe(res => {
    })
  }

  smsRequestTokens() {
    var number = this.cellphoneRequest
    number = number.replace('0', '+27')
    this.webreq.smsTokens(number, this.requestSiteID_SMS[0], [0],[0,1,2]).subscribe(res => {
    })
  }

  getUsers() {
    // this.loader.isLoading = true;
    this.webreq.getUsers().subscribe(users => {
      this.userList = users;
      this.adminService.allUsers = users;
      // this.loader.isLoading = false;
    })
  }

  getUserSites(username: any) {
    this.webreq.getSites(username).subscribe(sites => {
      if(sites == false) {
        this.userSitesList = [];
      } else {
        this.userSitesList = sites;
      }
    })
  }

  getCurrentUserSites() {
    this.webreq.getSites(localStorage.getItem('username')).subscribe(sites => {
      if (sites == false) {
        this.currentUserSitesList = [];
      } else {
        this.currentUserSitesList = sites;
      }
    })
  }

  openHelp(link:any) {
    window.open('https://wernerg123.github.io/prism-v2-docs/#'+link, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
  }

}
