import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { BluetoothService } from 'src/app/services/bluetooth.service';
import { LoaderService } from 'src/app/services/loader.service';
import { WebRequestsService } from 'src/app/services/web-requests.service';
import base64url from 'base64url';
import { NotificationsService } from 'src/app/services/notifications.service';
import { LoggingService } from 'src/app/services/logging.service';
import { ConnectivityService } from 'src/app/services/connectivity.service';

@Component({
  selector: 'app-testing',
  templateUrl: './testing.component.html',
  styleUrls: ['./testing.component.scss']
})
export class TestingComponent implements OnInit {

  siteID: any;

  myCharacteristic: any;

  counterValue: any = 0;
  clickable: boolean = true;
  isConnected = false;
  myContent: string = '';

  responses: string = "";

  customCmd: string = "";
  customCmdLabel: string = "";

  settingCustom: boolean = false;

  customCount = 0;
  customTimer = 0;
  pressed = false;

  otaInProgress = false;
  otaProgress = 0;

  customActions: any = [];

  @Output() debuggerClosed = new EventEmitter();
  // @Input() currentState: boolean = false;

  constructor(private webreq: WebRequestsService,
    public btService: BluetoothService,
    private snackBar: MatSnackBar,
    public loader: LoaderService,
    public notify: NotificationsService,
    public logger: LoggingService,
    public conn: ConnectivityService) { }

  ngOnInit(): void {

    if (localStorage.getItem('customActions') != null && localStorage.getItem('customActions') != undefined) {
      this.customActions = localStorage.getItem('customActions');
    } else {
      this.customActions = [{ label: 'myTest', action: '0x02' }];
    }

    this.siteID = localStorage.getItem('siteID')
    if (localStorage.getItem('customCmdLabel') == null || localStorage.getItem('customCmdLabel') == undefined) {
      this.customCmdLabel = "None";
    } else {
      this.customCmdLabel = localStorage.getItem('customCmdLabel')!;
    }
    if (localStorage.getItem('customCmd') == null || localStorage.getItem('customCmd') == undefined) {
      this.customCmd = "None";
    } else {
      this.customCmd = localStorage.getItem('customCmd')!;
    }
  }

  StopBluetooth() {
    if (this.btService.myReadCharacteristic) {
      this.btService.myReadCharacteristic.stopNotifications()
        .then((data: any) => {
          this.btService.myReadCharacteristic.removeEventListener('characteristicvaluechanged', this.handleNotifications);
          if (this.btService.myDevice.gatt.connected) {
            this.btService.myDevice.gatt.disconnect();
            this.snackBar.open('Disconnected...', 'Ok', { duration: 3000 })
            navigator.vibrate(200);
          }
          this.isConnected = false;
        })
        .catch((err: any) => {
          console.warn('Argh! ' + err);
        });
    }
  }

  Connect() {
    this.loader.isLoading = true;
    this.btService.connectDevice().then(conn => {
      if (conn) {
        this.isConnected = true;
        this.btService.myReadCharacteristic.startNotifications().then(() => {
          this.loader.isLoading = false;
          this.btService.myReadCharacteristic.addEventListener('characteristicvaluechanged', (event: any) => this.handleNotifications(event))
          navigator.vibrate(300);
        });
      }
    }).catch(err => {
      this.notify.openMessage("Connection Cancelled", "error");
    }).finally(() => {
      this.loader.isLoading = false;
    });
  }

  handleNotifications(event: any) {
    let utf8decoder = new TextDecoder();
    let value = event.target.value;
    let a = [];
    let b;
    // Convert raw data bytes to hex values just for the sake of showing something.
    // In the "real" world, you'd use data.getUint8, data.getUint16 or even
    // TextDecoder to process raw data bytes.
    alert("Got value from testing component")
    for (let i = 0; i < value.byteLength; i++) {
      a.push('0x' + ('00' + value.getUint8(i).toString(16)).slice(-2));
    }
    b = (utf8decoder.decode(value));
    if (b[0] == '0' || b[0] == '1' || b[0] == '2' || b[0] == '3' || b[0] == '4' || b[0] == '5' || b[0] == '7' || b[0] == '8' || b[0] == '9' || b[0] == ':' || b[0] == ';' || b[0] == '<' || b[0] == '=') {
      this.notify.openMessage(b[0], 'success');
      this.clickable = true;
      this.loader.isLoading = false;
    }

    this.counterValue = utf8decoder.decode(value);
  }

  sendContent() {
    this.loader.isLoading = true;
    this.clickable = false;
    var enc = new TextEncoder(); // Check need
    var myValue1 = enc.encode(this.myContent + "\n"); // Check need
    this.clickable = true;
    this.loader.isLoading = false;
    this.logger.addLog(this.myContent, this.conn.online, localStorage.getItem("selectedBTSite"), localStorage.getItem("selectedBTSiteID"));
    this.myContent = "";
    return this.btService.myWriteCharacteristic.writeValue(myValue1);
  }

  close() {
    this.debuggerClosed.emit('close');
  }

  openHelp(link: any) {
    window.open('https://wernerg123.github.io/prism-v2-docs/#' + link, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
  }

  setSN(event: string) {
  }

  getAndSendCustom(ca: any) {
    let myCommand = localStorage.getItem('customCmd');
    this.myContent = myCommand!;
    this.sendContent();
  }

  startCounter() {
    this.pressed = true;
    this.customTimer = window.setInterval(() => {
      this.customCount++;
      if (this.customCount == 3) {
        window.clearInterval(this.customTimer);
        this.customCount = 0;
        this.settingCustom = true;
      }
    }, 1000)
  }

  stopCounter() {
    this.pressed = false;
    window.clearInterval(this.customTimer);
    this.customCount = 0;
  }

  setCustomCmd() {
    localStorage.setItem('customCmd', this.customCmd);
    localStorage.setItem('customCmdLabel', this.customCmdLabel);
    this.settingCustom = false;
  }


  async attemptLocalOTA() {
    try {
      this.otaInProgress = true;
      // var enc = new TextEncoder();

      const result: any = await this.webreq.readOTAFile();
      const uint8Array = new Uint8Array(result);

      const chunkSize = 500;
      const MTU = 500;
      const PART = 16000;
      const totalChunks = Math.ceil(uint8Array.length / chunkSize);

      console.log(`Sending 0xFD`)
      await this.btService.myWriteCharacteristic.writeValue(new Uint8Array([0xFD])); // Gives a response from the device
      console.log(`Written 0xFD`)
      let fileParts = Math.ceil(uint8Array.length / PART);
      let fileLen = uint8Array.length;
      let fileSize: Uint8Array = new Uint8Array([
        0xFE,
        (fileLen >> 24) & 0xFF,
        (fileLen >> 16) & 0xFF,
        (fileLen >> 8) & 0xFF,
        fileLen & 0xFF
      ]);
      // Now send fileSize
      console.log(`Sending Filesize: ${fileSize}`)
      await this.btService.myWriteCharacteristic.writeValue(fileSize);
      console.log(`Sent Filesize`)
      let total = fileParts;

      const otaInfo: Uint8Array = new Uint8Array([
        0xFF,
        Math.floor(fileParts / 256),
        fileParts % 256,
        Math.floor(MTU / 256),
        MTU % 256
      ]);

      await this.btService.myWriteCharacteristic.writeValue(otaInfo)

      for (let x = 0; x < fileParts; x++) {
        console.log(`Sending part: ${x} of ${fileParts}`)
        this.otaProgress = Math.floor((x / fileParts) * 100);
        await this.sendPart(x, uint8Array, PART, MTU)
        console.log(`Written part ${x} of ${fileParts}`);
      }

      console.log(`Total Chunks: ${totalChunks}, Array length: ${uint8Array.length}`)

      console.log("OTA transfer completed.");
      // this.otaInProgress = false;
      // this.otaProgress = 0;
    } catch (error) {
      console.error("Error during OTA transfer:", error);
    }
  }

  async sendPart(position: number, data: Uint8Array, PART: any, MTU: any): Promise<void> {
    const start = position * PART;
    let end = (position + 1) * PART;
    if (data.length < end) {
      end = data.length;
    }
    const parts = Math.ceil((end - start) / MTU);

    for (let i = 0; i < parts; i++) {
      var toSar:any = [];
      toSar.push(0xFB);
      toSar.push(i);

      for (let y = 0; y < MTU; y++) {
        if ((position * PART) + (MTU * i) + y < data.length) {
          toSar.push(data[(position * PART) + (MTU * i) + y]);
        }
      }

       await this.send_data(new Uint8Array(toSar));
    }

    if ((end - start) % MTU !== 0) {
      const rem = (end - start) % MTU;
      // const toSend = new Uint8Array([0xFB, parts]);
      var toSar:any = [];
      toSar.push(0xFB);
      toSar.push(parts);
      for (let y = 0; y < rem; y++) {
        toSar.push(data[(position * PART) + (MTU * parts) + y]);
      }
      
       await this.send_data(new Uint8Array(toSar));
    }

    const update = new Uint8Array([
      0xFC,
      Math.floor((end - start) / 256),
      (end - start) % 256,
      Math.floor(position / 256),
      position % 256
    ]);
    
     await this.send_data(update);
    
  }

  async send_data(wData:any) {
      console.log(`About to write data...`)

        let writeRes = await this.btService.myWriteCharacteristic.writeValueWithoutResponse(wData);
        console.log(`Written data...`)

      
  }


}


