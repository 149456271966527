import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SwPush } from '@angular/service-worker';
import { AuthService } from './services/auth.service';
import { ConnectivityService } from './services/connectivity.service';
import { PushService } from './services/push.service';
import { PwaService } from './services/pwa.service';
import { WebRequestsService } from './services/web-requests.service';
import { LocalDatabaseService } from './services/local-database.service';
import * as FP from '@fingerprintjs/fingerprintjs';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'PRISM'; // This title should no longer be used - it was only the initial idea but since PRISM has grown into an e-thele product we can decide on all naming and designs as needed
  loggedIn = true;
  showSplash = false;

  allPermitted = true;

  constructor(private router: Router,
    public auth: AuthService,
    public conn: ConnectivityService,
    public push: PushService,
    public pwa: PwaService,
    public swPush: SwPush,
    public webReq: WebRequestsService,
    public localDB: LocalDatabaseService) { }


  ngOnInit() {

    this.setVisitorID();
    // self.caches.keys().then(keys => { keys.forEach(key => console.log(key)) });
    this.ensureLocalDB();
    console.log("Checking version...")
    this.pwa.checkForUpdateNew();
    if (localStorage.getItem('lang') == null || localStorage.getItem('lang') == undefined) {
      localStorage.setItem('lang', 'en');
    }
    this.showSplash = true;
    this.conn.checkOnline();
    setTimeout(() => { this.showSplash = false }, 1000)
    if (!('serviceWorker' in navigator)) {
      // Service Worker isn't supported on this browser, disable or hide UI.
      console.log('Service worker not available')
      return;
    } else {
      console.log('Service Worker Available')
    }

    if (!('PushManager' in window)) {
      // Push isn't supported on this browser, disable or hide UI.
      console.log('Push Manager not available')
      return;
    } else {
      console.log('Push Manager Available')
    }

    this.push.askPermission().then(res => {
      console.log(res)
    })

    if (!window.indexedDB) {
      alert("This browser does not support required database functionality. Please use Chrome instead");
    }

    this.askForPermissions();
  }

  // This sets the device ID used for identifying the user and device to interact with the API
  async setVisitorID() {
    let fpPromise = await FP.load();
    let fpRes = await fpPromise.get();
    let visitorID = fpRes.visitorId;
    localStorage.visitorID = visitorID;
  }
  navToTokens() {
    // console.log("Should Navigate")
    this.router.navigate(['/home'])
  }

  navToBluetooth() {
    // console.log("Should Navigate")
    this.router.navigateByUrl('/bluetooth')
    this.router.navigate(['/bluetooth'])
  }

  showTestNotification() {
    this.pwa.createNotification();
  }

  // This subscribes to push notifications
  subscribeNotifications() {
    if (this.swPush.isEnabled) {
      this.pwa.subscribeToPush().then(subscription => {
        // console.log("Subscription returned")
        // console.log(subscription)
        if (localStorage.getItem('notificationSubscription') != 'true') {
          this.webReq.subscribeNotifications({
            username: localStorage.getItem('username'),
            userID: localStorage.getItem('userID'),
            subscription: subscription
          }).subscribe((res: any) => {
            // console.log(res);
            if (res.success == 'true')
              localStorage.setItem('notificationSubscription', 'true');
          })
        }
      })
    }
  }

  // This subscribes the browser to clicking on push notifications
  subscribeNotificationClicks() {
    if (this.swPush.isEnabled) {
      this.pwa.subscribeToNotificationClicks().then(myClick => {
        // console.log(myClick);
      })
    }
  }

  // This ensures the local database is available and creates it if not
  ensureLocalDB() {
    this.localDB.confirmReportsDB();
    this.localDB.confirmDiagIMGDB();
    this.localDB.confirmTokenDB();
  }

  // This asks for all needed permissions to use the app, check the code as sometimes the warning screen shows up even if all permissions are granted
  askForPermissions() {
    // Array to hold permission promises
    const permissionPromises = [];

    // Check if geolocation is supported
    if ("geolocation" in navigator) {
      permissionPromises.push(navigator.permissions.query({ name: 'geolocation' })
        .then(permissionStatus => {
          if (permissionStatus.state === 'granted') {
            console.log('Geolocation permission granted');
            return true;
          } else {
            console.log('Requesting geolocation permission...');
            return navigator.geolocation.getCurrentPosition(
              position => {
                console.log('Geolocation permission granted');
                return true;
              },
              error => {
                console.error('Geolocation permission denied', error);
                return false;
              }
            );
          }
        })
        .catch(error => {
          console.error('Error getting geolocation permission', error);
          return false;
        }));
    } else {
      console.error('Geolocation is not supported');
    }

    // Check if camera is supported
    if ("mediaDevices" in navigator && navigator.mediaDevices.getUserMedia) {
      permissionPromises.push(new Promise((resolve, reject) => {
        console.log('Requesting camera permission...');
        navigator.mediaDevices.getUserMedia({ video: true })
          .then(stream => {
            console.log('Camera permission granted');
            resolve(true);
          })
          .catch(error => {
            console.error('Camera permission denied', error);
            resolve(false);
          });
      }));
    } else {
      console.error('Camera is not supported');
    }

    // Check if notifications are supported
    if ("Notification" in window) {
      permissionPromises.push(new Promise((resolve, reject) => {
        console.log('Requesting notification permission...');
        Notification.requestPermission().then(permission => {
          if (permission === "granted") {
            console.log('Notification permission granted');
            resolve(true);
          } else {
            console.error('Notification permission denied');
            resolve(false);
          }
        }).catch(error => {
          console.error('Error getting notification permission', error);
          resolve(false);
        });
      }));
    } else {
      console.error('Notifications are not supported');
    }

    // Wait for all permission promises to resolve
    Promise.all(permissionPromises)
      .then(results => {
        // Check if all permissions are granted
        if (results.every(permissionGranted => permissionGranted)) {
          console.log('All permissions granted!');
          this.allPermitted = true;
        } else {
          console.log('Some permissions were not granted. Retrying...');
          // Retry asking for permissions
          // this.askForPermissions();
          this.allPermitted = false;
        }
      });
  }

  reload() {
    window.location.reload();
  }

}
