<div fxFlex fxLayoutAlign="center center" fxLayout="column">

    <mat-card class="mat-elevation-z16" style="width: 90vw; height: 75vh;">
        <div style="width: 100%;" fxLayout="row" fxLayoutAlign="center center">

            <mat-form-field>
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyFilter($event)">
            </mat-form-field>

        </div>


        <div class="table-container">

            <table mat-table multiTemplateDataRows [dataSource]="dataSource" style="width:100%" matSort>

                <!-- <ng-container matColumnDef="{{column}}" *ngFor="let column of displayedColumns">
                <th mat-header-cell *matHeaderCellDef> {{column}} </th>
                <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
              </ng-container> -->

                <ng-container matColumnDef="siteID">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by siteID">
                        Site ID </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.siteID}}
                        <!-- {{element.email}} -->
                    </td>
                </ng-container>
                <ng-container matColumnDef="siteName">
                    <th mat-header-cell *matHeaderCellDef> Site Name </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.siteName}}
                        <!-- <mat-icon *ngIf="element.isAdmin" style="color: lime">check</mat-icon> -->
                        <!-- <mat-icon *ngIf="!element.isAdmin" style="color: red">close</mat-icon> -->
                    </td>
                </ng-container>

                <ng-container matColumnDef="_requestedBy">
                    <th mat-header-cell *matHeaderCellDef> Requested </th>
                    <td mat-cell *matCellDef="let element">
                        {{element._requestedBy}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="requestTime">
                    <th mat-header-cell *matHeaderCellDef> Time </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.requestTime | date:'dd/MM/yyyy-HH:mm'}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="sensor1">
                    <th mat-header-cell *matHeaderCellDef> Sensor1 </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.sensor1}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="sensor2">
                    <th mat-header-cell *matHeaderCellDef> Sensor2 </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.sensor2}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="sensor3">
                    <th mat-header-cell *matHeaderCellDef> Sensor3 </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.sensor3}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="sensor4">
                    <th mat-header-cell *matHeaderCellDef> Sensor4 </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.sensor4}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="actuator1">
                    <th mat-header-cell *matHeaderCellDef> Actuator1 </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.actuator1}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="actuator2">
                    <th mat-header-cell *matHeaderCellDef> Actuator2 </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.actuator2}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="handover">
                    <th mat-header-cell *matHeaderCellDef> Handover </th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-mini-fab
                            (click)="selectedHandover = element; isAdd=true; checkHO();"><mat-icon>handshake</mat-icon></button>
                    </td>
                </ng-container>


                <!-- ================================================ EXPANSION ===================================================== -->
                <!-- <ng-container matColumnDef="expand">
                    <th mat-header-cell *matHeaderCellDef aria-label="row actions">{{'app.userAdmin.moreLabel' |
                        translate}}</th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-icon-button aria-label="expand row"
                            (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
                            <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
                            <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
                        <div [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                            <div *ngIf="element == expandedElement">
                                <div> {{element.name}} {{element.surname}} </div>
                            </div>
                            <div *ngIf="element == expandedElement">
                                <a [href]="'mailto:'+element.email">{{element.email}}</a>
                            </div>
                            <div *ngIf="element == expandedElement">
                                <a [href]="'tel:'+element.cellphone">{{element.cellphone}}</a>
                            </div>
                            <div *ngIf="element == expandedElement">
                                <div> {{element.subcontractor}} </div>
                            </div>
                            <div *ngIf="element == expandedElement">
                                <div> {{element.canConfigure == true ? 'Configurator' : 'Not Configurator'}} </div>
                            </div>
                        </div>
                    </td>
                </ng-container> -->
                <!-- ================================================================================================================ -->

                <ng-container matColumnDef="options" stickyEnd style="width: 24px;">
                    <th mat-header-cell *matHeaderCellDef> {{'app.userAdmin.optionsLabel' | translate}} </th>
                    <td mat-cell *matCellDef="let element">
                        <mat-icon style="color: white;" [matMenuTriggerFor]="menu">more_vert</mat-icon>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)="goToEdit(element)">
                                <mat-icon>edit</mat-icon> {{'app.userAdmin.editLabel' | translate}}
                            </button>
                            <button mat-menu-item (click)="doDeleting(element)">
                                <mat-icon style="color: red;">delete</mat-icon> <label style="color:red">
                                    {{'app.userAdmin.deleteLabel' | translate}}</label>
                            </button>

                        </mat-menu>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="example-element-row"
                    [class.example-expanded-row]="expandedElement === row"></tr>
                <!-- <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr> -->

            </table>
        </div>
        <mat-paginator [length]="handoverList.length" [pageSizeOptions]="[5, 10]"
            style="position: absolute; bottom: 25px;"></mat-paginator>
        <!-- <a (click)="openHelp('4.1')" style="position: absolute; bottom: 0px; right: 0px; margin: 4px;"><mat-icon>help_outline</mat-icon></a> -->

    </mat-card>

    <mat-card *ngIf="isDeleting" class="mat-elevation-z16"
        style="position: absolute; width: 85vw;  border: 1px solid white; z-index: 2001;">
        <div fxLayout="column" fxLayoutAlign="space-between center">
            <h2>{{'app.userAdmin.confirmDeleteLabel' | translate}}</h2>
            <h2>({{selectedHandover.siteID}}) {{selectedHandover.siteName}}</h2>

            <div fxLayout="row" style="width: 100%;" fxLayoutAlign="space-evenly none">
                <button mat-raised-button color="warn"
                    (click)="deleteHandoverRequest(selectedHandover)"><mat-icon>delete</mat-icon>{{'app.userAdmin.deleteLabel'
                    | translate}}</button> <button mat-raised-button color="accent"
                    (click)="isDeleting=false"><mat-icon>cancel</mat-icon>{{'app.userAdmin.cancelLabel' |
                    translate}}</button>
            </div>
        </div>

    </mat-card>

    <mat-card
        style="z-index:3000; position:absolute;left:0;right:0;top:0;bottom:0;margin:auto; height:fit-content; width: 90vw; border: 1px solid white;"
        *ngIf="isAdd" fxLayout="column" fxLayoutAlign="space-evenly center">
        <h2>Handover ({{selectedHandover.siteID}}) {{selectedHandover.siteName}} device to:</h2>
        
        <div fxLayout="row" fxLayoutAlign="space-evenly start" style="width: 100%">
            <mat-form-field appearance="outline">
                <mat-label>Site Group</mat-label>
                <mat-select [(ngModel)]="selectedSiteGroup">
                    <mat-form-field>
                        <input matInput placeholder="Filter" (keyup)="applyHOFilter($event)" [(ngModel)]="handoverFilter" style="color: white">
                    </mat-form-field>
                    <mat-option *ngFor="let group of siteGroups" [value]="group">({{group.groupID}})
                        {{group.groupName}}</mat-option>
                </mat-select>
            </mat-form-field>
            <button mat-mini-fab style="background-color: lime" (click)="isEdit = true"><mat-icon>add</mat-icon></button>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-evenly center" style="width: 100%;">
            <button mat-raised-button color="primary" (click)="handover()">Handover</button>
            <button mat-raised-button color="warn" (click)="isAdd = false;">Cancel</button>
        </div>
    </mat-card>

    <mat-card
        style="z-index:3000; position:absolute;left:0;right:0;top:0;bottom:0;margin:auto; height:fit-content; width: 90vw; border: 1px solid white;"
        *ngIf="isEdit" fxLayout="column" fxLayoutAlign="space-evenly center">
        <h2>Enter site group details</h2>
        
           <mat-form-field appearance="outline">
                <mat-label>Group ID (T-Number)</mat-label>
                <input matInput [(ngModel)]="newSiteGroupID" pattern="[0-9]">
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Group Name</mat-label>
                <input matInput [(ngModel)]="newSiteGroupName">
            </mat-form-field>


        <div fxLayout="row" fxLayoutAlign="space-evenly center" style="width: 100%">
            <button mat-raised-button color="primary" (click)="addSiteGroup();">Add</button>
            <button mat-raised-button color="warn" (click)="isEdit = false">Cancel</button>
        </div>
    </mat-card>

</div>