import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogoCircleComponent } from './logo-circle.component';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';



@NgModule({
  declarations: [LogoCircleComponent],
  imports: [
    CommonModule,
    FlexModule,
    FlexLayoutModule,
  ],
  exports: [
    LogoCircleComponent
  ]
})
export class LogoCircleModule { }
