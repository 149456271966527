import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OptionalBreakPoint } from '@angular/flex-layout';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth.service';
import { BiometricsService } from 'src/app/services/biometrics.service';
import { LoaderService } from 'src/app/services/loader.service';
import { LocalDatabaseService } from 'src/app/services/local-database.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { WebRequestsService } from 'src/app/services/web-requests.service';
var bcrypt = require('bcryptjs');

@Component({
  selector: 'app-tokens',
  templateUrl: './tokens.component.html',
  styleUrls: ['./tokens.component.scss']
})
export class TokensComponent implements OnInit {

  @Output() close = new EventEmitter();

  @Input() reasonList: any[] = ["None"];

  public user = {}
  public email = ""
  public password = ""

  public db: any;
  public clientdb: any = "";

  reason: any;
  selectedSite: any;
  sites: any[] = [];
  filteredSites: any = [];
  siteFilter: any = "";
  fromTime: any = "";
  toTime: Date = new Date();
  fromDate: any = new Date(Date.now() + 119 * 60000).toISOString().slice(0, 16); // toISO returns GMT Time (-2 hours)
  toDate: any = new Date(Date.now() + 150 * 60000).toISOString().slice(0, 16);
  minDate: any = new Date(Date.now() + 118 * 60000).toISOString().slice(0, 16);

  gotTokens = false;
  tempToken0 = "";
  tempToken1 = "";
  tempToken2 = "";
  tokensSet: boolean = false;
  step: number = 1;
  edit: boolean = false;

  mySite = "";

  showEnterPwd = false;


  public isLoggedIn: boolean = false;

  constructor(private router: Router,
    private webReq: WebRequestsService,
    private auth: AuthService,
    public loader: LoaderService,
    public snackBar: MatSnackBar,
    public notifier: NotificationsService,
    public localDB: LocalDatabaseService,
    public translate: TranslateService,
    public biometrics: BiometricsService) { }

  ngOnInit() {
    if (localStorage.getItem('clientdb') != null && localStorage.getItem('clientdb') != undefined) {
      this.clientdb = localStorage.getItem('clientdb');
    }
    this.localDB.dbConnect().then((res: any) => {
      this.db = res.result;
    });
    if (localStorage.getItem('isLoggedIn') == "true") {
      this.isLoggedIn = true;
      var temp = localStorage.getItem('username')
      if (temp) {
        this.email = temp
      }
      this.getSites();
    }
  }

  applySitesFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.filteredSites = this.sites.filter((x: any) => x.siteName.toLowerCase().includes(filterValue) || x.siteID.toString().includes(filterValue));
    if (filterValue == "") {
      this.filteredSites = this.sites;
    }
  }

  clearFilter(event: any) {
    this.siteFilter = '';
    this.filteredSites = this.sites
  }

  login(email: string, password: string) {
    this.loader.isLoading = true;
    this.user = {
      'username': this.email.toLowerCase(),
      'password': this.password
    }
    this.webReq.login(this.user).subscribe(data => {
      if (data.loggedIn == true) {
        localStorage.setItem('username', this.email);
        localStorage.setItem('isLoggedIn', 'true');
        this.auth.isLoggedIn = true;
        this.isLoggedIn = true;
        this.loader.isLoading = false;
        this.getSites();
      }
    }, err => {
      this.loader.isLoading = false;
      this.snackBar.open('Invalid Credentials', 'Ok', { duration: 3000 })
    });
    // this.router.navigate(['sidenavnew'], {replaceUrl: true});
  }

  getSites() {
    this.loader.isLoading = true;
    this.webReq.getSites(this.email).subscribe((data: any) => {
      this.sites = data.filter((x: any) => x.clientdb == this.clientdb)
      // Add filter for handed over sites:
      this.sites = this.sites.filter((x:any) => x.handedOver != true);
      this.filteredSites = this.sites;
      this.loader.isLoading = false;
    }, err => {
      this.loader.isLoading = false;
      this.snackBar.open('Failed to get sites', 'Ok', { duration: 3000 })
    });
  }


  authenticate() {
    if(localStorage.getItem('authOnKey') == 'true') {
      var hasBiometrics = localStorage.getItem('hasBiometrics');
      if (hasBiometrics != null && hasBiometrics != undefined && hasBiometrics == 'true') {
        if (localStorage.getItem('userID') != null || localStorage.getItem('userID') != undefined) {
          this.biometrics.authenticateFinger().then(res => {
            if(res == true) { // Continue getting keys
              this.getTokensNew();
            }
            else {
              this.notifier.openMessage('Failed to authenticate', 'error', 3);
            }
          })
        }
  
      } else {
        this.showEnterPwd = true;
      }
    } else {
      this.getTokensNew();
    }
  }

  confirmPassword() {
           if (bcrypt.compareSync(this.password, localStorage.getItem('mop'))) { // Call this only if biometrics unavailable
          // Continue getting keys
          this.showEnterPwd = false;
          this.getTokensNew(); 
        } else {
          this.notifier.openMessage('Failed to authenticate', 'error', 3);
        }
  }


  getTokensNew() {

    this.loader.isLoading = true;
    // Codes are passed [<function_code>,<operational_code>] 

    // TODO - Add new way of generate for multidoor same as in desktop site...Figure out Reading of keys
    let codes = [];
    if (this.selectedSite.door1 == true && this.selectedSite.door2 == true) {
      codes = [[1, 0], [0, 0], [0, 1], [0, 2], [3, 0], [2, 0], [4, 0], [5,0], [5,1], [5,2]]
    } else if (this.selectedSite.door2 == true) {
      codes = [[1, 0], [3, 0], [2, 0], [4, 0], [5,0], [5,1], [5,2]]
    } else {
      codes = [[1, 0], [0, 0], [0, 1], [0, 2], [3, 0], [2, 0], [4, 0]]
    }
    this.webReq.generateTokenNew(this.selectedSite, codes, new Date(this.fromDate).getTime(), new Date(this.toDate).getTime(), localStorage.getItem('username'), this.reason).subscribe((tokens: any) => {

      let localList: any = [];
      if (tokens.tokens != undefined) {
        tokens.tokens.forEach((token: any) => {
          localList.push({
            "_id": "none",
            "username": localStorage.getItem('username'),
            "functionCode": token[0],
            "opCode": token[1],
            "siteID": this.selectedSite.siteID,
            "sitename": this.selectedSite.siteName,
            "token": token[2],
            "fromDate": tokens.from,
            "toDate": tokens.to,
            "clientdb": localStorage.getItem('clientdb')
          })
        });
        this.localDB.setAllTokens(this.db, localList).then(res => {
          this.loader.isLoading = false;
        });
        this.mySite = this.selectedSite.siteName;
        this.loader.isLoading = false;
        this.tokensSet = true;
      }
      else {
        this.loader.isLoading = false;
        this.notifier.openMessage(tokens.message, 'error');
        console.warn("Failed to get tokens");
      }

    }, err => {
      console.warn(err);
      this.loader.isLoading = false;
    });
  }

  showDetails() {
    console.info("Selected site ID: " + this.selectedSite.id + "\nFrom date: " + this.fromDate + " type: " + new Date(this.fromDate).getTime() + "\nFrom time: " + this.fromTime + " type: " + typeof (this.fromTime))
  }

  closeGenerator() {
    this.close.emit();
  }

  defaultToTime() {
    return new Date().toISOString();
  }

  defaultFromTime() {
    return new Date().toISOString();
  }
}
