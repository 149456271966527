import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { write } from 'fs';
import { Observable, Subject } from 'rxjs';
import { throwIfEmpty } from 'rxjs/operators';
import { BluetoothService } from 'src/app/services/bluetooth.service';
import { ConnectivityService } from 'src/app/services/connectivity.service';
import { LoaderService } from 'src/app/services/loader.service';
import { LoggingService } from 'src/app/services/logging.service';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { UserOptions } from 'jspdf-autotable';

import { PDF417DecodedBitStreamParser } from '@zxing/library';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { WebRequestsService } from 'src/app/services/web-requests.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { BluetoothHelpersService } from 'src/app/services/bluetooth-helpers.service';
import { LocalDatabaseService } from 'src/app/services/local-database.service';
import { ReportGeneratorService } from 'src/app/report-generator.service';
var pjson = require('../../../../package.json');

interface jsPDFWithPlugin extends jsPDF {
  autoTable: (options: UserOptions) => jsPDF;
}

@Component({
  selector: 'app-diagnostics',
  templateUrl: './diagnostics.component.html',
  styleUrls: ['./diagnostics.component.scss']
})
export class DiagnosticsComponent implements OnInit {

  public actuator: Actuator = new Actuator();
  public doorSensor: DoorSensor = new DoorSensor();
  public tamper: Tamper = new Tamper();
  public powerSupply: PowerSupply = new PowerSupply();
  public isBusy: boolean = true;
  public isTestAll: boolean = false;
  public testCounter = 0;
  public resultCounter = 0;
  public currentTest: any;
  public responseRecieved: boolean = false;
  public showImgWarning: boolean = false;
  public showMailReport: boolean = false;
  public isAligning: boolean = false;
  public mailAddress: string = "";

  showReportUpdates = false;

  sensor1 = 'n/a';
  sensor2 = 'n/a';
  sensor3 = 'n/a';
  sensor4 = 'n/a';

  actuator1 = 'n/a';
  actuator2 = 'n/a';


  public myDocument: any;

  public testArray: any[] = [];

  actuatorImages: any[] = [];
  sensorImages: any[] = [];
  tamperImages: any[] = [];
  powerSupplyImages: any[] = [];
  siteImages: any[] = [];
  capturing: any = '';
  imagesID = -1;

  testedSensors = false;
  testedActs = false;
  testedTamper = false;
  testedPS = false;
  showAskSubmit = false;
  submitHOReq = false;


  @Input() myCharacteristic: any;
  @Input() testSubject: Subject<any> = new Subject<any>();
  @Input() siteIDFromUnit: any = "";

  @Output() closed = new EventEmitter();
  @Output() action = new EventEmitter();
  @Output() reloadSubs = new EventEmitter();

  public images: any[] = [];
  public imageSelection = "Door Sensor";
  @Output() getPicture = new EventEmitter<WebcamImage>();
  showWebcam = false;
  isCameraExist = true;
  errors: WebcamInitError[] = [];
  // webcam snapshot trigger

  settingCustomFunction = false;
  myCustomName = "";
  myCustomContent = "";
  customFuncs: any = [];
  myContent = "";

  showCustomButtons = false;
  customBtnCount = 0;

  public reportStep = 1;
  public sdSuccess = false;
  public debugToken = "E+abeAFaPX8BYlvVfqTYFNriiMU="

  standdown = false;
  temperature: any;


  private trigger: Subject<void> = new Subject<void>();
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();

  constructor(public loader: LoaderService, public conn: ConnectivityService, public btService: BluetoothService,
    public logger: LoggingService, public webReq: WebRequestsService, public notify: NotificationsService, public translate: TranslateService,
    public btHelper: BluetoothHelpersService, public localDB: LocalDatabaseService, public reportGenerator: ReportGeneratorService) {
    translate.use(localStorage.getItem('lang')!);
  }
  // Return ok or err
  ngOnInit(): void {
    setTimeout(() => {
      this.action.emit('0x06');
    }, 1000)
    this.localDB.dbImgConnect().then(connection => {

      this.localDB.getImages(localStorage.getItem('selectedBTSiteID')).then((result: any) => {
        if (result != undefined) {
          this.actuatorImages = result.actuatorImages == undefined ? [] : result.actuatorImages;
          this.sensorImages = result.sensorImages == undefined ? [] : result.sensorImages;
          this.tamperImages = result.tamperImages == undefined ? [] : result.tamperImages;
          this.powerSupplyImages = result.powerSupplyImages == undefined ? [] : result.powerSupplyImages;
          this.siteImages = result.siteImages == undefined ? [] : result.siteImages;
          this.imagesID = result.id;
        }
      });
    })

    this.getCustomFuncs();
    this.testSubject.subscribe(res => {
      try {
        if(res.includes("Temperature")) {
          this.temperature = res;
          console.warn("Temperature set")
          // this.tempSet = true;
        }
        if (this.currentTest.name == 'StandDown') {
          if (res == '13') {
            this.standdown = true;
            this.reportStep = 2;
            this.sdSuccess = true;
            this.notify.openMessage(res, 'success', 5);
            this.generateReportS();

          } else if (res.includes('Testing')) {
            // this.notify.openMessage(res, 'info', 5);
          } else if (res == '10' || res == '14' || res == '12' || res == '11') {
            this.standdown = false;
            this.reportStep = 2;
            this.sdSuccess = false;
            this.generateReportS();
          }
        }
        if (res != true && res != false && res.length < 5 && this.currentTest.name == 'DoorSensor') {
          let dsRes = this.btHelper.getDiagnosticResult(res);
          this.doorSensor.state = 0;
          this.sensor1 = dsRes.sensor1;
          this.sensor2 = dsRes.sensor2;
          this.sensor3 = dsRes.sensor3;
          this.sensor4 = dsRes.sensor4;
        } else if (res != true && res != false && res.length < 5 && this.currentTest.name == 'Actuator') {
          let actRes = this.btHelper.getActuatorResult(res);
          this.actuator.state = 0;
          this.actuator1 = actRes.actuator1;
          this.actuator2 = actRes.actuator2;
        }
      } catch (e) {
        console.warn(e)
      }



      if (this.currentTest == undefined) {
        this.currentTest = new Actuator();
      }
      if (this.currentTest.name == 'DoorSensor' && res == true) {
        this.doorSensor.state = 0;
        this.sensor1 = 'closed';
        this.sensor2 = 'n/a'
        this.sensor3 = 'n/a'
        this.sensor4 = 'n/a'
      } else if (this.currentTest.name == 'DoorSensor' && res == false) {
        this.doorSensor.state = 1;

        this.sensor1 = 'open'
        this.sensor2 = 'n/a'
        this.sensor3 = 'n/a'
        this.sensor4 = 'n/a'
      }
      if (this.currentTest.name == 'Actuator' && res == true) {
        this.actuator.state = 0;
        this.actuator1 = 'good';
        this.actuator2 = 'n/a'
      } else if (this.currentTest.name == 'Actuator' && res == false) {
        this.actuator.state = 1;
        this.actuator1 = 'fault';
        this.actuator2 = 'n/a';
      }
      if (res == true) {
        this.currentTest.state = 0;
        console.log("Returned Test Result for: " + this.currentTest.name + " - " + this.currentTest.state)
        this.isBusy = false;
        this.logger.addLog(this.currentTest.name + ";" + this.currentTest.state, this.conn.online, localStorage.getItem("selectedBTSite"), localStorage.getItem("selectedBTSiteID"))
      }
      else if (res == false) {
        this.currentTest.state = 1;
        console.log("Returned Test Result for: " + this.currentTest.name + " - " + this.currentTest.state)
        this.isBusy = false;
        this.logger.addLog(this.currentTest.name + ";" + this.currentTest.state, this.conn.online, localStorage.getItem("selectedBTSite"), localStorage.getItem("selectedBTSiteID"))
      } else if (res != true && res != false) {
        this.isBusy = false
        console.warn(res)

      }
    });

    WebcamUtil.getAvailableVideoInputs().then((mediaDevices: MediaDeviceInfo[]) => {
      this.isCameraExist = mediaDevices && mediaDevices.length > 0;
    })
  }

  ngOnDestroy() {
    // needed if child gets re-created (eg on some model changes)
    // note that subsequent subscriptions on the same subject will fail
    // so the parent has to re-create parentSubject on changes
    this.testSubject.unsubscribe();
    this.reloadSubs.emit(true);
  }

  startSensorAlign() {
    if (this.isBusy == false) {
      // this.isBusy = true;
      // this.actuator.state = 3;
      // this.currentTest = this.actuator;
      this.action.emit('*;4');
      this.isAligning = true;
    }
  }
  stopSensorAlign() {
    if (this.isBusy == false) {
      // this.isBusy = true;
      // this.actuator.state = 3;
      // this.currentTest = this.actuator;
      this.action.emit('*;5');
      this.isAligning = false;
    }
  }

  testActuator() {
    if (this.isBusy == false) {
      this.isBusy = true;
      this.actuator.state = 3;
      this.currentTest = this.actuator;
      this.action.emit('*;2');
    }
  }

  testDoorSensor() {
    if (this.isBusy == false) {
      this.isBusy = true;
      this.doorSensor.state = 3;
      this.currentTest = this.doorSensor;
      this.action.emit('*;0');
    } 
  }

  testTamper() {
    if (this.isBusy == false) {
      this.isBusy = true;
      this.tamper.state = 3;
      this.currentTest = this.tamper;
      this.action.emit('*;1')
    }
  }

  testPowerSupply() {
    if (this.isBusy == false) {
      this.isBusy = true;
      this.powerSupply.state = 3;
      this.currentTest = this.powerSupply;
      this.action.emit('*;3')
    }
  }

  testAll() {
  }

  checkReportReady() {
    if (this.images.length < 5) {
      this.showImgWarning = true;
    } else {
      this.generateReport();
      // this.showMailReport = true;
    }
  }
  // Take your time to read through and understand what all this does (jspdf documentation is quite good)
  generateReport() {
    let actRes: string = "";
    let dsRes: string = "";
    let tmpRes: string = "";
    let psRes: string = "";

    actRes = this.returnTestState(this.actuator.state);
    dsRes = this.returnTestState(this.doorSensor.state);
    tmpRes = this.returnTestState(this.tamper.state);
    psRes = this.returnTestState(this.powerSupply.state);
    // this.actuator.state == 0 ? actRes = 'OK' : actRes = 'FAULT';
    // this.doorSensor.state == 0 ? dsRes = 'OK' : dsRes = 'FAULT';
    // this.tamper.state == 0 ? tmpRes = 'OK' : tmpRes = 'FAULT';
    // this.powerSupply.state == 0 ? psRes = 'OK' : psRes = 'FAULT';
    var imgData = new Image();
    var imgData1 = new Image();
    var DoorSensorImg = new Image();
    var TamperImg = new Image();
    var PowerSupplyImg = new Image();
    var SiteImg = new Image();
    var ActuatorImg = new Image();

    if (this.images.length != 5) {
      DoorSensorImg.src = "assets/no-img.jpg";
      TamperImg.src = "assets/no-img.jpg";
      SiteImg.src = "assets/no-img.jpg";
      ActuatorImg.src = "assets/no-img.jpg";
      PowerSupplyImg.src = "assets/no-img.jpg";
    } else {
      DoorSensorImg.src = this.images.filter(x => x.component == 'dsImg')[0].image.imageAsDataUrl;
      TamperImg.src = this.images.filter(x => x.component == 'tamperImg')[0].image.imageAsDataUrl;
      SiteImg.src = this.images.filter(x => x.component == 'siteImg')[0].image.imageAsDataUrl;
      ActuatorImg.src = this.images.filter(x => x.component == 'actImg')[0].image.imageAsDataUrl;
      PowerSupplyImg.src = this.images.filter(x => x.component == 'psImg')[0].image.imageAsDataUrl;
    }

    imgData.src = 'assets/ethele.png'
    imgData1.src = 'assets/prism.png'
    const doc = new jsPDF() as jsPDFWithPlugin;
    doc.addImage(imgData1, 'png', 160, 10, 25, 25);
    doc.addImage(imgData, 'png', 162, 35, 20, 10);
    doc.line(10, 75, 200, 75)
    doc.setFontSize(32);
    doc.text("Prism Test Report", 10, 30);
    doc.setFontSize(16);
    doc.text("Site: " + localStorage.selectedBTSite + "(" + this.siteIDFromUnit + ")", 10, 50);
    doc.text("Date: " + new Date().toLocaleString(), 10, 40);
    doc.text("This report was generated by " + localStorage.username, 10, 85);
    doc.line(10, 290, 200, 290)

    var mytb = '<table id="mypdftable" style="background-color: orange"><thead><tr><th>Component</th><th>Result</th><th>Image</th></tr></thead><tbody><tr><td>Actuator</td><td>' + actRes + '</td><td><img src="' + ActuatorImg.src + '" /></td></tr><tr><td>Door Sensor</td><td>' + dsRes + '</td><td><img src="' + DoorSensorImg.src + '" /></td></tr><tr><td>Tamper</td><td>' + tmpRes + '</td><td><img src="' + TamperImg.src + '" /></td></tr><tr><td>Power Supply</td><td>' + psRes + '</td><td><img src="' + PowerSupplyImg.src + '" /></td></tr></tbody></table>';

    var parser = new DOMParser();
    var oldBody = document.body;
    var myTBL = parser.parseFromString(mytb, 'text/html');
    document.body = myTBL.body

    doc.autoTable({
      html: '#mypdftable',
      bodyStyles: { minCellHeight: 30 },
      headStyles: { fillColor: '#ff5b0c' },
      startY: 90,
      didDrawCell: function (data) {
        if (data.column.index === 2 && data.cell.section === 'body') {
          var td = data.cell.raw?.valueOf();
          var img = td?.valueOf();
          var dim = data.cell.height - data.cell.padding('vertical');
          var textPos = data.cell.getTextPos();
          var imgSrc = "";
          switch (data.row.index) {
            case 0: imgSrc = ActuatorImg.src; break;
            case 1: imgSrc = DoorSensorImg.src; break;
            case 2: imgSrc = TamperImg.src; break;
            case 3: imgSrc = PowerSupplyImg.src; break;
            default: break;
          }
          doc.addImage(imgSrc, textPos.x, textPos.y, dim, dim);
        }
      }
    });

    doc.addImage(SiteImg, 'imageAsDataUrl', 155, 45, 25, 25);

    doc.text("* This report serves as indication that the above components marked", 10, 235);
    doc.text(" as 'OK' are in a working condition, conforming to ", 10, 245);
    doc.text(" Prism™ flow standards.", 10, 255)
    doc.text(" Please check any components marked with 'FAULT' for any issues", 10, 265)
    doc.text(" pertaining to the installation of the Prism™ unit", 10, 275)
    doc.save("prismPDF " + new Date().toISOString().replace('.', ':') + ".pdf");
    this.myDocument = doc;
    document.getElementById('mypdftable')?.remove();
    document.body.remove();
    document.body = oldBody;

  }

  mailReport() {
    this.reportStep = 6;
    var sendDoc = this.myDocument.output('blob');
    var b64Doc;
    var reader = new FileReader();
    reader.readAsDataURL(sendDoc)
    reader.onloadend = () => {
      b64Doc = reader.result?.toString();
      this.webReq.emailReport(localStorage.getItem('username'), localStorage.getItem('userID'), localStorage.getItem('selectedBTSite'), localStorage.getItem('selectedBTSiteID'), this.mailAddress, b64Doc).subscribe(res => {
        if (res.success == true) {
          this.reportStep = 7;
          this.notify.openMessage('Report Sent', 'success', 2);
          setTimeout(() => {
            this.showReportUpdates = false;
          });
        } else {
          this.showReportUpdates = false;
          this.notify.openMessage('Report Failed to Mail', 'info', 3);

        }
      }, err => {
        this.showReportUpdates = false;
        this.notify.openMessage('Failed to email report', 'error', 2);
      })
    }

  }

  returnTestState(state: any) {
    switch (state) {
      case 0: return 'OK';
        break;
      case 1: return 'FAULT';
        break;
      case 2: return 'NOT TESTED';
        break;
      default: return 'UNKNOWN';
    }
  }

  stateStyle(state: any) {
    switch (state) {
      case 0: return '#50ff4a';
        break;
      case 1: return 'red';
        break;
      case 2: return 'white';
        break;
      default: return 'white';
        break;
    }
  }

  writeAction(tokenID: string) {
    // this.loader.isLoading = true;

    var enc = new TextEncoder();
    var myValue;
    myValue = tokenID;

    if (myValue != null) {
      var myValue1 = enc.encode(myValue + "\n");
      // this.clickable = true;
      this.logger.addLog(tokenID, this.conn.online, this.siteIDFromUnit, localStorage.getItem("selectedBTSiteID"));
      setTimeout(() => {
        return this.btService.myWriteCharacteristic.writeValue(myValue1);
      }, 500);
    }
    else {
      alert("Will send: Nothing")
      return this.btService.myWriteCharacteristic.writeValue(enc.encode(""));
    }
  }

  close() {
    this.closed.emit();
  }

  openHelp() {
    window.open('https://wernerg123.github.io/prism-v2-docs/#3.3', '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
  }


  takeSnapshot(): void {
    this.trigger.next();
  }

  onOffWebCame() {
    this.showWebcam = !this.showWebcam;
  }

  handleInitError(error: WebcamInitError) {
    this.errors.push(error);
  }

  changeWebCame(directionOrDeviceId: boolean | string) {
    this.nextWebcam.next(directionOrDeviceId);
  }

  handleImage(webcamImage: WebcamImage) {
    this.getPicture.emit(webcamImage);
    if (this.capturing == '') {
      if (this.images.filter(x => x.component == this.imageSelection).length > 0) {
        this.images = this.images.filter(x => x.component != this.imageSelection);
      }
      this.images.push({ "image": webcamImage, "component": this.imageSelection });
      var myElement = document.getElementById(this.imageSelection);
      myElement?.setAttribute('src', webcamImage.imageAsDataUrl);
    } else {
      if (this.capturing == 'actuator') {
        this.actuatorImages.push({ "image": webcamImage })
      } else if (this.capturing == 'sensor') {
        this.sensorImages.push({ "image": webcamImage })
      } else if (this.capturing == 'tamper') {
        this.tamperImages.push({ "image": webcamImage })
      } else if (this.capturing == 'powersupply') {
        this.powerSupplyImages.push({ "image": webcamImage })
      } else if (this.capturing == 'site') {
        this.siteImages.push({ "image": webcamImage })
      }
      this.setDBImages();
    }

    this.showWebcam = false;
  }

  get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }

  findImage(component: string) {
    if (this.images.length > 0) {
      var myImg = this.images.filter(x => x.component == component)[0];
      if (myImg) {
        return myImg._imageAsDataUrl;
      } else {
        return "https://image.shutterstock.com/image-vector/add-button-260nw-506458858.jpg";
      }
    }
    else {
      return "https://image.shutterstock.com/image-vector/add-button-260nw-506458858.jpg";
    }
  }

  getSiteID() {
    // this.currentTest = "SiteID";

    this.action.emit('0x02');
  }

  addCustomFunc() {
    if (this.myCustomName == "populate" && this.myCustomContent == "auto") {
      for (let i = 0; i < 30; i++) {
        this.customFuncs.push({ name: "Test " + i, content: "xxx" })
      }
    } else {
      this.customFuncs.push({ name: this.myCustomName, content: this.myCustomContent });
      localStorage.setItem('customFuncs', JSON.stringify(this.customFuncs));
      this.myCustomContent = "";
      this.myCustomName = "";
      this.settingCustomFunction = false;
    }

  }

  removeCustomFunc(func: any) {
    let index = this.customFuncs.indexOf(func);
    if (index > -1) {
      this.customFuncs.splice(index, 1);
      localStorage.setItem('customFuncs', JSON.stringify(this.customFuncs))
    }
  }

  getCustomFuncs() {
    if (localStorage.getItem('customFuncs') == null || localStorage.getItem('customFuncs') == undefined) {
      this.customFuncs = [];
    } else {
      this.customFuncs = JSON.parse(localStorage.getItem('customFuncs')!);
    }
  }

  checkCustomButtons() {
    this.customBtnCount++;
    if (this.customBtnCount > 5) {
      this.showCustomButtons = true;
    }
  }

  getSensorClass(sensor: any) {
    if (sensor == 'closed' || sensor == 'good') {
      return 'sensorState good'
      // return {'background-color': 'limegreen'}
    } else if (sensor == 'open') {
      return 'sensorState open'
      // return {'background-color': 'red'}
    } else if (sensor == 'fault') {
      return 'sensorState fault';
    }
    else if (sensor == 'not present' || sensor == 'not connected' || sensor == 'n/a') {
      return 'sensorState notPresent'
      // return {'background-color': 'grey'}
    }
    else if (sensor == 'not set') {
      // return {'animation': 'mycolor 2s infinite'}
      return 'sensorState notSet'
      // return {'border': '2px solid black'}
    }
    else {
      return 'sensorState'
      // return {'background-color': 'orange'}
    }
  }

  getImgSource(img: any) {
    return img.image._imageAsDataUrl;
  }
  // testDB() {
  //   this.localDB.createDBForImages().then(result => {
  //     console.info(result)
  //   })
  // }

  addNewImg(imgType: any) {
    this.capturing = imgType;
    this.showWebcam = true;
  }

  deleteImg(img: any) {
    this.actuatorImages = this.actuatorImages.filter((x: any) => x.image != img.image);
    this.sensorImages = this.sensorImages.filter((x: any) => x.image != img.image);
    this.tamperImages = this.tamperImages.filter((x: any) => x.image != img.image);
    this.powerSupplyImages = this.powerSupplyImages.filter((x: any) => x.image != img.image);
    this.siteImages = this.siteImages.filter((x: any) => x.image != img.image);
    this.setDBImages();
  }
  setDBImages() {
    let imgObj = {
      siteID: localStorage.getItem('selectedBTSiteID'),
      actuatorImages: this.actuatorImages,
      sensorImages: this.sensorImages,
      tamperImages: this.tamperImages,
      siteImages: this.siteImages,
      powerSupplyImages: this.powerSupplyImages
    }
    this.localDB.storeImages(imgObj).then(result => {
    })
  }

  tryStandDown() {
    console.warn(`sensor1: ${this.sensor1}, sensor2: ${this.sensor2}, sensor3: ${this.sensor3}, sensor4: ${this.sensor4}, actuator1: ${this.actuator1}, actuator2: ${this.actuator2}, tamper:${JSON.stringify(this.tamper)}`)
    if (this.sensor1 != 'open' && this.sensor2 != 'open' && this.sensor3 != 'open' && this.sensor4 != 'open' && this.actuator1 != 'fault' && this.actuator2 != 'fault'
      && this.tamper.state == 0 && this.testedActs == true && this.testedTamper == true && this.testedSensors == true) {
      console.warn("Will now send stand down!!!!!!!!!!");
      this.currentTest = { name: 'StandDown' };
      this.action.emit('2');
      this.isBusy = true;
      this.loader.isLoading = true;
      this.reportStep = 1;
      this.showReportUpdates = true;
    } else if (this.testedActs == false || this.testedSensors == false || this.testedTamper == false) {
      alert("Please ensure all tests are done, then try to generate a report again")
    } else {
      this.reportStep = 2;
      this.sdSuccess = false;
      alert("Not all tests passed, but report will be generated");
      this.showReportUpdates = true;
      this.generateReportS();
    }

  }

  tryGenRep() {
    if (this.sensor1 != 'open' && this.sensor2 != 'open' && this.sensor3 != 'open' && this.sensor4 != 'open' && this.actuator1 != 'fault' && this.actuator2 != 'fault'
      && this.tamper.state == 0 && this.testedActs == true && this.testedTamper == true && this.testedSensors == true &&
      (localStorage.getItem('clientdb') == 'neocom' || localStorage.getItem('clientdb') == 'ethele')) {
      this.tryStandDown();
    } else {
      this.tryStandDown();
    }
  }

  async generateReportS() {
    // debugger
    let myLocation = await this.logger.getLocation();
    this.reportStep = 3;
    let repObj = {
      sensor1: this.sensor1,
      sensor2: this.sensor2,
      sensor3: this.sensor3,
      sensor4: this.sensor4,
      actuator1: this.actuator1,
      actuator2: this.actuator2,
      actuatorImages: this.actuatorImages,
      sensorImages: this.sensorImages,
      tamperImages: this.tamperImages,
      powerSupplyImages: this.powerSupplyImages,
      siteImages: this.siteImages,
      siteID: localStorage.getItem('selectedBTSiteID'),
      siteName: localStorage.getItem('selectedBTSite'),
      username: localStorage.getItem('username'),
      email: localStorage.getItem('email'),
      tamper: this.returnTestState(this.tamper.state),
      powerSupply: this.returnTestState(this.powerSupply.state),
      standdown: this.standdown,
      firmware: localStorage.getItem('firmware'),
      software: pjson.version,
      lat: myLocation.lat,
      lon: myLocation.lon,
      temperature: "CPU " + this.temperature,
      secondaryOffline: localStorage.getItem("secondaryOffline")
    }
    console.warn(repObj)
    this.myDocument = this.reportGenerator.generateReport(repObj);
    var sendDoc = this.myDocument.output('blob');
    this.reportStep = 4;

    // this.conn.online = false;
    if (this.conn.online) {
      let filename = new Date();
      console.warn("The following is the filename: ")
      console.warn(filename)
      console.warn(filename.toString())
      this.webReq.uploadReport({ file: sendDoc, siteID: localStorage.getItem('selectedBTSiteID'), clientdb: localStorage.getItem('clientdb'), filename: filename }).subscribe(result => {
        this.reportStep = 5;
        this.generateHORequest(filename);
        setTimeout(() => {
          this.showReportUpdates = false
        }, 5000);
      }, err => {
        this.reportStep = 6;
        this.generateHORequest(filename);
        console.log("error on upload....")
        console.log(err)
        setTimeout(() => {
          this.showReportUpdates = false
        }, 5000);
      })
    }
    else {
      this.reportStep = 5;
      let filename = new Date();
      this.generateHORequest(filename);

      let repRes = await this.localDB.addReport({ file: sendDoc, siteID: localStorage.getItem('selectedBTSiteID'), clientdb: localStorage.getItem('clientdb'), filename: filename });
      this.reportStep = 7;
      setTimeout(() => {
        this.showReportUpdates = false
      }, 2000);
    }
    

    this.loader.isLoading = false;
  }


  generateHORequest(reportFileName:any) {

    if (localStorage.getItem('clientdb') == 'ethele' || localStorage.getItem('clientdb') == 'neocom') {
      let repObj = {
        sensor1: this.sensor1,
        sensor2: this.sensor2,
        sensor3: this.sensor3,
        sensor4: this.sensor4,
        actuator1: this.actuator1,
        actuator2: this.actuator2,
        siteID: localStorage.getItem('selectedBTSiteID'),
        siteName: localStorage.getItem('selectedBTSite'),
        username: localStorage.getItem('username'),
        email: localStorage.getItem('email'),
        tamper: this.returnTestState(this.tamper.state),
        powerSupply: this.returnTestState(this.powerSupply.state),
        filename: new Date(reportFileName).toString(),
        standdown: this.standdown
      }
      if(this.conn.online) {
        this.webReq.generateHandoverRequest(repObj).subscribe(result => {
        });
      } else {
        // Store HO request locally
        let reqList = [];
        if(localStorage.getItem('handoverReqs') != null && localStorage.getItem('handoverReqs') != undefined) {
          reqList = JSON.parse(localStorage.getItem('handoverReqs')!)
        } 
        reqList.push(repObj);
        localStorage.setItem('handoverReqs', JSON.stringify(reqList));
      }
      
    }
  }
}

enum CheckState {
  ok, // (0)
  err, // (1)
  unknown,
  checking,
  checked,
}

class Actuator {
  state: CheckState = 2;
  name: string = "Actuator"
}

class Tamper {
  state: CheckState = 2;
  name: string = "Tamper"
}

class DoorSensor {
  state: CheckState = 2;
  name: string = "DoorSensor"
}

class PowerSupply {
  state: CheckState = 2;
  name: string = "PowerSupply"
}