import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA } from '@angular/material/legacy-snack-bar';
import { ConnectivityService } from 'src/app/services/connectivity.service';
import { LocalDatabaseService } from 'src/app/services/local-database.service';
import { LoggingService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent {

  public iconColor: string = "black";

  public db: any;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any, public logService: LoggingService, public conn: ConnectivityService, public localDB: LocalDatabaseService) {
    this.checkErrorCode(data.message);
    if (data.icon == 'error') {
      this.iconColor = 'red';
    } else if (data.icon == 'success') {
      this.iconColor = 'green'
      data.icon = 'check_circle'
    } else if (data.icon == 'info') {
      this.iconColor = 'gray'
      data.icon = 'info'
    }
  }

  dismiss() {
    // this.close();
    this.data.preClose();
  }

  checkErrorCode(errCode: string) {
    switch (errCode) {
      case '0':
        this.data.message = 'Success';
        this.iconColor = 'green';
        this.data.icon = 'success'
        break;
      case '1':
        this.data.message = 'Error';
        this.iconColor = 'red';
        this.data.icon = 'error';
        this.logService.addErrorBTLog('Error', this.conn.online, localStorage.getItem('selectedBTSite'))
        break;
      case '2':
        this.data.message = 'Packet too long';
        this.iconColor = 'red';
        this.data.icon = 'error';
        this.logService.addErrorBTLog('Packet too long', this.conn.online, localStorage.getItem('selectedBTSite'))
        break;
      case '3':
        this.data.message = 'Invalid CRC';
        this.iconColor = 'red';
        this.data.icon = 'error';
        this.logService.addErrorBTLog('Invalid CRC', this.conn.online, localStorage.getItem('selectedBTSite'))
        break;
      case '4':
        this.data.message = 'Valid CRC';
        this.iconColor = 'green';
        this.data.icon = 'success'
        break;
      case '5':
        this.data.message = 'Invalid v-Key';
        this.iconColor = 'red';
        this.data.icon = 'error';
        this.logService.addErrorBTLog('Invalid v-Key', this.conn.online, localStorage.getItem('selectedBTSite'))
        break;
      case '6':
        this.data.message = 'Valid v-Key';
        this.iconColor = 'green';
        this.data.icon = 'success'
        break;
      case '7':
        this.data.message = 'Future v-Key';
        this.iconColor = 'red';
        this.data.icon = 'error';
        this.logService.addErrorBTLog('Future v-Key', this.conn.online, localStorage.getItem('selectedBTSite'))
        break;
      case '8':
        this.data.message = 'Invalid site ID';
        this.iconColor = 'red';
        this.data.icon = 'error';
        this.logService.addErrorBTLog('Invalid Site ID', this.conn.online, localStorage.getItem('selectedBTSite'))
        break;
      case '9':
        this.data.message = 'Over current';
        this.iconColor = 'red';
        this.data.icon = 'error';
        this.logService.addErrorBTLog('Over Current', this.conn.online, localStorage.getItem('selectedBTSite'))
        break;
      case ':':
        this.data.message = 'Door open';
        this.iconColor = 'red';
        this.data.icon = 'error';
        this.logService.addErrorBTLog('Door Open', this.conn.online, localStorage.getItem('selectedBTSite'))
        break;
      case '10':
        this.data.message = 'Door open';
        this.iconColor = 'red';
        this.data.icon = 'error';
        this.logService.addErrorBTLog('Door Open', this.conn.online, localStorage.getItem('selectedBTSite'))
        break;
      case ';':
        this.data.message = "Check power supply";
        this.iconColor = 'red';
        this.data.icon = 'error';
        this.logService.addErrorBTLog('Check Power Supply', this.conn.online, localStorage.getItem('selectedBTSite'))
        break;
      case '<':
        this.data.message = "Check actuator";
        this.iconColor = 'red';
        this.data.icon = 'error';
        this.logService.addErrorBTLog('Check Actuator', this.conn.online, localStorage.getItem('selectedBTSite'))
        break;
      case '12':
        this.data.message = "Check actuator";
        this.iconColor = 'red';
        this.data.icon = 'error';
        this.logService.addErrorBTLog('Check Actuator', this.conn.online, localStorage.getItem('selectedBTSite'))
        break;
      case '=':
        this.data.message = "Standdown Successful";
        this.iconColor = 'green';
        this.data.icon = 'success';
        // remove the token here...
        this.localDB.dbConnect().then((res: any) => {
          this.db = res.result;
          this.localDB.getAllTokens(this.db).then((tokens: any) => {
            tokens.foreach((token: any) => {
              if (token.fromDate == localStorage.getItem('validFrom') && token.toDate == localStorage.getItem('validTo') && token.sitename == localStorage.getItem('selectedBTSite')) {
                this.localDB.deleteTokenSet(this.db, token.id).then((delRes: any) => {
                  // console.log("Removed token");
                });
              }
            })
          })
        })
        break;
      case '11':
        this.data.message = "Check power supply";
        this.iconColor = 'red';
        this.data.icon = 'error';
        this.logService.addErrorBTLog('Check Power Supply', this.conn.online, localStorage.getItem('selectedBTSite'))
        break;
      case '13':
        this.data.message = "Standdown Successful";
        this.iconColor = 'green';
        this.data.icon = 'success';
        this.logService.addLog("Stand down success", this.conn.online, localStorage.getItem("selectedBTSite"), localStorage.getItem("selectedBTSiteID"))
        // remove the token here...
        this.localDB.dbConnect().then((res: any) => {
          this.db = res.result;
          this.localDB.getAllTokens(this.db).then((tokens: any) => {
            tokens.forEach((token: any) => {
              if (token.fromDate == localStorage.getItem('validFrom') && token.toDate == localStorage.getItem('validTo') && token.sitename == localStorage.getItem('selectedBTSite')) {
                this.localDB.deleteTokenSet(this.db, token.id).then((delRes: any) => {
                  // console.log("Removed token");
                  for (var key in localStorage) {
                    for (var key in localStorage) {
                      let siteName = localStorage.getItem('selectedBTSite');
                      if (siteName != null) {
                        if (key.includes("t_") && key.includes(siteName)) {
                          localStorage.removeItem(key);
                        }
                      }
                    }
                  }
                  localStorage.removeItem('validTo');
                  localStorage.removeItem('validFrom');
                });
              }
            })
          })
        })
        break;
      case '>':
        this.data.message = "Door Fault";
        this.iconColor = 'red';
        this.data.icon = 'error';
        this.logService.addErrorBTLog('Door Fault', this.conn.online, localStorage.getItem('selectedBTSite'))
        break;
      case '14':
        this.data.message = "Door Fault";
        this.iconColor = 'red';
        this.data.icon = 'error';
        this.logService.addErrorBTLog('Door Fault', this.conn.online, localStorage.getItem('selectedBTSite'))
        break;
      default:
        break;
    }

  }
}
