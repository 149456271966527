import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-serializer',
  templateUrl: './serializer.component.html',
  styleUrls: ['./serializer.component.scss']
})
export class SerializerComponent implements OnInit {

  @Output() serialNumberCap: EventEmitter<string> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();
  cameraOpen: boolean = true;
  typingSN: boolean = false
  serialNumber: string ="";

  constructor(public translate: TranslateService) {
    translate.setDefaultLang('en');
    translate.use(localStorage.getItem('lang')!);
  }

  ngOnInit(): void {
    
  }

  scanSuccessHandler(event: any) {
    this.serialNumberCap.emit(event)
    this.cameraOpen = false;
  }

  onClose() {
    this.close.emit();
  }

}