import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { FlexLayoutModule } from '@angular/flex-layout';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';

import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
// import { LoginComponent } from './login/loginOld.component';
import { LoginComponent } from './pages/login/login.component';
import { FormsModule } from '@angular/forms';
import { HomeComponent } from './pages/home/home.component';
import { WebBluetoothModule } from '@manekinekko/angular-web-bluetooth';
// import { SidenavComponent } from './sidenav/sidenav.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatDividerModule} from '@angular/material/divider';
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';
import { BluetoothComponent } from './pages/bluetooth/bluetooth.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import { LoaderModule } from './components/loader/loader.module';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { MAT_LEGACY_SNACK_BAR_DEFAULT_OPTIONS as MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/legacy-snack-bar';
import { provideFirebaseApp, getApp, initializeApp } from '@angular/fire/app';
import { SplashScreenComponent } from './components/splash-screen/splash-screen.component';
import { SiteSelectComponent } from './pages/bluetooth/site-select/site-select.component';
import { QrScannerComponent } from './components/qr-scanner/qr-scanner.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { TestingComponent } from './pages/testing/testing.component';
import { DiagnosticsComponent } from './pages/diagnostics/diagnostics.component';
import { TokensComponent } from './pages/token-tools/tokens/tokens.component';
import { MatStepperModule } from '@angular/material/stepper';
import { GenerateSystemTokensComponent } from './pages/token-tools/generate-system-tokens/generate-system-tokens.component';
// import { PasswordUpdateComponent } from './components/password-update/password-update.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {WebcamModule} from 'ngx-webcam';
import { MyTokensComponent } from './pages/my-tokens/my-tokens.component';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { ConfigurationComponent } from './pages/configuration/configuration.component';
import {MatLegacySlideToggleModule as MatSlideToggleModule} from '@angular/material/legacy-slide-toggle';
import {MatLegacySliderModule as MatSliderModule} from '@angular/material/legacy-slider';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatLegacyPaginatorModule as MatPaginatorModule} from '@angular/material/legacy-paginator';
import { AuthInterceptorInterceptor } from './interceptors/auth-interceptor.interceptor';
import {MatRippleModule} from '@angular/material/core';
// import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import { BiometricsComponent } from './pages/admin/biometrics/biometrics.component';
import { MapSelectorComponent } from './components/map-selector/map-selector.component';
import { LogoCircleModule } from './components/logo-circle/logo-circle.module';
import { SerializerComponent } from './components/serializer/serializer.component';
import { SerializerModule } from './components/serializer/serializer.module';
// import { NotificationConfigComponent } from './pages/admin/notification-config/notification-config.component';
// import { LogoCircleComponent } from './components/logo-circle/logo-circle.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatMenuModule} from '@angular/material/menu';
// import { AddSubContractorComponent } from './pages/admin/add-sub-contractor/add-sub-contractor.component';
// import { SubContractorsAdminComponent } from './pages/admin/sub-contractors-admin/sub-contractors-admin.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PinCodeComponent } from './components/pin-code/pin-code.component';
// import { AddSiteGroupsComponent } from './components/add-site-groups/add-site-groups.component';
// import { SiteGroupsComponent } from './components/site-groups/site-groups.component';
import {MatRadioModule} from '@angular/material/radio';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { HandoverManagementComponent } from './pages/handover-management/handover-management.component';
import { PopupMenuComponent } from './components/popup-menu/popup-menu.component';
import { MyHistoryComponent } from './pages/my-history/my-history.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MyRequestsComponent } from './pages/my-requests/my-requests.component';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressBarModule } from '@angular/material/progress-bar';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    // LoginComponent,
    HomeComponent,
    // SidenavComponent,
    BluetoothComponent,
    TestingComponent,
    DiagnosticsComponent,
    NotificationsComponent,
    // TokensComponent,    
    SplashScreenComponent,
    SiteSelectComponent,
    MyTokensComponent,
    ConfigurationComponent,
    PinCodeComponent,
    HandoverManagementComponent,
    PopupMenuComponent,
    MyHistoryComponent,
    MyRequestsComponent,
    // AddSiteGroupsComponent,
    // SiteGroupsComponent,
    // AddSubContractorComponent,
    // SubContractorsAdminComponent,
    // SerializerComponent,
    // NotificationConfigComponent,
    // LogoCircleComponent,
    // MapSelectorComponent,
    // BiometricsComponent
    // PasswordUpdateComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    WebBluetoothModule.forRoot({
      enableTracing: true // or false, this will enable logs in the browser's console
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    ZXingScannerModule,
    FlexLayoutModule,
    MatCardModule,
    MatInputModule,
    MatButtonModule,
    HttpClientModule,
    FormsModule,
    MatSelectModule,
    MatSidenavModule,
    MatIconModule,
    MatToolbarModule,
    MatDividerModule,
    MatListModule,
    MatProgressSpinnerModule,
    LoaderModule,
    MatStepperModule,
    MatExpansionModule,
    MatTableModule,
    WebcamModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatRippleModule,
    MatTooltipModule,
    LogoCircleModule,
    SerializerModule,
    MatMenuModule,
    MatRadioModule,
    MatButtonToggleModule,
    MatTabsModule,
    MatSortModule,
    MatProgressBarModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    provideFirebaseApp(()=>initializeApp({}))
  ],
  providers: [
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration:3000}},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
