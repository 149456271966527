import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { NotificationsComponent } from '../components/notifications/notifications.component';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private snackBar: MatSnackBar) { }

  openMessage(message: string, icon: string, duration: number = 3) {
    if (message != undefined && !message?.includes('MAC')) {
      this.snackBar.openFromComponent(NotificationsComponent, {
        duration: duration * 1000,
        data: {
          message: message,
          icon: icon,
          preClose: () => { this.snackBar.dismiss() }
        }
      });
    }

  }
}
