<div fxFlex fxLayoutAlign="center center" fxLayout="column">
    <app-loader></app-loader>
    <mat-card class="mat-elevation-z16" style="height: 75vh;" fxLayoutAlign="space-evenly none" fxLayout="column">
        <button mat-mini-fab (click)="closeGenerator()"
            style="position: absolute; top: 8px; left: 8px; background-color: transparent; z-index: 2002">
            <mat-icon>arrow_back</mat-icon>
        </button>
        <mat-stepper orientation="vertical" linear=true style="max-height: 70vh; overflow: auto;">

            <mat-step *ngIf="!isLoggedIn">
                <ng-template matStepLabel>Enter user details</ng-template>
                <div *ngIf="!isLoggedIn">
                    <mat-form-field appearance="outline">
                        <mat-label>Username</mat-label>
                        <input [(ngModel)]="email" matInput placeholder="username" required>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Password</mat-label>
                        <input matInput [(ngModel)]="password" placeholder="password" type="password" required>
                    </mat-form-field>
                    <div>
                        <button mat-raised-button (click)="login(email, password)" color="primary">Confirm</button>
                    </div>
                </div>
                <div *ngIf="isLoggedIn">
                    <button mat-raised-button matStepperNext (click)="step = 2;" color="primary">Next</button>
                </div>
            </mat-step>


            <mat-step>
                <ng-template matStepLabel>{{'app.tokens.reason' | translate}}</ng-template>
                <mat-form-field appearance="outline">
                    <mat-label>{{'app.tokens.reason' | translate}}</mat-label>
                    <mat-select [(ngModel)]="reason">
                        <!-- <mat-form-field appearance="outline">
                            <mat-label>Filter</mat-label>
                            <input matInput type="text" (keyup)="applySitesFilter($event)" style="color:white" autocomplete="off" placeholder="Ex. default" [(ngModel)]="siteFilter">
                        </mat-form-field> -->
                        <mat-option *ngFor="let reason of reasonList" [value]="reason">
                            {{reason}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div>
                    <button mat-raised-button matStepperNext (click)="step = 2" color="primary">{{'app.tokens.next' | translate}}</button>
                </div>
            </mat-step>

            <mat-step>
                <ng-template matStepLabel>{{'app.tokens.selectSite' | translate}}</ng-template>
                <mat-form-field appearance="outline" *ngIf="sites.length > 0">
                    <mat-label>{{'app.tokens.site' | translate}}</mat-label>
                    <mat-select [(ngModel)]="selectedSite" (openedChange)="clearFilter($event)">
                        <mat-form-field appearance="outline">
                            <mat-label>Filter</mat-label>
                            <input matInput type="text" (keyup)="applySitesFilter($event)" style="color:white" autocomplete="off" placeholder="Ex. default" [(ngModel)]="siteFilter">
                        </mat-form-field>
                        <mat-option *ngFor="let site of filteredSites" [value]="site">
                            {{site.siteName}} ({{site.siteID}})
                        </mat-option>
                    </mat-select>
                </mat-form-field>
              
                <div *ngIf="selectedSite?.secondaryController">
                    <mat-checkbox [(ngModel)]="selectedSite.door1">Door 1</mat-checkbox>
                    <mat-checkbox [(ngModel)]="selectedSite.door2">Door 2</mat-checkbox>
                </div>

                <div>
                    <button mat-raised-button matStepperNext (click)="step = 3" color="primary" [disabled]="!selectedSite?.door1 && !selectedSite?.door2 && selectedSite?.secondaryController">{{'app.tokens.next' | translate}}</button>
                </div>
            </mat-step>

            <mat-step>
                <ng-template matStepLabel>{{'app.tokens.validFrom' | translate}}</ng-template>
                    <mat-form-field appearance="outline">
                        <input matInput [(ngModel)]="fromDate" [min]="minDate" type="datetime-local">
                    </mat-form-field>
                <div>
                    <button mat-raised-button matStepperNext color="primary">{{'app.tokens.next' | translate}}</button>
                </div>

            </mat-step>

            <mat-step>
                <ng-template matStepLabel>{{'app.tokens.validTo' | translate}}</ng-template>
                <mat-form-field appearance="outline">
                    <input matInput [(ngModel)]="toDate" [min]="fromDate" type="datetime-local">
                </mat-form-field>
                <div>
                    <button mat-raised-button matStepperNext (click)="authenticate()" [disabled]="selectedSite == undefined" color="primary"><mat-icon>sms</mat-icon> {{'app.tokens.generate' | translate}}</button>
                </div>
            </mat-step>

            <mat-step>
                <ng-template matStepLabel>{{'app.tokens.confirm' | translate}}</ng-template>
                <div style="position: absolute; left:-10%; top: 0; width: 100%;">
                    <div *ngIf="tokensSet" fxLayout="column" fxLayoutAlign="center center">
                        <mat-icon style="color: lightgreen; margin: 8px; ">check_circle</mat-icon>
                        <label style="margin: 8px;">{{'app.tokens.loaded' | translate}} </label>
                        <ul>
                            <li>
                                <label style="font-size:small">{{mySite}}</label>
                            </li>
                        </ul>
                    </div>
                    <div *ngIf="!tokensSet" fxLayout="column" fxLayoutAlign="center center">
                        <mat-icon style="color: red; margin: 8px;">highlight_off</mat-icon>
                        <label style="margin: 8px;">{{'app.getTokens.none' | translate}}</label>
                    </div>
                </div>
            </mat-step>

        </mat-stepper>
    </mat-card>


    <mat-card *ngIf="showEnterPwd" style="z-index: 2003; position: absolute; border: 1px solid white;" fxLayout="column">
        <h2>Enter Password</h2>
        <mat-form-field>
            <mat-label>Password</mat-label>
            <input matInput [(ngModel)]="password" type="password">
        </mat-form-field>
        <div fxLayoutAlign="space-evenly center">
            <button mat-raised-button (click)="confirmPassword()" color="primary">Confirm</button>
            <button mat-raised-button (click)="showEnterPwd = false;" color="warn">Cancel</button>
        </div>
    </mat-card>

</div>