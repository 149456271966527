import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { BiometricsService } from 'src/app/services/biometrics.service';
import { BluetoothHelpersService } from 'src/app/services/bluetooth-helpers.service';
import { BluetoothService } from 'src/app/services/bluetooth.service';
import { ConnectivityService } from 'src/app/services/connectivity.service';
import { LoaderService } from 'src/app/services/loader.service';
import { LoggingService } from 'src/app/services/logging.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { WebRequestsService } from 'src/app/services/web-requests.service';
// import { TestingComponent } from '../testing/testing.component';
var bcrypt = require('bcryptjs');

@Component({
  selector: 'app-bluetooth',
  templateUrl: './bluetooth.component.html',
  styleUrls: ['./bluetooth.component.scss']
})
export class BluetoothComponent implements OnInit {



  testSubject: Subject<any> = new Subject();
  confSubject: Subject<any> = new Subject();
  @Output() testResult = new EventEmitter();
  siteSelected: boolean = false;
  clickable: boolean = true;
  username: any;
  password: any;
  siteID: any;
  user = {};
  myCharacteristic: any;
  public counterValue: any;
  showCounter = false;
  testValue: string = "";
  tempToken: any;
  funcToken: any;
  serviceUUID: string = '6e400001-b5a3-f393-e0a9-e50e24dcca9e';
  writeUUID: string = '6e400002-b5a3-f393-e0a9-e50e24dcca9e';
  readUUID: string = '6e400003-b5a3-f393-e0a9-e50e24dcca9e';
  myDevice: any;
  lastToggle: string = "~B0";
  siteIDFromUnit: any = "";
  showMultiDoorSelect: boolean = false;
  showMultiUnlock = false;
  showMultiStandDown = false;

  isLockingNormally = false;

  hasDoor1 = false;
  hasDoor2 = false;
  selectedDoor = 1;

  public lastAction = '';

  public currentLockState1: any = 'not set';
  public currentDoorState1: any = 'not set';
  public currentLockState2: any = 'not set';
  public currentDoorState2: any = 'not set';
  public currentLockState3: any = 'not set';
  public currentDoorState3: any = 'not set';
  public currentLockState4: any = 'not set';
  public currentDoorState4: any = 'not set';
  listenType = 0; // Listen types: [0:normal,1:version,2:config]

  public isDebugging = false;
  public isDiagnostics = false;
  // public isDiagnostics = true;
  public isConnected = false;
  // public isConnected = true;
  public isConfig = false;
  // public isConfig = true;
  public authed = false;
  public pinAvailable = false;
  showEnterPwd = false;
  showFirmwareAlert = false;

  public isMultiDoor = false;

  public userType: any = 'advanced';

  shouldShowFeedback = false;
  feedbackLog: any[] = [];

  constructor(private webreq: WebRequestsService,
    public btService: BluetoothService,
    private snackBar: MatSnackBar,
    public loader: LoaderService,
    public notify: NotificationsService,
    public logger: LoggingService,
    public router: Router,
    public conn: ConnectivityService,
    public translate: TranslateService,
    public biometrics: BiometricsService,
    public btHelpers: BluetoothHelpersService) {
    translate.use(localStorage.getItem('lang')!);
  }

  server: any;
  service: any;

  ngOnInit(): void {
    if (localStorage.getItem("pin") != null && localStorage.getItem("pin") != undefined && localStorage.getItem("sospin") != null && localStorage.getItem("sospin") != undefined) {
      this.pinAvailable = true;
    }
    localStorage.removeItem("selectedBTSite");
    this.userType = localStorage.getItem('userType');
    this.username = localStorage.getItem('username');
    this.password = localStorage.getItem('password');
    this.siteID = localStorage.getItem('selectedBTSite')
    if (localStorage.getItem('isMultiDoor') == 'true') {
      this.isMultiDoor = true;
    } else {
      this.isMultiDoor = false;
    }
    if (localStorage.getItem('hasDoor1') == 'false') {
      this.selectedDoor = 2;
    } else {
      this.selectedDoor = 1;
    }
    this.user = { 'username': this.username, 'password': this.password }
    this.logger.getLocation();
    navigator.bluetooth.getAvailability().then((res: any) => {
      if (res == false) {
        alert("Bluetooth is not currently available!");
      }
    })
  }
  // Gets the trusted PRISM device and excludes all other Bluetooth devices
  GetTrustedDevices() {
    navigator.bluetooth.getDevices().then(devices => {
      if (devices.length > 0) {
        this.myDevice = devices[0]
      }
    }).catch((err: any) => {
      console.log(err);
    })
  }
  // Terminates the Bluetooth connection between the app and PRISM controller
  StopBluetooth() {
    // console.log("Stopping Service ...")
    localStorage.setItem('btRefresh', 'false');
    if (this.btService.myReadCharacteristic) {
      this.btService.myReadCharacteristic.stopNotifications()
        .then((data: any) => {
          // console.log('> Notifications stopped');
          this.showCounter = false;
          this.btService.myReadCharacteristic.removeEventListener('characteristicvaluechanged', this.handleNotifications);
          if (this.btService.myDevice.gatt.connected) {
            // console.log("Disconnecting from:")
            this.btService.myDevice.gatt.disconnect();
            this.snackBar.open('Disconnected...', 'Ok', { duration: 3000 })
            localStorage.removeItem("selectedBTSite");
            navigator.vibrate(200);
          }
          this.authed = false;
          this.isConnected = false;
          this.siteSelected = false;
        })
        .catch((err: any) => {
          console.log('Argh! ' + err);
        });
    }
  }

  // This recreates the test Subject subscriptions in the case where subscriptions are unsubscribed or when the component is recreated
  recreateSubscriptions() {
    this.testSubject = new Subject();
    this.confSubject = new Subject();
  }

  // Handles the responses from the PRISM controller
  // Responses come through in Hex values so we need to convert to string to read actual values
  handleNotifications(event: any) {
    let utf8decoder = new TextDecoder();
    let value = event.target.value;
    // console.log('Message Recieved:')
    // console.log(value)
    let a = [];
    let b;
    // Convert raw data bytes to hex values just for the sake of showing something.
    // In the "real" world, you'd use data.getUint8, data.getUint16 or even
    // TextDecoder to process raw data bytes.
    for (let i = 0; i < value.byteLength; i++) {
      a.push('0x' + ('00' + value.getUint8(i).toString(16)).slice(-2));
    }
    // console.log("Hex Value:")
    console.log(a)
    if(a[0] == '0xf1' && a[1] == '0x00') {
      this.btService.otaProgress = parseInt(a[2], 16)
    }
    b = (utf8decoder.decode(value));
    // Handle Response Here
    console.log('Text Value From BT > ' + b);

    if (b.toUpperCase().includes('PRO') || b.toUpperCase().includes('LITE') || b.toUpperCase().includes('PLUS')) {
      // this.feedbackLog.push(b)
      this.logger.addLog('Got Version - ' + b, this.conn.online, localStorage.getItem("selectedBTSite"), localStorage.getItem("selectedBTSiteID"));
      console.log("Got version - " + b);
      localStorage.setItem('firmware', b);
      if (this.testOldVersion(b)) {
        this.showFirmwareAlert = true;
      }
    }

    // Use this for a feedback log
    if (b.toLowerCase().includes('secondary')) {
      this.feedbackLog.push(b)
      localStorage.setItem("secondaryOffline", b);
      this.logger.addLog(b, this.conn.online, localStorage.getItem("selectedBTSite"), localStorage.getItem("selectedBTSiteID"));
    }

    // This reads the MAC address values returned
    if (b.includes('MAC')) {
      this.logger.addMACLog(b, this.conn.online, localStorage.getItem("selectedBTSiteID"));
      this.logger.addLog('Got MAC - ' + b, this.conn.online, localStorage.getItem("selectedBTSite"), localStorage.getItem("selectedBTSiteID"));
      localStorage.setItem('lastMAC', b.substring(4, b.length));
    }


    if (!this.isDiagnostics && !this.isConfig) { // These are general action responses i.e. when we are not in config or diagnostics mode
      if (b == '0' || b == '1' || b == '2' || b == '3' || b == '4' || b == '5' ||
        b == '7' || b == '8' || b == '9' || b == ':' || b == ';' || b == '<' ||
        b == '=' || b == '>' || b == '13' || b == '11' || b == '10' || b == '12' || b == '14') {
        if (!this.isDebugging) {
          this.notify.openMessage(b, 'success', 3);
          if ((b == '10' || b == '14' || b == '11' || b == '12') && this.lastAction == '2') {
            this.notify.openMessage(b, 'info', 60);
            if (localStorage.getItem('userType') == 'basic') {
              this.isLockingNormally = true;
              console.warn("Basic user, will now lock normally")
              this.writeAction('1');

              alert('Locking Normally without stand down')
            }

            this.loader.isLoading = false;
            
          }
          if(this.isLockingNormally && b == '0') {
            this.notify.openMessage('Stand Down failed, locked normally', 'success', 5);
          }
        } else {
          this.notify.openMessage(b, 'success', 60);
        }

        // This checks the firmware version and does a check for sensor states, delays on the LITE and PLUS firmware
        if(localStorage.firmware.toUpperCase().includes('LITE') || localStorage.firmware.toUpperCase().includes('PLUS')){
          setTimeout(() => {
            this.writeAction('?');
          }, 3000)
        } else {
          this.writeAction('?');
        }
        this.clickable = true;
        this.loader.isLoading = false;
      } else if (this.btHelpers.isLockStatus(b)) {  // These are lock state responses
        console.warn('State recieved: ' + b)
        this.setLockState(b);
      } else {
        if (b.length > 6 && !this.isConfig) {

          if (!this.isDebugging) {
            this.notify.openMessage(b, 'info', 3);
          } else {
            this.notify.openMessage(b, 'info', 60);
          }

        }
      }
      this.counterValue = utf8decoder.decode(value);
    } else if (this.isDiagnostics) {

      if (this.siteIDFromUnit == "" && !b.includes('Testing') && !b.includes("Temperature") && !b.includes('offline')) {
        this.siteIDFromUnit = b;
      }
      if (b.length >= 5 && !b.includes('offline') && !b.includes('Testing') && !b.includes("Temperature")) {
        // Should only occur when entering diagnostic mode
        this.siteIDFromUnit = b;
        this.loader.isLoading = false;
        this.testSubject.next("Site ID");
      }
      if(b.includes("Temperature")) {
        this.testSubject.next(b)
      }
      if (b[0] == '0' && b.length == 1) {
        // OK
        this.loader.isLoading = false;
        this.testSubject.next(true);
      }
      else if ((b.length == 1) && (b[0] == '1')) {
        // ERR
        this.loader.isLoading = false;
        this.testSubject.next(false);
      } else {
        this.loader.isLoading = false;
        if (b.length < 5) {
          this.testSubject.next(b)
        }
      }

    } else if (this.isConfig) {
      this.confSubject.next({ value: b, listenType: this.listenType });
    }

  }
  // This is the method to send data packets to the PRISM controller
  writeAction(tokenID: string) {

    try {

      if (!this.isDiagnostics && !this.isConfig && tokenID != 'conf' && tokenID != '?' && tokenID != 'diag') {
        this.loader.isLoading = true;
      }
      if (tokenID != 'time') {
        this.clickable = false;
      }
      var enc = new TextEncoder();
      var myValue: any;
      if (tokenID == undefined) {
        tokenID = 'null';
      }
      if (tokenID == 'time') {
        let date = Math.floor(new Date().getTime() / 1000.0)
        myValue = "*;" + date;
      } else if (tokenID.includes('*;')) {
        myValue = tokenID;
      } else if (tokenID == '99') {
        myValue = tokenID;
      } else if (tokenID == '0x02') {
        myValue = tokenID;
      } else if (tokenID == '?') {
        myValue = tokenID;
      } else if (this.isConfig) {
        if (tokenID == 'conf') {
          myValue = localStorage.getItem("t_" + localStorage.getItem("selectedBTSite") + "_conf");
        } else if (tokenID == 'reset') {
          myValue = localStorage.getItem("t_" + localStorage.getItem("selectedBTSite") + "_reset");
        } else {
          myValue = tokenID;
        }
      } else if (this.isDiagnostics && tokenID != '2') {
        myValue = tokenID;
      }
      else {
        myValue = localStorage.getItem("t_" + localStorage.getItem("selectedBTSite") + "_" + tokenID);
      }
      if (myValue != null) {
        var myValue1 = enc.encode(myValue + "\n");
        this.clickable = true;
        if (myValue != '?') {
          this.logger.addLog(tokenID, this.conn.online, localStorage.getItem("selectedBTSite"), localStorage.getItem("selectedBTSiteID")); // Don't log status check
        }

        setTimeout(() => {

          return this.btService.myWriteCharacteristic.writeValue(myValue1).then((res: any) => {

          }).catch((err: any) => {
            if (err.message.includes("operation already in progress")) {
              console.log("Caught operation in progress error")
              // Not sure if will work but try atleast
              if (myValue != '?') {
                setTimeout(() => {
                  return this.btService.myWriteCharacteristic.writeValue(myValue1).then((res: any) => { }).catch((err: any) => {
                    this.notify.openMessage('Device busy, try again', 'info', 2);
                  })
                }, 1000);
              }
              this.clickable = true;
              this.loader.isLoading = false;
            }
          });
        }, 300);
      }
      else {
        this.clickable = true;

        this.notify.openMessage('Please get v-Keys', 'info', 3);
        this.router.navigate(['/token-tools'])
        // return this.btService.myWriteCharacteristic.writeValue(enc.encode(""));
      }
    } catch (e: any) {
      this.loader.isLoading = false;
      console.log("Write Error:");
      console.log(e);
      this.notify.openMessage('Please try again', 'error', 3);
    }

  }

  getVersionToNumber(version: any) {
    let alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let versionLetter = version.split('-')[version.split('-').length - 1]
    let versionChecker = versionLetter.split('.')
    let foundNaN = false;
    let versionNumber = 0;

    versionChecker.forEach((letter: any) => {
      for (let i = 0; i < letter.length; i++) {
        if (isNaN(parseFloat(letter[i]))) {
          foundNaN = true;
          versionNumber = alphabet.indexOf(letter[i])
        }
      }
    })

    if (!foundNaN) {
      versionNumber = versionLetter
    }

    return versionNumber;
  }

  testOldVersion(version: any) {
    let oldVersions = ['A', 'B', 'C', '1C', 'D', '1D', 'E', '1E', 'F', '1F', 'G', '1G', 'H', '1H', 'I', '1I', 'J', '1J', 'K', '1K', 'L', '1L', 'M', '1M', 'N', '1N', 'O', '1O', 'P', '1P', '1'];
    let versionLetter = version.split('-')[version.split('-').length - 1];

    if (oldVersions.includes(versionLetter)) {
      return true;
    } else {
      return false;
    }


  }
  //  This is the event handler for managing config and diagnostic write and read actions
  doEventAction(event: any) {
    if (this.isDiagnostics) {
      this.writeAction(event);
    } else if (this.isConfig) {
      this.listenType = event.listenType;
      this.writeAction(event.code);
    }
  }
  // Creates the physical connection between the app and the PRISM controller
  Connect() {
    this.feedbackLog = [];
    localStorage.setItem("secondaryOffline", '');
    try {
      this.StopBluetooth();
      this.loader.isLoading = true;
      // this.clickable = false;
      this.btService.connectDevice().then(conn => {
        if (conn) {

          this.isConnected = true;
          this.checkDoorsAvailable();
          this.btService.myReadCharacteristic.startNotifications().then(() => {
            // console.log("Listening...")
            this.loader.isLoading = false;
            this.btService.myReadCharacteristic.addEventListener('characteristicvaluechanged', (event: any) => this.handleNotifications(event))
            navigator.vibrate(300);
          }).catch((err: any) => {
            this.loader.isLoading = false;
            console.log(err);
          });
          this.writeAction('time');
          setTimeout(() => {
            this.writeAction('?');
          }, 2500)
        } else {
          this.loader.isLoading = false;
        }
      }).catch(err => {
        this.loader.isLoading = false;

        this.notify.openMessage("Connection Cancelled", "error", 3);
      }).finally(() => {
        this.loader.isLoading = false;
      });
    } catch (e) {
      this.loader.isLoading = false;
      this.notify.openMessage("Connection error, try again", 'error', 3);
    }

  }

  ToggleLED() {
    let command = "";
    if (this.lastToggle == "~B0") {
      command = "~B1"
      this.lastToggle = "~B1"
    } else {
      command = "~B0"
      this.lastToggle = "~B0"
    }
    // this.clickable = false;
    this.btService.getWriteService().then(service => {
      var enc = new TextEncoder();
      service.writeValue(enc.encode(command + "\n"))
    }).catch((err: any) => {
      console.log(err);
    })
  }

  // Sets the PRISM controller to Diagnostics mode
  enterDiagnostics() {
    this.writeAction('diag');
    this.isDiagnostics = true;
  }

  // Sets the PRISM controller to config mode
  enterConfig() {
    this.isConfig = true;
    this.listenType = 1;
    this.writeAction('conf');
  }

  openHelp(link: string) {
    window.open('https://wernerg123.github.io/prism-v2-docs/#' + link, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
  }
  // Sets the current UI state of the lock
  setLockState(state: any) {
    let myObj = this.btHelpers.setLockState(state);

    this.currentLockState1 = myObj.lock1;
    this.currentLockState2 = myObj.lock2;
    this.currentLockState3 = myObj.lock3;
    this.currentLockState4 = myObj.lock4;
    this.currentDoorState1 = myObj.door1;
    this.currentDoorState2 = myObj.door2;
    this.currentDoorState3 = myObj.door3;
    this.currentDoorState4 = myObj.door4;

  }

  // Sets the UI class w.r.t. sensor states
  getSensorClass(sensor: any) {
    if (sensor == 'closed' || sensor == 'locked') {
      return 'sensorState good'
      // return {'background-color': 'limegreen'}
    } else if (sensor == 'fault') {
      return 'sensorState fault';
    }
    else if (sensor == 'not present' || sensor == 'not present') {
      return 'sensorState notPresent'
      // return {'background-color': 'grey'}
    }
    else if (sensor == 'not set') {
      // return {'animation': 'mycolor 2s infinite'}
      return 'sensorState notSet'
      // return {'border': '2px solid black'}
    } else if (sensor == 'open' || sensor == 'unlocked') {
      return 'sensorState open';
    }
    else {
      return 'sensorState'
      // return {'background-color': 'orange'}
    }
  }
  // Get the currently selected Bluetooth Site
  getSelectedSite() {
    return "Site: " + localStorage.getItem('selectedBTSite') + " (" + localStorage.getItem('selectedBTSiteID') + ")";
  }

  tryDebug() {
    if (localStorage.getItem('sysAdmin') == 'true') {
      this.isDebugging = true;
      return;
    } else if (localStorage.getItem('tempAdmin') == 'true') {
      this.isDebugging = true;
      return;
    } else if (localStorage.getItem('admin') == 'true') {
      this.isDebugging = true;
      return;
    } else {
      return;
    }
  }

  getButtonAvailable() {
    if (localStorage.getItem('sysAdmin') == 'true') {
      return 'blue';
    } else if (localStorage.getItem('tempAdmin') == 'true') {
      return 'blue';
    } else if (localStorage.getItem('admin') == 'true') {
      return 'blue';
    } else {
      return 'gray';
    }
  }

  tryConfig() {
    if (localStorage.getItem('canConfigure') == 'true') {
      this.enterConfig();
    } else {
      this.notify.openMessage('Inadequate permissions', 'info', 3);
    }
  }

  getConfigAvailable() {
    if (localStorage.getItem('canConfigure') == 'true') {
      return 'black';
    } else {
      return 'gray';
    }
  }

  authenticate() {
    if (localStorage.getItem('authOnConnect') == 'true') {
      var hasBiometrics = localStorage.getItem('hasBiometrics');
      if (hasBiometrics != null && hasBiometrics != undefined && hasBiometrics == 'true') {
        if (localStorage.getItem('userID') != null || localStorage.getItem('userID') != undefined) {
          this.biometrics.authenticateFinger().then(res => {
            if (res == true) { // Continue getting keys
              this.Connect();
            }
            else {
              this.notify.openMessage('Failed to authenticate', 'error', 3);
            }
          })
        }

      } else {
        this.showEnterPwd = true;
      }
    } else {
      this.Connect();
    }
  }

  confirmPassword() {
    if (bcrypt.compareSync(this.password, localStorage.getItem('mop'))) { // Call this only if biometrics unavailable
      // Continue getting keys
      this.showEnterPwd = false;
      this.Connect();
    } else {
      this.notify.openMessage('Failed to authenticate', 'error', 3);
    }
  }

  tryDumbLock() {
    if (localStorage.getItem('selectedBTSiteID') == '10000') {
      this.writeAction('1');
    } else {
      this.lastAction = '2';
      this.writeAction('2');

    }
  }

  tryMultiDoor(action: any) {

    if (this.selectedDoor == 1) {
      this.writeAction(action)
    } else {
      this.writeAction(action + '_a')
    }
  }

  checkSelectedDoor() {

    if (localStorage.getItem('hasDoor1') == 'true') {
      this.selectedDoor = 1;
    } else {
      this.selectedDoor = 2;
    }

    console.info("Selected door set to: " + this.selectedDoor)
  }

  changeSelectedDoor() {
    if (this.selectedDoor == 1) {
      this.selectedDoor = 2;
    } else {
      this.selectedDoor = 1;
    }
  }

  checkDoorAvailable(doorNo: number) {
    if (localStorage.getItem('hasDoor' + doorNo) == 'true') {
      return true
    } else {
      return false
    }
  }

  checkDoorsAvailable() {
    if (localStorage.getItem('hasDoor1') == 'true') {
      this.hasDoor1 = true;
    } else {
      this.hasDoor1 = false;
    }
    if (localStorage.getItem('hasDoor2') == 'true') {
      this.hasDoor2 = true;
    } else {
      this.hasDoor2 = false;
    }
  }

  // This is to set the UI to show feedback messages card
  showFeedback() {
    this.shouldShowFeedback = true
  }

  // Sets the Icons on UI for doors selection
  getIcon(door:any) {
    if(door == '1') {
      if(this.hasDoor1) {
        return 'vpn_key'
      } else {
        return 'vpn_key_off'
      }
    } else if(door == '2') {
      if(this.hasDoor2) {
        return 'vpn_key'
      } else {
        return 'vpn_key_off'
      }
    } else {
      return ''
    }
  }
}
