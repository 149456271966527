import { Injectable } from '@angular/core';
import { NotificationsService } from './notifications.service';

@Injectable({
  providedIn: 'root'
})
export class BluetoothService {

  // enum RETURN_CODES
  // {
  //     OK,
  //     ERROR,
  //     PACKET_TOO_LONG,
  //     INVALID_CRC,
  //     VALID_CRC,
  //     INVALID_TOKEN,   
  //     VALID_TOKEN,
  //     FUTURE_TOKEN,
  // };

  // enum FUNC_CODES
  // {
  //     NORMAL,
  //     SET_TIME,
  //     ENROLL,
  // };

  otaProgress = 0;

  serviceUUID: string = '6e400001-b5a3-f393-e0a9-e50e24dcca9e';
  writeUUID: string = '6e400002-b5a3-f393-e0a9-e50e24dcca9e';
  readUUID: string = '6e400003-b5a3-f393-e0a9-e50e24dcca9e';

  // serviceUUID: string = 'fb1e4001-54ae-4a28-9f74-dfccb248601d';
  // writeUUID: string = 'fb1e4002-54ae-4a28-9f74-dfccb248601d';
  // readUUID: string = 'fb1e4003-54ae-4a28-9f74-dfccb248601d';

  
SERVICE_UUID              ="fb1e4001-54ae-4a28-9f74-dfccb248601d"
CHARACTERISTIC_UUID_RX    ="fb1e4002-54ae-4a28-9f74-dfccb248601d"
CHARACTERISTIC_UUID_TX    ="fb1e4003-54ae-4a28-9f74-dfccb248601d"

  altWriteUUID: string = 'fb1e4002-54ae-4a28-9f74-dfccb248601d';
  public myDevice: any;
  public tokenSet: boolean;
  public myGattServer: any;
  public myGattService: any;
  public myReadCharacteristic: any;
  public myWriteCharacteristic: any;

  constructor(public notify: NotificationsService) {
    this.tokenSet = false;
  }

  bluetoothNameFilters = [{name: "Prism"}, {name: "!Prism"}, {namePrefix: "Prism"}, {namePrefix: "prism"}, {namePrefix: "!Prism"}, {namePrefix: "PRISM"}]

  getDevices(): Promise<BluetoothRemoteGATTServer> {
    return new Promise<BluetoothRemoteGATTServer>((res, rej) => {
      navigator.bluetooth.requestDevice({
        // filters:this.bluetoothNameFilters
        filters: [{ services: [this.serviceUUID] }],
        // acceptAllDevices: true,
        // optionalServices: [this.serviceUUID]
      }).then((devices: any) => {
        console.info("Connecting...")
        this.myDevice = devices;
        devices.addEventListener( 'gattserverdisconnected',  (event: any) => this.handleDisconnect(event) );
        res(devices.gatt.connect())
      }).catch(err => {
        rej(err);
      })
    })
  }

  getDevice() {
    return new Promise<BluetoothRemoteGATTServer>((res, rej) => {
      navigator.bluetooth.getDevices().then((devices: any) => {
        if (devices.length > 0) {
          console.info("Auto Connecting...");
          this.myDevice = devices[0];
          this.myGattServer = devices[0].gatt;
          res(devices[0].gatt.connect());
        } else {
          rej(false);
        }
      }).catch(err => {
        rej(err)
      })
    })
  }

  connectDevice() {
    localStorage.setItem('btRefresh', 'true');
    return new Promise<BluetoothRemoteGATTServer>((res, rej) => {
      this.getDevices().then(server => {
        this.myGattServer = server
        this.getPrimaryService().then(service => {
          this.myGattService = service;
          this.getReadService().then(read => {
            this.myReadCharacteristic = read;
            this.getWriteService().then(write => {
              this.myWriteCharacteristic = write;
              res(server)
            }).catch(err => {
              rej(err);
            });
          }).catch(err => {
            rej(err)
          });
        }).catch(err => {
          rej(err);
        });
      }).catch(err => {
        console.warn("Could not connect...")
        rej(err)
      });
    });
  }

  getPrimaryService() {
    return new Promise<BluetoothRemoteGATTService>((res, rej) => {
      this.myGattServer.getPrimaryService(this.serviceUUID).then((service: BluetoothRemoteGATTService) => {
        this.myGattService = service;
        res(service);
      }).catch((err:any) => {
        rej(err);
      });
    });
  }

  getReadService() {
    return new Promise<BluetoothRemoteGATTCharacteristic>((res, rej) => {
      this.myGattService.getCharacteristic(this.readUUID).then((reader: BluetoothRemoteGATTCharacteristic) => {
        res(reader);
      }).catch((err:any) => {
        rej(err);
      });
    });
  }

  getWriteService() {
    return new Promise<BluetoothRemoteGATTCharacteristic>((res, rej) => {
      this.myGattService.getCharacteristic(this.writeUUID).then((writer: BluetoothRemoteGATTCharacteristic) => {
        res(writer);
      }).catch((err:any) => {
        rej(err);
      });
    });
  }

  getOTAService() {
    return new Promise<BluetoothRemoteGATTCharacteristic>((res, rej) => {
      this.myGattService.getCharacteristic(this.altWriteUUID).then((writer: BluetoothRemoteGATTCharacteristic) => {
        res(writer);
      }).catch((err:any) => {
        rej(err);
      });
    });
  }

  setToken() {

  }

  handleDisconnect(event:any) {
    if(localStorage.getItem('btRefresh') == 'false') {

    } else {
      console.warn("Device Disconnected Unexpectedly")
      setTimeout(() => {
        window.location.reload();
        this.notify.openMessage('Device disconnected, please reconnect', 'info', 3)  
      }, 1000)
    }
  }

}
