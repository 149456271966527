import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ExportsService } from 'src/app/services/exports.service';
import { WebRequestsService } from 'src/app/services/web-requests.service';

@Component({
  selector: 'app-my-history',
  templateUrl: './my-history.component.html',
  styleUrls: ['./my-history.component.scss']
})
export class MyHistoryComponent implements OnInit, AfterViewInit {
  
  apiData = new MatTableDataSource();
  actionsData = new MatTableDataSource();

  displayedColumnsAPI = ['action', 'site', 'time'];
  displayedColumnsActions = ['action', 'site', 'time'];

  @ViewChild('apipaginator') apipaginator!: MatPaginator;
  @ViewChild('actionspaginator') actionspaginator!: MatPaginator;


  constructor(private webReq: WebRequestsService, public exportService: ExportsService){}
  
  async ngOnInit() {  
  }
  
  ngAfterViewInit(): void {
    this.webReq.getMyHistory().subscribe(result => {
      console.log(result);
      this.apiData.data = result.api;
      this.apiData.paginator = this.apipaginator;
      this.actionsData.data = result.actions;
      this.actionsData.paginator = this.actionspaginator;

    })
  }

  getAction(element:any) {
    if(element.action.includes("Generated Cloud vKeys")) {
      return element.action + " (" + element._for + ")";
    } else {
      return element.action;
    }
  }

  exportHistory() {
    this.exportService.exportToCsv(this.apiData.data, 'api_history ' + new Date() + '.csv');
    this.exportService.exportToCsv(this.actionsData.data, 'operation_history ' + new Date() + '.csv');
  }
}
