<div fxFlex fxLayoutAlign="center center" fxLayout="column">
    <app-loader></app-loader>
    <mat-card class="mat-elevation-z16" style="height: 75vh; width: 85vw;" fxLayoutAlign="space-evenly none"
        fxLayout="column">
        <button mat-mini-fab
            style="position: absolute; top: 8px; left: 8px; background-color: transparent; z-index: 2002;"
            (click)="closing()">
            <mat-icon>arrow_back</mat-icon>
        </button>


        <!-- Select User  -->
        <mat-stepper orientation="vertical" linear=true style="max-height: 60vh; overflow: auto">
            <mat-step>
                <ng-template matStepLabel>Images</ng-template>
                <div style="overflow-y: hidden; overflow-x: auto; max-width: 100%; height: fit-content; padding: 8px;"
                    fxLayout="column">
                    <label>Face</label>
                    <div class="imgBox" *ngIf="faceImg != null">

                        <!-- <img style="width: 90px; height:90px;" id="faceImg" [src]="getImgSource('face')"> -->
                        <img style="width: 90px; height:90px;" id="faceImg" [src]="faceImg">
                        <div class="deletePill" fxLayoutAlign="center center" matRipple (click)="faceImg = null">
                            <mat-icon>delete</mat-icon>
                        </div>
                    </div>

                    <div *ngIf="faceImg == null" class="imgBox" fxLayout="column" fxLayoutAlign="center center"
                        (click)="addNewImg('face')"><mat-icon>add</mat-icon></div>

                    <label>ID</label>
                    <div class="imgBox" *ngIf="idImg != null">

                        <img style="width: 90px; height:90px;" id="idImg" [src]="idImg">
                        <div class="deletePill" fxLayoutAlign="center center" matRipple (click)="idImg = null">
                            <mat-icon>delete</mat-icon>
                        </div>
                    </div>
                    <div *ngIf="idImg == null" class="imgBox" fxLayout="column" fxLayoutAlign="center center"
                        (click)="addNewImg('id')"><mat-icon>add</mat-icon></div>


                    <label>Ref</label>
                    <div class="imgBox" *ngIf="refImg != null">

                        <img style="width: 90px; height:90px;" id="refImg" [src]="refImg">
                        <div class="deletePill" fxLayoutAlign="center center" matRipple (click)="refImg = null">
                            <mat-icon>delete</mat-icon>
                        </div>
                    </div>

                    <div *ngIf="refImg == null" class="imgBox" fxLayout="column" fxLayoutAlign="center center"
                        (click)="addNewImg('ref')"><mat-icon>add</mat-icon></div>
                </div>
                <div *ngIf="faceImg != null && idImg != null && refImg != null;">
                    <button mat-raised-button matStepperNext color="primary">{{'app.cloudTokens.next' |
                        translate}}</button>
                </div>
            </mat-step>


            <mat-step>
                <ng-template matStepLabel>{{'app.cloudTokens.reason' | translate}}</ng-template>
                <mat-form-field appearance="outline">
                    <mat-label>{{'app.cloudTokens.reason' | translate}}</mat-label>
                    <mat-select [(ngModel)]="reason">
                        <!-- <mat-form-field appearance="outline">
                            <mat-label>Filter</mat-label>
                            <input matInput type="text" (keyup)="applySitesFilter($event)" style="color:white" autocomplete="off" placeholder="Ex. default" [(ngModel)]="siteFilter">
                        </mat-form-field> -->
                        <mat-option *ngFor="let reason of reasonList" [value]="reason">
                            {{reason}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div>
                    <button mat-raised-button matStepperNext color="primary">{{'app.cloudTokens.next' |
                        translate}}</button>
                </div>
            </mat-step>

            <!-- Select Site -->
            <mat-step>
                <ng-template matStepLabel>Select Site</ng-template>
                <mat-form-field appearance="outline" *ngIf="userSites != null">
                    <mat-label>{{'app.cloudTokens.site' | translate}}</mat-label>
                    <mat-select [(ngModel)]="siteID" (openedChange)="clearSitesFilter($event)">
                        <mat-form-field appearance="outline">
                            <mat-label>Filter</mat-label>
                            <input matInput type="text" (keyup)="applySitesFilter($event)" style="color:white"
                                autocomplete="off" placeholder="Ex. default" [(ngModel)]="siteFilter">
                        </mat-form-field>
                        <mat-option *ngFor="let site of filteredUserSites" [value]="site">({{site.siteID}})
                            {{site.siteName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <div *ngIf="siteID?.secondaryController">
                    <mat-checkbox [(ngModel)]="siteID.door1">Door 1</mat-checkbox>
                    <mat-checkbox [(ngModel)]="siteID.door2">Door 2</mat-checkbox>
                </div>

                <div>
                    <button mat-raised-button matStepperNext color="primary"
                        [disabled]="siteID?.secondaryController && !siteID?.door1 && !siteID?.door2">{{'app.cloudTokens.next'
                        | translate}}</button>
                </div>
            </mat-step>
            <!-- From Date -->
            <mat-step>
                <ng-template matStepLabel>{{'app.cloudTokens.validFrom' | translate}}</ng-template>
                <mat-form-field appearance="outline">
                    <mat-label>From</mat-label>
                    <input matInput [(ngModel)]="fromDate" [min]="minDate" type="datetime-local">
                </mat-form-field>
                <div>
                    <button mat-raised-button matStepperNext color="primary">{{'app.cloudTokens.next' |
                        translate}}</button>
                </div>
            </mat-step>
            <!-- To Date -->
            <mat-step>
                <ng-template matStepLabel>{{'app.cloudTokens.validTo' | translate}}</ng-template>
                <mat-form-field appearance="outline">
                    <mat-label>To</mat-label>
                    <input matInput [(ngModel)]="toDate" [min]="fromDate" type="datetime-local">
                </mat-form-field>
                <div>
                    <button mat-raised-button matStepperNext color="primary">{{'app.cloudTokens.next' |
                        translate}}</button>
                </div>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>{{'app.cloudTokens.confirm' | translate}}</ng-template>
                <div *ngIf="username != undefined && siteID != undefined">
                    <!-- <div>Reason: {{reason}}</div> -->
                    <div>{{username.username}}</div>
                    <div>{{username.email}}</div>
                    <div>{{siteID.siteID}}</div>
                    <div>{{siteID.siteName}}</div>
                    <div>{{fromDate | date:"dd/MM/yy HH:mm:ss"}}</div>
                    <div>{{toDate | date:"dd/MM/yy HH:mm:ss"}}</div>
                </div>
            </mat-step>

        </mat-stepper>




        <button mat-raised-button color="primary" (click)="authenticate()"
            [disabled]="username == undefined || siteID == undefined || faceImg == null || idImg == null || refImg == null">
            <mat-icon>sms</mat-icon> Request v-Keys
        </button>
    </mat-card>

    <mat-card *ngIf="showEnterPwd" style="z-index: 2003; position: absolute; border: 1px solid white;"
        fxLayout="column">
        <h2>Enter Password</h2>
        <mat-form-field>
            <mat-label>Password</mat-label>
            <input matInput [(ngModel)]="password" type="password">
        </mat-form-field>
        <div fxLayoutAlign="space-evenly center">
            <button mat-raised-button (click)="confirmPassword()" color="primary">Confirm</button>
            <button mat-raised-button (click)="showEnterPwd = false;" color="warn">Cancel</button>
        </div>
    </mat-card>
</div>


<div class="backdrop" *ngIf="showAskAdd" (click)="showAskAdd = false;"></div>
<!-- Camera Component -->
<mat-card *ngIf="showAskAdd" class="popup-card">
    <mat-card-header>
        <mat-card-title>
            How do you wish to add image?
        </mat-card-title>
    </mat-card-header>

    <mat-card-content>
        <div fxLayout="column" fxLayoutAlign="space-evenly center">
            <button mat-raised-button color="primary" (click)="addCamera(); showAskAdd = false;"
                style="width: 80%; margin: 8px;"><mat-icon>camera</mat-icon>Camera</button>
            <button mat-raised-button color="primary" (click)="addFile()"
                style="width: 80%; margin: 8px;"><mat-icon>folder</mat-icon>Browse Files</button>
        </div>
        <input type="file" id="myFileInput" (change)="onFileSelected($event)" accept="image/*" hidden="true">

    </mat-card-content>
</mat-card>


<div class="webcam-window" [ngClass]="{ 'nodisplay': !showWebcam}" *ngIf="showWebcam">

    <button mat-mini-fab style="position: absolute;top: 15vh;right: 2rem; z-index:3012; top: 5rem;" color="warn"
        (click)="showWebcam = false">
        <mat-icon>close</mat-icon>
    </button>
    <webcam [trigger]="triggerObservable" (imageCapture)="handleImage($event)"></webcam>
    <button mat-fab color="primary"
        style="position: absolute; bottom: 8vh; left:0; right: 0; margin-left:auto;margin-right: auto;"
        (click)="takeSnapshot()">
        <mat-icon>camera</mat-icon>
    </button>
    <!-- <button mat-mini-fab style="position: absolute; bottom: 10vh; right: 10vw" (click)="onOffWebCame()">
       <mat-icon>flip_camera_android</mat-icon>
   </button> -->
</div>