import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { WebRequestsService } from 'src/app/services/web-requests.service';

@Component({
  selector: 'app-handover-management',
  templateUrl: './handover-management.component.html',
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])],
  styleUrls: ['./handover-management.component.scss']
})
export class HandoverManagementComponent implements OnInit {
  isEdit = false;
  isAdd = false;
  isDeleting = false;
  selectedHandover: any;
  handoverList:any[] = [];
  siteGroups:any[] = [];
  siteGroupsFiltered:any[] = [];
  selectedSiteGroup: any;
  newSiteGroupID:any;
  newSiteGroupName:any;
  handoverFilter: any = "";
  siteList: any[] = [];

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  
  dataSource = new MatTableDataSource<any>(this.handoverList);
  displayedColumns: string[] = ['siteID', 'siteName', '_requestedBy', 'sensor1',  'sensor2','sensor3', 'sensor4', 'actuator1', 'actuator2', 'requestTime', 'handover'];
  columnsToDisplayWithExpand = [...this.displayedColumns, 'expand'];
  expandedElement: any | null;
 
  constructor(public webReq: WebRequestsService) {}
  
  ngOnInit() {
    this.getHandoverRequests();
    this.getSiteGroups();
    this.getSites();
    
  }

  getHandoverRequests() {
    this.webReq.getHandoverRequests().subscribe(handovers => {
      this.handoverList = handovers;
      this.dataSource.data = handovers
    })
  }

  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  applyHOFilter(event:Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.siteGroups = this.siteGroupsFiltered.filter(x => x.groupID.includes(filterValue) || x.groupName.toLowerCase().includes(filterValue.toLocaleLowerCase()))
  }

  goToEdit(item:any) {

  }

  doDeleting(item:any) {

  }
  deleteHandoverRequest(item:any) {

  }

  getSiteGroups() {
    this.webReq.getSiteGroups().subscribe(sitegroups => {
      this.siteGroups = sitegroups.siteGroups;
      this.siteGroupsFiltered = sitegroups.siteGroups;
    })
  }

  getSites() {
    this.webReq.getSitesAll().subscribe(sites => {
      this.siteList = sites;
    })
  }

  addSiteGroup() {
    this.webReq.addSiteGroup({groupName: this.newSiteGroupName, groupID: this.newSiteGroupID}).subscribe(result => {
      if(result.success == true) {
        this.getSiteGroups();
        this.isEdit=false;
      } else {
        alert(result.message)
      }
    })
  }

  handover() {
    this.webReq.iCryptoAddDevice({DeviceID: this.selectedHandover.siteID, DeviceName: this.selectedHandover.siteName, SiteID: this.selectedSiteGroup.groupID,  SiteName: this.selectedSiteGroup.groupName}).subscribe(result => {
      let mySite = this.siteList.filter(x => x.siteID == this.selectedHandover.siteID)[0];
      this.selectedSiteGroup.sites.push(mySite);
      this.webReq.updateSiteGroup(this.selectedSiteGroup).subscribe(result => {
        this.selectedHandover._handoverBy = localStorage.getItem('username')
        this.selectedHandover._handoverByEmail = localStorage.getItem('email')
        this.webReq.acceptHandover(this.selectedHandover).subscribe(results => {
          this.getHandoverRequests();
          this.isAdd = false;
        })
      })
    })
  }

  checkHO() {
    // console.log(this.selectedHandover)
  }

}
