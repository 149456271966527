<div fxFlex fxLayoutAlign="center center" fxLayout="column">
    <!-- <app-loader></app-loader> -->

    <app-tokens (close)="isGenerating=false;" *ngIf="isGenerating" [reasonList]="reasonList"></app-tokens>
    <app-get-system-tokens (close)="isGetting = false" *ngIf="isGetting"></app-get-system-tokens>
    <app-generate-system-tokens (close)="isSystem=false" *ngIf="isSystem" [users]="userList" [filteredUsers]="userList"
        [reasonList]="reasonList"></app-generate-system-tokens>
    <app-request-tokens (close)="isRequesting=false" *ngIf="isRequesting"
        [reasonList]="reasonList"></app-request-tokens>
    <app-qr-scanner (close)="isScanning = false" *ngIf="isScanning"></app-qr-scanner>

    <mat-card *ngIf="!isSystem && !isGenerating && !isScanning && !isGetting && !isRequesting" class="mat-elevation-z16"
        style="height: 75vh; width: 85vw;" fxLayoutAlign="space-evenly none" fxLayout="column">

        <div fxLayout="row" fxLayoutAlign="space-evenly center">


            <div matRipple (click)="isGetting=true" class="block-button mat-elevation-z16" fxLayout="column"
                fxLayoutAlign="center center">
                <!-- <mat-spinner *ngIf="doorSensor.state == 3" class="button-spinner" diameter="25"></mat-spinner> -->
                <mat-icon style="color: orange;">cloud_download</mat-icon>
                <label>{{'app.token-tools.getKeys' | translate}}</label>
            </div>

            <div matRipple (click)="openRequest()" class="block-button mat-elevation-z16" fxLayout="column"
                fxLayoutAlign="center center">
                <mat-icon style="color: orange;">compare_arrows</mat-icon>
                <label>Request</label>
            </div>
            <!-- <div matRipple (click)="wip()" class="block-button mat-elevation-z16" fxLayout="column"
                fxLayoutAlign="center center">
                <mat-icon style="color: orange;">compare_arrows</mat-icon>
                <label>Request</label>
            </div> -->
            <!-- <div matRipple (click)="isScanning=true" class="block-button mat-elevation-z16" fxLayout="column"
                fxLayoutAlign="center center">
                <mat-icon style="color: orange;">qr_code_scanner</mat-icon>
                <label>{{'app.token-tools.qr' | translate}}</label>
            </div> -->

        </div>

        <div fxLayout="row" fxLayoutAlign="space-evenly center">
            <div matRipple *ngIf="isAdmin" (click)="getUsers()" class="block-button mat-elevation-z16" fxLayout="column"
                fxLayoutAlign="center center">
                <!-- <mat-spinner *ngIf="doorSensor.state == 3" class="button-spinner" diameter="25"></mat-spinner> -->
                <mat-icon style="color: greenyellow">cloud_upload</mat-icon>
                <label>{{'app.token-tools.cloudKeys' | translate}}</label>
            </div>

            <div matRipple *ngIf="canGenerate" (click)="openGenerating()" class="block-button mat-elevation-z16"
                fxLayout="column" fxLayoutAlign="center center">
                <!-- <mat-spinner *ngIf="doorSensor.state == 3" class="button-spinner" diameter="25"></mat-spinner> -->
                <mat-icon style="color: greenyellow">generating_tokens</mat-icon>
                <label>{{'app.token-tools.generateKeys' | translate}}</label>
            </div>

        </div>

        <a (click)="openHelp()"
            style="position: absolute; bottom: 0px; right: 0px; margin: 4px;"><mat-icon>help_outline</mat-icon></a>
    </mat-card>
</div>