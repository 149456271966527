import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LoaderService } from 'src/app/services/loader.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { WebRequestsService } from 'src/app/services/web-requests.service';

@Component({
  selector: 'app-my-requests',
  templateUrl: './my-requests.component.html',
  styleUrls: ['./my-requests.component.scss']
})
export class MyRequestsComponent implements OnInit{

  myRequests: any= new MatTableDataSource();
  displayedColumns: any = ['siteID', 'siteName', 'from', 'to', 'access', 'comment', 'delete']
  @ViewChild('apipaginator') apipaginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(private webreq: WebRequestsService, private notify: NotificationsService, public loader: LoaderService){}

  ngOnInit(): void {
      this.getMyRequests();
  }

  getMyRequests() {
    this.loader.isLoading = true;
    this.webreq.getMyRequests().subscribe(results =>{
      this.myRequests.data = results; 
      this.myRequests.paginator = this.apipaginator;
      this.myRequests.sort = this.sort;
      this.loader.isLoading = false;

    });
  }
  deleteKeyRequest(element:any) {
    this.loader.isLoading = true;
    this.webreq.deleteKeyRequest(element).subscribe(results => {
      if(results.affectedRows == 1) {
        this.notify.openMessage('Key Request Removed', 'success', 3);
      this.loader.isLoading = false;

        this.getMyRequests();
      }
    });
  }

  getClassByAccess(access:any) {
    if(access == 'granted') {
      return 'granted';
    } else if(access == 'denied') {
      return 'denied';
    } else {
      return 'requested';
    }
  }
}
