<mat-card class="mat-elevation-z16" style="height: 65vh; width: 85vw;" fxLayoutAlign="center center">
    <div fxLayout="column" style="width: 100%" fxLayoutAlign="space-between center">

        <h3 *ngIf="step == 1">Please enter your normal PIN</h3>
        <h3 *ngIf="step == 2">Please enter your distress PIN</h3>
        <h3 *ngIf="step == 3">Please enter your unlock PIN</h3>

        <mat-form-field appearance="outline" *ngIf="!showPin">
            <input matInput [(ngModel)]="maskedPin">
            <mat-icon matSuffix (click)="showPin = true">visibility</mat-icon>
        </mat-form-field>
        <mat-form-field appearance="outline" *ngIf="showPin">
            <input matInput [(ngModel)]="unmaskedPin">
            <mat-icon matSuffix (click)="showPin = false">visibility_off</mat-icon>
        </mat-form-field>
        <div fxLayout="row" style="width: 100%; margin-bottom: 16px;" fxLayoutAlign="space-evenly center">
            <div matRipple class="pinbtn" fxLayoutAlign="center center" (click)="appendString('1')">1</div>
            <div matRipple class="pinbtn" fxLayoutAlign="center center" (click)="appendString('2')">2</div>
            <div matRipple class="pinbtn" fxLayoutAlign="center center" (click)="appendString('3')">3</div>
        </div>
        <div fxLayout="row" style="width: 100%; margin-bottom: 16px;" fxLayoutAlign="space-evenly center">
            <div matRipple class="pinbtn" fxLayoutAlign="center center" (click)="appendString('4')">4</div>
            <div matRipple class="pinbtn" fxLayoutAlign="center center" (click)="appendString('5')">5</div>
            <div matRipple class="pinbtn" fxLayoutAlign="center center" (click)="appendString('6')">6</div>
        </div>
        <div fxLayout="row" style="width: 100%; margin-bottom: 16px;" fxLayoutAlign="space-evenly center">
            <div matRipple class="pinbtn" fxLayoutAlign="center center" (click)="appendString('7')">7</div>
            <div matRipple class="pinbtn" fxLayoutAlign="center center" (click)="appendString('8')">8</div>
            <div matRipple class="pinbtn" fxLayoutAlign="center center" (click)="appendString('9')">9</div>
        </div>
        <div fxLayout="row" style="width: 100%" fxLayoutAlign="space-evenly center">
            <div matRipple class="pinbtn" fxLayoutAlign="center center"><mat-icon style="color: red" (click)="popString()">backspace</mat-icon></div>
            <div matRipple class="pinbtn" fxLayoutAlign="center center" (click)="appendString('0')">0</div>
            <div matRipple class="pinbtn" fxLayoutAlign="center center" (click)="confirmPin()"><mat-icon style="color: limegreen">check</mat-icon></div>
        </div>
    </div>
    
</mat-card>