import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-popup-menu',
  templateUrl: './popup-menu.component.html',
  styleUrls: ['./popup-menu.component.scss'],
  animations: [
    trigger('radialAnimation', [
      state('closed', style({
        transform: 'translateX(0) translateY(0)'
      })),
      state('open', style({
        transform: 'translateX(0) translateY(0)'
      })),
      transition('closed <=> open', animate('300ms ease-in-out', keyframes([
        style({ transform: 'translateX(0) translateY(0)', offset: 0 }),
        style({ transform: 'translateX({{getXPosition(i)}}) translateY({{getYPosition(i)}})', offset: 1 })
      ]))),
    ])
  ]
})
export class PopupMenuComponent {

  menuOpen: boolean = false;

  menuItems = [
    { label: 'Button 1', action: () => console.log('Button 1 clicked') },
    { label: 'Button 2', action: () => console.log('Button 2 clicked') },
    // Add more menu items as needed
  ];

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

  onMenuItemClick(item: any) {
    item.action();
    this.toggleMenu();
  }

  getXPosition(index: number): string {
    const angle = (index / this.menuItems.length) * 2 * Math.PI;
    const radius = 100; // Adjust the radius as needed
    const x = radius * Math.cos(angle);
    return `${x}px`;
  }

  getYPosition(index: number): string {
    const angle = (index / this.menuItems.length) * 2 * Math.PI;
    const radius = 100; // Adjust the radius as needed
    const y = radius * Math.sin(angle);
    return `${y}px`;
  }
}
