import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import base64url from 'base64url';
import { WebcamImage } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { LoaderService } from 'src/app/services/loader.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { WebRequestsService } from 'src/app/services/web-requests.service';

@Component({
  selector: 'app-request-tokens',
  templateUrl: './request-tokens.component.html',
  styleUrls: ['./request-tokens.component.scss']
})
export class RequestTokensComponent implements OnInit {

  @Output() getPicture = new EventEmitter<WebcamImage>();
  @Output() close = new EventEmitter();
  @Input() reasonList: any = [];

  userSites: any = [];
  filteredUserSites: any = [];
  siteFilter: any = "";
  siteID: any;
  fromDate: any = new Date(Date.now() + 119 * 60000).toISOString().slice(0, 16); // toISO returns GMT Time (-2 hours);
  minDate: any = new Date(Date.now() + 118 * 60000).toISOString().slice(0, 16);
  toDate: any = new Date(Date.now() + 150 * 60000).toISOString().slice(0, 16);
  username: any = {};
  showEnterPwd: boolean = false;
  password: any;
  reason: any;
  showWebcam = false;
  capturing: any;
  faceImg: any = null;
  idImg: any = null;
  refImg: any = null;
  showAskAdd = false;
  tempData: any;


  private trigger: Subject<void> = new Subject<void>();



  constructor(private webreq: WebRequestsService, private notify: NotificationsService, public loader: LoaderService) {

  }

  ngOnInit(): void {
    this.username.username = localStorage.getItem('username')
    this.getSites();
    this.getReasons();
  }

  getSites(){
    this.webreq.getSites(this.username.username).subscribe(sites => {
      this.userSites = sites;
      this.filteredUserSites = sites;
    })
  }
  getReasons() {
    this.webreq.getReasons().subscribe(reasons => {
      this.reasonList = reasons.reasons;
    })
  }


  clearSitesFilter(event: any) {
    this.siteFilter = "";
    this.filteredUserSites = this.userSites;
  }
  applySitesFilter(event: any) {
    this.filteredUserSites = this.userSites.filter((x:any) => x.siteID.toString().includes(this.siteFilter) || x.siteName.toLowerCase().includes(this.siteFilter.toLowerCase()));
  }

  authenticate() {
    this.loader.isLoading = true;
    let myObj = {
      username: this.username.username,
      site: this.siteID,
      from: this.fromDate,
      to: this.toDate,
      reason: this.reason,
      clientdb: localStorage.getItem('clientdb'),
      email: localStorage.getItem('email'),
      faceImg: this.faceImg,
      idImg: this.idImg,
      refImg: this.refImg

    }
    console.log(myObj)
    this.webreq.requestKeys(myObj).subscribe((result: any) => {
      console.warn(result)
      this.loader.isLoading = false;
      this.notify.openMessage('v-Key Requested', 'success', 3);
      this.close.emit();
    })
  }

  confirmPassword() {

  }
  closing() {
    this.close.emit();
  }

  addNewImg(imgType: any) {
    this.capturing = imgType;
    // this.showWebcam = true;
    this.showAskAdd = true;
  }

  addCamera() {
    this.showWebcam = true;
  }

  getImgSource(img: any) {
    // return img.image._imageAsDataUrl;
    if (img == 'face') {
      return this.faceImg.imageAsDataUrl;
    } else if (img == 'id') {
      return this.idImg.imageAsDataUrl;
    } else if (img == 'ref') {
      return this.refImg.imageAsDataUrl;
    }
    // return '';
  }

  get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  handleImage(webcamImage: WebcamImage) {
    this.getPicture.emit(webcamImage);
    if (this.capturing == '') {


    } else if (this.capturing == 'face') {
      this.faceImg = webcamImage.imageAsDataUrl;
      var myElement = document.getElementById('faceImg');
      myElement?.setAttribute('src', this.faceImg);
    } else if (this.capturing == 'id') {
      this.idImg = webcamImage.imageAsDataUrl;
      var myElement = document.getElementById('idImg');
      myElement?.setAttribute('src', this.idImg);
    } else if (this.capturing == 'ref') {
      this.refImg = webcamImage.imageAsDataUrl;
      var myElement = document.getElementById('refImg');
      myElement?.setAttribute('src', this.refImg);
    }

    this.showWebcam = false;
  }

  takeSnapshot(): void {
    this.trigger.next();
  }

  onOffWebCame() {
    this.showWebcam = !this.showWebcam;
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        if (this.capturing == 'face') {
          this.faceImg = e.target.result;
          this.setImageSrc('face');
        } else if (this.capturing == 'id') {
          this.idImg = e.target.result;
          this.setImageSrc('id');
        } else if (this.capturing == 'ref') {
          this.refImg = e.target.result;
          this.setImageSrc('ref');
        }
        // this.imageUrl = e.target.result;
      };
      reader.readAsDataURL(file);
      // reader.readAsText(file,'base64url');
    }
  }

  setImageSrc(type: any) {
    // this.faceImg ={};
    if (type == 'face') {
      var myElement = document.getElementById('faceImg');
      myElement?.setAttribute('src', this.faceImg);
    } else if (type == 'id') {
      var myElement = document.getElementById('idImg');
      myElement?.setAttribute('src', this.idImg);
    } else if (type == 'ref') {
      var myElement = document.getElementById('refImg');
      myElement?.setAttribute('src', this.refImg);
    }
    this.showAskAdd = false;
  }

  addFile() {
    var myElement = document.getElementById('myFileInput');
    myElement?.click();
  }

}
