import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/services/loader.service';
import { WebRequestsService } from 'src/app/services/web-requests.service';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnInit {

  public logs:any;
  public btLogs:any;
  public errLogs:any;
  public successLogs:any;
  public showLogs:boolean = false;
  public users: any = [];
  public from: any;
  public to:any;
  public user:string = "";

  constructor(public webreq: WebRequestsService, public loader: LoaderService) { }

  ngOnInit(): void {
    this.loader.isLoading = true;
    if(localStorage.getItem('username') == 'sysadmin') {
      
      this.webreq.getUsers().subscribe(users => {
        this.users = users; 
        this.loader.isLoading = false;
      }, err => {console.warn("Failed to get users");this.loader.isLoading = false;});
    }
  }

  async getLogs() {
    return new Promise((resolve,reject) => {
      this.webreq.getLogs().subscribe(logs => {
        resolve(logs)
      }, err => {
        this.loader.isLoading = false;
        console.warn("Failed to get logs");
      })
    })
  }

  getBtLogs() {
    this.getLogs().then((logs:any) => {
      this.btLogs = logs.btLog;
      this.errLogs = logs.errLog;
      this.successLogs = logs.successLog;
      this.loader.isLoading = false;
    });
  }

  findLogs(from:any,to:any,user:any) {
    this.webreq.getBTLogs(from,to,user).subscribe(loggies => {
      let tempLogs:any[] = [];
      loggies.forEach((mylog:any) => {
        tempLogs.push(mylog.user+", " + mylog.action + ", " + mylog.time.toString('YYYY/MM/DD') + "\n");
      });
      this.showLogs = true;
      this.logs = tempLogs;
    },err => {
      console.warn(err);
    });
  }
}
