import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';
import { UserOptions } from 'jspdf-autotable';
interface jsPDFWithPlugin extends jsPDF {
  autoTable: (options: UserOptions) => jsPDF;
}


@Injectable({
  providedIn: 'root'
})
export class ReportGeneratorService {

  lineY = 0;
  lineX = 0;
  limitY = 245;
  doc:any;

  constructor() { }

  // Read through this report generation method thoroughly as each item and line is calculated in the code
  generateReport(repObj:any) {
    var oldBody = document.body;
    // document.body = myTBL.body
    let lineX = 0;
    let lineY = 0;
    let limitY = 245;

    const doc = new jsPDF() as jsPDFWithPlugin;


    // =========================================  HEADERS =========================================================
    doc.line(10,10,100,10)
    
    doc.setFontSize(32);
    doc.text("Prism Test Report", 10, 30);
    lineY+=40;

    var imgData = new Image();
    var imgData1 = new Image();

    imgData.src = 'assets/ethele.png'
    imgData1.src = 'assets/prism.png'
    doc.addImage(imgData1, 'png', 160, 10, 25, 25);
    doc.addImage(imgData, 'png', 162, 35, 20, 10);


    doc.setFontSize(16);
    doc.text("Site: (" + repObj.siteID+") " + repObj.siteName, 10, lineY);
    lineY+=10;
    doc.text("User: (" + repObj.username + ") " + repObj.email, 10, lineY);
    lineY+=10;
    doc.text("Date: " + new Date(), 10, lineY);
    lineY+=10;
    doc.text("Stand Down Success: " + (repObj.standdown ? 'YES' : 'NO'), 10, lineY);
    lineY+=10;
    doc.line(10, lineY, 200, lineY); // lineY =80
    lineY+=15;

    //  ========================================= SENSORS ======================================================

    doc.text("Sensors:", 10, lineY) // lineY = 95
    let sensorImgLine1Y = lineY;
    let lineYBeforeImg = lineY;
    let sensorImgLine1X=100;
    lineY+=10;
    doc.text("Sensor 1: " + (repObj.sensor1 == 'not connected' ? 'n/a' : repObj.sensor1), 10, lineY) // lineY = 105
    lineY+=10;
    doc.text("Sensor 2: " + (repObj.sensor2 == 'not connected' ? 'n/a' : repObj.sensor2), 10, lineY) // lineY = 115
    lineY+=10;
    doc.text("Sensor 3: " + (repObj.sensor3 == 'not connected' ? 'n/a' : repObj.sensor3), 10, lineY) // lineY = 125
    lineY+=10;
    doc.text("Sensor 4: " + (repObj.sensor4 == 'not connected' ? 'n/a' : repObj.sensor4), 10, lineY) // lineY = 135
    lineY+=10; // lineY = 145

    repObj.sensorImages.forEach((sensorImage:any) => {
      let imgObj = new Image()
      imgObj.src = sensorImage.image._imageAsDataUrl;
      doc.addImage(imgObj, 'imageAsDataUrl', sensorImgLine1X, sensorImgLine1Y, 25, 25);
      sensorImgLine1X += 30;
      if(sensorImgLine1X >= 170) {
        sensorImgLine1Y += 30;
        sensorImgLine1X = 100;
      }
      if(sensorImgLine1Y >= limitY) {
        doc.addPage()
        lineY = 10;
        sensorImgLine1Y = 10;
      }
    });

    if((sensorImgLine1Y - lineYBeforeImg) < 45 ) {
      lineY = sensorImgLine1Y + 45; 
    } else {
      lineY = sensorImgLine1Y + 30; 
    }

    if(lineY >= limitY) {
      doc.addPage();
      lineY = 10;
    }
    doc.line(10, lineY,200, lineY)
    lineY+=10;


    // ======================================================== LOCKS =================================================

    doc.text("Locks: ", 10, lineY) // No image lineY = 150
    let actImgLineY = lineY;
    let actImgLineX = 100;
    lineY+=10;
    doc.text("Lock 1: " + (repObj.actuator1 == 'not connected' ? 'n/a' : repObj.actuator1), 10, lineY) // No image lineY = 160
    lineY+=10;
    doc.text("Lock 2: " + (repObj.sensor2 == 'not connected' ? 'n/a' : repObj.sensor2), 10, lineY) // No image lineY = 170
    lineY+=10;

    repObj.actuatorImages.forEach((actImage:any) => {
      let imgObj = new Image()
      imgObj.src = actImage.image._imageAsDataUrl;
      doc.addImage(imgObj, 'imageAsDataUrl', actImgLineX, actImgLineY, 25, 25);
      actImgLineX += 30;
      if(actImgLineX >= 170) {
        actImgLineY += 30;
        actImgLineX = 100;
      }
      if(actImgLineY >= limitY) {
        doc.addPage()
        lineY = 10;
        actImgLineY = 10;
      }
    });

    lineY = actImgLineY + 30;
    if(lineY >= limitY) {
      doc.addPage();
      lineY = 10;
    }

    doc.line(10,lineY, 200, lineY); // No image lineY = 180
    lineY+=10;

    // ==================================================== TAMPERS ==================================================

    doc.text("Tampers: ", 10, lineY) // No image lineY = 190
    let tampImgLineY = lineY;
    let tampImgLineX = 100;
    lineY +=10;
    doc.text("Results: " + repObj.tamper, 10, lineY); // No image lineY = 200
    lineY +=10;

    repObj.tamperImages.forEach((tamperImage:any) => {
      let imgObj = new Image()
      imgObj.src = tamperImage.image._imageAsDataUrl;
      doc.addImage(imgObj, 'imageAsDataUrl', tampImgLineX, tampImgLineY, 25, 25);
      tampImgLineX += 30;
      if(tampImgLineX >= 170) {
        tampImgLineY += 30;
        tampImgLineX = 100;
      }
      if(tampImgLineY >= limitY) {
        doc.addPage()
        lineY = 10;
        tampImgLineY = 10;
      }
    });

    lineY = tampImgLineY + 30;
    if(lineY >= limitY) {
      doc.addPage();
      lineY = 10;
    }
    doc.line(10,lineY, 200, lineY); // No image lineY = 220
    lineY+=10;

    // ================================================== POWER SUPPLY ===============================================

    doc.text("Power Supply: ", 10, lineY) // No image lineY = 230
    let psImgLineY = lineY;
    let psImgLineX = 100;
    lineY +=10;
    doc.text("Results: " + repObj.powerSupply, 10, lineY); // No image lineY = 240
    lineY +=10;

    repObj.powerSupplyImages.forEach((psImage:any) => {
      let imgObj = new Image()
      imgObj.src = psImage.image._imageAsDataUrl;
      doc.addImage(imgObj, 'imageAsDataUrl', psImgLineX, psImgLineY, 25, 25);
      psImgLineX += 30;
      if(psImgLineX >= 170) {
        psImgLineY += 30;
        psImgLineX = 100;
      }
      if(psImgLineY >= limitY) {
        doc.addPage()
        lineY = 10;
        psImgLineY = 10;
      }
    });

    lineY = psImgLineY + 30;
    if(lineY >= limitY) {
      doc.addPage();
      lineY = 10;
    }
    doc.line(10,lineY, 200, lineY); // No image lineY = 260
    lineY+=10;

    //  ================================================= SITE =======================================================

    doc.text("Site Overview: ", 10, lineY) // No image lineY = 270
    let siteImgLineY = lineY;
    let siteImgLineX = 100;
    lineY +=10;
    doc.text("Firmware: " + repObj.firmware, 10, lineY);
    lineY +=10;
    doc.text("App Version: " + repObj.software, 10, lineY);
    lineY +=10;
    doc.text("Lat: " + repObj.lat + ", Lon: " + repObj.lon, 10, lineY);
    lineY +=10;
    doc.text(repObj.temperature, 10, lineY);
    lineY +=10;
    if(localStorage.getItem('secondaryOffline') != undefined && localStorage.getItem('secondaryOffline') != '') {
      doc.text('*' + repObj.secondaryOffline, 10, lineY);
      lineY +=10;
    }

    repObj.siteImages.forEach((siteImage:any) => {
      let imgObj = new Image()
      imgObj.src = siteImage.image._imageAsDataUrl;
      doc.addImage(imgObj, 'imageAsDataUrl', siteImgLineX, siteImgLineY, 25, 25);
      siteImgLineX += 30;
      if(siteImgLineX >= 170) {
        siteImgLineY += 30;
        siteImgLineX = 100;
      }
      if(siteImgLineY >= limitY) {
        doc.addPage()
        lineY = 10;
        siteImgLineY = 10;
      }
    });

    
    if(siteImgLineY + 30 >= 60) {
      lineY = siteImgLineY + 60;
    } else {
      lineY += 50;
    }
    if(lineY >= limitY) {
      doc.addPage();
      lineY = 10;
    }
    doc.line(10,lineY, 200, lineY); // No image lineY = 300
    lineY+=10;

    // ==================================================== SAVE/DOWNLOAD ============================================

    if(lineY >= 250) {
      doc.addPage()
      lineY = 10;
    }
    doc.text("* This report serves as indication that the above components marked", 10, lineY);
    lineY+=10;
    doc.text(" as 'OK' are in a working condition, conforming to ", 10, lineY);
    lineY+=10;
    doc.text(" Prism™ flow standards.", 10, lineY)
    lineY+=10;
    doc.text(" Please check any components marked with 'FAULT' for any issues", 10, lineY)
    lineY+=10;
    doc.text(" pertaining to the installation of the Prism™ unit", 10, lineY)


    doc.save("prismPDF " + new Date().toISOString().replace('.', ':') + ".pdf");
    // this.myDocument = doc;
    document.getElementById('mypdftable')?.remove();
    document.body.remove();
    document.body = oldBody;
    return doc;
  }
  
  // Read through this report generation method thoroughly as each item and line is calculated in the code
  generateReportNew(repObj:any) {
    var oldBody = document.body;
    // document.body = myTBL.body
    this.lineX = 0;
    this.lineY = 0;
    this.limitY = 245;
    
    this.doc = new jsPDF() as jsPDFWithPlugin;


    // =========================================  HEADERS =========================================================
    this.doc.line(10,10,100,10)
    
    this.doc.setFontSize(32);
    this.doc.text("Prism Test Report", 10, 30);
    this.lineY+=40;

    var imgData = new Image();
    var imgData1 = new Image();

    imgData.src = 'assets/ethele.png'
    imgData1.src = 'assets/prism.png'
    this.doc.addImage(imgData1, 'png', 160, 10, 25, 25);
    this.doc.addImage(imgData, 'png', 162, 35, 20, 10);


    this.doc.setFontSize(16);
    this.doc.text("Site: (" + repObj.siteID+") " + repObj.siteName, 10, this.lineY);
    this.lineY+=10;
    this.doc.text("User: (" + repObj.username + ") " + repObj.email, 10, this.lineY);
    this.lineY+=10;
    this.doc.text("Date: " + new Date(), 10, this.lineY);
    this.lineY+=10;
    this.doc.text("Stand Down Success: " + (repObj.standdown ? 'YES' : 'NO'), 10, this.lineY);
    this.lineY+=10;
    this.doc.line(10, this.lineY, 200, this.lineY); 
    this.lineY+=15;

    //  ========================================= SENSORS ======================================================

    this.doc.text("Sensors:", 10, this.lineY)  // lineY = 105
    let sensorImgLine1Y = this.lineY;
    let lineYBeforeImg = this.lineY;
    let sensorImgLine1X=100;
    this.lineY+=10;
    this.doc.text("Sensor 1: " + repObj.sensor1, 10, this.lineY)
    this.lineY+=10;
    this.doc.text("Sensor 2: " + repObj.sensor2, 10, this.lineY)
    this.lineY+=10;
    this.doc.text("Sensor 3: " + repObj.sensor3, 10, this.lineY)
    this.lineY+=10;
    this.doc.text("Sensor 4: " + repObj.sensor4, 10, this.lineY)
    this.lineY+=10; // this.lineY = 145

    repObj.sensorImages.forEach((sensorImage:any) => {
      let imgObj = new Image()
      imgObj.src = sensorImage.image._imageAsDataUrl;
      this.doc.addImage(imgObj, 'imageAsDataUrl', sensorImgLine1X, sensorImgLine1Y, 25, 25);
      sensorImgLine1X += 30;
      if(sensorImgLine1X >= 170) {
        sensorImgLine1Y += 30;
        sensorImgLine1X = 100;
      }

      if(sensorImgLine1Y >= this.limitY) {
        this.doc.addPage()
        this.lineY = 10;
        sensorImgLine1Y = 10;
      }
    });

    if((sensorImgLine1Y - lineYBeforeImg) < 45 ) {
      this.lineY = sensorImgLine1Y + 45; 
    } else {
      this.lineY = sensorImgLine1Y + 30; 
    }
    this.doc.line(10, this.lineY,200, this.lineY)
    this.lineY+=10;


    // ======================================================== LOCKS =================================================

    this.doc.text("Locks: ", 10, this.lineY)
    let actImgLineY = this.lineY;
    let actImgLineX = 100;
    this.lineY+=10;
    this.doc.text("Lock 1: " + repObj.actuator1, 10, this.lineY)
    this.lineY+=10;
    this.doc.text("Lock 2: " + repObj.actuator2, 10, this.lineY)
    this.lineY+=10;

    repObj.actuatorImages.forEach((actImage:any) => {
      let imgObj = new Image()
      imgObj.src = actImage.image._imageAsDataUrl;
      this.doc.addImage(imgObj, 'imageAsDataUrl', actImgLineX, actImgLineY, 25, 25);
      actImgLineX += 30;
      if(actImgLineX >= 170) {
        actImgLineY += 30;
        actImgLineX = 100;
      }
      if(actImgLineY >= this.limitY) {
        this.doc.addPage()
        this.lineY = 10;
        actImgLineY = 10;
      }
    });

    this.lineY = actImgLineY + 30;
    this.doc.line(10,this.lineY, 200, this.lineY);
    this.lineY+=10;

    // ==================================================== TAMPERS ==================================================

    this.doc.text("Tampers: ", 10, this.lineY)
    let tampImgLineY = this.lineY;
    let tampImgLineX = 100;
    this.lineY +=10;
    this.doc.text("Results: " + repObj.tamper, 10, this.lineY);
    this.lineY +=10;

    repObj.tamperImages.forEach((tamperImage:any) => {
      let imgObj = new Image()
      imgObj.src = tamperImage.image._imageAsDataUrl;
      this.doc.addImage(imgObj, 'imageAsDataUrl', tampImgLineX, tampImgLineY, 25, 25);
      tampImgLineX += 30;
      if(tampImgLineX >= 170) {
        tampImgLineY += 30;
        tampImgLineX = 100;
      }
      if(tampImgLineY >= this.limitY) {
        this.doc.addPage()
        this.lineY = 10;
        tampImgLineY = 10;
      }
    });

    this.lineY = tampImgLineY + 30;
    this.doc.line(10,this.lineY, 200, this.lineY);
    this.lineY+=10;

    // ================================================== POWER SUPPLY ===============================================

    this.doc.text("Power Supply: ", 10, this.lineY)
    let psImgLineY = this.lineY;
    let psImgLineX = 100;
    this.lineY +=10;
    this.doc.text("Results: " + repObj.powerSupply, 10, this.lineY);
    this.lineY +=10;

    repObj.powerSupplyImages.forEach((psImage:any) => {
      let imgObj = new Image()
      imgObj.src = psImage.image._imageAsDataUrl;
      this.doc.addImage(imgObj, 'imageAsDataUrl', psImgLineX, psImgLineY, 25, 25);
      psImgLineX += 30;
      if(psImgLineX >= 170) {
        psImgLineY += 30;
        psImgLineX = 100;
      }
      if(psImgLineY >= this.limitY) {
        this.doc.addPage()
        this.lineY = 10;
        psImgLineY = 10;
      }
    });

    this.lineY = psImgLineY + 30;
    this.doc.line(10,this.lineY, 200, this.lineY);
    this.lineY+=10;

    //  ================================================= SITE =======================================================

    this.doc.text("Site Overview: ", 10, this.lineY)
    let siteImgLineY = this.lineY;
    let siteImgLineX = 100;
    this.lineY +=10;
    this.doc.text("Firmware: " + repObj.firmware, 10, this.lineY);
    this.lineY +=10;
    this.doc.text("App Version: " + repObj.software, 10, this.lineY);
    this.lineY +=10;
    this.doc.text("Lat: " + repObj.lat + ", Lon: " + repObj.lon, 10, this.lineY);
    this.lineY +=10;
    this.doc.text(repObj.temperature, 10, this.lineY);
    this.lineY +=10;


    repObj.siteImages.forEach((siteImage:any) => {
      let imgObj = new Image()
      imgObj.src = siteImage.image._imageAsDataUrl;
      this.doc.addImage(imgObj, 'imageAsDataUrl', siteImgLineX, siteImgLineY, 25, 25);
      siteImgLineX += 30;
      if(siteImgLineX >= 170) {
        siteImgLineY += 30;
        siteImgLineX = 100;
      }
      if(siteImgLineY >= this.limitY) {
        this.doc.addPage()
        this.lineY = 10;
        siteImgLineY = 10;
      }
    });

    this.lineY = siteImgLineY + 30;
    this.doc.line(10,this.lineY, 200, this.lineY);
    this.lineY+=10;

    // ==================================================== SAVE/DOWNLOAD ============================================

    if(this.lineY >= 250) {
      this.doc.addPage()
      this.lineY = 10;
    }
    this.doc.text("* This report serves as indication that the above components marked", 10, this.lineY);
    this.lineY+=10;
    this.doc.text(" as 'OK' are in a working condition, conforming to ", 10, this.lineY);
    this.lineY+=10;
    this.doc.text(" Prism™ flow standards.", 10, this.lineY)
    this.lineY+=10;
    this.doc.text(" Please check any components marked with 'FAULT' for any issues", 10, this.lineY)
    this.lineY+=10;
    this.doc.text(" pertaining to the installation of the Prism™ unit", 10, this.lineY)


    this.doc.save("prismPDF " + new Date().toISOString().replace('.', ':') + ".pdf");
    // this.mythis.document = this.doc;
    document.getElementById('mypdftable')?.remove();
    document.body.remove();
    document.body = oldBody;
    return this.doc;
  }

}
