import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-logo-circle',
  templateUrl: './logo-circle.component.html',
  styleUrls: ['./logo-circle.component.scss']
})
export class LogoCircleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
