import { Injectable } from '@angular/core';
const helpers=require('../../assets/biometric-helpers/helpers');
const serverSample = require('../../assets/biometric-helpers/server.sample')

@Injectable({
  providedIn: 'root'
})
export class WebauthnService {

  constructor() { }

  webAuthnSignup(userID: any, username: any): Promise<any> {
    const publicKeyCredentialCreationOptions: PublicKeyCredentialCreationOptions = {
      // Challenge shoulda come from the server
      challenge: this.getChallenge(), // User our API to get user challenge by using User ID
      rp: {
        name: 'WebAuthn Test',
        // id: 'localhost:4200',
      },
      user: {
        // Some user id coming from the server
        id: Uint8Array.from(userID),  
        name: username,
        displayName: username,
      },
      pubKeyCredParams: [{ alg: -7, type: 'public-key' }],
      authenticatorSelection: {
        authenticatorAttachment: 'platform',
        // requireResidentKey: true,
      },
      timeout: 60000,
      attestation: 'direct'
    };

    return navigator.credentials.create({
      publicKey: publicKeyCredentialCreationOptions,
    });

  }

  webAuthnSignin(user:any): Promise<any> {
    // webAuthnSignin(user:any): Promise<CredentialType> {
    const allowCredentials: PublicKeyCredentialDescriptor[] = user.credentials.map((c:any) => {
      return { type: 'public-key', id: Uint8Array.from(Object.values(c.credentialId)) };
    });
    const credentialRequestOptions: PublicKeyCredentialRequestOptions = {
      challenge: this.getChallenge(),
      allowCredentials,
    };

    return navigator.credentials.get({
      publicKey: credentialRequestOptions,
    });
  }

  getChallenge() {
    return new Uint16Array(8) // get this challenge from server
  }


  // ================== WebAuthnWorks FIDO2WebAuthnSeries BasicExample

  myRegister() {
    serverSample.startPasswordlessEnrolment({username: "werner"}).then((res:any)=> {
    });
  }

  myAuthenticate() {
    serverSample.startAuthenticationPasswordless({username:'werner'}).then((res:any) => {
    })
  }

}
