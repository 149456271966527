<div class="app-view">
    <app-splash-screen *ngIf="showSplash"></app-splash-screen>
    <!-- <app-splash-screen ></app-splash-screen> -->
    <router-outlet *ngIf="allPermitted"></router-outlet>

    <div *ngIf="!allPermitted" style="background-color: gray; width: 100vw; height: 100vh; position: fixed; z-index: 3011;">
        <p style="color: white; font-size: large;">Please Clear App Permissions and Allow all permissions and press 'Reload'</p>
        <p>To learn how to manage app permissions <a href="https://support.google.com/chrome/answer/114662?hl=en&co=GENIE.Platform%3DAndroid&oco=1" target="_blank">Click Here</a></p>

        <button mat-raised-button color="primary" (click)="reload()">Reload</button>
    </div>
</div>


